import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import CategoriesListContain from '../../../Component/Assets/AddAsset/CategoryList';

const ListAssetCategories = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Assets" title="Category List" />
      <CategoriesListContain />
    </Fragment>
  );
};
export default ListAssetCategories;