import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import CategoriesDetailsContain from '../../Component/AssetCategory/Categories/index';

const CategoriesDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="AssetCategory" title="Categories" />
      <CategoriesDetailsContain />
    </Fragment>
  );
};
export default CategoriesDetails;