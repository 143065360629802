import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AddEditSiteDetails from '../../../Component/Site/AddSite/AddEdit';

const addSite = () => {
  return (
    <Fragment>
    <Breadcrumbs parent="Sites" title="Manage Site" />
      <AddEditSiteDetails />
    </Fragment>
  );
};
export default addSite;