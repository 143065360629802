import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AddAssetTemplateContain from '../../../Component/Assets/AssetTemplate/AddAssetTemplate';

const AddAssetTemplate = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Assets" title="Manage Asset Template" />
      <AddAssetTemplateContain />
    </Fragment>
  );
};
export default AddAssetTemplate;