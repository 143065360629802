import React, { Fragment, useState, useEffect, useCallback } from "react";
import { H5, H4, H6, Btn, P, H2 } from "../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  CardBody,
  Form,
  Label,
} from "reactstrap";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import uuid from "react-native-uuid";
import moment from "moment";
import Pagination from "rc-pagination";
import cloneDeep from "lodash/cloneDeep";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import AWS from "aws-sdk";
import jsPDF from "jspdf";
import {
  S3_BUCKET,
  REGION,
  ASSET_OWNER_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
  USERPOOLID,
  limit,
} from "../../Config/Config";
import DatePicker from "react-datepicker";
import Select from "react-select";

Amplify.configure(awsExports);

const GenerateLogBookReports = ({ data }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [sites, setSites] = useState([]);
  const [distributionBox, setDistributionBox] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [assets, setAssets] = useState([]);
  const [siteId, setSiteId] = useState("");
  const [loading, setLoading] = useState(false);
  const [siteDetail, setSiteDetail] = useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [assetDemo, setAssetDemo] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [mc, setMc] = useState([]);
  const [ao, setAo] = useState([]);
  const [company, setCompany] = useState([]);
  const [selectedImg, setselectedImg] = useState(null);
  const [downloadedFile, setdownloadedFile] = useState(null);
  const [assetDemoRR, setAssetDemoRR] = useState([]);
  const [assetRR, setAssetRR] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const LoginUserGroup = localStorage.getItem("groups");
  const [asset, setAsset] = useState([]);
  const [demofilter, setFilter] = useState([]);
  const [assetVal, setAssetVal] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [warrantyDate, setWarrantyDate] = useState(null);
  //const [limit, setLimit] = useState(100000);

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  async function getCompanyByAssetId() {
    try {
      await API.graphql({
        query: queries.listAssetOwners,
        variables: {
          limit: limit,
          filter: {
            id: {
              eq: LoginUserDetails.id,
            },
          },
        },
        authMode: "API_KEY",
      })
        .then((result2) => {
          console.log(result2);
          if (result2.data.listAssetOwners.items.length > 0) {
            API.graphql({
              query: queries.listCompanyDetails,
              variables: {
                limit: limit,
                filter: {
                  id: {
                    eq: result2.data.listAssetOwners.items[0].companydetailsID,
                  },
                },
              },
              authMode: "API_KEY",
            })
              .then((result) => {
                console.log(result);
                setCompany(result.data.listCompanyDetails.items[0]);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    console.log(data);
    getSite(data.sites);
    getDistributionBox(data.distributionBox);
    console.log(data.sites);
    if (
      data.sites === "allSites" &&
      data.distributionBox === "allDistribution"
    ) {
      console.log("All site  and all distribution boards selected");
      console.log(LoginUserGroup);
      getCompanyByAssetId();
      if (LoginUserGroup === "site_user") {
        fetchSiteDetailsSU();
        fetchRRSU();
      } else if (LoginUserGroup === "electrician") {
        fetchSiteDetailsSU();
        fetchRRSU();
      } else {
        fetchListLogBookDetails();
        getAssetsRRDemo();
      }
    } else if (
      data.sites !== "allSites" &&
      data.distributionBox === "allDistribution"
    ) {
      console.log("single site  and all distribution boards selected");
      fetchListLogBookDetails1(data.sites, data.assetTemp, data.warranty);
      getAssetsRR(data.sites, data.assetTemp);
    } else {
      getAssets(data.sites, data.distributionBox);
      getAssetsRRSingleDBSite(data.sites, data.distributionBox);
    }
    //getAssets(data.sites, data.distributionBox);
    getUserdetails();
    //getAssetsRR(data.sites);
    //getAO();
    if (LoginUserGroup === "site_user") {
      fetchSUByAo();
    } else if (LoginUserGroup === "electrician") {
      fetchSUByAo();
    } else {
      getAO();
    }
    //fetchAssets(data.sites, data.distributionBox);
  }, []);

  async function fetchAssets(site, db) {
    console.log("Asset - sites", sites);
    if (data.sites !== "allSites") {
      await API.graphql({
        query: queries.listAssets,
        authMode: "API_KEY",
        variables: {
          limit: limit,
          filter: {
            siteId: {
              eq: site,
            },
          },
        },
      })
        .then((result) => {
          console.log(result);
          if (result.data.listAssets.items.length > 0) {
            let asset = result.data.listAssets.items.filter((item) => {
              const _matchingAssetTemplate = data.assetTemp?.find(
                (temp) => temp === item?.assettemplateID
              );
              if (_matchingAssetTemplate) {
                return item;
              }
            });
            let assetfilter = [];
            console.log(data.distributionBox);
            console.log(db);
            if (data.distributionBox === "allDistribution") {
              assetfilter = asset;
            } else {
              assetfilter = asset.filter(
                (item) => item.distributionboxID === db
              );
              console.log(assetfilter);
            }
            const sort = assetfilter.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            console.log(sort);
            const assetOptions = [{ value: null, label: "All Assets" }];
            sort.map(async (item) => {
              assetOptions.push({
                value: item.id,
                label: item.fittingNumber,
              });
            });

            setAsset(assetOptions);
          } else {
            setAsset([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setAsset([]);
        });
    } else {
      if (LoginUserGroup === "site_owner") {
        await API.graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: limit,
          },
          authMode: "API_KEY",
        })
          .then((sites) => {
            console.log("sites");
            console.log(sites);
            //setSites(sites.data.siteDetailsByAssetownerID.items);
            let siteIds = [];
            if (sites.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.data.siteDetailsByAssetownerID.items.map((item) => {
                siteIds.push(item.id);
              });
              console.log(siteIds);
              const promises = sites.data.siteDetailsByAssetownerID.items.map(
                async (siteee) => {
                  return API.graphql({
                    query: queries.assetsBySitedetailsID,
                    variables: {
                      sitedetailsID: siteee.id,
                      limit: limit,
                    },
                  });
                }
              );
              Promise.all(promises)
                .then((results) => {
                  const testReport1 = [];
                  let assetFilter = [];
                  results.forEach((result1) => {
                    const assetList = result1.data.assetsBySitedetailsID.items;
                    //console.log(assetList);
                    if (assetList.length > 0) {
                      console.log(assetList);
                      let asset = assetList.filter((item) => {
                        const _matchingAssetTemplate = data.assetTemp?.find(
                          (temp) => temp === item?.assettemplateID
                        );
                        if (_matchingAssetTemplate) {
                          return item;
                        }
                      });
                      testReport1.push(...asset);
                    }
                  });
                  console.log("final data");
                  console.log(testReport1);
                  const sorted = testReport1.sort(
                    (a, b) => a.fittingNumber - b.fittingNumber
                  );
                  console.log(sorted);
                  const assetOptions = [{ value: null, label: "All Assets" }];
                  sorted.map(async (item) => {
                    assetOptions.push({
                      value: item.id,
                      label: item.fittingNumber,
                    });
                  });
                  setAsset(assetOptions);
                })
                .catch((error) => {
                  console.log(error);
                  setErrorMessage("Unable to fetch the list");
                });
            } else {
              //setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            //setIsLoading(false);
          });
      } else {
        await API.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: limit,
          },
        })
          .then((dataDetails) => {
            const siteDetails =
              dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
            if (siteDetails.length > 0) {
              API.graphql({
                query: queries.listSiteDetails,
                variables: {
                  limit: limit,
                },
              })
                .then((result) => {
                  console.log(result);
                  const siteDetail = result.data.listSiteDetails.items.filter(
                    (item) => {
                      const _matchingAssetTemplate = siteDetails?.find(
                        (site) => site.sitedetailsID === item?.id
                      );
                      if (_matchingAssetTemplate) {
                        return item;
                      }
                    }
                  );
                  console.log(siteDetail);
                  let siteIds = [];
                  if (siteDetail.length > 0) {
                    siteDetail.map((item) => {
                      siteIds.push(item.id);
                    });
                    console.log(siteIds);
                    const promises = siteDetail.map(async (siteee) => {
                      return API.graphql({
                        query: queries.assetsBySitedetailsID,
                        variables: {
                          sitedetailsID: siteee.id,
                          filter: {
                            status: {
                              eq: "Active",
                            },
                          },
                          limit: limit,
                        },
                      });
                    });
                    Promise.all(promises)
                      .then((results) => {
                        const testReport1 = [];
                        let assetFilter = [];
                        results.forEach((result1) => {
                          const assetList =
                            result1.data.assetsBySitedetailsID.items;
                          console.log(assetList);
                          if (assetList.length > 0) {
                            console.log(assetList);
                            testReport1.push(...assetList);
                          }
                        });
                        console.log("final data");
                        console.log(testReport1);
                        const sort = testReport1.sort(
                          (a, b) => a.fittingNumber - b.fittingNumber
                        );
                        console.log(sort);
                        const assetOptions = [
                          { value: null, label: "All Assets" },
                        ];
                        sort.map(async (item) => {
                          assetOptions.push({
                            value: item.id,
                            label: item.fittingNumber,
                          });
                        });
                        setAsset(assetOptions);
                      })
                      .catch((error) => {
                        console.log(error);
                        setErrorMessage("Unable to fetch the list");
                      });
                  } else {
                    //setIsLoading(false);
                  }
                })
                .catch((error2) => {
                  console.log(error2);
                  //setIsLoading(false);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            //setIsLoading(false);
          });
      }
    }
  }

  async function getSite(siteId) {
    if (data.sites !== "allSites") {
      await API.graphql({
        query: queries.listSiteDetails,
        variables: {
          limit: limit,
          filter: {
            id: {
              eq: siteId,
            },
          },
        },
        authMode: "API_KEY",
      })
        .then((result) => {
          console.log(result);
          setSites(result.data.listSiteDetails.items[0]);
          const CompId = result.data.listSiteDetails.items[0].companydetailsID;
          console.log(CompId);
          API.graphql({
            query: queries.listCompanyDetails,
            variables: {
              limit: limit,
              filter: {
                id: {
                  eq: CompId,
                },
              },
            },
            authMode: "API_KEY",
          })
            .then((result2) => {
              console.log(result2);
              setCompany(result2.data.listCompanyDetails.items[0]);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          limit: limit,
          assetownerID: LoginUserDetails.id,
        },
        authMode: "API_KEY",
      })
        .then((result) => {
          console.log(result);
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            setSites(result.data.siteDetailsByAssetownerID.items.filter((item) => item.status === "Active"));
            const sites = result.data.siteDetailsByAssetownerID.items.filter((item) => item.status === "Active");
            const compID = [];
            sites.map((item) => {
              compID.push(item.companydetailsID);
            });
            API.graphql({
              query: queries.listCompanyDetails,
              variables: {
                limit: limit,
                filter: {
                  id: {
                    status: "Active",
                  },
                },
              },
              authMode: "API_KEY",
            })
              .then((result2) => {
                console.log(result2);
                if (result2.data.listCompanyDetails.items.length > 0) {
                  let company = result2.data.listCompanyDetails.items.filter(
                    (item) => {
                      const _matchingCompany = compID?.find(
                        (temp) => temp === item?.id
                      );
                      if (_matchingCompany) {
                        return item;
                      }
                    }
                  );
                  setCompany(company);
                } else {
                  setCompany([]);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setSites([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function getDistributionBox(dbId) {
    if (dbId != "allDistribution") {
      {
        /*
      await API.graphql({
        query: queries.getDistributionBox,
        authMode: "API_KEY",
        variables: {
          id: dbId,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
      })
        .then((result) => {
          console.log(result);
          console.log(result.data.getDistributionBox);
          setDistributionBox(result.data.getDistributionBox);
          setDistributionBoxfilter(result.data.getDistributionBox);
        })
        .catch((error) => {
          console.log(error);
        });
        */
      }
      //--
      let nextToken = "";
      let assets = [];
      await API.graphql({
        query: queries.listDistributionBoxes,
        authMode: "API_KEY",
        variables: {
          filter: {
            id: {
              eq: dbId,
            },
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
      })
        .then(async (result) => {
          let assetDatas = result.data.listDistributionBoxes.items;
          nextToken = result.data.listDistributionBoxes.nextToken;
          if (result.data.listDistributionBoxes.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.listDistributionBoxes,
              variables: {
                filter: {
                  id: {
                    eq: dbId,
                  },
                  status: {
                    eq: "Active",
                  },
                },
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.listDistributionBoxes.nextToken;

                if (results.data.listDistributionBoxes.items.length > 0) {
                  assets.push(results.data.listDistributionBoxes.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            setDistributionBox(mergeAssets);
            setDistributionBoxfilter(mergeAssets);
          } else {
            setDistributionBox([]);
            setDistributionBoxfilter([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //--
      let nextToken = "";
      let assets = [];
      await API.graphql({
        query: queries.listDistributionBoxes,
        authMode: "API_KEY",
        variables: {
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
      })
        .then(async (result) => {
          let assetDatas = result.data.listDistributionBoxes.items;
          nextToken = result.data.listDistributionBoxes.nextToken;
          if (result.data.listDistributionBoxes.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.listDistributionBoxes,
              variables: {
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.listDistributionBoxes.nextToken;

                if (results.data.listDistributionBoxes.items.length > 0) {
                  assets.push(results.data.listDistributionBoxes.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            setDistributionBox(mergeAssets);
            setDistributionBoxfilter(mergeAssets);
          } else {
            setDistributionBox([]);
            setDistributionBoxfilter([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      {
        /*
      await API.graphql({
        query: queries.listDistributionBoxes,
        authMode: "API_KEY",
        variables: {
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: 200000,
        },
      })
        .then((result) => {
          console.log(result);
          setDistributionBox(result.data.listDistributionBoxes.items);
          setDistributionBoxfilter(result.data.listDistributionBoxes.items);
        })
        .catch((error) => {
          console.log(error);
        });
      */
      }
    }
  }

  function getDB(id) {
    const db = distributionBox.find((item) => item.id === id);
    return db ? db.distributionBoxName : "";
  }

  React.useEffect(() => {
    if (siteId !== "") {
      setDistributionBoxfilter(
        distributionBox.filter((item) => {
          return item.sitedetailsID === siteId;
        })
      );
      setSiteDetail(
        sites.filter((item) => {
          return item.id === siteId;
        })
      );
    } else {
      setSiteDetail([]);
      setDistributionBoxfilter(distributionBox);
    }
  }, [siteId]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      console.log(data);
      getAssets(data.sites, data.distributionBox);

      const [newStartDate, newEndDate] = dateRange;
      // data.startDate = newStartDate;
      // data.endDate = newEndDate;
    } catch (e) {
      console.log(e);
    }
  };

  // maintenance  contractor
  async function getUserdetails() {
    //---
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.listUserDetails,
      variables: {
        limit: limit,
        filter: {
          id: {
            eq: LoginUserDetails.id,
          },
        },
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.listUserDetails.items;
        nextToken = result.data.listUserDetails.nextToken;
        if (result.data.listUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listUserDetails,
            variables: {
              limit: limit,
              filter: {
                id: {
                  eq: LoginUserDetails.id,
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.listUserDetails.nextToken;

              if (results.data.listUserDetails.items.length > 0) {
                assets.push(results.data.listUserDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setMc(mergeAssets[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    {
      /*
    await API.graphql({
      query: queries.getUserdetails,
      variables: {
        limit: 10000,
        filter: {
          id: {
            eq: LoginUserDetails.id,
          },
        },
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setMc(result.data.listUserDetails.items[0]);
      })
      .catch((error) => {
        console.log(error);
      });
    */
    }
  }

  // ----Single Site And Single Db
  async function getAssets(siteId, distriButionBox) {
    //---
    setLoading(true);
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.assetsBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        filter: {
          status: {
            ne: "delete",
          },
        },
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.assetsBySitedetailsID.items;
        nextToken = result.data.assetsBySitedetailsID.nextToken;
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.assetsBySitedetailsID,
            variables: {
              sitedetailsID: siteId,
              limit: limit,
              filter: {
                status: {
                  ne: "delete",
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.assetsBySitedetailsID.nextToken;
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets.push(results.data.assetsBySitedetailsID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const distri = mergeAssets.filter(
            (dd) => dd.distributionboxID === distriButionBox
          );
          console.log(distri);
          let assetFilter = [];
          let testReport = [];
          const assetLists = distri;
          if (assetLists.length > 0) {
            let testing = assetLists.filter((item) => {
              const _matchingAssetTemplate = data.assetTemp?.find(
                (temp) => temp === item?.assettemplateID
              );
              if (_matchingAssetTemplate) {
                return item;
              }
            });
            console.log(testing);
            const assetOptions = [{ value: null, label: "All Assets" }];
            const sortt = testing.filter((item) => item.status === "Active");
            const sortoption = sortt.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            sortoption.map(async (item) => {
              assetOptions.push({
                value: item.id,
                label: item.fittingNumber,
              });
            });
            setAsset(assetOptions);
            console.log(data.warranty);
            if (data.warranty !== "") {
              if (data.warranty === "InWarranty") {
                console.log("In Warranty");
                const today = new Date();
                assetFilter = testing.filter((item) => {
                  const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                  return assetWarrantyEndDate > today;
                });
              } else {
                console.log("Out Warranty");
                const today = new Date();
                assetFilter = testing.filter((item) => {
                  const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                  return assetWarrantyEndDate < today;
                });
              }
            } else {
              assetFilter = testing;
            }
            if (assetFilter.length > 0) {
              assetFilter.map((asset) => {
                let customFormatted = [];
                if (asset.assetType === "UPS Template") {
                  console.log(" ups template ");
                  customFormatted = "-";
                  console.log(customFormatted);
                } else {
                  if (
                    asset.formFieldValues === null ||
                    (Array.isArray(asset.formFieldValues) &&
                      asset.formFieldValues.length === 0)
                  ) {
                    customFormatted = "-";
                  } else {
                    try {
                      const custom = JSON.parse(asset.formFieldValues);
                      customFormatted =
                        custom &&
                        custom.map((item) => {
                          const key = Object.keys(item)[0];
                          const formattedValue = item[key];
                          return `${key} : ${formattedValue}`;
                        });
                      console.log(custom);
                    } catch (error) {
                      console.error("Error parsing JSON:", error);
                    }
                  }
                  console.log(customFormatted);
                }
                const customFieldsFormatted =
                  customFormatted === "-"
                    ? customFormatted
                    : customFormatted.join("\n");
                console.log(customFieldsFormatted);

                testReport.push({
                  id: asset.id,
                  createdAt: asset.createdAt,
                  asset: asset.fittingNumber,
                  location: asset.assetLocation,
                  distributionBox: asset.distributionboxID,
                  customFields: customFieldsFormatted,
                  status: asset.status,
                  //logbookdate: item.logbookDateTime,
                  warranty: asset.assetWarrantyEnd,
                  //remarks: item.remarks,
                });
              });
              const sort = testReport.sort((a, b) => a.asset - b.asset);
              console.log(sort);
              console.log(data.date);
              if (data.date !== "") {
                if (data.date === "Custom") {
                  const startDate = new Date(data.startDate);
                  const endDate = new Date(data.endDate);

                  const filteredData = sort.filter((item) => {
                    const createdAtDate = new Date(item.createdAt);
                    return (
                      createdAtDate >= startDate && createdAtDate <= endDate
                    );
                  });

                  console.log(filteredData);
                  setAssetDemo(filteredData);
                  setFilter(filteredData);
                  setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
                } else {
                  const today = new Date();
                  const thirtyDaysAgo = new Date();
                  thirtyDaysAgo.setDate(today.getDate() - data.date);

                  const filteredData = sort.filter((item) => {
                    const createdAtDate = new Date(item.createdAt);
                    return (
                      createdAtDate >= thirtyDaysAgo && createdAtDate <= today
                    );
                  });
                  console.log(filteredData);
                  setAssetDemo(filteredData);
                  setFilter(filteredData);
                  setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
                }
              } else {
                const today = new Date();
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(today.getDate() - 30);

                const filteredData = sort.filter((item) => {
                  const createdAtDate = new Date(item.createdAt);
                  return (
                    createdAtDate >= thirtyDaysAgo && createdAtDate <= today
                  );
                });
                console.log("filteredData", filteredData);
                setAssetDemo(filteredData);
                setFilter(filteredData);
                setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
              }
              console.log(sort);
              console.log(testReport);
              setLoading(false);
            } else {
              setAssetDemo(testReport);
              setFilter(testReport);
              setAssets(cloneDeep(testReport.slice(0, countPerPage)));
              setLoading(false);
            }
          } else {
            setAssetDemo(testReport);
            setAssets(cloneDeep(testReport.slice(0, countPerPage)));
            setLoading(false);
          }
        } else {
          setAssetDemo([]);
          setAssets(cloneDeep([].slice(0, countPerPage)));
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ----Single Site And All Distribution For Asset Owner ---
  async function fetchListLogBookDetails1(siteId, assettemp, warranty) {
    try {
      setLoading(true);
      let nextToken = "";
      let assets = [];
      let count = 0;
      for (let assetTempId of assettemp) {
        count = count + 1;
        let assetTempelateSiteId = assetTempId + "_" + siteId;
        const assetsData = await API.graphql({
          query: queries.assetsByAssettemplateSiteIDAndAssetNum,
          variables: {
            assettemplateSiteID: assetTempelateSiteId,
            limit: 1000,
          },
          authMode: "API_KEY",
        });
        console.log(assetsData);
        const data1 = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
        nextToken = data1.nextToken;
        if (data1.items.length > 0) {
          assets.push(data1.items);
        }
        while (nextToken !== null) {
          const assetsData = await API.graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: assetTempelateSiteId,
              limit: 1000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          console.log(assetsData);
          const data2 = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
          nextToken = data2.nextToken;
          if (data2.items.length > 0) {
            assets.push(data2.items);
          }
        }
        console.log(assets);
        if (count === assettemp.length) {
          let assetFilter = [];
          let testReport = [];
          const mergeAssets = assets.flat(1);
          const assetOptions = [{ value: null, label: "All Assets" }];
          const sortt = mergeAssets.filter((item) => item.status === "Active");
          const sortoption = sortt.sort(
            (a, b) => a.fittingNumber - b.fittingNumber
          );
          sortoption.map(async (item) => {
            assetOptions.push({
              value: item.id,
              label: item.fittingNumber,
            });
          });
          setAsset(assetOptions);
          console.log(warranty);
          if (warranty !== "") {
            if (warranty === "InWarranty") {
              console.log("In Warranty");
              const today = new Date();
              assetFilter = mergeAssets.filter((item) => {
                const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                return assetWarrantyEndDate > today;
              });
            } else {
              console.log("Out Warranty");
              const today = new Date();
              assetFilter = mergeAssets.filter((item) => {
                const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                return assetWarrantyEndDate < today;
              });
            }
          } else {
            assetFilter = mergeAssets;
          }
          console.log(assetFilter);

          if (assetFilter.length > 0) {
            for (let asset of assetFilter) {
              let customFormatted = [];
              if (asset.assetType === "UPS Template") {
                console.log(" ups template ");
                const custom = JSON.parse(asset.formFieldValues);
                const numberOfKeys = Object.keys(custom).length;
                customFormatted = "-";
                console.log(customFormatted);
              } else {
                if (
                  asset.formFieldValues === null ||
                  (Array.isArray(asset.formFieldValues) &&
                    asset.formFieldValues.length === 0)
                ) {
                  customFormatted = "-";
                } else {
                  try {
                    const custom = JSON.parse(asset.formFieldValues);
                    customFormatted =
                      custom &&
                      custom.map((item) => {
                        const key = Object.keys(item)[0];
                        const formattedValue = item[key];
                        return `${key} : ${formattedValue}`;
                      });
                    console.log(custom);
                  } catch (error) {
                    console.error("Error parsing JSON:", error);
                  }
                }
                console.log(customFormatted);
              }
              const customFieldsFormatted =
                customFormatted === "-"
                  ? customFormatted
                  : customFormatted.join("\n");
              console.log(customFieldsFormatted);

              testReport.push({
                id: asset.id,
                createdAt: asset.createdAt,
                asset: asset.fittingNumber,
                location: asset.assetLocation,
                distributionBox: asset.distributionboxID,
                customFields: customFieldsFormatted,
                status: asset.status,
                //logbookdate: item.logbookDateTime,
                warranty: asset.assetWarrantyEnd,
                //remarks: item.remarks,
              });
            }
            const sort = testReport.sort((a, b) => a.asset - b.asset);
            console.log("data.date", data.date);
            console.log(sort);
            if (data.date !== "" && data.date !== undefined) {
              if (data.date === "Custom") {
                const startDate = new Date(data.startDate);
                const endDate = new Date(data.endDate);

                const filteredData = sort.filter((item) => {
                  const createdAtDate = new Date(item.createdAt);
                  return createdAtDate >= startDate && createdAtDate <= endDate;
                });

                console.log(filteredData);
                setAssetDemo(filteredData);
                setFilter(filteredData);
                setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
              } else {
                const today = new Date();
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(today.getDate() - data.date);

                const filteredData = sort.filter((item) => {
                  const createdAtDate = new Date(item.createdAt);
                  return (
                    createdAtDate >= thirtyDaysAgo && createdAtDate <= today
                  );
                });
                console.log(filteredData);
                setAssetDemo(filteredData);
                setFilter(filteredData);
                setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
              }
            } else {
              const today = new Date();
              const thirtyDaysAgo = new Date();
              thirtyDaysAgo.setDate(today.getDate() - 30);

              const filteredData = sort.filter((item) => {
                const createdAtDate = new Date(item.createdAt);
                return createdAtDate >= thirtyDaysAgo && createdAtDate <= today;
              });
              console.log("filteredData", filteredData);
              setAssetDemo(filteredData);
              setFilter(filteredData);
              setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
            }
            setLoading(false);
          } else {
            console.log(testReport);
            setAssetDemo(testReport);
            setFilter(testReport);
            setAssets(cloneDeep(testReport.slice(0, countPerPage)));
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
      setAssetDemo([]);
      setAssets([]);
      setLoading(false);
    }
  }

  //---- All Sites  and All Distribution  for Asset Owner ----
  async function fetchListLogBookDetails() {
    setLoading(true);
    //---
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.siteDetailsByAssetownerID.nextToken;

              if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                assets.push(results.data.siteDetailsByAssetownerID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          if (mergeAssets.length > 0) {
            getAssetTemplate(mergeAssets);
          } else {
            //setSiteDetails([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //---
  const getAssetTemplate = async (siteData) => {
    await API.graphql({
      query: queries.assetTemplatesByCategoriesID,
      variables: {
        categoriesID: data.categoryId,
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result.data.assetTemplatesByCategoriesID.items);
        if (result.data.assetTemplatesByCategoriesID.items.length > 0) {
          getAssetsByTemplates(
            result.data.assetTemplatesByCategoriesID.items,
            siteData
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function getAssetsByTemplates(assetTemplates, siteData) {
    try {
      setLoading(true);
      let nextToken = "";
      let assets = [];
      let count = 0;
      const testReport = [];
      let counter = 0;
      assetTemplates.map((assetTemplate) => {
        count = count + 1;
        siteData.map(async (sites) => {
          let tempSiteId = assetTemplate.id + "_" + sites.id;
          await API.graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: tempSiteId,
              limit: limit,
              filter: {
                status: {
                  ne: "delete",
                },
              },
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              let assetDatas =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
              nextToken =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
              if (
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                  .length > 0
              ) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    limit: limit,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken =
                      results.data.assetsByAssettemplateSiteIDAndAssetNum
                        .nextToken;
                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      assets.push(
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setLoading(false);
                  });
              }
              console.log(assets);
            })
            .catch((error) => {
              console.log(error);
            });
          if (count === assetTemplates.length) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);

            //---
            let assetFilter = [];
            const assetOptions = [{ value: null, label: "All Assets" }];
            const sortt = mergeAssets.filter(
              (item) => item.status === "Active"
            );
            const sortoption = sortt.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            sortoption.map(async (item) => {
              assetOptions.push({
                value: item.id,
                label: item.fittingNumber,
              });
            });
            setAsset(assetOptions);

            console.log(data.warranty);
            if (data.warranty !== "") {
              if (data.warranty === "InWarranty") {
                console.log("In Warranty");
                const today = new Date();
                assetFilter = mergeAssets.filter((item) => {
                  const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                  return assetWarrantyEndDate > today;
                });
              } else {
                console.log("Out Warranty");
                const today = new Date();
                assetFilter = mergeAssets.filter((item) => {
                  const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                  return assetWarrantyEndDate < today;
                });
              }
            } else {
              assetFilter = mergeAssets;
            }

            if (assetFilter.length > 0) {
              console.log(assetFilter);
              assetFilter.map((asset) => {
                counter = counter + 1;
                let customFormatted = [];
                if (asset.assetType === "UPS Template") {
                  console.log(" ups template ");
                  const custom = JSON.parse(asset.formFieldValues);
                  const numberOfKeys = Object.keys(custom).length;
                  customFormatted = "-";
                  console.log(customFormatted);
                } else {
                  if (
                    asset.formFieldValues === null ||
                    (Array.isArray(asset.formFieldValues) &&
                      asset.formFieldValues.length === 0)
                  ) {
                    customFormatted = "-";
                  } else {
                    try {
                      const custom = JSON.parse(asset.formFieldValues);
                      customFormatted =
                        custom &&
                        custom.map((item) => {
                          const key = Object.keys(item)[0];
                          const formattedValue = item[key];
                          return `${key} : ${formattedValue}`;
                        });
                      console.log(custom);
                    } catch (error) {
                      console.error("Error parsing JSON:", error);
                    }
                  }
                  console.log(customFormatted);
                }
                const customFieldsFormatted =
                  customFormatted === "-"
                    ? customFormatted
                    : customFormatted.join("\n");
                console.log(customFieldsFormatted);
                console.log("outside  date loop");
                testReport.push({
                  id: asset.id,
                  createdAt: asset.createdAt,
                  asset: asset.fittingNumber,
                  location: asset.assetLocation,
                  distributionBox: asset.distributionboxID,
                  customFields: customFieldsFormatted,
                  status: asset.status,
                  //logbookdate: item.logbookDateTime,
                  warranty: asset.assetWarrantyEnd,
                  //remarks: item.remarks,
                });
                if (counter === testReport.length) {
                  const sort = testReport.sort((a, b) => a.asset - b.asset);
                  console.log(sort);
                  const uniqueCombinedArray = Array.from(
                    new Map(sort.map((item) => [item.id, item])).values()
                  );
                  console.log(data.dateRange);
                  if (data.date !== "") {
                    if (data.date === "Custom") {
                      const startDate = new Date(data.startDate);
                      const endDate = new Date(data.endDate);

                      const filteredData = uniqueCombinedArray.filter(
                        (item) => {
                          const createdAtDate = new Date(item.createdAt);
                          return (
                            createdAtDate >= startDate &&
                            createdAtDate <= endDate
                          );
                        }
                      );

                      console.log(filteredData);
                      setAssetDemo(filteredData);
                      setFilter(filteredData);
                      setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
                    } else {
                      const today = new Date();
                      const thirtyDaysAgo = new Date();
                      thirtyDaysAgo.setDate(today.getDate() - data.date);

                      const filteredData = uniqueCombinedArray.filter(
                        (item) => {
                          const createdAtDate = new Date(item.createdAt);
                          return (
                            createdAtDate >= thirtyDaysAgo &&
                            createdAtDate <= today
                          );
                        }
                      );
                      console.log(filteredData);
                      setAssetDemo(filteredData);
                      setFilter(filteredData);
                      setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
                    }
                  } else {
                    const today = new Date();
                    const thirtyDaysAgo = new Date();
                    thirtyDaysAgo.setDate(today.getDate() - 30);

                    const filteredData = uniqueCombinedArray.filter((item) => {
                      const createdAtDate = new Date(item.createdAt);
                      return (
                        createdAtDate >= thirtyDaysAgo && createdAtDate <= today
                      );
                    });
                    console.log(filteredData);
                    setAssetDemo(filteredData);
                    setFilter(filteredData);
                    setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
                  }
                  setLoading(false);
                }
              });
            } else {
              setFilter(testReport);
              setAssetDemo(testReport);
              setAssets(cloneDeep(testReport.slice(0, countPerPage)));
              setLoading(false);
            }
          } else {
            if (counter === testReport.length) {
              const sort = testReport.sort(
                (a, b) => b.fittingNumber - a.fittingNumber
              );
              console.log(sort);
              setFilter(sort);
              setAssetDemo(sort);
              setAssets(cloneDeep(sort.slice(0, countPerPage)));
              setLoading(false);
            }
          }
        });
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  //---SU
  const getAssetTemplateSU = async (siteData) => {
    await API.graphql({
      query: queries.assetTemplatesByCategoriesID,
      variables: {
        categoriesID: data.categoryId,
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result.data.assetTemplatesByCategoriesID.items);
        if (result.data.assetTemplatesByCategoriesID.items.length > 0) {
          getAssetsByTemplatesSU(
            result.data.assetTemplatesByCategoriesID.items,
            siteData
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function getAssetsByTemplatesSU(assetTemplates, siteData) {
    try {
      setLoading(true);
      let nextToken = "";
      let assets = [];
      let count = 0;
      const testReport = [];
      let counter = 0;
      assetTemplates.map((assetTemplate) => {
        count = count + 1;
        siteData.map(async (sites) => {
          let tempSiteId = assetTemplate.id + "_" + sites.sitedetailsID;
          await API.graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: tempSiteId,
              limit: limit,
              filter: {
                status: {
                  ne: "delete",
                },
              },
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              let assetDatas =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
              nextToken =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;

              if (
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                  .length > 0
              ) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    limit: limit,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken =
                      results.data.assetsByAssettemplateSiteIDAndAssetNum
                        .nextToken;

                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      assets.push(
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setLoading(false);
                  });
              }
              console.log(assets);
            })
            .catch((error) => {
              console.log(error);
            });
          if (count === assetTemplates.length) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);

            //---
            let assetFilter = [];
            const assetOptions = [{ value: null, label: "All Assets" }];
            const sortt = mergeAssets.filter(
              (item) => item.status === "Active"
            );
            const sortoption = sortt.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            sortoption.map(async (item) => {
              assetOptions.push({
                value: item.id,
                label: item.fittingNumber,
              });
            });
            setAsset(assetOptions);

            console.log(data.warranty);
            if (data.warranty !== "") {
              if (data.warranty === "InWarranty") {
                console.log("In Warranty");
                const today = new Date();
                assetFilter = mergeAssets.filter((item) => {
                  const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                  return assetWarrantyEndDate > today;
                });
              } else {
                console.log("Out Warranty");
                const today = new Date();
                assetFilter = mergeAssets.filter((item) => {
                  const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                  return assetWarrantyEndDate < today;
                });
              }
            } else {
              assetFilter = mergeAssets;
            }

            if (assetFilter.length > 0) {
              console.log(assetFilter);

              assetFilter.map((asset) => {
                counter = counter + 1;
                let customFormatted = [];
                if (asset.assetType === "UPS Template") {
                  console.log(" ups template ");
                  const custom = JSON.parse(asset.formFieldValues);
                  const numberOfKeys = Object.keys(custom).length;
                  customFormatted = "-";
                  console.log(customFormatted);
                } else {
                  if (
                    asset.formFieldValues === null ||
                    (Array.isArray(asset.formFieldValues) &&
                      asset.formFieldValues.length === 0)
                  ) {
                    customFormatted = "-";
                  } else {
                    try {
                      const custom = JSON.parse(asset.formFieldValues);
                      customFormatted =
                        custom &&
                        custom.map((item) => {
                          const key = Object.keys(item)[0];
                          const formattedValue = item[key];
                          return `${key} : ${formattedValue}`;
                        });
                      console.log(custom);
                    } catch (error) {
                      console.error("Error parsing JSON:", error);
                    }
                  }
                  console.log(customFormatted);
                }
                const customFieldsFormatted =
                  customFormatted === "-"
                    ? customFormatted
                    : customFormatted.join("\n");
                console.log(customFieldsFormatted);

                console.log("outside  date loop");
                testReport.push({
                  id: asset.id,
                  createdAt: asset.createdAt,
                  asset: asset.fittingNumber,
                  location: asset.assetLocation,
                  distributionBox: asset.distributionboxID,
                  customFields: customFieldsFormatted,
                  status: asset.status,
                  //logbookdate: item.logbookDateTime,
                  warranty: asset.assetWarrantyEnd,
                  //remarks: item.remarks,
                });
                if (counter === testReport.length) {
                  const sort = testReport.sort((a, b) => a.asset - b.asset);
                  // const data = sort.sort((a, b) => {
                  //   const extractDigits = (assetNumber) =>
                  //     parseInt(assetNumber.match(/\d+/)[0]);
                  //   const assetNumberA = extractDigits(a.asset);
                  //   const assetNumberB = extractDigits(b.asset);
                  //   return assetNumberB - assetNumberA;
                  // });
                  console.log(sort);
                  const uniqueCombinedArray = Array.from(
                    new Map(sort.map((item) => [item.id, item])).values()
                  );
                  {
                    /*
                  setFilter(uniqueCombinedArray);
                  setAssetDemo(uniqueCombinedArray);
                  setAssets(cloneDeep(uniqueCombinedArray.slice(0, countPerPage)));
                  */
                  }
                  console.log(data.dateRange);
                  if (data.date !== "") {
                    if (data.date === "Custom") {
                      const startDate = new Date(data.startDate);
                      const endDate = new Date(data.endDate);

                      const filteredData = uniqueCombinedArray.filter(
                        (item) => {
                          const createdAtDate = new Date(item.createdAt);
                          return (
                            createdAtDate >= startDate &&
                            createdAtDate <= endDate
                          );
                        }
                      );

                      console.log(filteredData);
                      setAssetDemo(filteredData);
                      setFilter(filteredData);
                      setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
                    } else {
                      const today = new Date();
                      const thirtyDaysAgo = new Date();
                      thirtyDaysAgo.setDate(today.getDate() - data.date);

                      const filteredData = uniqueCombinedArray.filter(
                        (item) => {
                          const createdAtDate = new Date(item.createdAt);
                          return (
                            createdAtDate >= thirtyDaysAgo &&
                            createdAtDate <= today
                          );
                        }
                      );
                      console.log(filteredData);
                      setAssetDemo(filteredData);
                      setFilter(filteredData);
                      setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
                    }
                  } else {
                    const today = new Date();
                    const thirtyDaysAgo = new Date();
                    thirtyDaysAgo.setDate(today.getDate() - 30);

                    const filteredData = uniqueCombinedArray.filter((item) => {
                      const createdAtDate = new Date(item.createdAt);
                      return (
                        createdAtDate >= thirtyDaysAgo && createdAtDate <= today
                      );
                    });
                    console.log(filteredData);
                    setAssetDemo(filteredData);
                    setFilter(filteredData);
                    setAssets(cloneDeep(filteredData.slice(0, countPerPage)));
                  }
                  setLoading(false);
                }
              });
            } else {
              setFilter(testReport);
              setAssetDemo(testReport);
              setAssets(cloneDeep(testReport.slice(0, countPerPage)));
              setLoading(false);
            }
          } else {
            if (counter === testReport.length) {
              const sort = testReport.sort(
                (a, b) => b.fittingNumber - a.fittingNumber
              );
              // const data = sort.sort((a, b) => {
              //   const extractDigits = (assetNumber) =>
              //     parseInt(assetNumber.match(/\d+/)[0]);
              //   const assetNumberA = extractDigits(a.asset);
              //   const assetNumberB = extractDigits(b.asset);
              //   return assetNumberB - assetNumberA;
              // });
              console.log(sort);
              setFilter(sort);
              setAssetDemo(sort);
              setAssets(cloneDeep(sort.slice(0, countPerPage)));
              setLoading(false);
            }
          }
        });
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  //-------- All Sites and All Distribution For Site User -----
  async function fetchSiteDetailsSU() {
    ///--
    setLoading(true);
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: limit,
      },
    })
      .then(async (result) => {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;

        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: LoginUserDetails.id,
              limit: limit,
              nextToken: nextToken,
            },
          })
            .then((results) => {
              nextToken =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;

              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesByUserdetailsID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          getAssetTemplateSU(mergeAssets);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Repair/Replace table for single site and all distribution
  async function getAssetsRR(siteId, assettemp) {
    //---
    let nextToken = "";
    let assets = [];
    let count = 0;
    for (let assetTempId of assettemp) {
      count = count + 1;
      let assetTempelateSiteId = assetTempId + "_" + siteId;
      const assetsData = await API.graphql({
        query: queries.assetsByAssettemplateSiteIDAndAssetNum,
        variables: {
          assettemplateSiteID: assetTempelateSiteId,
          limit: 1000,
        },
        authMode: "API_KEY",
      });
      console.log(assetsData);
      const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
      nextToken = data.nextToken;
      if (data.items.length > 0) {
        assets.push(data.items);
      }
      while (nextToken !== null) {
        const assetsData = await API.graphql({
          query: queries.assetsByAssettemplateSiteIDAndAssetNum,
          variables: {
            assettemplateSiteID: assetTempelateSiteId,
            limit: 1000,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        console.log(assetsData);
        const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
        nextToken = data.nextToken;
        if (data.items.length > 0) {
          assets.push(data.items);
        }
      }
      if (count === assettemp.length) {
        let testReport = [];
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        console.log(mergeAssets);
        if (mergeAssets.length > 0) {
          let nextToken1 = "";
          mergeAssets.map(async (item) => {
            let assets1 = [];
            await API.graphql({
              query: queries.repairsByAssetsID,
              variables: {
                limit: limit,
                assetsID: item.id,
              },
              authMode: "API_KEY",
            })
              .then(async (result) => {
                let assetDatas = result.data.repairsByAssetsID.items;
                nextToken1 = result.data.repairsByAssetsID.nextToken;
                if (result.data.repairsByAssetsID.items.length > 0) {
                  assets1.push(assetDatas);
                }
                while (nextToken1 !== null) {
                  await API.graphql({
                    query: queries.repairsByAssetsID,
                    variables: {
                      limit: limit,
                      assetsID: item.id,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                    .then((results) => {
                      nextToken1 = results.data.repairsByAssetsID.nextToken;
                      if (results.data.repairsByAssetsID.items.length > 0) {
                        assets1.push(results.data.repairsByAssetsID.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets1);
                if (assets1.length > 0) {
                  const mergeAssets1 = assets1.flat(1);
                  console.log(mergeAssets1);
                  //-

                  const testing = mergeAssets1;
                  console.log(testing);
                  if (testing.length > 0) {
                    testing.map((asset) => {
                      testReport.push({
                        id: asset.id,
                        asset: item.fittingNumber,
                        assetsID: item.id,
                        RepairDate: asset.repairDate,
                        RepairTime: asset.repairTime,
                        replaced: asset.details,
                        location: item.assetLocation,
                        distributionBox: item.distributionboxID,
                        customFields: asset.customFields,
                        logbookdate: asset.logbookDateTime,
                        warranty: asset.warrantyUpto,
                        remarks: asset.remarks,
                        additionalInfo: asset.additionalInformation,
                      });
                    });
                    setAssetDemoRR(testReport);
                    setAssetRR(testReport);
                    console.log(testReport);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      }
    }
    {
      /*    await API.graphql({
      query: queries.assetsBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        limit: limit,
        filter: {
          status: {
            ne: "delete",
          },
        },
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.assetsBySitedetailsID.items;
        nextToken = result.data.assetsBySitedetailsID.nextToken;
        console.log(
          "first token count",
          result.data.assetsBySitedetailsID.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.assetsBySitedetailsID,
            variables: {
              sitedetailsID: siteId,
              limit: limit,
              filter: {
                status: {
                  ne: "delete",
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.assetsBySitedetailsID.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.assetsBySitedetailsID.items.length);
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets.push(results.data.assetsBySitedetailsID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          const asset = mergeAssets;
          let testReport1 = asset.filter((it) => {
            const _matchingAssetTemplate = data.assetTemp?.find(
              (temp) => temp === it?.assettemplateID
            );
            if (_matchingAssetTemplate) {
              return it;
            }
          });
          console.log(testReport1);
          const testReport = [];
          if (testReport1.length > 0) {
            let nextToken1 = "";
            testReport1.map(async (item) => {
              let assets1 = [];
              await API.graphql({
                query: queries.listRepairs,
                variables: {
                  limit: limit,
                  filter: {
                    assetsID: {
                      eq: item.id,
                    },
                  },
                },
                authMode: "API_KEY",
              })
                .then(async (result) => {
                  let assetDatas = result.data.listRepairs.items;
                  nextToken1 = result.data.listRepairs.nextToken;
                  console.log(
                    "first token count",
                    result.data.listRepairs.items.length
                  );
                  console.log("nextToken 1", nextToken1);
                  if (result.data.listRepairs.items.length > 0) {
                    assets1.push(assetDatas);
                  }
                  while (nextToken1 !== null) {
                    await API.graphql({
                      query: queries.listRepairs,
                      variables: {
                        limit: limit,
                        filter: {
                          assetsID: {
                            eq: item.id,
                          },
                        },
                        nextToken: nextToken1,
                      },
                      authMode: "API_KEY",
                    })
                      .then((results) => {
                        nextToken1 = results.data.listRepairs.nextToken;
                        console.log("nextToken", nextToken1);
                        console.log(results.data.listRepairs.items.length);
                        if (results.data.listRepairs.items.length > 0) {
                          assets1.push(results.data.listRepairs.items);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  console.log(assets1);
                  if (assets1.length > 0) {
                    const mergeAssets1 = assets1.flat(1);
                    console.log(mergeAssets1);
                    //-

                    const testing = mergeAssets1;
                    console.log(testing);
                    if (testing.length > 0) {
                      testing.map((asset) => {
                        testReport.push({
                          id: asset.id,
                          asset: item.fittingNumber,
                          assetsID: item.id,
                          RepairDate: asset.repairDate,
                          RepairTime: asset.repairTime,
                          replaced: asset.details,
                          location: item.assetLocation,
                          distributionBox: item.distributionboxID,
                          customFields: asset.customFields,
                          logbookdate: asset.logbookDateTime,
                          warranty: asset.warrantyUpto,
                          remarks: asset.remarks,
                          additionalInfo: asset.additionalInformation,
                        });
                      });
                      setAssetDemoRR(testReport);
                      setAssetRR(testReport);
                      console.log(testReport);
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
   
    await API.graphql({
      query: queries.assetsBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        limit: 20000,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        const asset = result.data.assetsBySitedetailsID.items;
        let testReport1 = asset.filter((it) => {
          const _matchingAssetTemplate = data.assetTemp?.find(
            (temp) => temp === it?.assettemplateID
          );
          if (_matchingAssetTemplate) {
            return it;
          }
        });
        console.log(testReport1)
        const testReport = [];
        if (testReport1.length > 0) {
          testReport1.map((item) => {
            API.graphql({
              query: queries.listRepairs,
              variables: {
                limit: 20000,
                filter: {
                  assetsID: {
                    eq: item.id,
                  },
                },
              },
            })
              .then((testData) => {
                const testing = testData.data.listRepairs.items;
                console.log(testing);
                if (testing.length > 0) {
                  testing.map((asset) => {
                    testReport.push({
                      id: asset.id,
                      asset: item.fittingNumber,
                      assetsID: item.id,
                      RepairDate: asset.repairDate,
                      RepairTime: asset.repairTime,
                      replaced: asset.details,
                      location: item.assetLocation,
                      distributionBox: item.distributionboxID,
                      customFields: asset.customFields,
                      logbookdate: asset.logbookDateTime,
                      warranty: asset.warrantyUpto,
                      remarks: asset.remarks,
                      additionalInfo: asset.additionalInformation,
                    });
                  });
                  setAssetDemoRR(testReport);
                  setAssetRR(testReport);
                  console.log(testReport);
                  setLoading(false);
                }
              })
              .then((error) => {
                console.log(error);
              });
          });
        } else {
          //setAssetDemo(testReport);
          //setAssets(cloneDeep(testReport.slice(0, countPerPage)));
          setLoading(false);
        }
      })
      .catch((error) => {
        setAssetDemoRR([]);
        setAssetRR([]);
        //setAssets([]);
        console.log(error);
      });
    */
    }
  }

  //---Repair/replace for single site and single distribution board
  async function getAssetsRRSingleDBSite(siteId, dbid) {
    //--
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.assetsBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        limit: limit,
        filter: {
          status: {
            ne: "delete",
          },
        },
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.assetsBySitedetailsID.items;
        nextToken = result.data.assetsBySitedetailsID.nextToken;
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.assetsBySitedetailsID,
            variables: {
              sitedetailsID: siteId,
              limit: limit,
              filter: {
                status: {
                  ne: "delete",
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.assetsBySitedetailsID.nextToken;

              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets.push(results.data.assetsBySitedetailsID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          const asset = mergeAssets;
          const assetlist = asset.filter(
            (item) => item.distributionboxID === dbid
          );
          let testReport1 = assetlist.filter((it) => {
            const _matchingAssetTemplate = data.assetTemp?.find(
              (temp) => temp === it?.assettemplateID
            );
            if (_matchingAssetTemplate) {
              return it;
            }
          });
          console.log(testReport1);
          const testReport = [];
          if (testReport1.length > 0) {
            let nextToken1 = "";
            testReport1.map(async (item) => {
              let assets1 = [];
              await API.graphql({
                query: queries.listRepairs,
                variables: {
                  limit: limit,
                  filter: {
                    assetsID: {
                      eq: item.id,
                    },
                  },
                },
                authMode: "API_KEY",
              })
                .then(async (result) => {
                  let assetDatas = result.data.listRepairs.items;
                  nextToken1 = result.data.listRepairs.nextToken;
                  console.log(
                    "first token count",
                    result.data.listRepairs.items.length
                  );
                  console.log("nextToken 1", nextToken1);
                  if (result.data.listRepairs.items.length > 0) {
                    assets1.push(assetDatas);
                  }
                  while (nextToken1 !== null) {
                    await API.graphql({
                      query: queries.listRepairs,
                      variables: {
                        limit: limit,
                        filter: {
                          assetsID: {
                            eq: item.id,
                          },
                        },
                        nextToken: nextToken1,
                      },
                      authMode: "API_KEY",
                    })
                      .then((results) => {
                        nextToken1 = results.data.listRepairs.nextToken;

                        if (results.data.listRepairs.items.length > 0) {
                          assets1.push(results.data.listRepairs.items);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  console.log(assets1);
                  if (assets1.length > 0) {
                    const mergeAssets1 = assets1.flat(1);
                    console.log(mergeAssets1);
                    //-

                    const testing = mergeAssets1;
                    console.log(testing);
                    if (testing.length > 0) {
                      testing.map((asset) => {
                        testReport.push({
                          id: asset.id,
                          asset: item.fittingNumber,
                          assetsID: item.id,
                          RepairDate: asset.repairDate,
                          RepairTime: asset.repairTime,
                          replaced: asset.details,
                          location: item.assetLocation,
                          distributionBox: item.distributionboxID,
                          customFields: asset.customFields,
                          logbookdate: asset.logbookDateTime,
                          warranty: asset.warrantyUpto,
                          remarks: asset.remarks,
                          additionalInfo: asset.additionalInformation,
                        });
                      });
                      setAssetDemoRR(testReport);
                      setAssetRR(testReport);
                      console.log(testReport);
                      //setLoading(false);
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    {
      /*
    await API.graphql({
      query: queries.assetsBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        limit: 20000,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        const asset = result.data.assetsBySitedetailsID.items;
        const assetlist = asset.filter(item => item.distributionboxID === dbid)
        let testReport1 = assetlist.filter((it) => {
          const _matchingAssetTemplate = data.assetTemp?.find(
            (temp) => temp === it?.assettemplateID
          );
          if (_matchingAssetTemplate) {
            return it;
          }
        });
        console.log(testReport1)
        const testReport = [];
        if (testReport1.length > 0) {
          testReport1.map((item) => {
            API.graphql({
              query: queries.listRepairs,
              variables: {
                limit: 20000,
                filter: {
                  assetsID: {
                    eq: item.id,
                  },
                },
              },
            })
              .then((testData) => {
                const testing = testData.data.listRepairs.items;
                console.log(testing);
                if (testing.length > 0) {
                  testing.map((asset) => {
                    testReport.push({
                      id: asset.id,
                      asset: item.fittingNumber,
                      assetsID: item.id,
                      RepairDate: asset.repairDate,
                      RepairTime: asset.repairTime,
                      replaced: asset.details,
                      location: item.assetLocation,
                      distributionBox: item.distributionboxID,
                      customFields: asset.customFields,
                      logbookdate: asset.logbookDateTime,
                      warranty: asset.warrantyUpto,
                      remarks: asset.remarks,
                      additionalInfo: asset.additionalInformation,
                    });
                  });
                  setAssetDemoRR(testReport);
                  setAssetRR(testReport);
                  console.log(testReport);
                  setLoading(false);
                }
              })
              .then((error) => {
                console.log(error);
              });
          });
        } else {
          //setAssetDemo(testReport);
          //setAssets(cloneDeep(testReport.slice(0, countPerPage)));
          setLoading(false);
        }
      })
      .catch((error) => {
        setAssetDemoRR([]);
        setAssetRR([]);
        //setAssets([]);
        console.log(error);
      });
    */
    }
  }

  // Repair and replace for all site and all distribution for ao
  async function getAssetsRRDemo() {
    //setLoading(true);
    //--
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.siteDetailsByAssetownerID.nextToken;

              if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                assets.push(results.data.siteDetailsByAssetownerID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          let nextToken1 = "";
          let assets1 = [];
          mergeAssets.map(async (item) => {
            await API.graphql({
              query: queries.assetsBySitedetailsID,
              variables: {
                sitedetailsID: item.id,
                filter: {
                  status: {
                    ne: "delete",
                  },
                },
                limit: limit,
              },
              authMode: "API_KEY",
            })
              .then(async (result) => {
                let assetDatas = result.data.assetsBySitedetailsID.items;
                nextToken1 = result.data.assetsBySitedetailsID.nextToken;
                console.log(
                  "first token count",
                  result.data.assetsBySitedetailsID.items.length
                );
                console.log("nextToken 1", nextToken1);
                if (result.data.assetsBySitedetailsID.items.length > 0) {
                  assets1.push(assetDatas);
                }
                while (nextToken1 !== null) {
                  await API.graphql({
                    query: queries.assetsBySitedetailsID,
                    variables: {
                      sitedetailsID: item.id,
                      filter: {
                        status: {
                          ne: "delete",
                        },
                      },
                      limit: limit,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                    .then((results) => {
                      nextToken1 = results.data.assetsBySitedetailsID.nextToken;

                      if (results.data.assetsBySitedetailsID.items.length > 0) {
                        assets1.push(results.data.assetsBySitedetailsID.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets1);
                if (assets1.length > 0) {
                  const mergeAssets1 = assets1.flat(1);
                  console.log(mergeAssets1);
                  //--
                  let testReport2 = mergeAssets1.filter((it) => {
                    const _matchingAssetTemplate = data.assetTemp?.find(
                      (temp) => temp === it?.assettemplateID
                    );
                    if (_matchingAssetTemplate) {
                      return it;
                    }
                  });
                  console.log(testReport2);
                  let testReport = [];
                  //---
                  if (testReport2.length > 0) {
                    let nextToken2 = "";
                    testReport2.map(async (item) => {
                      let assets2 = [];
                      await API.graphql({
                        query: queries.listRepairs,
                        variables: {
                          limit: limit,
                          filter: {
                            assetsID: {
                              eq: item.id,
                            },
                          },
                        },
                        authMode: "API_KEY",
                      })
                        .then(async (result) => {
                          let assetDatas = result.data.listRepairs.items;
                          nextToken2 = result.data.listRepairs.nextToken;
                          console.log(
                            "first token count",
                            result.data.listRepairs.items.length
                          );
                          console.log("nextToken 1", nextToken2);
                          if (result.data.listRepairs.items.length > 0) {
                            assets2.push(assetDatas);
                          }
                          while (nextToken2 !== null) {
                            await API.graphql({
                              query: queries.listRepairs,
                              variables: {
                                limit: limit,
                                filter: {
                                  assetsID: {
                                    eq: item.id,
                                  },
                                },
                                nextToken: nextToken2,
                              },
                              authMode: "API_KEY",
                            })
                              .then((results) => {
                                nextToken2 = results.data.listRepairs.nextToken;

                                if (results.data.listRepairs.items.length > 0) {
                                  assets2.push(results.data.listRepairs.items);
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          }
                          console.log(assets2);
                          if (assets2.length > 0) {
                            const mergeAssets2 = assets2.flat(1);
                            console.log(mergeAssets2);
                            //-

                            const testing = mergeAssets2;
                            console.log(testing);
                            if (testing.length > 0) {
                              testing.map((asset) => {
                                testReport.push({
                                  id: asset.id,
                                  asset: item.fittingNumber,
                                  assetsID: item.id,
                                  RepairDate: asset.repairDate,
                                  RepairTime: asset.repairTime,
                                  replaced: asset.details,
                                  location: item.assetLocation,
                                  distributionBox: item.distributionboxID,
                                  customFields: asset.customFields,
                                  logbookdate: asset.logbookDateTime,
                                  warranty: asset.warrantyUpto,
                                  remarks: asset.remarks,
                                  additionalInfo: asset.additionalInformation,
                                });
                              });
                              setAssetDemoRR(testReport);
                              setAssetRR(testReport);
                              console.log(testReport);
                              //setLoading(false);
                            }
                          } else {
                            setAssetDemoRR([]);
                            setAssetRR([]);
                            console.log([]);
                            //setLoading(false);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          setAssetDemoRR([]);
                          setAssetRR([]);
                          console.log([]);
                          //setLoading(false);
                        });
                    });
                  } else {
                    setAssetDemoRR([]);
                    setAssetRR([]);
                    console.log([]);
                    //setLoading(false);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                setAssetDemoRR([]);
                setAssetRR([]);
                console.log([]);
                //setLoading(false);
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAssetDemoRR([]);
        setAssetRR([]);
        console.log([]);
        //setLoading(false);
      });
    {
      /*
    await API.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then((sites) => {
        console.log(sites);
        let siteIds = [];
        if (sites.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.data.siteDetailsByAssetownerID.items.map((item) => {
            siteIds.push(item.id);
          });
          const promises = sites.data.siteDetailsByAssetownerID.items.map(
            async (siteee) => {
              return API.graphql({
                query: queries.assetsBySitedetailsID,
                variables: {
                  sitedetailsID: siteee.id,
                  filter: {
                    status: {
                      eq: "Active",
                    },
                  },
                  limit: 2000000,
                },
              });
            }
          );
          Promise.all(promises)
            .then((results) => {
              const testReport1 = [];
              let testReport = [];
              results.forEach((result1) => {
                const assetList = result1.data.assetsBySitedetailsID.items;
                //console.log(assetList);
                if (assetList.length > 0) {
                  console.log(assetList);
                  testReport1.push(...assetList);
                }
              });
              console.log("final data");
              console.log(testReport1);
              let testReport2 = testReport1.filter((it) => {
                const _matchingAssetTemplate = data.assetTemp?.find(
                  (temp) => temp === it?.assettemplateID
                );
                if (_matchingAssetTemplate) {
                  return it;
                }
              });
              console.log(testReport2)
              //---
              if (testReport2.length > 0) {
                testReport2.map((item) => {
                  API.graphql({
                    query: queries.listRepairs,
                    variables: {
                      limit: 2000000,
                      filter: {
                        assetsID: {
                          eq: item.id,
                        },
                      },
                    },
                  })
                    .then((testData) => {
                      const testing = testData.data.listRepairs.items;
                      console.log(testing);
                      if (testing.length > 0) {
                        testing.map((asset) => {
                          testReport.push({
                            id: asset.id,
                            asset: item.fittingNumber,
                            assetsID: item.id,
                            RepairDate: asset.repairDate,
                            RepairTime: asset.repairTime,
                            replaced: asset.details,
                            location: item.assetLocation,
                            distributionBox: item.distributionboxID,
                            customFields: asset.customFields,
                            logbookdate: asset.logbookDateTime,
                            warranty: asset.warrantyUpto,
                            remarks: asset.remarks,
                            additionalInfo: asset.additionalInformation,
                          });
                        });
                        setAssetDemoRR(testReport);
                        setAssetRR(testReport);
                        console.log(testReport);
                        setLoading(false);
                      }
                    })
                    .then((error) => {
                      console.log(error);
                    });
                });
              } else {
                setAssetDemoRR([]);
                setAssetRR([]);
                setLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setErrorMessage("Unable to fetch the list");
            });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    */
    }
  }

  // Repair and replace for all site and all distribution for site user

  async function fetchRRSU() {
    //-

    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: limit,
      },
    })
      .then(async (result) => {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: LoginUserDetails.id,
              limit: limit,
              nextToken: nextToken,
            },
          })
            .then((results) => {
              nextToken =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;

              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesByUserdetailsID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          let nextToken1 = "";
          let assets1 = [];
          mergeAssets.map(async (item) => {
            await API.graphql({
              query: queries.assetsBySitedetailsID,
              variables: {
                sitedetailsID: item.sitedetailsID,
                filter: {
                  status: {
                    ne: "delete",
                  },
                },
                limit: limit,
              },
              authMode: "API_KEY",
            })
              .then(async (result) => {
                let assetDatas = result.data.assetsBySitedetailsID.items;
                nextToken1 = result.data.assetsBySitedetailsID.nextToken;
                console.log(
                  "first token count",
                  result.data.assetsBySitedetailsID.items.length
                );
                console.log("nextToken 1", nextToken1);
                if (result.data.assetsBySitedetailsID.items.length > 0) {
                  assets1.push(assetDatas);
                }
                while (nextToken1 !== null) {
                  await API.graphql({
                    query: queries.assetsBySitedetailsID,
                    variables: {
                      sitedetailsID: item.sitedetailsID,
                      limit: limit,
                      filter: {
                        status: {
                          ne: "delete",
                        },
                      },
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                    .then((results) => {
                      nextToken1 = results.data.assetsBySitedetailsID.nextToken;

                      if (results.data.assetsBySitedetailsID.items.length > 0) {
                        assets1.push(results.data.assetsBySitedetailsID.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets1);
                if (assets1.length > 0) {
                  const mergeAssets1 = assets1.flat(1);
                  console.log(mergeAssets1);
                  //--
                  let testReport2 = mergeAssets1.filter((it) => {
                    const _matchingAssetTemplate = data.assetTemp?.find(
                      (temp) => temp === it?.assettemplateID
                    );
                    if (_matchingAssetTemplate) {
                      return it;
                    }
                  });
                  console.log(testReport2);
                  let testReport = [];
                  //---
                  if (testReport2.length > 0) {
                    let nextToken2 = "";
                    testReport2.map(async (item) => {
                      let assets2 = [];
                      await API.graphql({
                        query: queries.listRepairs,
                        variables: {
                          limit: limit,
                          filter: {
                            assetsID: {
                              eq: item.id,
                            },
                          },
                        },
                        authMode: "API_KEY",
                      })
                        .then(async (result) => {
                          let assetDatas = result.data.listRepairs.items;
                          nextToken2 = result.data.listRepairs.nextToken;
                          console.log(
                            "first token count",
                            result.data.listRepairs.items.length
                          );
                          console.log("nextToken 1", nextToken2);
                          if (result.data.listRepairs.items.length > 0) {
                            assets2.push(assetDatas);
                          }
                          while (nextToken2 !== null) {
                            await API.graphql({
                              query: queries.listRepairs,
                              variables: {
                                limit: limit,
                                filter: {
                                  assetsID: {
                                    eq: item.id,
                                  },
                                },
                                nextToken: nextToken2,
                              },
                              authMode: "API_KEY",
                            })
                              .then((results) => {
                                nextToken2 = results.data.listRepairs.nextToken;

                                if (results.data.listRepairs.items.length > 0) {
                                  assets2.push(results.data.listRepairs.items);
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          }
                          console.log(assets2);
                          if (assets2.length > 0) {
                            const mergeAssets2 = assets2.flat(1);
                            console.log(mergeAssets2);
                            //-

                            const testing = mergeAssets2;
                            console.log(testing);
                            if (testing.length > 0) {
                              testing.map((asset) => {
                                testReport.push({
                                  id: asset.id,
                                  asset: item.fittingNumber,
                                  assetsID: item.id,
                                  RepairDate: asset.repairDate,
                                  RepairTime: asset.repairTime,
                                  replaced: asset.details,
                                  location: item.assetLocation,
                                  distributionBox: item.distributionboxID,
                                  customFields: asset.customFields,
                                  logbookdate: asset.logbookDateTime,
                                  warranty: asset.warrantyUpto,
                                  remarks: asset.remarks,
                                  additionalInfo: asset.additionalInformation,
                                });
                              });
                              setAssetDemoRR(testReport);
                              setAssetRR(testReport);
                              console.log(testReport);
                              //setLoading(false);
                            }
                          } else {
                            setAssetDemoRR([]);
                            setAssetRR([]);
                            console.log([]);
                            //setLoading(false);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          setAssetDemoRR([]);
                          setAssetRR([]);
                          console.log([]);
                          //setLoading(false);
                        });
                    });
                  } else {
                    setAssetDemoRR([]);
                    setAssetRR([]);
                    console.log([]);
                    //setLoading(false);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                setAssetDemoRR([]);
                setAssetRR([]);
                console.log([]);
                //setLoading(false);
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setAssetDemoRR([]);
        setAssetRR([]);
        console.log([]);
        //setLoading(false);
      });

    {
      /*
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: 2000000,
      },
    })
      .then((dataDetails) => {
        const siteDetails =
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
        if (siteDetails.length > 0) {
          API.graphql({
            query: queries.listSiteDetails,
            variables: {
              limit: 2000000,
            },
          })
            .then((result) => {
              console.log(result);
              const siteDetail = result.data.listSiteDetails.items.filter(
                (item) => {
                  const _matchingAssetTemplate = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                }
              );
              console.log();
              let siteIds = [];
              if (siteDetail.length > 0) {
                siteDetail.map((item) => {
                  siteIds.push(item.id);
                });
                const promises = siteDetail.map(
                  async (siteee) => {
                    return API.graphql({
                      query: queries.assetsBySitedetailsID,
                      variables: {
                        sitedetailsID: siteee.id,
                        filter: {
                          status: {
                            eq: "Active",
                          },
                        },
                        limit: 2000000,
                      },
                    });
                  }
                );
                Promise.all(promises)
                  .then((results) => {
                    const testReport1 = [];
                    let testReport = [];
                    results.forEach((result1) => {
                      const assetList = result1.data.assetsBySitedetailsID.items;
                      //console.log(assetList);
                      if (assetList.length > 0) {
                        testReport1.push(...assetList);
                      }
                    });
                    console.log("final data");
                    console.log(testReport1);
                    let testReport2 = testReport1.filter((it) => {
                      const _matchingAssetTemplate = data.assetTemp?.find(
                        (temp) => temp === it?.assettemplateID
                      );
                      if (_matchingAssetTemplate) {
                        return it;
                      }
                    });
                    console.log(testReport2)
                    //--
                    if (testReport2.length > 0) {
                      testReport2.map((item) => {
                        API.graphql({
                          query: queries.listRepairs,
                          variables: {
                            limit: 2000000,
                            filter: {
                              assetsID: {
                                eq: item.id,
                              },
                            },
                          },
                        })
                          .then((testData) => {
                            const testing = testData.data.listRepairs.items;
                            console.log(testing);
                            if (testing.length > 0) {
                              testing.map((asset) => {
                                testReport.push({
                                  id: asset.id,
                                  asset: item.fittingNumber,
                                  assetsID: item.id,
                                  RepairDate: asset.repairDate,
                                  RepairTime: asset.repairTime,
                                  replaced: asset.details,
                                  location: item.assetLocation,
                                  distributionBox: item.distributionboxID,
                                  customFields: asset.customFields,
                                  logbookdate: asset.logbookDateTime,
                                  warranty: asset.warrantyUpto,
                                  remarks: asset.remarks,
                                  additionalInfo: asset.additionalInformation,
                                });
                              });
                              setAssetDemoRR(testReport);
                              setAssetRR(testReport);
                              console.log(testReport);
                              setLoading(false);
                            }
                          })
                          .then((error) => {
                            console.log(error);
                          });
                      });
                    } else {
                      setAssetDemoRR([]);
                      setAssetRR([]);
                      setLoading(false);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setErrorMessage("Unable to fetch the list");
                  });
              } else {
                setAssetDemoRR([]);
                setAssetRR([]);
                setLoading(false);
              }
            })
            .catch((error2) => {
              console.log(error2);
            });
        } else {
          setAssetDemoRR([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    */
    }
  }

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setAssets(cloneDeep(assetDemo.slice(from, to)));
  };

  // ----site user invited by ao
  async function fetchSUByAo() {
    await API.graphql({
      query: queries.listUserDetails,
      variables: {
        limit: limit,
        filter: {
          id: {
            eq: LoginUserDetails.id,
          },
        },
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        const aoid = result.data.listUserDetails.items[0].assetOwnerId;
        console.log(aoid);
        API.graphql({
          query: queries.listAssetOwners,
          variables: {
            limit: limit,
            filter: {
              id: {
                eq: aoid,
              },
            },
          },
          authMode: "API_KEY",
        })
          .then((result2) => {
            console.log(result2);
            setAo(result2.data.listAssetOwners.items[0]);
            getfilename(result2.data.listAssetOwners.items[0].profilePicture);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // AO details
  async function getAO() {
    await API.graphql({
      query: queries.listAssetOwners,
      variables: {
        limit: limit,
        filter: {
          id: {
            eq: LoginUserDetails.id,
          },
        },
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setAo(result.data.listAssetOwners.items[0]);
        getfilename(result.data.listAssetOwners.items[0].profilePicture);
        console.log(result.data.listAssetOwners.items[0].profilePicture);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getImage = (filename) => {
    try {
      var params = {
        Bucket: S3_BUCKET,
        Key: `public/${ASSET_OWNER_FOLDER}/${filename}`,
      };
      var promise = myBucket.getSignedUrlPromise("getObject", params);
      promise.then(
        function (url) {
          console.log(url);
          setdownloadedFile(url);
          setselectedImg(url);
        },
        function (err) {
          console.log(err);
        }
      );
    } catch (error) {
      console.log("Error while fetching:", error);
    }
  };
  //-------------------------------------------------------------------------
  function getfilename(path) {
    try {
      const files = JSON.parse(path);
      if (files.length > 0) {
        var newfilename = files[0].fileName;
        getImage(newfilename);
      }
    } catch (error) {
      console.log("Error parsing JSON input:", error);
    }
  }

  //----pdf---
  const generatePDF = () => {
    try {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3, or A4
      const orientation = "portrait"; // portrait or landscape

      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        doc.getStringUnitWidth("Logbook") * doc.internal.getFontSize();
      const textOffset = (pageWidth - textWidth) / 2;

      const headingY = 20;
      doc.text("Logbook", textOffset, headingY);

      const imageY = headingY + 10;

      if (downloadedFile != null) {
        try {
          doc.addImage(downloadedFile, "JPEG", textOffset + 10, imageY, 50, 50);
        } catch (error) {
          console.error("Error while adding image to document:", error);
        }
      }

      doc.setFontSize(7);
      const textOffsetSite = 40;
      const siteDetailsHeadingY = imageY + 100;
      doc.text("Site Details", textOffsetSite, siteDetailsHeadingY);

      const unitNumber = sites && sites.unitNumber ? sites.unitNumber : "-";

      const siteInfoTableData = [
        ["Site Name", sites?.siteName || "All Sites"],
        ["Site Address", unitNumber],
        [
          "Distribution Board",
          data.distributionBox === "allDistribution"
            ? "All Distribution"
            : distributionBox.map((item) => item.distributionBoxName),
        ],
        ["Maintenance Contractor", mc?.username || "N/A"],
      ];

      const siteInfoTableStartY = siteDetailsHeadingY + 10;

      const columnWidthsSite = [200, 315.28];

      const siteInfoTableConfig = {
        startY: siteInfoTableStartY,
        headStyles: {
          fillColor: [0, 0, 0],
          textColor: [255, 255, 255],
        },
        bodyStyles: { textColor: [0, 0, 0] },
        margin: { top: 20 },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.5,
        drawHeaderRow: (row, data) => {
          doc.setDrawColor(0, 0, 0);
          doc.setLineWidth(0.5);
          doc.line(
            data.settings.margin.left,
            row.y,
            data.settings.margin.right,
            row.y
          );
        },
        styles: { fontSize: 7 },
      };

      doc.autoTable({
        body: siteInfoTableData,
        startY: siteInfoTableStartY,
        ...siteInfoTableConfig,
        columnStyles: {
          0: { cellWidth: columnWidthsSite[0], fillColor: [220, 220, 220] },
          1: { cellWidth: columnWidthsSite[1] },
        },
      });

      const textOffsetAO = 40;
      const AOHeadingY = siteDetailsHeadingY + 120;
      doc.text("Asset Owner", textOffsetAO, AOHeadingY);

      const aof = ao && ao.firstName ? ao.firstName : "N/A";
      const aol = ao && ao.lastName ? ao.lastName : "N/A";
      const companyName =
        company && company.companyName ? company.companyName : "N/A";
      const companyBN =
        company && company.companyBN ? company.companyBN : "N/A";
      const companyAddress =
        company && company.companyAddress ? company.companyAddress : "N/A";

      const AOInfoTableData = [
        ["Name", aof + " " + aol],
        ["Company Name", companyName],
        ["Company BN#", companyBN],
        ["Company Address", companyAddress],
      ];

      const AOInfoTableStartY = AOHeadingY + 10;
      const AOInfoTableConfig = {
        startY: AOInfoTableStartY,
        headStyles: {
          fillColor: [0, 0, 0],
          textColor: [255, 255, 255],
        },
        bodyStyles: { textColor: [0, 0, 0] },
        margin: { top: 20 },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.5,
        drawHeaderRow: (row, data) => {
          doc.setDrawColor(0, 0, 0);
          doc.setLineWidth(0.5);
          doc.line(
            data.settings.margin.left,
            row.y,
            data.settings.margin.right,
            row.y
          );
        },
        styles: { fontSize: 7 },
      };

      const columnWidths = [200, 315.28];

      doc.autoTable({
        body: AOInfoTableData,
        startY: AOInfoTableStartY,
        ...AOInfoTableConfig,
        columnStyles: {
          0: { cellWidth: columnWidths[0], fillColor: [220, 220, 220] },
          1: { cellWidth: columnWidths[1] },
        },
      });

      // logbook report heading
      const textOffsetlogbook = 40;
      const LogbookReportHeadingY = AOInfoTableStartY + 120;
      doc.text("Logbook Report", textOffsetlogbook, LogbookReportHeadingY);

      const tableData = [];

      const tableHeader = [
        "Fitting Number",
        "Location",
        "Distribution Board",
        "Warranty Upto",
        "Custom Fields",
      ];
      tableData.push(tableHeader);

      assetDemo.forEach((item) => {
        const rowData = [
          item.asset,
          item.location,
          getDB(item.distributionBox),
          moment(item.warranty).format("DD/MM/YYYY"),
          item.customFields,
        ];
        tableData.push(rowData);
      });

      const tableStartY = LogbookReportHeadingY + 10;
      const tableConfig = {
        startY: tableStartY,
        headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
        margin: { top: 20 },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.5,
        drawHeaderRow: (row, data) => {
          doc.setDrawColor(0, 0, 0);
          doc.setLineWidth(0.5);

          const headerHeight = row.height + data.settings.margin.top;
          doc.rect(
            data.settings.margin.left,
            row.y,
            data.table.width,
            headerHeight
          );
          doc.line(
            data.settings.margin.left,
            row.y + headerHeight,
            data.settings.margin.right,
            row.y + headerHeight
          );
        },
        styles: { fontSize: 7 },
      };

      doc.autoTable({
        head: [tableHeader],
        body: tableData.slice(1),
        startY: tableStartY,
        ...tableConfig,
      });

      // rapair replacement heading
      const textOffsetRR = 40;
      const RRHeadingY = tableStartY + tableData.length * 20 + 60;
      doc.text("Repair/Replacement Details", textOffsetRR, RRHeadingY);

      const tableDataRR = [];

      const tableHeaderRR = [
        "Fitting Number",
        "Warranrt Upto",
        "Repair Date & Time",
        "Replaced Date",
        "Replaced",
        "Additional Information",
      ];
      tableDataRR.push(tableHeaderRR);

      assetDemoRR.forEach((item) => {
        const repairDateTime =
          moment(item.RepairDate).format("DD/MM/YYYY") + " " + item.RepairTime;
        const rowData = [
          item.asset,
          item.warranty,
          repairDateTime,
          "",
          item.replaced,
          item.additionalInfo,
        ];
        tableDataRR.push(rowData);
      });

      const tableStartYRR = RRHeadingY + 10;

      const tableConfigRR = {
        startY: tableStartYRR,
        headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
        margin: { top: 20 },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.5,
        drawHeaderRow: (row, data) => {
          doc.setDrawColor(0, 0, 0);
          doc.setLineWidth(0.5);
          doc.line(
            data.settings.margin.left,
            row.y,
            data.settings.margin.right,
            row.y
          );
        },
        styles: { fontSize: 7 },
      };

      doc.autoTable({
        head: [tableHeaderRR],
        body: tableDataRR.slice(1),
        startY: tableStartYRR,
        ...tableConfigRR,
      });

      doc.save("LogbookReport.pdf");
    } catch (error) {
      console.log(error);
    }
  };

  const filterAssets = (assetValue) => {
    console.log(assetValue);
    try {
      setAssetVal(assetValue);
      if (assetValue !== null && assetValue.value !== null) {
        var filterAssets = demofilter.filter((item) => {
          return item.id === assetValue.value;
        });
        if (dateRange[0] !== null) {
          const [newStartDate, newEndDate] = dateRange;
          var filterAssets1 = filterAssets.filter((item) => {
            return (
              moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(newStartDate).format("DD/MM/YYYY") &&
              moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(newEndDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets1.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));

          //--RR filter
          var filterAssetsRR = assetRR.filter((item) => {
            return item.assetsID === assetValue.value;
          });
          console.log(filterAssetsRR);
          setAssetDemoRR(filterAssetsRR);
        } else if (warrantyDate !== null) {
          var filterAssets = demofilter.filter((item) => {
            return (
              moment(item.warranty).format("DD/MM/YYYY") ===
              moment(warrantyDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
          //--
          var filterAssetsRR = assetRR.filter((item) => {
            return item.assetsID === assetValue.value;
          });
          console.log(filterAssetsRR);
          setAssetDemoRR(filterAssetsRR);
        } else {
          const sort = filterAssets.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
          //--
          var filterAssetsRR = assetRR.filter((item) => {
            return item.assetsID === assetValue.value;
          });
          console.log(filterAssetsRR);
          setAssetDemoRR(filterAssetsRR);
        }
      } else {
        if (dateRange[0] !== null) {
          const [newStartDate, newEndDate] = dateRange;
          var filterAssets = demofilter.filter((item) => {
            return (
              moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(newStartDate).format("DD/MM/YYYY") &&
              moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(newEndDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else if (warrantyDate !== null) {
          var filterAssets = demofilter.filter((item) => {
            return (
              moment(item.warranty).format("DD/MM/YYYY") ===
              moment(warrantyDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          setAssetDemo(demofilter);
          setAssets(cloneDeep(demofilter.slice(0, countPerPage)));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterDateRage = (value, value1) => {
    try {
      if (value !== null && value1 !== null) {
        var filterAssets = demofilter.filter((item) => {
          return (
            moment(item.createdAt).format("DD/MM/YYYY") >=
              moment(value).format("DD/MM/YYYY") &&
            moment(item.createdAt).format("DD/MM/YYYY") <=
              moment(value1).format("DD/MM/YYYY")
          );
        });
        if (assetVal !== null && assetVal !== "" && assetVal.value !== null) {
          var filterAssets1 = filterAssets.filter((item) => {
            return item.id === assetVal.value;
          });
          const sort = filterAssets1.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else if (warrantyDate !== null) {
          var filterAssets = demofilter.filter((item) => {
            return (
              moment(item.warranty).format("DD/MM/YYYY") ===
              moment(warrantyDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          const sort = filterAssets.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        }
      } else {
        if (assetVal !== null && assetVal !== "" && assetVal.value !== null) {
          var filterAssets = demofilter.filter((item) => {
            return item.id === assetVal.value;
          });
          const sort = filterAssets.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else if (warrantyDate !== null) {
          var filterAssets = demofilter.filter((item) => {
            return (
              moment(item.warranty).format("DD/MM/YYYY") ===
              moment(warrantyDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          setAssetDemo(demofilter);
          setAssets(cloneDeep(demofilter.slice(0, countPerPage)));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterWarrantyDate = (value) => {
    try {
      if (startDate !== null && endDate !== null) {
        var filterAssets1 = [];
        var filterAssets = demofilter.filter((item) => {
          return (
            moment(item.createdAt).format("DD/MM/YYYY") >=
              moment(startDate).format("DD/MM/YYYY") &&
            moment(item.createdAt).format("DD/MM/YYYY") <=
              moment(endDate).format("DD/MM/YYYY")
          );
        });
        if (assetVal !== null && assetVal !== "" && assetVal.value !== null) {
          filterAssets1 = filterAssets.filter((item) => {
            return item.id === assetVal.value;
          });
          const sort = filterAssets1.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        }
        if (
          value !== null &&
          assetVal !== null &&
          assetVal !== "" &&
          assetVal.value !== null
        ) {
          var filterAssets2 = filterAssets1.filter((item) => {
            return (
              moment(item.warranty).format("DD/MM/YYYY") ===
              moment(value).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets2.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else if (value !== null) {
          var filterAssets2 = filterAssets.filter((item) => {
            return (
              moment(item.warranty).format("DD/MM/YYYY") ===
              moment(value).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets2.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          setAssetDemo(demofilter);
          setAssets(cloneDeep(demofilter.slice(0, countPerPage)));
        }
      } else if (
        assetVal !== null &&
        assetVal !== "" &&
        assetVal.value !== null &&
        value !== null
      ) {
        var filterAssets = [];
        if (assetVal !== null && assetVal !== "" && assetVal.value !== null) {
          filterAssets = demofilter.filter((item) => {
            return item.id === assetVal.value;
          });
          const sort = filterAssets.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          console.log(filterAssets);
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        }

        if (value !== null) {
          console.log(filterAssets);
          var filterAssets1 = filterAssets.filter((item) => {
            return (
              moment(item.warranty).format("DD/MM/YYYY") ===
              moment(value).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets1.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          setAssetDemo(sort);
          setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else if (
          value === null &&
          assetVal === null &&
          assetVal === "" &&
          assetVal.value === null
        ) {
          setAssetDemo(demofilter);
          setAssets(cloneDeep(demofilter.slice(0, countPerPage)));
        }
      } else if (value === null) {
        console.log("cleared");
        setAssetDemo(demofilter);
        setAssets(cloneDeep(demofilter.slice(0, countPerPage)));
      } else {
        console.log(demofilter);
        var filterAssets1 = demofilter.filter((item) => {
          return (
            moment(item.warranty).format("DD/MM/YYYY") ===
            moment(value).format("DD/MM/YYYY")
          );
        });
        const sort = filterAssets1.sort(
          (a, b) => b.fittingNumber - a.fittingNumber
        );
        setAssetDemo(sort);
        setAssets(cloneDeep(sort.slice(0, countPerPage)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  //-----------------------------------------Display------------------------------------------
  const renderList = (
    <>
      {" "}
      <Row>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <Row>
              <Col md="6">
                <H2>Logbook Report</H2>
              </Col>
              {assets.length > 0 &&
                userPermission.includes("Download Reports") && (
                  <Col md="6" className="text-end">
                    <Btn attrBtn={{ color: "primary", onClick: generatePDF }}>
                      Download PDF
                    </Btn>
                  </Col>
                )}
            </Row>
            <Row>
              <H4>Filters</H4>
              <Col md="4">
                <Select
                  value={assetVal}
                  onChange={filterAssets}
                  options={asset}
                  isSearchable={true}
                  hideSelectedOptions={false}
                  placeholder="Choose Asset"
                  isClearable={true}
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </Col>
              <Col md="4">
                <DatePicker
                  className="form-control"
                  selectsRange={false}
                  selected={warrantyDate}
                  onChange={(d) => {
                    console.log(d);
                    setWarrantyDate(d);
                    filterWarrantyDate(d);
                  }}
                  isClearable={true}
                  placeholderText="Warranty Upto"
                  dateFormat="dd-MM-yyyy"
                />
              </Col>
              <Col md="4">
                <DatePicker
                  className="form-control"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(d) => {
                    setDateRange(d);
                    const [newStartDate, newEndDate] = d;
                    filterDateRage(newStartDate, newEndDate);
                  }}
                  isClearable={true}
                  placeholderText="Choose Date Range"
                  dateFormat="dd-MM-yyyy"
                />
              </Col>
            </Row>
            <Row>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>Fitting No.</th>
                          <th>Location</th>
                          <th>Distribution Board</th>
                          <th>Asset Warranty Upto</th>
                          <th>Custom Fields</th>
                          <th>Asset Status</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {assetDemo.map((item) => (
                          <tr key={item.id}>
                            <td>{item.asset}</td>
                            <td>{item.location}</td>
                            <td>{getDB(item.distributionBox)}</td>
                            <td>
                              {item.warranty
                                ? moment(item.warranty).format("DD/MM/YYYY")
                                : "-"}
                            </td>
                            <td className="custom-fields-cell">
                              {item.customFields}
                            </td>
                            <td>{item.status}</td>
                          </tr>
                        ))}
                        {assetDemo.length === 0 && (
                          <tr>
                            <td className="text-center" colSpan="12">
                              No Reports Found for the selected Site and
                              Distribution Board
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </div>
            </Row>
            <CardFooter className="row">
              <Pagination
                className="p-t-10"
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={assetDemo.length}
                showTotal={(total, range) =>
                  `${range[0]} - ${range[1]} of ${total} items`
                }
              />
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
  return (
    <div>
      {loading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};
export default GenerateLogBookReports;
