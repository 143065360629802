import React, { Fragment, useState, useEffect, useCallback } from "react";
import { H5, H4, H6, P } from "../../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Media,
  Label,
} from "reactstrap";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Btn } from "../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import { toast } from "react-toastify";
import uuid from "react-native-uuid";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { Link, useNavigate, useLocation } from "react-router-dom";

const AddEditLogBookDetails = () => {
  const state = useLocation();
  const navigate = useNavigate();
  const [testingId, setTestingId] = useState("");
  const [assetId, setAssetId] = useState("");
  const [formDetails, setFormDetails] = useState([]);
  const [electrician, setElectrician] = useState([]);
  const [distributionBox, setDistributionBox] = useState([]);
  // const { register, handleSubmit, reset, formState: { errors }, control, e, ...form } = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    e,
    ...form
  } = useForm();
  const [listofTesting, setlistofTesting] = useState();

  const [testOn, settestOn] = useState(new Date());
  const [nextTestSchedule, setnextTestSchedule] = useState(new Date());

  const [testResult, setTestResult] = useState(false);
  const [testResultToggle, setTestResultToggle] = useState("");
  const [checkedCalendar, setcheckedCalendar] = useState(false);
  const [emailNofication, setemailNofication] = useState(false);

  //----------------------------List-------------------------------------------------------------------

  useEffect(async () => {
    if (state.state.id) {
      setTestingId(state.state.id);
      async function listTestingDetails() {
        const dataDetails = await API.graphql({
          query: queries.listTestings,
          variables: {
            filter: {
              id: {
                eq: state.state.id,
              },
            },
          },
          authMode: "API_KEY",
        });
        const TestingDetails = dataDetails.data.listTestings.items;
        console.log("----direct useEffect--data-testing");
        console.log(TestingDetails);
        setlistofTesting({ ...TestingDetails });
        reset({ ...TestingDetails });
      }
      listTestingDetails();
    }
    setAssetId(state.state.assetid);
    async function listAssetDetails() {
      await API.graphql({
        query: queries.listAssets,
        variables: {
          filter: {
            id: {
              eq: assetId,
            },
          },
        },
        authMode: "API_KEY",
      })
        .then((result) => {
          const assetDetails = result.data.listAssets.items;
          console.log(assetDetails);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    listAssetDetails();
    console.log(state.state.fields);
  }, [state.state, reset]);

  useEffect(async () => {
    console.log(state);
    getElectrician();
    getDistributionBox();
    setAssetId(state.state.assetid);
    setFormDetails(JSON.parse(state.state.fields));
    async function listAssetDetails() {
      await API.graphql({
        query: queries.listAssets,
        variables: {
          filter: {
            id: {
              eq: assetId,
            },
          },
        },
        authMode: "API_KEY",
      })
        .then((result) => {
          const assetDetails = result.data.listAssets.items;
          console.log(assetDetails);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    listAssetDetails();
  }, []);

  const getOptions = (dataModel, label) => {
    if (label === "Technician") {
      electrician.map((item) => {
        return (
          <option value={item.id} key={item.id}>
            {item.fname} {item.lname}
          </option>
        );
      });
    }
  };

  const formInputs = Object.keys(formDetails).map((e) => {
    try {
      const { rules, label, type, options, onSelectFile } = formDetails[e];
      const { required, message } = rules;
      if (e !== "subForm") {
        return (
          <Col md="6 mb-3" key={e}>
            <label>{label}</label>
            {type === "text" && (
              <input
                placeholder={label}
                className="form-control"
                type={type}
                name={e}
                {...register(e)}
              />
            )}
            {type === "date" && (
              <input
                placeholder={label}
                className="form-control"
                type={type}
                name={e}
                {...register(e)}
              />
            )}
            {type === "dropdown" && (
              <select className="form-control" name={e} {...register(e)}>
                <option value="">Choose data...</option>
                {typeof options === "string" &&
                  electrician.map((tech) => (
                    <option value={tech.id} key={tech.id}>
                      {tech.fname} {tech.lname}
                    </option>
                  ))}
                {typeof options !== "string" &&
                  options.map((item) => <option value={item}>{item}</option>)}
              </select>
            )}
            {type === "autocomplete" && (
              <GooglePlacesAutocomplete
                apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
                className="form-control"
                onPlaceSelected={(place) => {
                  const address = place.formatted_address;
                  console.log(address);
                  //setCompanyAdress(address);
                }}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "AU" },
                }}
                placeholder={label}
                name={e}
                {...register(e)}
              />
            )}
            {type === "radio" &&
              options.map((item) => (
                <Label className="d-block" for={item}>
                  <input
                    className="radio_animated"
                    id={item}
                    type="radio"
                    name={e}
                    {...register(e)}
                  />
                  {item}
                </Label>
              ))}
            {type === "checkbox" &&
              options.map((item) => (
                <div className="checkbox">
                  <input id={item} type="checkbox" name={e} {...register(e)} />
                  <Label htmlFor={item}>{item}</Label>
                </div>
              ))}
            {type === "file" && (
              <input
                type="file"
                className="form-control"
                name={e}
                {...register(e)}
                onChange={onSelectFile}
              />
            )}
            {type === "textarea" && (
              <input
                placeholder={label}
                className="form-control"
                type={type}
                rows="3"
                name={e}
                {...register(e)}
              />
            )}
            {type === "toggle" && (
              <Media body className="icon-state switch-lg">
                <Label className="switch">
                  <input
                    type="checkbox"
                    name={e}
                    {...register(e)}
                    onChange={(e) => chooseTestResult(e)}
                    defaultChecked={testResult}
                  />
                  <span
                    className={
                      testResult
                        ? "switch-state bg-success"
                        : "switch-state bg-danger"
                    }
                  ></span>
                </Label>
              </Media>
            )}
            <span>{errors.e && { message }}</span>
          </Col>
        );
      } else {
        return "";
      }
    } catch (e) {
      console.log(e);
    }
  });

  const chooseTestResult = (event) => {
    setTestResult(event.target.checked);
    console.log(event.target.checked);
  };

  async function getElectrician() {
    await API.graphql({
      query: queries.listSPUserDetails,
      variables: {
        filter: {
          userGroup: {
            eq: "electrician",
          },
        },
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setElectrician(result.data.listSPUserDetails.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getDistributionBox() {
    await API.graphql({
      query: queries.listDistributionBoxes,
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setDistributionBox(result.data.listDistributionBoxes.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //----------------------------add--edit------------------------------------

  async function onSubmit(data) {
    if (testingId) {
      console.log("in updating testing" + testingId);
      try {
        const dataTesting = API.graphql({
          query: mutations.updateTesting,
          variables: {
            input: {
              id: testingId,
              distributionBox: data.distributionBox,
              fittingId: data.fittingId,
              electrician: data.electrician,
              testResult: data.testResult,
              warranty: data.warranty,
              testOn: testOn,
              nextTestSchedule: nextTestSchedule,
              testReport: data.testReport,
              emailNofication: data.emailNofication,
              calendar: data.calendar,
              remindar: data.remindar,
            },
          },
          authMode: "API_KEY",
        }).then((response) => console.log(response));
        e.target.reset();
        toast.success("The Testing is Updated Succesfully!..", {
          position: toast.POSITION.TOP_CENTER,
        });
      } catch (e) {
        toast.error("Error While Updating Log book... " + e.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      console.log("in create testing", data);
      try {
        const fileArray = [];
        if (data.uploadReport.length > 0) {
          fileArray.push({
            name: data.uploadReport[0].name,
            type: data.uploadReport[0].type,
          });
        }
        API.graphql({
          query: mutations.createTesting,
          variables: {
            input: {
              // distributionBox:data.distributionBox,
              // fittingId:data.fittingId,
              technician: data.technician,
              testResult: data.testResult,
              // warranty:data.warranty,
              testOn: data.testDate,
              nextTestSchedule: data.nextTestSchedule,
              testReport: fileArray,
              emailNofication: data.emailNofication,
              calendar: data.calendar,
              reminder: data.reminder,
              assetsID: assetId,
              status: "Active",
            },
          },
          authMode: "API_KEY",
        })
          .then((result) => {
            toast.success("The Testing is Creating Succesfully!..", {
              position: toast.POSITION.TOP_CENTER,
            });
            navigate(`${process.env.PUBLIC_URL}/formsLists`, {
              state: {
                catId: `${state.state.catId}`,
                fields: `${state.state.fields}`,
                assetId: `${assetId}`,
              },
            });
            console.log(result);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        toast.error("Error While Creating Testing... " + e.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }

  //-----------------------------------------Display------------------------------------------
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <H4>Testing</H4>
                  <Col md="12">
                    <Form
                      className="needs-validation"
                      noValidate=""
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {/* render the form inputs */}
                      <Row>
                        {
                          formInputs
                          //  && formDetails.length > 0 && formDetails.map((item, i) => (
                          //     <Col key={i} md="6">
                          //       <div className="form-group">
                          //         <input
                          //           name={item.name}
                          //           {...register(item.name, {require: item.rules?.required})}
                          //           className="form-control"
                          //           type={item.type}
                          //         />
                          //         <div className="invalid-feedback">
                          //           {item.rules?.message}
                          //         </div>
                          //       </div>
                          //     </Col>
                          //   ))
                        }
                      </Row>
                      <div style={{ textAlign: "center" }}>
                        <Btn attrBtn={{ color: "primary" }}>Success</Btn>
                      </div>
                    </Form>
                    {/* <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        {formInputs}  
                      </Row>   
                      {/* <Row>
                        <Col md="6 mb-3">                                 
                          <Label >Electrician</Label>
                          <select className="form-control" name="electrician" placeholder='Choose Electrician...' {...register('electrician', { required: true })}>                                      
                            <option>Choose Electrician...</option>
                            {
                              electrician.map(item => 
                                <option key={item.id} value={item.id}>{item.fname} {item.lname}</option>   
                              )
                            }
                          </select>
                          <span>{errors.electrician && 'Fitting Id is required'}</span>
                        </Col>
                        <Col md="6 mb-3">
                          <Label >Test Date</Label>
                            <input type="date" className="form-control" {...register('testOn', { required: true })} />
                            <span>{errors.testOn && 'Test Date is required'}</span>
                            <div className="valid-feedback">{'Looks good!'}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6 mb-3">
                          <Label >FittingId</Label>
                          <input className="form-control" name="fittingId" type="text" placeholder="Fitting #" {...register('fittingId', { required: true })} />
                          <span>{errors.fittingId && 'Fitting Id is required'}</span>
                          <div className="valid-feedback">{'Looks good!'}</div>
                        </Col>
                        <Col md="6 mb-3">
                          <Label >Test Result</Label>
                          <select className="form-control" name="testResult" placeholder='Choose Test Result...' {...register('testResult', { required: true })}>                                      
                            <option value="Pass">Pass</option>
                            <option value="Fail">Fail</option>
                          </select>
                          <span>{errors.testResult && 'Testing Result is required'}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>Upload Report</p>
                        </Col>
                        <Col md="6 mb-3">
                          <Label >Distribution Board</Label>
                          <select className="form-control" name="distributionBox" placeholder='Choose Distribution Board...' {...register('distributionBox', { required: true })}>
                            <option>Distribution Board</option>
                            {
                              distributionBox.map(item => 
                                <option key={item.id} value={item.id}>{item.distributionBoxName}</option>   
                              )
                            }                          
                          </select>
                          <span>{errors.distributionBox && 'Distribution Board is required'}</span>
                        </Col>
                      </Row>
                      <Row>  
                        <Col md="6 mb-3">
                          <Label >Next Test Date</Label>
                          <InputGroup className="date" id="dt-minimum" data-target-input="nearest">
                            <DatePicker
                            name='nextTestSchedule'
                            control={control}
                              {...register('nextTestSchedule', { required: false })}
                              className="form-control datetimepicker-input digits"
                              value={nextTestSchedule}
                              dateFormat="yyyy/MM/dd"
                              onChange={(date) => {
                                const d = new Date(date).toLocaleDateString('fr-CA');
                                console.log(d);
                                setnextTestSchedule(d);
                              }}
                            />
                            <div className="input-group-text" data-target="#dt-date" data-toggle="datetimepicker"><i className="fa fa-calendar"></i></div>
                          </InputGroup>
                          <span>{errors.nextTestSchedule && 'nextTestSchedule Id is required'}</span>
                          <div className="valid-feedback">{'Looks good!'}</div>
                        </Col>
                        <Col md="6 mb-3">
                            <Label >Set Reminder</Label>
                            <input className="form-control" name="remindar" type="text" placeholder="Fitting #" {...register('remindar', { required: true })} />
                            <span>{errors.remindar && 'Fitting Id is required'}</span>
                            <div className="valid-feedback">{'Looks good!'}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6 mb-3">
                            <div className="form-control">
                                  <Input
                                      type="checkbox"
                                      name="emailNofication"
                                      value={emailNofication}
                                      {...register('emailNofication', { required: false })}
                                      onChange={e =>{
                                        setemailNofication(!emailNofication)
                                        console.log(emailNofication);
                                      }}
                                    />  
                                  <Label htmlFor="checkbox1">E-mail Notification</Label>
                            </div>
                          </Col>
                          <Col md="6 mb-3">
                            <div className="form-control">
                            <Input
                                type="checkbox"
                                name="calendar"
                                value={checkedCalendar}
                                {...register('calendar', { required: false })}
                                onChange={e =>{
                                  setcheckedCalendar(!checkedCalendar)
                                  console.log(checkedCalendar);
                                }}
                              />  
                                  <Label htmlFor="checkbox2">Add to Calendar</Label>
                            </div>
                          </Col>
                      </Row>              
                      <Link  to={`/formsLists`}>
                          <Button variant="danger">Cancel</Button>
                      </Link>
                      <Btn attrBtn={{ color: 'primary' }}> Add Testing</Btn>
                    </Form> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardFooter className="row"></CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default AddEditLogBookDetails;
