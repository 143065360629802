import React, { Fragment,useState,useEffect,useCallback } from 'react';
import { H5,H4,H6, P } from '../../../AbstractElements';
import { Container, Row, Input, Table, CardHeader, CardFooter, Col, Card, InputGroup } from 'reactstrap';
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import '../../../assets/scss/index.less';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Btn } from '../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { toast } from 'react-toastify';
import awsExports from "../../../aws-exports";
import uuid from 'react-native-uuid';

Amplify.configure(awsExports);

const TestingList = ({assetId, catId, fields}) => {
  const navigate = useNavigate();
  const [listofTesting, setlistofTesting] = useState([]);
  const [listofTestingFilter, setlistofTestingFilter] = useState([]);
  const [electrician, setElectrician] = useState([]);
  const [distributionBox, setDistributionBox] = useState([]);
  const [distributionBoxSet, setDistributionBoxSet] = useState([]);
  const [asset, setAsset] = useState([]);
  
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalUpdate, setModalUpdate] = useState(false);
  const toggleUpdate = () => setModal(!modalUpdate);
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [collection, setCollection] = React.useState(
    cloneDeep(listofTesting.slice(0, countPerPage))
  );
  

  const globalSearch = (searchValue) => {
    try{
      let filteredData = listofTesting.filter((site) => {
        return (
          site.testResult.toLowerCase().includes(searchValue.toLowerCase()) ||
          site.testOn.toString().toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
    catch(e){
      console.log(e);
    }
  };

  useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(listofTesting.slice(0, countPerPage)));
    } else {
      globalSearch(value);
    }
  },[value]);

  const updatePage = p => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(listofTesting.slice(from, to)));
  };

  const handleView = useCallback((row) => navigate(`${process.env.PUBLIC_URL}/formsLists/addeditTesting/${row.id}/view`), []);

  const handleDelete = (id) =>{
    if (window.confirm(`Are you sure?? Do you want to delete the Testing..?`)) {      
      try{
        // const dataTesting = API.graphql({
        // query: mutations.updateTesting,
        // variables: {input: {
        //     id:id,
        //     _version:1
        //     // reports:"",
        // }},
        // authMode: 'API_KEY'
        // });
        toast.success('The Testing is Deleted Succesfully!..', {
            position: toast.POSITION.TOP_CENTER 
        });
        // console.log(dataTesting);
            
      }
      catch(e){
          toast.error('Error While Deleting the Testing... '+ e.message, {
              position: toast.POSITION.TOP_CENTER
          });
      } 
    }
  };
  
  useEffect(() => {
    getDistributionBox();
    getAssetDetails();
    getElectrician();
    listTestingDetails();
  }, []);
  
  async function listTestingDetails() {
    await API.graphql({
      query: queries.listTestings,
      authMode: 'API_KEY'
    }).then((result) => {
      const testingDetails = result.data.listTestings.items;
      console.log(testingDetails);
      setlistofTesting(testingDetails);
      setlistofTestingFilter(testingDetails);
      setCollection(testingDetails);
    }).catch((error) => {
      console.log(error)
    });
  }

  async function getAssetDetails() {
    await API.graphql({
      query: queries.listAssets,
      authMode: 'API_KEY'
    }).then((result) => {
      const assetDetails = result.data.listAssets.items;
      const distributionBox = [];
      assetDetails.map((item) => {
        API.graphql({
          query: queries.listDistributionBoxes,
          variables:{
            filter:{
              id:{
                eq: item.distributionboxID
              }
            }
          },
          authMode: 'API_KEY'
        }).then((dbresult) => {
          const dbDetails = dbresult.data.listDistributionBoxes.items;
          setDistributionBox(dbDetails);
          distributionBox.push({
            'assetId': item.id,
            'dbId': dbDetails[0].id,
            'dbNumber': dbDetails[0].distributionBoxName
          });
          setDistributionBoxSet(distributionBox);
        }).catch((error) => {
          console.log(error);
        });
      });
      setAsset(assetDetails);
    }).catch((error) => {
      console.log(error);
    });
  }
  

  
  async function getDistributionBox(){
    await API.graphql({
      query: queries.listDistributionBoxes,
      authMode: 'API_KEY'
    }).then((result) => {
      const assetDetails = result.data.listDistributionBoxes.items;
      setDistributionBox(assetDetails)
      console.log(assetDetails)
    }).catch((error) => {
      console.log(error)
    });
  }
  
  async function getElectrician(){
    await API.graphql({
      query: queries.listSPUserDetails,
      variables: {
        filter: {
          userGroup: {
            eq: 'electrician'
          }
        }
      },
      authMode: 'API_KEY'
    }).then((result) => {
      const assetDetails = result.data.listSPUserDetails.items;
      setElectrician(assetDetails)
      console.log(assetDetails)
    }).catch((error) => {
      console.log(error)
    });
  }

  const filterElectrician = electricianId => {
    if(electricianId !== null && electricianId.length > 0){
      const technician = electrician.filter(item => {return item.id === electricianId[0]});
      if(technician.length>0){
        return technician[0].fname + ' ' + technician[0].lname;
      }
      else{
        return '';
      }
    }
  }

  const filterDistributionBox = id => {
    const groupName = distributionBoxSet.filter(item => { return item.assetId === id});
      if(groupName.length > 0){
        return groupName[0].dbNumber;
      }
      else{
        return '';
      }    
  }

//-----------------------------------------Display------------------------------------------
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader >
                <Row>
                  <Col md="6">
                    <InputGroup><span className="input-group-text"><i className="icon-search"></i></span>
                      <Input  type="text" placeholder="Search.." value={value}
                          onChange={e => setValue(e.target.value)} />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                              Import Testing
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Bulk Upload</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Download Testing</Dropdown.Item>
                            </Dropdown.Menu>
                    </Dropdown>
                    <Link  to={`${process.env.PUBLIC_URL}/formsLists/addeditTesting`} state={{assetid: `${assetId}`, fields: `${fields}`, catId: `${catId}`}}> <Button>Add Testing</Button></Link>   
                 </Col>
                </Row>
              </CardHeader>  
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">        
                  <div className="table-responsive">       
                    <Table className='table-striped  table-de'>
                      <thead className='table-dark'>
                        <tr>
                          <th>Distribution Board</th>
                          <th>Electrician</th>
                          <th>Test Result</th>
                          <th>Warranty</th>
                          <th>Test On</th>
                          <th>Next Test Schedule</th>
                          <th>Action</th>
                      </tr>

                      </thead>
                      <tbody className="trhover" > 
                        {
                          distributionBoxSet && collection.map((item) => 
                            <tr key={item.id}>
                              <td>
                                {filterDistributionBox(item.assetsID)}
                              </td>
                              <td>
                                {filterElectrician(item.technician)}
                              </td>
                              <td>
                                {item.testResult}
                              </td>
                              <td>
                                {item.warranty}
                              </td>
                              <td>
                                {item.testOn}
                              </td>
                              <td>
                                {item.nextTestSchedule}
                              </td>
                              <td>
                                <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete',  onClick: () => handleDelete(item.id)}} ><i className="fa fa-trash-o"></i></Btn>
                                <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'info', title: 'Edit',  onClick: () => navigate(`${process.env.PUBLIC_URL}/formsLists/addeditTesting`, {state: {id:`${item.id}`, assetid: `${assetId}`, fields: `${fields}`}})}} ><i className="fa fa-edit "></i></Btn>
                                <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'primary', title: 'View',  onClick: () => handleView(item)}}  ><i className="fa fa-eye"></i> </Btn>
                              </td>
                            </tr>
                          )
                        }
                        </tbody>
                    </Table>
                </div>
              </Col>
            </div>
              <CardFooter className="row">
                  <Pagination
                    className='p-t-10'
                    pageSize={countPerPage}
                    onChange={updatePage}
                    current={currentPage}
                    total={listofTesting.length} 
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    } 
                  />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TestingList;