import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AddReport from '../../../Component/Assets/AddAsset/AddReport';

const AddReportContain = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Assets" title="Add Report" />
      <AddReport />
    </Fragment>
  );
};
export default AddReportContain;