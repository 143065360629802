import React, { Fragment } from "react";
import { Card, CardBody, Col, Media } from "reactstrap";
import { Link } from "react-router-dom";
import { H1, P, Image } from "../../../AbstractElements";
import dashImg from "../../../assets/images/images.svg";

const Greeting = () => {
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");

  const sitelink = `${process.env.PUBLIC_URL}/sites`;
  const Userpanellink = `${process.env.PUBLIC_URL}/userpanels`;
  const link = sitelink; // LoginUserGroup === 'site_user' ? sitelink : Userpanellink;
  return (
    <Fragment>
      <Col xl="6" md="6" className="dash-xl-50 box-col-12">
        <Card className="profile-greeting">
          <CardBody>
            <Media>
              <Media body>
                <div className="greeting-user">
                  <H1>Hello, {LoginUserDetails.username}</H1>
                  <P>Welcome back, your dashboard is ready!</P>
                  { LoginUserGroup === "site_user" && (
                  <Link to={link} className="btn btn-outline-white_color">
                    Get Started<i className="icon-arrow-right"></i>
                  </Link>
                  )}
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default Greeting;
