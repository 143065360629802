import { H5,H4,H6, P } from '../../AbstractElements';
import React, { Fragment } from 'react';
import  { useCallback, useState,state,useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row,Button ,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table ,Media,Label
} from 'reactstrap';
import CountUp from 'react-countup';
import Form from 'react-bootstrap/Form';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import awsExports from "../../aws-exports";
import uuid from 'react-native-uuid';

const Management = () => {

    const getList = async () => API.graphql({ query:queries.listSiteDetails });

    const [siteList,setsiteList] = useState([]);
    const [category,setCategory] = useState([]);

    useEffect(() => {
      async function listSiteDetails() {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const companyId = userDetails.companyDetailsId;
        const dataDetails = await API.graphql({
          query: queries.listSiteDetails,
          authMode: 'API_KEY'
        });
        const siteDetails = dataDetails.data.listSiteDetails.items;
        // const companyDetails = dataDetails.data.listLogBooks.items.filter(item => item.id === companyId);
        console.log("site_details list...");
        console.log(siteDetails);
        setsiteList(siteDetails);
      }
      listSiteDetails();
    }, []);

    useEffect(() => {
      async function listCategories() {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const companyId = userDetails.companyDetailsId;
        const dataDetails = await API.graphql({
          query: queries.listCategories,
          authMode: 'API_KEY'
        });
        const siteDetails = dataDetails.data.listCategories.items;
        // const companyDetails = dataDetails.data.listLogBooks.items.filter(item => item.id === companyId);
  
        setCategory(siteDetails);
      }
      listCategories();
    }, []);
  
  //-----------------------Function Widgets-----------------
 
   const selectedWidgetId = (wigetid) => {
      // alert(wigetid);
    };

    //-----------------------Function Buttons-----------------

    const getAssetData = (arg1) => {
     
    };
 
    return (
      <Fragment>
        <Container fluid={true}>
        {/* <Card> */}
          <Row>
            <Col md="6 mb-3">
              
                <div>
                    <Button onClick={() => getAssetData("all")}
                      color="primary"
                      outline
                    >
                      All Time
                    </Button>
                    {' '}
                    <Button onClick={() => getAssetData("This Year")}
                      color="primary"
                      outline
                      >
                      This Year
                    </Button>
                    {' '}
                    <Button onClick={() => getAssetData("This Month")}
                      color="primary"
                      outline
                    >
                      This Month
                    </Button>
                    {' '}
                    <Button onClick={() => getAssetData("This Week")}
                      color="primary"
                      outline
                    >
                      This Week
                    </Button>
                    {' '}
                    <Button onClick={() => getAssetData("Today")}
                      color="primary"
                      outline
                    >
                      Today
                    </Button>
                    {' '}
                    <Button onClick={() => getAssetData("Custom")}
                      color="primary"
                      outline
                    >
                      Custom
                   </Button>
                </div>
            </Col>
            <Col md="3 mb-3"></Col>
            <Col md="3 mb-3">
                <Form.Select name="site" >
                            <option value="1">Site</option>
                                {siteList.map((item) => (
                                    <option key={item.id} value={item.unitNumber}>       
                                    {item.unitNumber+'-'+item.country+'-'+item.state}
                            </option>
                                ))}
                </Form.Select>
            </Col> 
          </Row>
          {/* </Card> */}
          <Row>
                {
                  category.map((item) => {
                    return (
                      <Col  key={item.id}>
                        <Card className="o-hidden">
                          <CardBody>
                            <Media className="static-widget">
                              <Media body><H4 attrH6={{ className: 'font-roboto' }}>{item.category}</H4>
                                {/* <H6 color="Blue" attrH4={{ className: 'mb-0 counter' }}><CountUp end={item.count} /></H6> */}
                              </Media>
                              {item.icon}
                            </Media>
                           <H4><Link  to={`${process.env.PUBLIC_URL}/userpanel/assettable/`+item.category}  title={item.category} onClick={() => selectedWidgetId(item.id)}>
                           {/* <H4><Link  to={`${process.env.PUBLIC_URL}/sites`} onClick={() => selectedWidgetId(item.id)}> */}
                                  ${item.assetcount}
                                  {/* <span>({item.count || 0})</span> */}
                            </Link></H4>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })
                }
        </Row>     
        </Container>
      </Fragment>
    );
};
export default Management;


