import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import SubscriptionsListAdminDetails from '../../Component/Subscriptions/subscriptionsListAdmin';

const SubscriptionsListAdmin = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Subscribers" title="Subscriptions" />
      <SubscriptionsListAdminDetails />
    </Fragment>
  );
};
export default SubscriptionsListAdmin;