import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import CatCertContain from '../../Component/Certificates/index';

const CatCertDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Certficates" title="Categories" />
      <CatCertContain />
    </Fragment>
  );
};
export default CatCertDetails;