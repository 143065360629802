import * as queries from "../../graphql/queries";
import SiteDBData from "../../Data/DistributionBoard/index";
import SiteData from "../../Data/Sites/index";
import { limit } from "../../Config/Config";
import API from "aws-amplify";
var base64 = require("base-64");
var SiteDetailsData = (function () {
  async function fetchSiteDetailsDataAO(assetOwnerId) {
    try {
      let nextToken = "";
      let sites = [];
      await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: assetOwnerId,
          limit: 200000,
        },
        authMode: "API_KEY",
      })
        .then(async (result) => {
          let assetDatas = result.data.siteDetailsByAssetownerID.items;
          nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            sites.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.siteDetailsByAssetownerID,
              variables: {
                assetownerID: assetOwnerId,
                limit: 200000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.siteDetailsByAssetownerID.nextToken;
                if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                  sites.push(results.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(sites);
          if (sites.length > 0) {
            const mergeSites = sites.flat(1);
            SiteData.setSiteData(mergeSites);
            const promises1 = mergeSites.map(async (item) => {
              return API.graphql({
                query: queries.assetCountsBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  limit: 10000,
                },
              });
            });
            Promise.all(promises1).then(async (results) => {
              console.log(results);
              let assetCount = [];
              if (results.length > 0) {
                results.forEach((result1) => {
                  let counts = result1.data.assetCountsBySitedetailsID;
                  if (counts !== null) {
                    if (counts.items.length > 0) {
                      counts.items.map((item) => {
                        assetCount.push(item);
                      });
                    }
                  }
                });
                if (assetCount.length > 0) {
                  localStorage.setItem(
                    "categoryAssetCount",
                    base64.encode(JSON.stringify(assetCount))
                  );
                } else {
                  localStorage.setItem(
                    "categoryAssetCount",
                    base64.encode(JSON.stringify(assetCount))
                  );
                }
              }
            });
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(mergeSites))
            );
            fetchlistDistributionBox(mergeSites);
            return mergeSites;
          }
        })
        .catch((error) => {
          console.log(error);
          SiteData.setSiteData([]);
          return "";
        });
    } catch (error) {
      console.log(error);
      SiteData.setSiteData([]);
      return "";
    }
  }

  async function fetchSiteDetailsDataSP(assetOwnerId) {
    try {
      let nextToken = "";
      let assets = [];
      await API.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: assetOwnerId,
          limit: limit,
        },
      })
        .then(async (result) => {
          let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
          nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
          if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: assetOwnerId,
                limit: limit,
                nextToken: nextToken,
              },
            })
              .then((results) => {
                nextToken =
                  results.data.linkUsersAndSitesByUserdetailsID.nextToken;
                if (
                  results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
                ) {
                  assets.push(
                    results.data.linkUsersAndSitesByUserdetailsID.items
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            const siteDetails = mergeAssets;
            let nextToken1 = "";
            let assets1 = [];
            await API.graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: limit,
              },
              authMode: "API_KEY",
            })
              .then(async (result) => {
                let assetDatas = result.data.listSiteDetails.items;
                nextToken1 = result.data.listSiteDetails.nextToken;
                if (result.data.listSiteDetails.items.length > 0) {
                  assets1.push(assetDatas);
                }
                while (nextToken1 !== null) {
                  await API.graphql({
                    query: queries.listSiteDetails,
                    variables: {
                      limit: limit,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                    .then((results) => {
                      nextToken1 = results.data.listSiteDetails.nextToken;
                      if (results.data.listSiteDetails.items.length > 0) {
                        assets1.push(results.data.listSiteDetails.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets1);
                if (assets1.length > 0) {
                  const mergeAssets1 = assets1.flat(1);
                  const siteDetail = mergeAssets1.filter((item) => {
                    const _matchingAssetTemplate = siteDetails?.find(
                      (site) => site.sitedetailsID === item?.id
                    );
                    if (_matchingAssetTemplate) {
                      return item;
                    }
                  });
                  fetchlistDistributionBox(siteDetail);
                  localStorage.setItem(
                    "site",
                    base64.encode(JSON.stringify(siteDetail))
                  );
                  SiteData.setSiteData(siteDetail);

                  const promises1 = siteDetail.map(async (item) => {
                    return API.graphql({
                      query: queries.assetCountsBySitedetailsID,
                      variables: {
                        sitedetailsID: item.id,
                        limit: limit,
                      },
                    });
                  });
                  Promise.all(promises1).then(async (results) => {
                    console.log(results);
                    let assetCount = [];
                    if (results.length > 0) {
                      results.forEach((result1) => {
                        if (result1.data.assetCountsBySitedetailsID !== null)
                          assetCount.push(
                            result1.data.assetCountsBySitedetailsID.items[0]
                          );
                      });
                      if (assetCount.length > 0) {
                        localStorage.setItem(
                          "categoryAssetCount",
                          base64.encode(JSON.stringify(assetCount))
                        );
                      } else {
                        localStorage.setItem(
                          "categoryAssetCount",
                          base64.encode(JSON.stringify(assetCount))
                        );
                      }
                    }
                  });
                  return siteDetail;
                }
              })
              .catch((error) => {
                console.log(error);
                SiteData.setSiteData([]);
                return "";
              });
          }
        })
        .catch((error) => {
          console.log(error);
          SiteData.setSiteData([]);
          return "";
        });
    } catch (error) {
      console.log(error);
      SiteData.setSiteData([]);
      return "";
    }
  }

  async function fetchlistDistributionBox(siteData) {
    try {
      const promises = siteData.map((item) => {
        return API.graphql({
          query: queries.distributionBoxesBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            limit: 200000,
          },
        });
      });
      Promise.all(promises)
        .then(async (results) => {
          console.log("results -> ", results);
          let SiteDB = [];
          results.map((items) => {
            if (items.data.distributionBoxesBySitedetailsID.items.length > 0) {
              SiteDB.push(items.data.distributionBoxesBySitedetailsID.items);
            }
          });
          if (SiteDB.length > 0) {
            SiteDBData.setSiteDBData(SiteDB.flat(1));
            console.log("SiteDB", SiteDB.flat(1));
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(SiteDB.flat(1)))
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  return {
    fetchSiteDetailsAO: fetchSiteDetailsDataAO,
    fetchSiteDetailsSP: fetchSiteDetailsDataSP,
  };
})();

export default SiteDetailsData;
