import React, { Fragment, useState } from "react";
import StepZilla from "react-stepzilla";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
import { H5, H4, H6, Image } from "../../../AbstractElements";
import { FormWizardWithIcon } from "../../../Constant";
import CompanyDetails from "./CompanyDetails";
import BillingPlan from "./BillingPlan";
import RegisterFrom from "../../Pages/Auth/RegisterFrom";
import UsersDetails from "./UsersDetails";
import SpContain from "./ServiceProvider";
import PaymentOption from "./PaymentOption";
import imgg from "../../../assets/images/logo/KYASP logo.png";
import { useNavigate, useLocation } from "react-router-dom";

const RegistrationElectricianContain = () => {
  const { state } = useLocation();
  console.log(state)
  const [pageData, setPageData] = useState({
    step: 1,
    CompanyName: "",
    businessNumber: "",
    companyaddress: "",
    licencenumb: "",
    phone: "",
    website: "",
    EC: "",
    FAX: "",
    logo: "",
    PaymentId: "",
    userDetails: [],
    UserId: "",
    CompanyId: "",
    Email: "",
    CompanyIdSelected: "",
    companySelected: "",
    firstName: "",
    compEmail: "",
    lastName: "",
    spemail: "",
    spmobileNumber: "",
    splicence: "",
    spProfilepic: ""
  });
  const nextStep = () => {
    const {
      step,
      CompanyId,
      CompanyName,
      userDetails,
      businessNumber,
      companyaddress,
      licencenumb,
      phone,
      website,
      EC,
      FAX,
      logo,
      PaymentId,
      UserId,
      Email,
      CompanyIdSelected,
      companySelected,
      firstName,
      lastName,
      compEmail,
      spemail,
      spmobileNumber,
      splicence,
      spProfilepic
    } = pageData;
    setPageData({
      step: step + 1,
      CompanyId: CompanyId,
      CompanyName: CompanyName,
      userDetails: userDetails,
      businessNumber: businessNumber,
      companyaddress: companyaddress,
      PaymentId: PaymentId,
      licencenumb: licencenumb,
      phone: phone,
      website: website,
      EC: EC,
      FAX: FAX,
      logo: logo,
      UserId: UserId,
      Email: Email,
      companySelected: companySelected,
      CompanyIdSelected: CompanyIdSelected,
      firstName: firstName,
      compEmail: compEmail,
      lastName: lastName,
      spemail: spemail,
      spmobileNumber: spmobileNumber,
      splicence: splicence,
      spProfilepic: spProfilepic,
    });
  };
  // Go to prev step
  const prevStep = () => {
    const {
      step,
      CompanyId,
      CompanyName,
      userDetails,
      businessNumber,
      licencenumb,
      phone,
      website,
      EC,
      FAX,
      logo,
      companyaddress,
      PaymentId,
      UserId,
      Email,
      CompanyIdSelected,
      companySelected,
      firstName,
      compEmail,
      lastName,
      spemail,
      spmobileNumber,
      splicence,
      spProfilepic
    } = pageData;
    setPageData({
      step: step - 1,
      CompanyId: CompanyId,
      CompanyName: CompanyName,
      userDetails: userDetails,
      businessNumber: businessNumber,
      companyaddress: companyaddress,
      PaymentId: PaymentId,
      licencenumb: licencenumb,
      phone: phone,
      website: website,
      EC: EC,
      FAX: FAX,
      logo: logo,
      UserId: UserId,
      Email: Email,
      companySelected: companySelected,
      CompanyIdSelected: CompanyIdSelected,
      firstName: firstName,
      lastName: lastName,
      compEmail: compEmail,
      spemail: spemail,
      spmobileNumber: spmobileNumber,
      splicence: splicence,
      spProfilepic: spProfilepic
    });
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xl="12">
          <div className="login-card">
            <Card>
              <CardHeader>
                <div className="login-header text-center">
                  <div className="logo-wrapper">
                    <a href="#javascript">
                      <Image
                        attrImage={{
                          className: "img-fluid",
                          src: `${imgg}`,
                          alt: "",
                        }}
                      />
                    </a>
                  </div>
                  <H4>Create your account</H4>
                </div>
              </CardHeader>
              <CardBody>
                {pageData.step === 1 && (
                  <CompanyDetails nextStep={nextStep} values={pageData} />
                )}
                {pageData.step === 2 && (
                  <SpContain
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                 {pageData.step === 3 && (
                  <RegisterFrom
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                {/*
                {pageData.step === 4 && (
                  <PaymentOption
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )} */}
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default RegistrationElectricianContain;
