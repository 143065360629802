import React, { useState,Fragment } from 'react';
import Calendar from 'react-calendar';
import { Row,FormGroup, Input, Col } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';

function DateRangePicker({ onDateRangeSelected }) {

  console.log("onDateRangeSelected");
  console.log(onDateRangeSelected);
  
  const [selectedOption, setSelectedOption] = useState('Today');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    switch (event.target.value) {
      case 'Today':
        onDateRangeSelected([new Date(), new Date()]);
        break;
      case 'This Week':
        onDateRangeSelected(getWeekRange());
        break;
      case 'This Month':
        onDateRangeSelected(getMonthRange());
        break;
      case 'This Year':
        onDateRangeSelected(getThisYearRange());
        break;
      case 'Custom':
        break;
      default:
        break;
    }
  };

//-------------------------------------------------------------------------------------

const currentDate = new Date();

const todayDate = (moment(new Date()).format('YYYY-MM-DD'));

const currentDayOfWeek = currentDate.getDay();
const startOfWeek = ( moment(
new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDayOfWeek
)
).format('YYYY-MM-DD'));
const endOfWeek =( moment(
    new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + (6 - currentDayOfWeek)
    ) 
).format('YYYY-MM-DD'));

//Month---------------------------------------------------------------

const startOfMonth = (moment(
new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
)
).format('YYYY-MM-DD'));
const endOfMonth = (moment(
new Date(
currentDate.getFullYear(),
currentDate.getMonth() + 1,
0
)
).format('YYYY-MM-DD'));

//-------------------Year--------------------------------------------------

const startOfThisYear = (moment(
new Date(
currentDate.getFullYear(),
0,
1
)
).format('YYYY-MM-DD'));
const endOfThisYear =(moment(
new Date(
currentDate.getFullYear(),
11,
31
)).format('YYYY-MM-DD'));

//-----custom-------------------------------------------------------

const [dateRange, setDateRange] = useState([null, null]);
const [startDate, endDate] = dateRange;

//-------------------------------------------------------------------------
const getTodayRange = () => {
  console.log('Today');
}

const getCustomRange = () => {
  console.log('Custom');
}

const getAllTimeRange = () => {
    console.log('All Time');
  }

const getWeekRange = () => {
  console.log(startOfWeek,endOfWeek);
  };

const getMonthRange = () => {
  console.log(startOfMonth,endOfMonth);
  };

const getThisYearRange = () => {
  console.log(startOfThisYear,endOfThisYear);
  };
 
//-------------------------------------------------------------------------

    return (
      <div className="daterange-card">
       <Row>
         <Col xs="6">
           <FormGroup>
             <Input type="select" value={selectedOption}  onChange={handleOptionChange}>
               <option value="All Time">All Time</option>
               <option value="Today">Today</option>
               <option value="This Week">This Week</option>
               <option value="This Month">This Month</option>
               <option value="This Year">This Year</option>
               <option value="Custom">Custom</option>
             </Input>
           </FormGroup>
         </Col>
         <Col xs="6">
          <FormGroup>
             {selectedOption === 'Custom' && (
               <Fragment>
                 <Row className="d-flex justify-content-end">
                   <Col xl="6">
                     <div className="daterange-card">
                       <div className="mb-3">
                         <FormGroup>
                           <DatePicker
                             className="form-control digits"
                             selectsRange={true}
                             startDate={startDate}
                             endDate={endDate}
                             // onSelect={(d)=>{setDateRange(d);
                             //   fetchDetailsData('Custom');
                             // }}
                             onChange={(d)=>{
                               setDateRange(d);
                             console.log(d);
                             console.log(startDate);
                             console.log(endDate);
                            
                             }}
                             isClearable={true} 
                             placeholderText="Enter Date Range" />
                         </FormGroup>
                       </div>
                     </div>
                   </Col>
                 </Row>
             </Fragment>
             )}
           </FormGroup>
         </Col>
         </Row>    
    </div>
  );
}

export default DateRangePicker;
