import { Btn } from "../../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Form,
  Label,
  Input,
  InputGroup,
  CardFooter,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../../assets/scss/index.less";
import { AssetOwnerData } from "../../../../Constant";
import { PlusCircle } from "react-feather";
import { LargeModal } from "../../../../Constant/index";
import AddFormContain from "../AddForm/index";
import ViewCompanyDetailsContain from "../../../CompanyDetails/ViewCompanyDetails";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import * as mutations from "../../../../graphql/mutations";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";

const FormDetailsContain = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formDetails, setFormDetails] = useState([]);
  const [serviceGroup, setServiceGroup] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [collection, setCollection] = React.useState([]);
  const [staticFormDetails, setStaticFormDetails] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [primarycolorTab, setprimarycolorTab] = useState("1");
  const [ViewLarge, setViewLarge] = useState(false);
  const ViewLargeModaltoggle = () => setViewLarge(!ViewLarge);
  const [serviceName, setServiceName] = useState([]);

  const [filteredData, setFilteredData] = React.useState([]);

  const ViewLargeModal = (formData) => {
    console.log(formData);
    setViewData(formData);
    setStaticFormDetails(JSON.parse(formData.formFields[0]));
    setViewLarge(!ViewLarge);
  };

  const handelAction = (row) => {
    const servicename = getServicegroup(row.servicegroupID);
    navigate(`${process.env.PUBLIC_URL}/assetcategory/add-forms`, {
      state: { serviceName: servicename, id: row.id, data: row, edit: true },
    });
  };

  //---Approve Status-----------------------------

  const ApproveStatus = (status) => {
    return status.toLowerCase() === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  const handleChange = async (item) => {
    var statusSp;

    if (item.status.toLowerCase() === "active") {
      statusSp = "Inactive";
      toast.success("Deactivated successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      statusSp = "Active";
      toast.success("Activated successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    console.log(item);
    await API.graphql(
      graphqlOperation(mutations.updateFormDetails, {
        input: { id: item.id, status: statusSp },
      })
    )
      .then((result) => {
        fetchFormDetailsData();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error while Updating ", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //-----------------------Search-----------------------------------------------
  const globalSearch = (searchValue) => {
    let filteredData = formDetails.filter((row) => {
      return (
        row.formName.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.status.toLowerCase().includes(searchValue.toLowerCase()) ||
        getServicegroup(row.serviceGroup)
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
          moment(row.createdAt).format("DD/MM/YYYY")
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(formDetails.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;

    if (!value) {
      setCollection(cloneDeep(formDetails.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      const pageData = filteredData.slice(from, to);
      setCollection(cloneDeep(pageData));
    }
  };

  //----list-----------------------
  async function fetchFormDetailsData() {
    setIsLoading(true);
    const dataDetails = await API.graphql({
      query: queries.listFormDetails,
      variables: {
        filter: {
          formType: {
            ne: "Asset Template",
          },
        },
        limit: 10000,
      },
      authMode: "API_KEY",
    })
      .then((response) => {
        setIsLoading(false);
        const sortedItems = response.data.listFormDetails.items.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.log(sortedItems);
        const formDetails = sortedItems;
        setFormDetails(formDetails);
        setCollection(cloneDeep(formDetails.slice(0, countPerPage)));
      })
      .catch(() => {
        setErrorMessage("Unable to fetch the list");
        setIsLoading(false);
      });
  }

  async function fetchServiceGroupData() {
    const dataDetails = await API.graphql({
      query: queries.listServiceGroups,
      authMode: "API_KEY",
      variables: {
        limit: 10000,
      },
    });
    const serviceGroupDetails = dataDetails.data.listServiceGroups.items;
    setServiceGroup(serviceGroupDetails);
  }

  useEffect(() => {
    fetchFormDetailsData();
    fetchServiceGroupData();
  }, []);

  const getServicegroup = (id) => {
    const groupName = serviceGroup.filter((item) => {
      return item.id === id;
    });
    if (groupName.length > 0) {
      return groupName[0].serviceName;
    } else {
      return "";
    }
  };
  //----------------------------------

  const formInputs = Object.keys(staticFormDetails).map((e) => {
    try {
      const { rules, label, type, options, onSelectFile, placeholder } =
        staticFormDetails[e];
      const { required, message } = rules;
      if (e !== "subForm") {
        return (
          <Col md="6 mb-3" key={e}>
            <label>{label}</label>
            {type === "text" && (
              <input
                placeholder={placeholder}
                className="form-control disabled"
                type={type}
                name={e}
              />
            )}
            {type === "date" && (
              <input
                placeholder={placeholder}
                className="form-control disabled"
                type={type}
                name={e}
              />
            )}
            {type === "dropdown" && (
              <select className="form-control disabled" name={e}>
                <option value="">Choose {label}...</option>
              </select>
            )}
            {type === "autocomplete" && (
              <input
                placeholder={placeholder}
                className="form-control disabled"
                type="text"
                name={e}
              />
            )}
            {type === "radio" &&
              options.map((item) => (
                <Label className="d-block" for={item}>
                  <input
                    className="radio_animated disabled"
                    id={item}
                    type="radio"
                    name={e}
                  />
                  {item}
                </Label>
              ))}
            {type === "checkbox" &&
              options.map((item) => (
                <div className="checkbox">
                  <input
                    id={item}
                    type="checkbox"
                    className="form-control disabled"
                    name={e}
                  />
                  <Label htmlFor={item}>{item}</Label>
                </div>
              ))}
            {type === "file" && (
              <input
                type="file"
                className="form-control disabled"
                name={e}
                onChange={onSelectFile}
              />
            )}
            {type === "textarea" && (
              <input
                placeholder={placeholder}
                className="form-control disabled"
                type={type}
                rows="3"
                name={e}
              />
            )}
            {type === "toggle" && (
              <Media body className="icon-state switch-lg disabled">
                <Label className="switch">
                  <input type="checkbox" name={e} />
                  <span className="switch-state bg-success"></span>
                </Label>
              </Media>
            )}
          </Col>
        );
      } else {
        return "";
      }
    } catch (e) {
      console.log(e);
    }
  });

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <Row>
              <Col md="4">
                <InputGroup>
                  <span className="input-group-text">
                    <i className="icon-search"></i>
                  </span>
                  <Input
                    type="search"
                    placeholder="Search Forms.."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>Form No.</th>
                          <th>Form Name</th>
                          <th>Service Group</th>
                          <th>Added On</th>
                          <th>Status</th>
                          <th>Active</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                      {collection.length > 0 ? (
                        collection.map((item, i) => (
                          <tr key={item.id}>
                            <td>{item.formNumber}</td>
                            <td>{item.formName}</td>
                            <td>{getServicegroup(item.serviceGroup)}</td>
                            <td>
                              {moment(item.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>{item.status}</td>
                            <td>
                              <Media body className="icon-state switch-sm">
                                <Label className="switch">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => handleChange(item, e)}
                                  />
                                  <span
                                    className={ApproveStatus(item.status)}
                                  ></span>
                                </Label>
                              </Media>
                            </td>
                            <td>
                              {/* <Btn attrBtn={{ id: `${item.id}`, className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete',   onClick: () => handelAction('Delete', `${item.id}`)}} ><i className="fa fa-trash-o"></i></Btn> */}
                              <Btn
                                attrBtn={{
                                  id: `${item.id}`,
                                  className: "btn-outline-* btn-xs m-r-5",
                                  color: "info",
                                  title: "Edit",
                                  onClick: () => handelAction(item),
                                }}
                              >
                                <i className="fa fa-edit "></i>
                              </Btn>
                              <Btn
                                attrBtn={{
                                  id: `${item.id}`,
                                  className: "btn-outline-* btn-xs m-r-5",
                                  color: "primary",
                                  title: "View",
                                  onClick: () => ViewLargeModal(item),
                                }}
                              >
                                <i className="fa fa-eye"></i>{" "}
                              </Btn>
                            </td>
                          </tr>
                        ))
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="5" className="text-center">
                                No Data Available.
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </div>
            </Row>
            <CardFooter className="row">
              <Pagination
                className="p-t-10"
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={value ? filteredData.length : formDetails.length}
                showTotal={(total, range) =>
                  `${range[0]} - ${range[1]} of ${total} items`
                }
              />
            </CardFooter>
          </Card>
        </Col>
        <Modal
          isOpen={ViewLarge}
          toggle={ViewLargeModaltoggle}
          size="lg"
          centered
        >
          <ModalHeader toggle={ViewLargeModaltoggle}>
            Preview Form {viewData.formName}
          </ModalHeader>
          <ModalBody className="modal-body">
            <Container fluid={true}>
              <Row>
                <Col md="12">
                  <Nav className="nav-primary mb-1" tabs>
                    <NavItem>
                      <NavLink
                        className={primarycolorTab === "1" ? "active" : ""}
                        onClick={() => setprimarycolorTab("1")}
                      >
                        Web View
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={primarycolorTab === "2" ? "active" : ""}
                        onClick={() => setprimarycolorTab("2")}
                      >
                        Mobile View
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={primarycolorTab}>
                    <TabPane className="fade show" tabId="1">
                      <Row>{formInputs}</Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <div class="wrapperCss">
                        <div class="iphone12max">
                          <div class="iphone12max__screen">
                            <div class="iphone12max__viewport">
                              <div class="dummyWebsite">
                                <Row>{formInputs}</Row>
                              </div>
                            </div>

                            <div class="iphone12max__topIcons"></div>
                            <div class="iphone12max__bottomIcons"></div>
                            <div class="iphone12max__bottomBar"></div>
                          </div>

                          <div class="iphone12max__notch"></div>
                          <div class="iphone12max__leftButtons"></div>
                          <div class="iphone12max__rightButton"></div>
                          <div class="iphone12max__sideAntennas"></div>
                          <div class="iphone12max__topBottomAntennas"></div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default FormDetailsContain;
