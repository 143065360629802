import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Amplify, API } from "aws-amplify";
import { Col, Media, Row } from 'reactstrap';
import * as queries from "../../../graphql/queries";
import { H4, H6, P } from '../../../AbstractElements';
import { JohanDeo, JohanDeoMailId, ProjectDescription, ProjectDescriptionDetails } from '../../../Constant';
import { Image } from '../../../AbstractElements';
import user1 from '../../../assets/images/user/1.jpg';
import awsExports from "../../../aws-exports";
import AWS from "aws-sdk";
import {
  S3_BUCKET,
  REGION,
  ASSET_OWNER_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../../Config/Config";

Amplify.configure(awsExports);

const InvoiceDescription = () => {


  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  console.log(LoginUserDetails);

  const LoginUserGroup = localStorage.getItem("groups");
  console.log(LoginUserGroup);
  const [ao, setAo] = useState([]);
  const [downloadedFile, setdownloadedFile] = useState(null);

  async function fetchAo() {
    const company = await API.graphql({
      query: queries.getAssetOwner,
      authMode: "API_KEY",
      variables: {
        id: LoginUserDetails.id,
      },
    });
    console.log(company);
    getfilename(company.data.getAssetOwner.profilePicture);
    setAo(company.data.getAssetOwner)
  }

  const getImage = (filename) => {
    var params = {
      Bucket: S3_BUCKET,
      Key: `public/${ASSET_OWNER_FOLDER}/${filename}`,
    };
    var promise = myBucket.getSignedUrlPromise("getObject", params);
    promise.then(
      function (url) {
        setdownloadedFile(url);
        console.log(url);
      },
      function (err) {
        console.log(err);
      }
    );
  };
  //-------------------------------------------------------------------------
  function getfilename(path) {
    try {
      const files = JSON.parse(path);
      if (files.length > 0) {
        var newfilename = files[0].fileName;
        getImage(newfilename);
      }
    } catch (error) {
      console.log("Error parsing JSON input:", error);
    }
  }

  useEffect(() => {
    fetchAo();
  }, [])

  return (
    <Fragment>
      <Row className="invo-profile">
        <Col xl="4">
          <Media>
            <div className="media-left"><Image attrImage={{ className: 'media-object rounded-circle img-60', src: `${downloadedFile}`, alt: '' }} /></div>
            <Media body className='m-l-20'>
              <H4 attrH4={{ className: 'media-heading' }}>{ao.firstName + " " + ao.lastName}</H4>
              <P>{ao.email}<br /><span>{ao.mobile}</span></P>
            </Media>
          </Media>
        </Col>
        {/* <Col xl="8">
          <div className="text-xl-end" id="project">
            <H6>{ProjectDescription}</H6>
            <P>{ProjectDescriptionDetails}</P>
          </div>
        </Col> */}
      </Row>
    </Fragment>
  );
};
export default InvoiceDescription;