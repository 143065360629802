import React, { useState } from "react";
import { Container } from "reactstrap";
import SitesImport from "./SitesImport";
import DistrbutionImport from "./DistrbutionImport";
import LogbookAssetDetails from "./LogbookAssetDetails";
import CategoryImport from "./CategoryImport";
import { useNavigate, useLocation, Link } from "react-router-dom";

const Import = () => {
  const LoginUserGroup = localStorage.getItem("groups");
  const state = useLocation();
  const [pageData, setPageData] = useState({
    step:
      LoginUserGroup === "site_owner" || LoginUserGroup === "spao"
        ? state.state && state.state.category && state.state.site
          ? 3
          : 1
        : 3,
    siteDetailsImport: "",
    dbDetailsImport: "",
    logbookAssetDetailsImport: "",
  });

  const nextStep = () => {
    const {
      step,
      siteDetailsImport,
      dbDetailsImport,
      logbookAssetDetailsImport,
    } = pageData;
    setPageData({
      step: step + 1,
      siteDetailsImport: siteDetailsImport,
      dbDetailsImport: dbDetailsImport,
      logbookAssetDetailsImport: logbookAssetDetailsImport,
    });
  };
  // Go to prev step
  const prevStep = () => {
    const {
      step,
      siteDetailsImport,
      dbDetailsImport,
      logbookAssetDetailsImport,
    } = pageData;
    setPageData({
      step: step - 1,
      siteDetailsImport: siteDetailsImport,
      dbDetailsImport: dbDetailsImport,
      logbookAssetDetailsImport: logbookAssetDetailsImport,
    });
  };
  return (
    <Container fluid={true} className="p-0">
      {pageData.step === 1 && (
        <SitesImport nextStep={nextStep} values={pageData} />
      )}
      {pageData.step === 2 && (
        <DistrbutionImport
          prevStep={prevStep}
          nextStep={nextStep}
          values={pageData}
        />
      )}
      {/* {pageData.step === 3 && (
        <CategoryImport
          prevStep={prevStep}
          nextStep={nextStep}
          values={pageData}
        />
      )} */}
      {pageData.step === 3 && (
        <LogbookAssetDetails
          prevStep={prevStep}
          nextStep={nextStep}
          values={pageData}
        />
      )}
    </Container>
  );
};
export default Import;
