import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import AddCertificateContain from "../../Component/Certificates/AddCertificates";

const AddCertificates = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Certficates" title="Add Certificates" />
      <AddCertificateContain />
    </Fragment>
  );
};
export default AddCertificates;
