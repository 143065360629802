var base64 = require("base-64");
var Spuser = (function () {
  var siteDBDataDetails = "";

  var getSPData = function () {
    try {
      if (siteDBDataDetails === "") {
        siteDBDataDetails =
          localStorage.getItem("spuser") !== null
            ? JSON.parse(base64.decode(localStorage.getItem("spuser")))
            : [];
        console.log("spuser", siteDBDataDetails);
      }
    } catch (error) {
      console.log(error);
    }
    return siteDBDataDetails; // Or pull this from cookie/localStorage
  };

  var setSPData = function (siteDBData) {
    try {
      if (siteDBData === "") {
        siteDBDataDetails = JSON.parse(
          base64.decode(localStorage.getItem("spuser"))
        );
      } else {
        siteDBDataDetails = siteDBData;
      }
    } catch (error) {
      console.log(error);
    }
    // Also set this in cookie/localStorage
  };

  return {
    getSPData: getSPData,
    setSPData: setSPData,
  };
})();

export default Spuser;
