var base64 = require("base-64");
var SiteData = (function () {
  var siteDataDetails = "";

  var getSiteData = function () {
    try {
      if (siteDataDetails === "") {
        siteDataDetails = localStorage.getItem("site") !== null ? JSON.parse(
          base64.decode(localStorage.getItem("site"))
        ) : [];
      }
    } catch (error) {
      console.log(error);
    }
    return siteDataDetails; // Or pull this from cookie/localStorage
  };

  var setSiteData = function (siteData) {
    try {
      if (siteData === "") {
        siteDataDetails = JSON.parse(
          base64.decode(localStorage.getItem("site"))
        );
      } else {
        siteDataDetails = siteData;
      }
    } catch (error) {
      console.log(error);
    }

    // Also set this in cookie/localStorage
  };

  return {
    getSiteData: getSiteData,
    setSiteData: setSiteData,
  };
})();

export default SiteData;
