import React, { Fragment } from 'react';
import PrintComponent from './Print';
import { useNavigate, useParams, useLocation } from "react-router-dom";

const InvoiceContain = () => {
  console.log("invoice from transaction");
  const state = useLocation();
  console.log(state);
  return (
    <Fragment>
      <PrintComponent />
    </Fragment >
  );
};
export default InvoiceContain;