import React, { Fragment } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import AssetsListDetails from "../../../Component/Assets/AddAsset/index";
import ListAssetDetails from "../../../Component/Assets/AddAsset/assetListing";

const ListAsset = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Assets" title="Assets List" />
      <AssetsListDetails />
    </Fragment>
  );
};
export default ListAsset;
