import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AddEditSiteUserDetails from '../../Component/SiteOwnerPanel/AddEditSiteUser/addEditSiteUser';

const AddEditSiteUser = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Site Users" title="Manage Site Users" />
      <AddEditSiteUserDetails />
    </Fragment>
  );
};
export default AddEditSiteUser;
