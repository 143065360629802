import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Input,
  Table,
  Col,
  Card,
  InputGroup,
  ModalBody,
  ModalHeader,
  Modal,
  Form,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { allData } from "../../../Constant";
import { PlusCircle } from "react-feather";
import { LargeModal } from "../../../Constant/index";
import { Btn, H4, H1, Badges, H6 } from "../../../AbstractElements";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { SiteContext } from "../../../App";
import { Grid } from "react-feather";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { limit } from "../../../Config/Config";
import SiteDBData from "../../../Data/DistributionBoard/index";
import SiteData from "../../../Data/Sites/index";
var base64 = require("base-64");

const DeletedAssetsList = () => {
  const navigate = useNavigate();
  const state = useLocation();

  const [assets, setAssets] = useState([]);
  const [fullAssets, setFullAssets] = useState([]);
  const [value, setValue] = useState("");
  const [collection, setCollection] = React.useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedSiteData, setSelectedSiteData] = useState("");
  const [selectedSearchSiteData, setSelectedSearchSiteData] = useState("");
  const [selectedSearchDBData, setSelectedSearchDBData] = useState("");
  const [sites, setSites] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [isLoading, setIsLoading] = useState(false);
  const [firstSearch, setFirstSearch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [distributionBoxList, setDistributionBoxList] = useState(
    SiteDBData.getSiteDBData()
  );
  const [distributionBoxFilter, setDistributionBoxfilter] = useState(
    SiteDBData.getSiteDBData()
  );
  const [sitess, setSitess] = useState(SiteData.getSiteData());
  const [selectedBox, setSelectedBox] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [assetView, setAssetView] = useState("list");
  const LoginUserGroup = localStorage.getItem("groups");
  const countPerPageCard = 8;
  const [currentPageCard, setCurrentPageCard] = useState(1);
  const [assetDemo, setAssetsDemo] = React.useState([]);
  const [assetDemoCard, setAssetsDemoCard] = React.useState([]);
  const [collectionCard, setCollectionCard] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 10;
  const [filteredData, setFilteredData] = React.useState([]);
  const [assetTemplatData, setAssetTemplatData] = React.useState([]);
  const [activedata, setactiveData] = React.useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [deleteAsset, setDelete] = useState(false);
  const [selectedItemsAsset, setSelectedItemsAsset] = useState([]);
  const [AssetsSelected, setAssetsSelected] = useState([]);
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const [Loading, setLoading] = useState(false);
  //const [limit, setLimit] = useState(100000);

  const [deleteModal, setDeleteModal] = useState(false);
  const deleteToggle = () => {
    selectedItemsAsset.length > 0
      ? setDeleteModal(!deleteModal)
      : setDeleteModal(false);
  };

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    const data =
      value ||
      (state.state && state.state.site ? false : selectedSite) ||
      selectedLocation ||
      selectedBox
        ? filteredData
        : state.state && state.state.site && state.state.category
        ? assetDemo
        : assetDemo;
    setCollection(cloneDeep(data.slice(from, to)));
  };

  const updatePageCard = (p) => {
    setCurrentPageCard(p);
    const to = countPerPageCard * p;
    const from = to - countPerPageCard;
    const data =
      value ||
      (state.state && state.state.site ? false : selectedSite) ||
      selectedLocation ||
      selectedBox
        ? filteredData
        : state.state && state.state.site && state.state.category
        ? assetDemoCard
        : assetDemoCard;
    setCollectionCard(cloneDeep(data.slice(from, to)));
  };

  // --- site user assets ---
  async function fetchSitesDetailsSU() {
    setIsLoading(true);
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    }).then(async (result) => {
      const sites = result.data.linkUsersAndSitesByUserdetailsID.items;
      if (sites.length > 0) {
        const promises = sites.map(async (item) => {
          return API.graphql({
            query: queries.getSiteDetails,
            variables: {
              id: item.sitedetailsID,
            },
          });
        });
        Promise.all(promises)
          .then((results) => {
            console.log(results);
            let siteData = [];
            if (results.length > 0) {
              results.map((site) => {
                if (site.data.getSiteDetails !== null)
                  siteData.push(site.data.getSiteDetails);
              });
              if (siteData.length > 0) {
                const siteOptions = [];
                if (state.state) {
                  getAssetTemplate(siteData);
                }
                setSiteDetails(siteData);
                siteData.map(async (item) => {
                  siteOptions.push({
                    value: item.id,
                    label:
                      item.siteName +
                      " " +
                      (item.unitNumber ? item.unitNumber : " "),
                  });
                });
                setSites(siteOptions);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setSites([]);
        setAssets([]);
        setIsLoading(false);

        setErrorMessage("No Assets to fetch");
      }
    });
  }

  async function fetchSitesDetails() {
    setIsLoading(true);
    await API.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    }).then(async (result) => {
      const sites = result.data.siteDetailsByAssetownerID.items;
      let nextToken = result.data.siteDetailsByAssetownerID.nextToken;
      let siteData = [];
      if (sites.length > 0) {
        siteData.push(sites);
      }
      while (nextToken !== null) {
        await API.graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        })
          .then((result) => {
            nextToken = result.data.siteDetailsByAssetownerID.nextToken;
            if (result.data.siteDetailsByAssetownerID.items.length > 0) {
              siteData.push(result.data.siteDetailsByAssetownerID.items);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (siteData.length > 0) {
        let mergeSites = siteData.flat(1);
        const siteFilterArray = [];
        const siteOptions = [];
        let siteFilter = [];
        if (state.state && state.state.site) {
          siteFilter = mergeSites.filter(
            (site) => site.id === state.state.site.id
          );
        }
        if (state.state) {
          siteFilter.length > 0
            ? getAssetTemplate(siteFilter)
            : getAssetTemplate(mergeSites);
        }
        siteFilter.length > 0
          ? setSiteDetails(siteFilter)
          : setSiteDetails(mergeSites);
        mergeSites.map(async (item) => {
          siteFilterArray.push(item.id);
          siteOptions.push({
            value: item.id,
            label:
              item.siteName + " " + (item.unitNumber ? item.unitNumber : " "),
          });
        });
        setSites(siteOptions);
      } else {
        setAssets([]);
        setIsLoading(false);

        setErrorMessage("No Assets to fetch");
      }
    });
  }

  const getAssetTemplate = async (siteData) => {
    await API.graphql({
      query: queries.assetTemplatesByCategoriesID,
      variables: {
        categoriesID: state.state.category.id,
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result.data.assetTemplatesByCategoriesID.items);
        if (result.data.assetTemplatesByCategoriesID.items.length > 0) {
          setAssetTemplatData(result.data.assetTemplatesByCategoriesID.items);
          getAssetsByTemplates(
            result.data.assetTemplatesByCategoriesID.items,
            siteData
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function fetchlistDistributionBox() {
    console.log(sitess);
    if (sitess.length > 0) {
      let nextToken = "";
      let assets = [];
      sitess.map(async (item) => {
        await API.graphql({
          query: queries.distributionBoxesBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            limit: limit,
          },
          authMode: "API_KEY",
        })
          .then(async (result) => {
            let assetDatas = result.data.distributionBoxesBySitedetailsID.items;
            nextToken = result.data.distributionBoxesBySitedetailsID.nextToken;
            console.log(
              "first token count",
              result.data.distributionBoxesBySitedetailsID.items.length
            );
            console.log("nextToken 1", nextToken);
            if (result.data.distributionBoxesBySitedetailsID.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              await API.graphql({
                query: queries.distributionBoxesBySitedetailsID,
                variables: {
                  limit: limit,
                  sitedetailsID: item.id,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
                .then((results) => {
                  nextToken =
                    results.data.distributionBoxesBySitedetailsID.nextToken;
                  console.log("nextToken", nextToken);
                  console.log(
                    results.data.distributionBoxesBySitedetailsID.items.length
                  );
                  if (
                    results.data.distributionBoxesBySitedetailsID.items.length >
                    0
                  ) {
                    assets.push(
                      results.data.distributionBoxesBySitedetailsID.items
                    );
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            console.log(assets);
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              setDistributionBoxList(mergeAssets);
              setDistributionBoxfilter(mergeAssets);
              SiteDBData.setSiteDBData(mergeAssets);
              localStorage.setItem(
                "siteDB",
                base64.encode(JSON.stringify(mergeAssets))
              );
            } else {
              setDistributionBoxList([]);
              setDistributionBoxfilter([]);
            }
          })
          .catch((error) => {
            setDistributionBoxList([]);
            setDistributionBoxfilter([]);
            console.log(error);
          });
      });
    } else {
      let nextToken = "";
      let assets = [];
      await API.graphql({
        query: queries.listDistributionBoxes,
        variables: {
          limit: limit,
        },
        authMode: "API_KEY",
      })
        .then(async (result) => {
          let assetDatas = result.data.listDistributionBoxes.items;
          nextToken = result.data.listDistributionBoxes.nextToken;
          console.log(
            "first token count",
            result.data.listDistributionBoxes.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.listDistributionBoxes.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.listDistributionBoxes,
              variables: {
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.listDistributionBoxes.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.listDistributionBoxes.items.length);
                if (results.data.listDistributionBoxes.items.length > 0) {
                  assets.push(results.data.listDistributionBoxes.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            setDistributionBoxList(mergeAssets);
            setDistributionBoxfilter(mergeAssets);
            SiteDBData.setSiteDBData(mergeAssets);
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(mergeAssets))
            );
          } else {
            setDistributionBoxList([]);
            setDistributionBoxfilter([]);
          }
        })
        .catch((error) => {
          setDistributionBoxList([]);
          setDistributionBoxfilter([]);
          console.log(error);
        });
    }
  }

  useEffect(() => {
    console.log("select global Site");
    if (distributionBoxList.length === 0) {
      fetchlistDistributionBox();
    }
    async function fetchDBDetails() {
      await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: limit,
        },
        authMode: "API_KEY",
      }).then(async (result) => {
        const sites = result.data.siteDetailsByAssetownerID.items;
        if (sites.length > 0) {
          const siteFilterArray = [];
          sites.map(async (item) => {
            siteFilterArray.push(item.id);
          });
          //---
          let nextToken = "";
          let assets = [];
          await API.graphql({
            query: queries.listDistributionBoxes,
            variables: {
              limit: limit,
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              let assetDatas = result.data.listDistributionBoxes.items;
              nextToken = result.data.listDistributionBoxes.nextToken;
              console.log(
                "first token count",
                result.data.listDistributionBoxes.items.length
              );
              console.log("nextToken 1", nextToken);
              if (result.data.listDistributionBoxes.items.length > 0) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await API.graphql({
                  query: queries.listDistributionBoxes,
                  variables: {
                    limit: limit,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken = results.data.listDistributionBoxes.nextToken;
                    console.log("nextToken", nextToken);
                    console.log(
                      results.data.listDistributionBoxes.items.length
                    );
                    if (results.data.listDistributionBoxes.items.length > 0) {
                      assets.push(results.data.listDistributionBoxes.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setDistributionBoxList([]);
                    setDistributionBoxfilter([]);
                  });
              }
              console.log(assets);
              if (assets.length > 0) {
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                //--
                const Assets = mergeAssets.filter((item) => {
                  const _matchSites = siteFilterArray?.find(
                    (id) => id === item?.sitedetailsID
                  );
                  if (_matchSites) {
                    return item;
                  }
                });
                console.log(Assets);
                setDistributionBoxList(Assets);
                setDistributionBoxfilter(Assets);
              }
            })
            .catch((error) => {
              console.log(error);
              setDistributionBoxList([]);
              setDistributionBoxfilter([]);
            });
          {
            /*
          API.graphql({
            query: queries.listDistributionBoxes,
            variables: {
              limit: 10000,
            },
          })
            .then((result) => {
              const Assets = result.data.listDistributionBoxes.items.filter(
                (item) => {
                  const _matchSites = siteFilterArray?.find(
                    (id) => id === item?.sitedetailsID
                  );
                  if (_matchSites) {
                    return item;
                  }
                }
              );
              setDistributionBoxList(Assets);
              setDistributionBoxfilter(Assets);
            })
            .catch((error) => {});
          */
          }
        } else {
          setDistributionBoxList([]);
          setDistributionBoxfilter([]);
        }
      });
    }
    async function fetchlistDistributionBoxSU() {
      await API.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: LoginUserDetails.id,
          limit: limit,
        },
      })
        .then((dataDetails) => {
          const siteDetails =
            dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
          if (siteDetails.length > 0) {
            const siteFilterArray = [];
            siteDetails.map(async (item) => {
              siteFilterArray.push({
                id: {
                  eq: item.sitedetailsID,
                },
              });
            });
            API.graphql({
              query: queries.listSiteDetails,
              variables: {
                //filter: {
                //  or: siteFilterArray,
                //},
                limit: limit,
              },
            })
              .then(async (result) => {
                //const sites = result.data.listSiteDetails.items;
                const sites = result.data.listSiteDetails.items.filter(
                  (item) => {
                    const _matchSites = siteFilterArray?.find(
                      (id) => id === item?.id
                    );
                    if (_matchSites) {
                      return item;
                    }
                  }
                );
                console.log(sites);
                if (sites.length > 0) {
                  const siteFilterArray = [];
                  sites.map(async (item) => {
                    siteFilterArray.push(item.id);
                  });
                  //---
                  let nextToken = "";
                  let assets = [];
                  await API.graphql({
                    query: queries.listDistributionBoxes,
                    variables: {
                      limit: limit,
                    },
                    authMode: "API_KEY",
                  })
                    .then(async (result) => {
                      let assetDatas = result.data.listDistributionBoxes.items;
                      nextToken = result.data.listDistributionBoxes.nextToken;
                      console.log(
                        "first token count",
                        result.data.listDistributionBoxes.items.length
                      );
                      console.log("nextToken 1", nextToken);
                      if (result.data.listDistributionBoxes.items.length > 0) {
                        assets.push(assetDatas);
                      }
                      while (nextToken !== null) {
                        await API.graphql({
                          query: queries.listDistributionBoxes,
                          variables: {
                            limit: limit,
                            nextToken: nextToken,
                          },
                          authMode: "API_KEY",
                        })
                          .then((results) => {
                            nextToken =
                              results.data.listDistributionBoxes.nextToken;
                            console.log("nextToken", nextToken);
                            console.log(
                              results.data.listDistributionBoxes.items.length
                            );
                            if (
                              results.data.listDistributionBoxes.items.length >
                              0
                            ) {
                              assets.push(
                                results.data.listDistributionBoxes.items
                              );
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                            setDistributionBoxList([]);
                            setDistributionBoxfilter([]);
                          });
                      }
                      console.log(assets);
                      if (assets.length > 0) {
                        const mergeAssets = assets.flat(1);
                        console.log(mergeAssets);
                        //--
                        const Assets = mergeAssets.filter((item) => {
                          const _matchSites = siteFilterArray?.find(
                            (id) => id === item?.sitedetailsID
                          );
                          if (_matchSites) {
                            return item;
                          }
                        });
                        console.log(Assets);
                        setDistributionBoxList(Assets);
                        setDistributionBoxfilter(Assets);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      setDistributionBoxList([]);
                      setDistributionBoxfilter([]);
                    });
                  {
                    /*
                  API.graphql({
                    query: queries.listDistributionBoxes,
                    variables: {
                      limit: 10000,
                    },
                  })
                    .then((result) => {
                      //console.log(result);
                      const Assets =
                        result.data.listDistributionBoxes.items.filter(
                          (item) => {
                            const _matchSites = siteFilterArray?.find(
                              (id) => id === item?.sitedetailsID
                            );
                            if (_matchSites) {
                              return item;
                            }
                          }
                        );
                      //console.log(Assets);
                      setDistributionBoxList(Assets);
                      setDistributionBoxfilter(Assets);
                    })
                    .catch((error) => {
                      //console.log(error);
                    });
                  */
                  }
                } else {
                  setDistributionBoxList([]);
                  setDistributionBoxfilter([]);
                }
              })
              .catch((error2) => {
                //console.log(error2);
              });
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    }
    // if (LoginUserGroup === "site_user") {
    //   //fetchSitesDetailsSU();
    //   //fetchlistDistributionBoxSU();
    // } else if (LoginUserGroup === "electrician") {
    //   //fetchSitesDetailsSU();
    //   //fetchlistDistributionBoxSU();
    // } else {
    //   //fetchSitesDetails();
    //   //fetchDBDetails();
    // }
  }, []);

  async function getAssetsByTemplates(assetTemplates, siteData) {
    try {
      setIsLoading(true);
      let nextToken = "";
      let assets = [];
      let count = 0;
      assetTemplates.map((assetTemplate) => {
        count = count + 1;
        siteData.map(async (sites) => {
          let tempSiteId = assetTemplate.id + "_" + sites.id;
          await API.graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: tempSiteId,
              limit: limit,
              filter: {
                status: {
                  eq: "delete",
                },
              },
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              let assetDatas =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
              nextToken =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
              if (
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                  .length > 0
              ) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    nextToken: nextToken,
                    limit: limit,
                    filter: {
                      status: {
                        eq: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken =
                      results.data.assetsByAssettemplateSiteIDAndAssetNum
                        .nextToken;
                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      assets.push(
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setFullAssets([]);
                    setIsLoading(false);
                  });
              }
              console.log(assets);
              if (count === assetTemplates.length) {
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                let siteAssets = [];
                if (state.state && state.state.site) {
                  siteAssets = mergeAssets.filter(
                    (item) => item.sitedetailsID === state.state.site.id
                  );
                }
                console.log(siteAssets);
                siteAssets.length > 0
                  ? setFullAssets(siteAssets)
                  : setFullAssets(mergeAssets);
                const sort =
                  siteAssets.length > 0
                    ? siteAssets.sort((a, b) => a.assetNum - b.assetNum)
                    : mergeAssets.sort((a, b) => a.assetNum - b.assetNum);
                console.log(sort);
                setactiveData(sort);
                setFullAssets(sort);
                setAssetsDemo(sort);
                setCollection(cloneDeep(sort.slice(0, countPerPage)));
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setFullAssets([]);
              setIsLoading(false);
            });
        });
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (LoginUserGroup === "site_user") {
      fetchSitesDetailsSU();
      //fetchlistDistributionBoxSU();
    } else if (LoginUserGroup === "electrician") {
      fetchSitesDetailsSU();
      //fetchlistDistributionBoxSU();
    } else {
      fetchSitesDetails();
      //fetchDBDetails();
    }

    if (selectedSiteGlobal) {
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === selectedSiteGlobal;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [selectedSiteGlobal]);

  useEffect(() => {
    console.log("selected Site");
    if (selectedSite !== "") {
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === selectedSite;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [selectedSite]);

  useEffect(() => {
    console.log("assets");
    //setIsLoading(true);
    const fullAsset = [];
    console.log(assets);
    if (assets.assets && assets.assets.length > 0) {
      assets.assets.map(async (item) => {
        const aTemp = assets.assetTemplate.filter(
          (assetItem) => assetItem.id === item.assettemplateID
        );
        console.log(aTemp);
        if (aTemp.length > 0) {
          const tempid = aTemp[0];
          await API.graphql({
            query: queries.listSiteDetails,
            variables: {
              filter: {
                id: {
                  eq: item.siteId,
                },
              },
              limit: limit,
            },
            authMode: "API_KEY",
          })
            .then(async (siteResult) => {
              const siteNumber = siteResult.data.listSiteDetails.items;
              if (item.distributionboxID !== "") {
                const dbNumber = distributionBoxList.filter((db) => {
                  return db.id === item.distributionboxID;
                });
                console.log(dbNumber);

                await API.graphql({
                  query: queries.getServiceGroup,
                  variables: {
                    id: tempid.servicegroupID,
                    limit: limit,
                  },
                  authMode: "API_KEY",
                })
                  .then((serviceGroup) => {
                    const sg = serviceGroup.data.getServiceGroup;
                    fullAsset.push({
                      status: item.status,
                      assetModel: item.assetModel,
                      logbookId: item.logbookId,
                      distributionboxID:
                        dbNumber.length > 0 ? item.distributionboxID : "",
                      siteId: item.siteId,
                      createdAt: item.createdAt,
                      id: item.id,
                      assetName: tempid.templatename,
                      formId: tempid.formId,
                      lampTypeId: item.lampTypeId,
                      installedOn: item.installedOn,
                      fittingTypeId: item.fittingTypeId,
                      formFieldValues: item.formFieldValues,
                      batteryTypeId: item.batteryTypeId,
                      assettemplateID: item.assettemplateID,
                      assetWarrantyStart: item.assetWarrantyStart,
                      assetWarrantyEnd: item.assetWarrantyEnd,
                      assetType: tempid.templatename,
                      assetSerialNumber: item.assetSerialNumber,
                      assetNumber: item.assetNumber,
                      fittingNumber: item.fittingNumber,
                      assetMake: item.assetMake,
                      assetLocation: item.assetLocation,
                      assetDescription: item.assetDescription,
                      distributionBox:
                        dbNumber.length > 0
                          ? dbNumber[0].distributionBoxName
                          : "",
                      site: siteNumber[0].siteName,
                      serviceGroupValue: sg !== null ? sg.serviceName : "",
                      assetImage: item.assetImage,
                      duplicate: item.duplicate,
                    });
                    console.log(fullAsset);
                    //const sort = fullAsset.sort(
                    //  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    //);
                    const sort = fullAsset.sort(
                      (a, b) => a.fittingNumber - b.fittingNumber
                    );
                    console.log(sort);
                    setactiveData(sort);
                    const filteredAssets = sort.filter((item) =>
                      isActive
                        ? item.status === "Active"
                        : item.status === "Inactive"
                    );
                    setFullAssets(filteredAssets);
                    setAssetsDemo(filteredAssets);
                    setCollection(
                      cloneDeep(filteredAssets.slice(0, countPerPage))
                    );
                    setAssetsDemoCard(filteredAssets);
                    setCollectionCard(
                      cloneDeep(filteredAssets.slice(0, countPerPageCard))
                    );
                  })
                  .catch((error1) => {});
              } else {
                API.graphql({
                  query: queries.getServiceGroup,
                  variables: {
                    id: tempid.servicegroupID,
                    limit: limit,
                  },
                  authMode: "API_KEY",
                })
                  .then((serviceGroup) => {
                    const sg = serviceGroup.data.getServiceGroup;
                    fullAsset.push({
                      status: item.status,
                      assetModel: item.assetModel,
                      logbookId: item.logbookId,
                      distributionboxID: "",
                      siteId: item.siteId,
                      createdAt: item.createdAt,
                      id: item.id,
                      assetName: tempid.templatename,
                      formId: tempid.formId,
                      lampTypeId: item.lampTypeId,
                      installedOn: item.installedOn,
                      fittingTypeId: item.fittingTypeId,
                      formFieldValues: item.formFieldValues,
                      batteryTypeId: item.batteryTypeId,
                      assettemplateID: item.assettemplateID,
                      assetWarrantyStart: item.assetWarrantyStart,
                      assetWarrantyEnd: item.assetWarrantyEnd,
                      assetType: tempid.templatename,
                      assetSerialNumber: item.assetSerialNumber,
                      assetNumber: item.assetNumber,
                      fittingNumber: item.fittingNumber,
                      assetMake: item.assetMake,
                      assetLocation: item.assetLocation,
                      assetDescription: item.assetDescription,
                      distributionBox: "",
                      site: siteNumber[0].siteName,
                      serviceGroupValue: sg !== null ? sg.serviceName : "",
                      assetImage: item.assetImage,
                    });
                    console.log(fullAsset);
                    const sort = fullAsset.sort(
                      (a, b) => a.fittingNumber - b.fittingNumber
                    );
                    console.log(sort);
                    setFullAssets(sort);
                    setAssetsDemo(sort);
                    setCollection(cloneDeep(sort.slice(0, countPerPage)));
                    setAssetsDemoCard(sort);
                    setCollectionCard(
                      cloneDeep(sort.slice(0, countPerPageCard))
                    );
                  })
                  .catch((error1) => {
                    setIsLoading(false);
                  });
              }
            })
            .catch((error) => {});
        }
      });
    } else {
      console.log("assets length is 0");
      setFullAssets([]);
      setAssetsDemo([]);
      setAssetsDemoCard([]);
      setCollection(cloneDeep([]));
      setCollectionCard(cloneDeep([]));
      setIsLoading(false);
    }
  }, [assets]);

  const globalSearch = (searchValue) => {
    let filteredData = fullAssets.filter((row) => {
      return (
        row.assetModel.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.distributionboxID
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        row.siteId.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.createdAt
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        row.assetName.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    setCollection(cloneDeep(filteredData));
    setCollectionCard(cloneDeep(filteredData));
  };

  useEffect(() => {
    console.log("select Site view");
    if (
      state.state &&
      state.state.site &&
      state.state.category &&
      !firstSearch
    ) {
      setSelectedSite(state.state.site.id);
      setSelectedSiteData(state.state.site);
      handleSelectedSiteSearch();
    }
  }, [assets && collection]);

  const handleSelectedSiteSearch = () => {
    setDistributionBoxfilter(
      distributionBoxList.filter(
        (item) => item.sitedetailsID === state.state.site.id
      )
    );
  };

  const handleSearch = () => {
    setIsSearch(true);
    let filteredAssets = fullAssets;
    if (value) {
      filteredAssets = filteredAssets.filter((asset) => {
        let siteName = getSiteName(asset.sitedetailsID);
        let dbName =
          asset.distributionBox !== null && asset.distributionBox !== undefined
            ? getDBName(asset.distributionboxID)
            : "";
        let assetTemp = assetTemplatData.filter((item) => {
          return (item.id = asset.assettemplateID);
        });
        return (
          (asset.assetModel !== null &&
            asset.assetModel.toLowerCase().includes(value.toLowerCase())) ||
          (dbName !== "" &&
            dbName.toLowerCase().includes(value.toLowerCase())) ||
          siteName.toLowerCase().includes(value.toLowerCase()) ||
          (asset.fittingNumber !== null &&
            asset.fittingNumber.includes(value)) ||
          moment(asset.createdAt)
            .format("DD/MM/YYYY")
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          (assetTemp.length > 0 &&
            assetTemp[0].templatename
              .toLowerCase()
              .includes(value.toLowerCase()))
        );
      });
    }
    if (selectedSite) {
      filteredAssets = filteredAssets.filter(
        (asset) => asset.siteId === selectedSite
      );
      setDistributionBoxfilter(
        distributionBoxList.filter(
          (item) => item.sitedetailsID === selectedSite
        )
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
    if (selectedBox) {
      filteredAssets = filteredAssets.filter(
        (asset) => asset.distributionboxID === selectedBox
      );
    }
    if (selectedLocation && selectedLocation.trim()) {
      filteredAssets = filteredAssets.filter(
        (asset) => asset.assetLocation === selectedLocation
      );
    }
    //console.log(filteredAssets);
    const sort = filteredAssets.sort(
      (a, b) => a.fittingNumber - b.fittingNumber
    );
    const to = countPerPage * currentPage;
    const from = to - countPerPage;
    const tocard = countPerPageCard * currentPageCard;
    const fromcard = tocard - countPerPageCard;
    const paginatedAssets = sort.slice(from, to);
    const paginatedAssetsCard = sort.slice(fromcard, tocard);
    setFilteredData(sort);

    setCollection(cloneDeep(sort.slice(0, countPerPage)));
    setCollectionCard(cloneDeep(sort.slice(0, countPerPageCard)));
    setCurrentPage(1);
  };

  const activeInactive = () => {
    setIsActive(!isActive);
    //setCollection(cloneDeep(collection.slice(0, countPerPage)));
    //setCollectionCard(cloneDeep(collection.slice(0, countPerPageCard)));
    console.log(isActive);
    console.log(activedata);
    const data = isActive
      ? activedata.filter((item) => item.status === "Inactive")
      : activedata.filter((item) => item.status === "Active");
    console.log(data);

    if (
      value ||
      selectedSite ||
      selectedBox ||
      (selectedLocation && selectedLocation.trim())
    ) {
      let filteredAssets = data;
      if (value) {
        filteredAssets = filteredAssets.filter((asset) => {
          return (
            (asset.assetModel !== null &&
              asset.assetModel.toLowerCase().includes(value.toLowerCase())) ||
            (asset.distributionBox !== null &&
              asset.distributionBox
                .toLowerCase()
                .includes(value.toLowerCase())) ||
            (asset.site !== null &&
              asset.site.toLowerCase().includes(value.toLowerCase())) ||
            (asset.fittingNumber !== null &&
              asset.fittingNumber.includes(value)) ||
            moment(asset.createdAt)
              .format("DD/MM/YYYY")
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            (asset.assetName !== null &&
              asset.assetName.toLowerCase().includes(value.toLowerCase()))
          );
        });
      }
      if (selectedSite) {
        filteredAssets = filteredAssets.filter(
          (asset) => asset.siteId === selectedSite
        );
        setDistributionBoxfilter(
          distributionBoxList.filter(
            (item) => item.sitedetailsID === selectedSite
          )
        );
      } else {
        setDistributionBoxfilter(distributionBoxList);
      }
      if (selectedBox) {
        filteredAssets = filteredAssets.filter(
          (asset) => asset.distributionboxID === selectedBox
        );
      }
      if (selectedLocation && selectedLocation.trim()) {
        filteredAssets = filteredAssets.filter(
          (asset) => asset.assetLocation === selectedLocation
        );
      }
      console.log(filteredAssets);
      const sort = filteredAssets.sort(
        (a, b) => a.fittingNumber - b.fittingNumber
      );
      setFilteredData(sort);
      setCollection(cloneDeep(sort.slice(0, countPerPage)));
      setCollectionCard(cloneDeep(sort.slice(0, countPerPageCard)));
    } else {
      setAssetsDemo(data);
      setAssetsDemoCard(data);
      setCollection(cloneDeep(data.slice(0, countPerPage)));
      setCollectionCard(cloneDeep(data.slice(0, countPerPageCard)));
    }
  };

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/assets`, {
      state: {
        category: state.state.category,
        site: state.state.site,
      },
    });
  };

  const handleSiteDDChange = (selectedOption) => {
    setSelectedSite(selectedOption.value);
    setSelectedSearchSiteData(selectedOption);
  };

  const optionsDB = [
    { value: "", label: "All Distribution Board" },
    ...distributionBoxFilter.map((item) => ({
      value: item.id,
      label: item.distributionBoxName,
    })),
  ];

  const handleSiteDB = (selectedOption) => {
    setSelectedBox(selectedOption.value);
    setSelectedSearchDBData(selectedOption);
  };

  const getSiteName = (siteId) => {
    try {
      const sites = siteDetails.filter((site) => {
        return site.id === siteId;
      });
      console.log("display site name", sites);
      return sites.length > 0 ? sites[0].siteName : "";
    } catch (error) {
      console.log("Error while displaying site name", error);
      return "";
    }
  };

  const getDBName = (dbId) => {
    try {
      const db = distributionBoxList.filter((db) => {
        return db.id === dbId;
      });
      return db.length > 0 ? db[0].distributionBoxName : "";
    } catch (error) {
      console.log("display site name", error);
      return "";
    }
  };

  const handleCheckboxAssetChange = (event, itemId) => {
    let assets = isSearch ? filteredData : fullAssets;
    const isChecked = event.target.checked;
    if (itemId === "selectAll") {
      setIsAllSelected(isChecked);

      if (isChecked) {
        const allItemIds = assets.map((item) => item.id);
        setSelectedItemsAsset(allItemIds);
      } else {
        setSelectedItemsAsset([]);
      }
    } else {
      setSelectedItemsAsset((prevSelectedItems) => {
        if (isChecked && !prevSelectedItems.includes(itemId)) {
          return [...prevSelectedItems, itemId];
        } else if (!isChecked && prevSelectedItems.includes(itemId)) {
          return prevSelectedItems.filter((item) => item !== itemId);
        }
        return prevSelectedItems;
      });

      const allItemIds = assets.map((item) => item.id);
      setIsAllSelected(selectedItemsAsset.length === allItemIds.length);
    }
  };

  let counter = 0;
  const handleDeleteAssets = () => {
    try {
      const selectedAssets = assetDemo.filter((item) =>
        selectedItemsAsset.includes(item.id)
      );
      console.log(selectedAssets);
      setLoading(true);
      selectedItemsAsset.map(async (item, i) => {
        //await sleep(Math.random() * i * 17); // wait
        await sleep(5000);
        await API.graphql(
          graphqlOperation(mutations.deleteAssets, {
            input: { id: item },
          })
        )
          .then(async (result) => {
            console.log(result);
            counter = counter + 1;
            if (counter === selectedItemsAsset.length) {
              setLoading(false);
              toast.success(
                "Selected Assets Successfully Deleted Permanently.",
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );
              //fetchSiteDetailsAO();
              //--- action log table
              await API.graphql({
                query: mutations.createActionLogs,
                variables: {
                  input: {
                    dataType: "Assets",
                    action: "delete",
                    recordDetails: JSON.stringify(selectedAssets),
                    actionedBy: LoginUserDetails.id,
                  },
                },
                authMode: "API_KEY",
              })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => {
                  console.log(error);
                });
              navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
                state: {
                  category: state.state.category,
                  site: state.state.site,
                },
              });
            }
          })
          .catch((error) => {
            console.log(error);
            // setLoading(false);
          });
      });
      let assetCount = [];
      selectedItemsAsset.map(async (item, i) => {
        let assets = assetDemo.filter((items) => items.id === item);
        await API.graphql({
          query: queries.assetCountsBySitedetailsID,
          variables: {
            sitedetailsID: assets[0].siteId,
            limit: 100000,
            filter: {
              categoriesID: {
                eq: state.state.category.id,
              },
            },
          },
          authMode: "API_KEY",
        })
          .then(async (result) => {
            console.log(result);
            if (result.data.assetCountsBySitedetailsID.items.length > 0) {
              assetCount.push(result.data.assetCountsBySitedetailsID.items);
              if (assetCount.length === selectedItemsAsset.length) {
                await API.graphql({
                  query: mutations.updateAssetCounts,
                  variables: {
                    input: {
                      id: result.data.assetCountsBySitedetailsID.items[0].id,
                      assetCount:
                        parseInt(
                          result.data.assetCountsBySitedetailsID.items[0]
                            .assetCount
                        ) - parseInt(selectedItemsAsset.length),
                    },
                  },
                  authMode: "API_KEY",
                });
                const addedsite = await API.graphql({
                  query: queries.getAssetCounts,
                  variables: {
                    id: result.data.assetCountsBySitedetailsID.items[0].id,
                    limit: 10000,
                  },
                  authMode: "API_KEY",
                });
                console.log(addedsite);
                if (addedsite.data.getAssetCounts !== null) {
                  let activeAssets = [];
                  if (localStorage.getItem("categoryAssetCount") !== null) {
                    activeAssets = JSON.parse(
                      base64.decode(localStorage.getItem("categoryAssetCount"))
                    );
                    console.log(activeAssets);
                    const existingIndex = activeAssets.findIndex(
                      (item) => item.id === addedsite.data.getAssetCounts.id
                    );
                    console.log(existingIndex);
                    if (existingIndex > -1) {
                      activeAssets[existingIndex] =
                        addedsite.data.getAssetCounts;
                    } else {
                      activeAssets.push(addedsite.data.getAssetCounts);
                    }
                    console.log(activeAssets);
                    localStorage.setItem(
                      "categoryAssetCount",
                      base64.encode(JSON.stringify(activeAssets))
                    );
                  }
                }
                //fetchSiteDetailsAO();
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  //---updating assetscount
  async function fetchSiteDetailsAO() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let sites = [];
      await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      })
        .then(async (result) => {
          let assetDatas = result.data.siteDetailsByAssetownerID.items;
          nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            sites.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.siteDetailsByAssetownerID,
              variables: {
                assetownerID: LoginUserDetails.id,
                limit: 200000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.siteDetailsByAssetownerID.nextToken;
                if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                  sites.push(results.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(sites);
          if (sites.length > 0) {
            const mergeSites = sites.flat(1);
            SiteData.setSiteData(mergeSites);
            const promises1 = mergeSites.map(async (item) => {
              return API.graphql({
                query: queries.assetCountsBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  limit: 10000,
                },
              });
            });
            Promise.all(promises1).then(async (results) => {
              console.log(results);
              let assetCount = [];
              if (results.length > 0) {
                results.forEach((result1) => {
                  let counts = result1.data.assetCountsBySitedetailsID;
                  if (counts !== null) {
                    if (counts.items.length > 0) {
                      counts.items.map((item) => {
                        assetCount.push(item);
                      });
                    }
                  }
                });
                console.log(assetCount);
                const totalAssetCount = assetCount.reduce(
                  (accumulator, current) => {
                    return accumulator + current.assetCount;
                  },
                  0
                );
                console.log(totalAssetCount);
                if (assetCount.length > 0) {
                  localStorage.setItem(
                    "categoryAssetCount",
                    base64.encode(JSON.stringify(assetCount))
                  );
                } else {
                  localStorage.setItem(
                    "categoryAssetCount",
                    base64.encode(JSON.stringify(assetCount))
                  );
                }
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const renderList = (
    <Fragment>
      <Container fluid={true} className="p-0 project-list">
        {state.state && state.state.category && (
          <H1>{state.state.category.categoryName}</H1>
        )}
        <Card className="custom-card">
          {/* <CardHeader> */}
          <Row>
            <Col md="4">
              <InputGroup>
                <span className="input-group-text">
                  <i className="icon-search"></i>
                </span>
                <Input
                  type="search"
                  placeholder="Search Assets.."
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md="4">
              {selectedSiteData !== "" || selectedSiteGlobal ? (
                <select
                  className="form-select"
                  onChange={(e) => setSelectedSite(e.target.value)}
                  disabled={
                    state.state.site ? true : selectedSiteGlobal ? true : false
                  }
                  value={selectedSiteGlobal ? selectedSiteGlobal : selectedSite}
                  menuPlacement="auto"
                  menuPosition="fixed"
                >
                  <option value="">All Sites</option>
                  {sites.map((site, index) => (
                    <option value={site.value} key={index}>
                      {site?.label}
                    </option>
                  ))}
                </select>
              ) : (
                <Select
                  value={selectedSearchSiteData}
                  onChange={handleSiteDDChange}
                  options={sites}
                  isSearchable={true}
                  hideSelectedOptions={false}
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              )}
            </Col>
            <Col md="4">
              <Select
                value={selectedSearchDBData}
                onChange={handleSiteDB}
                options={optionsDB}
                isSearchable={true}
                hideSelectedOptions={false}
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md="6" className="d-flex">
              <Btn attrBtn={{ color: "primary", onClick: handleSearch }}>
                Search
              </Btn>
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () => {
                    setIsLoading(false);
                    setDelete(false);
                    setSelectedItemsAsset([]);
                    state.state.site
                      ? setSelectedSite(state.state.site.id)
                      : setSelectedSite("");
                    setSelectedBox("");
                    setValue("");
                    setSelectedLocation("");
                    setSelectedSearchSiteData("");
                    setSelectedSearchDBData("");
                    updatePage(1);
                    updatePageCard(1);
                    if (state.state.site && state.state.category) {
                      setCollection(
                        cloneDeep(fullAssets.slice(0, countPerPage))
                      );
                    } else {
                      setCollection(
                        cloneDeep(assetDemo.slice(0, countPerPage))
                      );
                      setCollectionCard(assetDemoCard);
                    }
                  },
                }}
              >
                Reset
              </Btn>
              <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                Back
              </Btn>
              <Btn attrBtn={{ color: "primary", onClick: deleteToggle }}>
                Delete Assets Permanently
              </Btn>
            </Col>
          </Row>
        </Card>
        <Row></Row>
        <div className="card-block row">
          <Col sm="12" lg="12" xl="12">
            <div className="table-responsive">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Table className="table-striped  table-de ">
                    <thead className="custom-table-head">
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={isAllSelected}
                            onChange={(event) =>
                              handleCheckboxAssetChange(event, "selectAll")
                            }
                          />
                        </th>
                        <th>Fitting No.</th>
                        <th>Asset Model</th>
                        <th>Site</th>
                        <th>Distribution board</th>
                        <th>Installed On</th>
                        <th>Asset Warranty</th>
                        <th>Asset Location</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="trhover">
                      {collection.length > 0 ? (
                        collection &&
                        collection.map((item, i) => (
                          <tr key={item.id}>
                            {/* <td>{item.duplicate}</td> */}
                            <td>
                              <input
                                type="checkbox"
                                name="completionLink"
                                checked={selectedItemsAsset.includes(item.id)}
                                onChange={(event) =>
                                  handleCheckboxAssetChange(event, item.id)
                                }
                              />
                            </td>
                            <td>
                              {userPermission.includes("Edit Assets") ? (
                                state.state && (
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/asset/view`}
                                    state={{
                                      category: state.state.category,
                                      item: item,
                                      site: getSiteName(item.sitedetailsID),
                                      db: getDBName(item.distributionboxID),
                                    }}
                                  >
                                    {item.fittingNumber}
                                  </Link>
                                )
                              ) : (
                                <>{item.fittingNumber}</>
                              )}
                            </td>
                            <td>{item.assetModel}</td>
                            <td>{getSiteName(item.sitedetailsID)}</td>
                            <td>{getDBName(item.distributionboxID)}</td>
                            <td>
                              {item.installedOn !== null
                                ? moment(item.installedOn).format("DD/MM/YYYY")
                                : "-"}
                            </td>
                            {item.assetWarrantyStart != null && (
                              <td>
                                {moment(item.assetWarrantyStart).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                -
                                {moment(item.assetWarrantyEnd).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                            )}
                            {item.assetWarrantyStart === null && <td>-</td>}
                            <td>{item.assetLocation}</td>
                            <td>{item.status}</td>
                          </tr>
                        ))
                      ) : (
                        <p>No Assets Found</p>
                      )}{" "}
                    </tbody>
                  </Table>
                </>
              )}
            </div>
            <Pagination
              className="p-t-10"
              pageSize={countPerPage}
              onChange={updatePage}
              current={currentPage}
              total={
                value ||
                (state.state && state.state.site ? false : selectedSite) ||
                selectedLocation ||
                selectedBox
                  ? filteredData.length
                  : state.state && state.state.site && state.state.category
                  ? assetDemo.length
                  : assetDemo.length
              }
              showTotal={(total, range) =>
                `${range[0]} - ${range[1]} of ${total} items`
              }
            />
          </Col>
        </div>
      </Container>
      <Modal isOpen={deleteModal} toggle={deleteToggle}>
        <ModalBody>
          <Form className="form-bookmark">
            <H4>Are you sure, to delete selected Assets??</H4>
            <Btn
              attrBtn={{
                color: "primary",
                className: "ms-1",
                onClick: deleteToggle,
              }}
            >
              Cancel
            </Btn>
            &nbsp;&nbsp;
            <Btn attrBtn={{ color: "primary", onClick: handleDeleteAssets }}>
              {Loading ? "Deleting..." : "Ok"}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default DeletedAssetsList;
