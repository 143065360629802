import { useState, useRef, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { limit } from "../../Config/Config";
const useInfiniteLoadingAssets = ({ state }) => {
  const [items, setItems] = useState([]);
  const pageToLoad = useRef(
    new URLSearchParams(window.location.search).get("page") || 1
  );
  const initialPageLoaded = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserDetailsId =
    LoginUserDetails.hasOwnProperty("id") && LoginUserDetails.id !== null
      ? LoginUserDetails.id
      : [];
  const nextTokenRef = useRef(null);
  const isLoading = useRef(false);
  console.log(state);
  const getItems = async () => {
    if (isLoading.current || !hasMore) return;
    isLoading.current = true;
    try {
      const response = await API.graphql({
        query: queries.assetsBySitedetailsID,
        variables: {
          sitedetailsID: state.state.item.sitedetailsID,
          filter: {
            status: {
              ne: "delete",
            },
          },
          limit: limit,
        },
        authMode: "API_KEY",
      });
      const data = response.data.assetsBySitedetailsID;
      console.log("response", data);
      nextTokenRef.current = data.nextToken;
      setItems((prevItems) => [...prevItems, ...data.items]);
      setHasMore(data.nextToken !== null);
      isLoading.current = false;
    } catch (error) {
      console.error("Error fetching data:", error);
      isLoading.current = false;
    }
  };
  useEffect(() => {
    getItems();
  }, []);
  return {
    items,
    loadMoreItems: getItems,
    hasMore,
  };
};
export default useInfiniteLoadingAssets;
