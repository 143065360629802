import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import TasksContain from '../../Component/TasksAO/index';

const TasksAOContain = () => {
  return (
    <Fragment>
      {/* <Breadcrumbs parent="Tasks" title="Manage Tasks" /> */}
      <TasksContain />
    </Fragment>
  );
};
export default TasksAOContain;