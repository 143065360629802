import React, {
  Fragment,
  useState,
  useEffect,
  createRef,
  useRef,
  useContext,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { Btn, H5, H1, H6, LI, P, UL } from "../../AbstractElements";
import {
  Col,
  Container,
  CardFooter,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ScrollBar from "react-perfect-scrollbar";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import * as mergedQueries from "../../graphql/mergedQueries";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import AWS from "aws-sdk";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "rc-pagination";
import cloneDeep from "lodash/cloneDeep";
import { PlusCircle } from "react-feather";
import {
  S3_BUCKET,
  REGION,
  CERTIFICATES_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
  limit,
} from "../../Config/Config";

import { SiteContext } from "../../App";
import { aocontext } from "../../App";
import SiteData from "../../Data/Sites/index";
var base64 = require("base-64");

Amplify.configure(awsExports);

const Certificates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const LoggedInUserId = LoginUserDetails.id;
  const [certificateDetails, setCertificateDetails] = useState([]);
  const [collection, setCollection] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const LoginUserGroup = localStorage.getItem("groups");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const state = useLocation();
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);
  //const [limit, setLimit] = useState(100000);
  //console.log(selectedSiteGlobal);

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  async function fetchSiteDetailsAO() {
    try {
      ////setIsLoading(true);
      let nextToken = "";
      let sites = [];
      const result = await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setSiteDetails(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setSiteDetails([]);
      //setIsLoading(false);
    }
  }

  //--sites for sp
  async function fetchSiteDetailsSP(assetOwnerId) {
    ////setIsLoading(true);
    let nextToken = "";
    let sites = [];
    const result = await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: assetOwnerId,
        limit: limit,
      },
    });
    if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
      nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
      sites.push(assetDatas);
      while (nextToken !== null) {
        const results = await API.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: assetOwnerId,
            limit: limit,
            nextToken: nextToken,
          },
        });
        nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
        if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          sites.push(results.data.linkUsersAndSitesByUserdetailsID.items);
        }
      }
      console.log(sites);
      if (sites.length > 0) {
        const mergesites = sites.flat(1);
        let sitesforUsers = [];
        const promises = mergesites.map(async (item) => {
          return await API.graphql({
            query: queries.getSiteDetails,
            variables: {
              id: item.sitedetailsID,
            },
            authMode: "API_KEY",
          });
        });
        Promise.all(promises).then((sitesData) => {
          sitesData.forEach((sites) => {
            if (sites.data.getSiteDetails !== null) {
              sitesforUsers.push(sites.data.getSiteDetails);
            }
          });
          //setSites(sitesforUsers.filter((item) => item.status === "Active"));
          setSiteDetails(
            sitesforUsers.filter((item) => item.status === "Active")
          );
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(sitesforUsers))
          );
          //SiteData.setSiteData(sitesforUsers);
        });
        // for (let site of mergesites) {
        //   const sitesData = await API.graphql({
        //     query: queries.getSiteDetails,
        //     variables: {
        //       id: site.id,
        //     },
        //     authMode: "API_KEY",
        //   });
        //   if (sitesData.data.getSiteDetails !== null) {
        //     sitesforUsers.push(sitesData.data.getSiteDetails);
        //     setSites(sitesforUsers);
        //     setSiteDetails(sitesforUsers);
        //     localStorage.setItem(
        //       "site",
        //       base64.encode(JSON.stringify(sitesforUsers))
        //     );
        //     SiteData.setSiteData(sitesforUsers);
        //   }
        // }
      }
    }
  }
  async function fetchSiteDetailsSU() {
    try {
      //setIsLoading(true);
      const response = await API.graphql({
        query: mergedQueries.getSiteUsersFromLink,
        authMode: "API_KEY",
        variables: {
          //nextToken: '',
          assetownerID: LoginUserDetails.assetOwnerId,
          userdetailsID: LoginUserDetails.id,
        },
      });
      console.log(response);
      let nextToken = "";
      let sitess = [];
      if (response.data.siteDetailsByAssetownerID.items.length > 0) {
        let sites = response.data.siteDetailsByAssetownerID.items;
        nextToken = response.data.siteDetailsByAssetownerID.nextToken;
        for (let site of sites) {
          console.log(site);
          console.log(site.LinkUsersAndSites.items.length);
          if (site.LinkUsersAndSites.items.length > 0) {
            sitess.push(site);
          }
        }
        while (nextToken !== null) {
          const results = await API.graphql({
            query: mergedQueries.getSiteUsersFromLink,
            variables: {
              nextToken: nextToken,
              assetownerID: LoginUserDetails.assetOwnerId,
              userdetailsID: LoginUserDetails.id,
            },
            authMode: "API_KEY",
          });
          nextToken = results.data.siteDetailsByAssetownerID.nextToken;
          sites = results.data.siteDetailsByAssetownerID.items;
          for (let site of sites) {
            console.log(site);
            console.log(site.LinkUsersAndSites.items.length);
            if (site.LinkUsersAndSites.items.length > 0) {
              sitess.push(site);
            }
          }
        }
        console.log(sitess);
        if (sitess.length > 0) {
          const mergeSites = sitess.flat(1);
          setSiteDetails(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setSiteDetails([]);
      setIsLoading(false);
    }
  }

  const imageURL = async (fileName) => {
    try {
      //const parsedJSON = JSON.parse(fileurl);
      console.log(fileName);
      //const fileName = parsedJSON[0]?.fileName || null;

      var params = {
        Bucket: S3_BUCKET,
        Key: `public/${CERTIFICATES_FOLDER}/${fileName}`,
      };
      var promise = myBucket.getSignedUrlPromise("getObject", params);
      promise.then(
        function (url) {
          console.log(url);
          window.open(url, "_blank");
        },
        function (err) {
          console.log(err);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const extractFileName = (path) => {
    try {
      const parsedJSON = JSON.parse(path);
      const fileNames = parsedJSON.map((obj) => obj.fileName);
      return fileNames;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  async function fetchCertificates(catID, siteId) {
    try {
      //setIsLoading(true);
      const response = await API.graphql({
        query: queries.certificatesBySitedetailsID,
        variables: {
          limit: limit,
          sitedetailsID: siteId,
          filter: {
            categoriesID: {
              eq: catID,
            },
          },
        },
        authMode: "API_KEY",
      });
      if (response.data.certificatesBySitedetailsID.items.length > 0) {
        setIsLoading(false);
        const certificateDetails =
          response.data.certificatesBySitedetailsID.items;
        console.log(certificateDetails);
        console.log(LoggedInUserId);
        setCertificateDetails(certificateDetails);
        setCollection(cloneDeep(certificateDetails.slice(0, countPerPage)));
      } else {
        setIsLoading(false);
        setCertificateDetails([]);
        setCollection([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // --
  async function fetchListCertificates(catId) {
    try {
      //--

      console.log(siteDetails);
      if (siteDetails.length > 0) {
        //setIsLoading(true);
        let nextToken1 = "";
        let assets1 = [];
        for (let item of siteDetails) {
          const result = await API.graphql({
            query: queries.certificatesBySitedetailsID,
            variables: {
              sitedetailsID: item.id,
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (result.data.certificatesBySitedetailsID.items.length > 0) {
            let assetDatas = result.data.certificatesBySitedetailsID.items;
            nextToken1 = result.data.certificatesBySitedetailsID.nextToken;
            if (result.data.certificatesBySitedetailsID.items.length > 0) {
              assets1.push(assetDatas);
            }
            while (nextToken1 !== null) {
              const results = await API.graphql({
                query: queries.certificatesBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  limit: limit,
                  nextToken: nextToken1,
                },
                authMode: "API_KEY",
              });
              if (results.data.certificatesBySitedetailsID.items.length > 0) {
                nextToken1 = results.data.certificatesBySitedetailsID.nextToken;
                if (results.data.certificatesBySitedetailsID.items.length > 0) {
                  assets1.push(results.data.certificatesBySitedetailsID.items);
                }
              }
            }
          }
        }
        console.log(assets1);
        if (assets1.length > 0) {
          const mergeAssets1 = assets1.flat(1);
          console.log(mergeAssets1);
          //--
          const certiData = mergeAssets1.filter(
            (item) => item.categoriesID === catId
          );

          const sortItems = certiData.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          console.log(sortItems);
          console.log(LoggedInUserId);
          const certificateDetails = sortItems;
          setCertificateDetails(certificateDetails);
          setCollection(cloneDeep(certificateDetails.slice(0, countPerPage)));
          setIsLoading(false);
        } else {
          setCertificateDetails([]);
          setCollection([]);
          setIsLoading(false);
        }
      } else {
        //setIsLoading(true);
        let nextToken = "";
        let sites = [];
        const result = await API.graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: 200000,
          },
          authMode: "API_KEY",
        });
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          let assetDatas = result.data.siteDetailsByAssetownerID.items;
          nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            sites.push(assetDatas);
          }
          while (nextToken !== null) {
            const results = await API.graphql({
              query: queries.siteDetailsByAssetownerID,
              variables: {
                assetownerID: LoginUserDetails.id,
                limit: 200000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              nextToken = results.data.siteDetailsByAssetownerID.nextToken;
              if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                sites.push(results.data.siteDetailsByAssetownerID.items);
              }
            }
          }
          console.log(sites);
          if (sites.length > 0) {
            let nextToken1 = "";
            let assets1 = [];
            const mergeSites = sites.flat(1);
            const sitefilter = mergeSites.filter(
              (item) => item.status === "Active"
            );
            for (let item of sitefilter) {
              const result = await API.graphql({
                query: queries.certificatesBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  limit: limit,
                },
                authMode: "API_KEY",
              });
              if (result.data.certificatesBySitedetailsID.items.length > 0) {
                let assetDatas = result.data.certificatesBySitedetailsID.items;
                nextToken1 = result.data.certificatesBySitedetailsID.nextToken;
                if (result.data.certificatesBySitedetailsID.items.length > 0) {
                  assets1.push(assetDatas);
                }
                while (nextToken1 !== null) {
                  const results = await API.graphql({
                    query: queries.certificatesBySitedetailsID,
                    variables: {
                      sitedetailsID: item.id,
                      limit: limit,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  });
                  if (
                    results.data.certificatesBySitedetailsID.items.length > 0
                  ) {
                    nextToken1 =
                      results.data.certificatesBySitedetailsID.nextToken;
                    if (
                      results.data.certificatesBySitedetailsID.items.length > 0
                    ) {
                      assets1.push(
                        results.data.certificatesBySitedetailsID.items
                      );
                    }
                  }
                }
              }
            }
            console.log(assets1);
            if (assets1.length > 0) {
              const mergeAssets1 = assets1.flat(1);
              console.log(mergeAssets1);
              const certiData = mergeAssets1.filter(
                (item) => item.categoriesID === catId
              );

              const sortItems = certiData.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              console.log(sortItems);
              console.log(LoggedInUserId);
              const certificateDetails = sortItems;
              console.log(certificateDetails);
              setCertificateDetails(sortItems);
              setCollection(cloneDeep(sortItems.slice(0, countPerPage)));
              setIsLoading(false);
            } else {
              setCertificateDetails([]);
              setCollection([]);
              setIsLoading(false);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  //-- site user certificates
  async function fetchListCertificatesSU(catId) {
    try {
      //setIsLoading(true);
      //--
      let nextToken1 = "";
      let assets1 = [];
      if (siteDetails.length > 0) {
        for (let item of siteDetails) {
          const result = await API.graphql({
            query: queries.certificatesBySitedetailsID,
            variables: {
              sitedetailsID: item.id,
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (result.data.certificatesBySitedetailsID.items.length > 0) {
            let assetDatas = result.data.certificatesBySitedetailsID.items;
            nextToken1 = result.data.certificatesBySitedetailsID.nextToken;
            if (result.data.certificatesBySitedetailsID.items.length > 0) {
              assets1.push(assetDatas);
            }
            while (nextToken1 !== null) {
              const results = await API.graphql({
                query: queries.certificatesBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  limit: limit,
                  nextToken: nextToken1,
                },
                authMode: "API_KEY",
              });
              if (results.data.certificatesBySitedetailsID.items.length > 0) {
                nextToken1 = results.data.certificatesBySitedetailsID.nextToken;
                if (results.data.certificatesBySitedetailsID.items.length > 0) {
                  assets1.push(results.data.certificatesBySitedetailsID.items);
                }
              }
            }
          }
        }
        console.log(assets1);
        if (assets1.length > 0) {
          const mergeAssets1 = assets1.flat(1);
          console.log(mergeAssets1);
          //--
          const certiData = mergeAssets1.filter(
            (item) => item.categoriesID === catId
          );

          const sortItems = certiData.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          console.log(sortItems);
          console.log(LoggedInUserId);
          const certificateDetails = sortItems;
          console.log(certificateDetails);
          setCertificateDetails(certificateDetails);
          setCollection(cloneDeep(certificateDetails.slice(0, countPerPage)));
          setIsLoading(false);
        } else {
          setCertificateDetails([]);
          setCollection([]);
          setIsLoading(false);
        }
      } else {
        try {
          ////setIsLoading(true);
          let nextToken = "";
          let sites = [];
          const result = await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.assetOwnerId,
              limit: 200000,
            },
            authMode: "API_KEY",
          });
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            let assetDatas = result.data.siteDetailsByAssetownerID.items;
            nextToken = result.data.siteDetailsByAssetownerID.nextToken;
            if (result.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(assetDatas);
            }
            while (nextToken !== null) {
              const results = await API.graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: LoginUserDetails.assetOwnerId,
                  limit: 200000,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                nextToken = results.data.siteDetailsByAssetownerID.nextToken;
                if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                  sites.push(results.data.siteDetailsByAssetownerID.items);
                }
              }
            }
            console.log(sites);
            if (sites.length > 0) {
              const mergeSites = sites.flat(1);
              const sitefilter = mergeSites.filter(
                (item) => item.status === "Active"
              );
              for (let item of sitefilter) {
                const result = await API.graphql({
                  query: queries.certificatesBySitedetailsID,
                  variables: {
                    sitedetailsID: item.id,
                    limit: limit,
                  },
                  authMode: "API_KEY",
                });
                if (result.data.certificatesBySitedetailsID.items.length > 0) {
                  let assetDatas =
                    result.data.certificatesBySitedetailsID.items;
                  nextToken1 =
                    result.data.certificatesBySitedetailsID.nextToken;
                  if (
                    result.data.certificatesBySitedetailsID.items.length > 0
                  ) {
                    assets1.push(assetDatas);
                  }
                  while (nextToken1 !== null) {
                    const results = await API.graphql({
                      query: queries.certificatesBySitedetailsID,
                      variables: {
                        sitedetailsID: item.id,
                        limit: limit,
                        nextToken: nextToken1,
                      },
                      authMode: "API_KEY",
                    });
                    if (
                      results.data.certificatesBySitedetailsID.items.length > 0
                    ) {
                      nextToken1 =
                        results.data.certificatesBySitedetailsID.nextToken;
                      if (
                        results.data.certificatesBySitedetailsID.items.length >
                        0
                      ) {
                        assets1.push(
                          results.data.certificatesBySitedetailsID.items
                        );
                      }
                    }
                  }
                }
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                console.log(mergeAssets1);
                //--
                const certiData = mergeAssets1.filter(
                  (item) => item.categoriesID === catId
                );

                const sortItems = certiData.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                console.log(sortItems);
                console.log(LoggedInUserId);
                const certificateDetails = sortItems;
                console.log(certificateDetails);
                setCertificateDetails(certificateDetails);
                setCollection(
                  cloneDeep(certificateDetails.slice(0, countPerPage))
                );
                setIsLoading(false);
              } else {
                setCertificateDetails([]);
                setCollection([]);
                setIsLoading(false);
              }
            }
          }
        } catch (error) {
          console.log(error);
          setSiteDetails([]);
          //setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    //fetchSiteDetails();
    if (localStorage.getItem("site") === null && siteDetails.length === 0) {
      LoginUserGroup === "site_owner"
        ? fetchSiteDetailsAO()
        : LoginUserGroup === "site_user"
        ? fetchSiteDetailsSU(LoginUserDetails.assetOwnerId)
        : fetchSiteDetailsSP(LoginUserDetails.id);
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSiteDetails(activeSites.filter((item) => item.status === "Active"));
    }
    if (state.state && state.state.category && state.state.site) {
      fetchCertificates(state.state.category.id, state.state.site.id);
    } else {
      if (LoginUserGroup === "site_user") {
        // if (localStorage.getItem("site") === null && siteDetails.length === 0) {
        //   fetchSiteDetailsSU(LoginUserDetails.assetOwnerId);
        // } else {
        //   let activeSites = JSON.parse(
        //     base64.decode(localStorage.getItem("site"))
        //   );
        //   setSiteDetails(
        //     activeSites.filter((item) => item.status === "Active")
        //   );
        // }
        if (
          state &&
          state.state &&
          state.state.category &&
          state.state.category.id
        ) {
          fetchListCertificatesSU(state.state.category.id);
        } else {
          console.error("State or its properties are null or undefined.");
        }
      } else if (LoginUserGroup === "electrician") {
        //fetchListCertificatesSP(state.state.category.id);
        // if (siteDetails.length === 0) {
        //   fetchSiteDetailsSP(LoginUserDetails.id);
        // }
        // if (localStorage.getItem("site") === null && siteDetails.length === 0) {
        //   fetchSiteDetailsSP(LoginUserDetails.id);
        // } else {
        //   let activeSites = JSON.parse(
        //     base64.decode(localStorage.getItem("site"))
        //   );
        //   setSiteDetails(
        //     activeSites.filter((item) => item.status === "Active")
        //   );
        // }
        if (
          state &&
          state.state &&
          state.state.category &&
          state.state.category.id
        ) {
          fetchListCertificatesSP(state.state.category.id);
        } else {
          console.error("State or its properties are null or undefined.");
        }
      } else {
        // if (localStorage.getItem("site") === null && siteDetails.length === 0) {
        //   fetchSiteDetailsAO();
        // } else {
        //   let activeSites = JSON.parse(
        //     base64.decode(localStorage.getItem("site"))
        //   );
        //   setSiteDetails(
        //     activeSites.filter((item) => item.status === "Active")
        //   );
        // }
        if (
          state &&
          state.state &&
          state.state.category &&
          state.state.category.id
        ) {
          fetchListCertificates(state.state.category.id);
        } else {
          console.error("State or its properties are null or undefined.");
        }
      }
    }
  }, [selectedSiteGlobal, selectedAssetOwnerGlobal]);

  useEffect(() => {
    if (selectedSiteGlobal) {
      if (
        state &&
        state.state &&
        state.state.category &&
        state.state.category.id
      ) {
        //fetchListCertificates(state.state.category.id);
        fetchCertificates(state.state.category.id, selectedSiteGlobal);
      } else {
        console.error("State or its properties are null or undefined.");
      }
    }
  }, [selectedSiteGlobal]);

  const cancelForm = () => {
    if (state.state.category && state.state.site) {
      navigate(`${process.env.PUBLIC_URL}/sites/listsubcat`, {
        state: {
          category: state.state.category,
          site: state.state.site,
        },
      });
    } else {
      navigate(`${process.env.PUBLIC_URL}/certificates`);
    }
  };

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    if (!value) {
      setCollection(cloneDeep(certificateDetails.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      setCollection(cloneDeep(filteredData.slice(from, to)));
    }
  };

  // ---Search ----
  const globalSearch = (searchValue) => {
    let filteredData = certificateDetails.filter((row) => {
      return (
        row.certificateName.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.certificateType.toLowerCase().includes(searchValue.toLowerCase()) ||
        moment(row.createdAt)
          .format("DD/MM/YYYY")
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(certificateDetails.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const getSiteName = (siteId) => {
    const siteName = siteDetails.filter((item) => {
      return item.id === siteId;
    });
    return siteName.length > 0
      ? siteName[0].siteName + " " + "(" + siteName[0].unitNumber + ")"
      : "";
  };

  async function fetchListCertificatesSP(catId) {
    try {
      //setIsLoading(true);
      if (siteDetails.length > 0) {
        const data = siteDetails.filter(
          (item) => item.assetownerID === selectedAssetOwnerGlobal
        );
        const filterdata = selectedAssetOwnerGlobal ? data : siteDetails;
        console.log(filterdata);

        let nextToken2 = "";
        let assets2 = [];
        for (let item of filterdata) {
          const result = await API.graphql({
            query: queries.certificatesBySitedetailsID,
            variables: {
              sitedetailsID: item.id,
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (result.data.certificatesBySitedetailsID.items.length > 0) {
            let assetDatas = result.data.certificatesBySitedetailsID.items;
            nextToken2 = result.data.certificatesBySitedetailsID.nextToken;
            if (result.data.certificatesBySitedetailsID.items.length > 0) {
              assets2.push(assetDatas);
            }
            while (nextToken2 !== null) {
              const results = await API.graphql({
                query: queries.certificatesBySitedetailsID,
                variables: {
                  sitedetailsID: item.sitedetailsID,
                  limit: limit,
                  nextToken: nextToken2,
                },
                authMode: "API_KEY",
              });
              if (results.data.certificatesBySitedetailsID.items.length > 0) {
                nextToken2 = results.data.certificatesBySitedetailsID.nextToken;
                if (results.data.certificatesBySitedetailsID.items.length > 0) {
                  assets2.push(results.data.certificatesBySitedetailsID.items);
                }
              }
            }
          }
        }
        console.log(assets2);
        if (assets2.length > 0) {
          const mergeAssets2 = assets2.flat(1);
          console.log(mergeAssets2);
          //--
          const certiData = mergeAssets2.filter(
            (item) => item.categoriesID === catId
          );

          const sortItems = certiData.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          console.log(sortItems);
          console.log(LoggedInUserId);
          const certificateDetails = sortItems;
          console.log(certificateDetails);
          setCertificateDetails(certificateDetails);
          setCollection(cloneDeep(certificateDetails.slice(0, countPerPage)));
          setIsLoading(false);
        } else {
          setCertificateDetails([]);
          setCollection([]);
          setIsLoading(false);
        }
      } else {
        let nextToken = "";
        let sites = [];
        const result = await API.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: limit,
          },
        });
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
          nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
          sites.push(assetDatas);
          while (nextToken !== null) {
            const results = await API.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: LoginUserDetails.id,
                limit: limit,
                nextToken: nextToken,
              },
            });
            nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
            if (
              results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
            ) {
              sites.push(results.data.linkUsersAndSitesByUserdetailsID.items);
            }
          }
          console.log(sites);
          if (sites.length > 0) {
            const mergesites = sites.flat(1);
            let sitesforUsers = [];
            const promises = mergesites.map(async (item) => {
              return await API.graphql({
                query: queries.getSiteDetails,
                variables: {
                  id: item.sitedetailsID,
                },
                authMode: "API_KEY",
              });
            });
            Promise.all(promises).then(async (sitesData) => {
              sitesData.forEach((sites) => {
                if (sites.data.getSiteDetails !== null) {
                  sitesforUsers.push(sites.data.getSiteDetails);
                }
              });

              const sitess = sitesforUsers.filter(
                (item) => item.status === "Active"
              );
              if (sitess.length > 0) {
                const data = sitess.filter(
                  (item) => item.assetownerID === selectedAssetOwnerGlobal
                );
                const filterdata = selectedAssetOwnerGlobal ? data : sitess;
                console.log(filterdata);

                let nextToken2 = "";
                let assets2 = [];
                for (let item of filterdata) {
                  const result = await API.graphql({
                    query: queries.certificatesBySitedetailsID,
                    variables: {
                      sitedetailsID: item.id,
                      limit: limit,
                    },
                    authMode: "API_KEY",
                  });
                  if (
                    result.data.certificatesBySitedetailsID.items.length > 0
                  ) {
                    let assetDatas =
                      result.data.certificatesBySitedetailsID.items;
                    nextToken2 =
                      result.data.certificatesBySitedetailsID.nextToken;
                    if (
                      result.data.certificatesBySitedetailsID.items.length > 0
                    ) {
                      assets2.push(assetDatas);
                    }
                    while (nextToken2 !== null) {
                      const results = await API.graphql({
                        query: queries.certificatesBySitedetailsID,
                        variables: {
                          sitedetailsID: item.sitedetailsID,
                          limit: limit,
                          nextToken: nextToken2,
                        },
                        authMode: "API_KEY",
                      });
                      if (
                        results.data.certificatesBySitedetailsID.items.length >
                        0
                      ) {
                        nextToken2 =
                          results.data.certificatesBySitedetailsID.nextToken;
                        if (
                          results.data.certificatesBySitedetailsID.items
                            .length > 0
                        ) {
                          assets2.push(
                            results.data.certificatesBySitedetailsID.items
                          );
                        }
                      }
                    }
                  }
                }
                console.log(assets2);
                if (assets2.length > 0) {
                  const mergeAssets2 = assets2.flat(1);
                  console.log(mergeAssets2);
                  //--
                  const certiData = mergeAssets2.filter(
                    (item) => item.categoriesID === catId
                  );

                  const sortItems = certiData.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  });
                  console.log(sortItems);
                  console.log(LoggedInUserId);
                  const certificateDetails = sortItems;
                  console.log(certificateDetails);
                  setCertificateDetails(certificateDetails);
                  setCollection(
                    cloneDeep(certificateDetails.slice(0, countPerPage))
                  );
                  setIsLoading(false);
                } else {
                  setCertificateDetails([]);
                  setCollection([]);
                  setIsLoading(false);
                }
              }
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card pd-1">
              <CardHeader>
                <H1>
                  {state.state && state.state.category.categoryName} Related
                  Certificates
                </H1>
                <Row>
                  <Col md="6">
                    <InputGroup>
                      <span className="input-group-text">
                        <i className="icon-search"></i>
                      </span>
                      <Input
                        type="search"
                        placeholder="Search Certificates.."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    {userPermission.includes("Create Certificates") && (
                      <Btn
                        attrBtn={{
                          color: "primary",
                          onClick: () => {
                            state.state.category && state.state.site
                              ? navigate(
                                  `${process.env.PUBLIC_URL}/addcertificates`,
                                  {
                                    state: {
                                      category: state.state.category,
                                      site: state.state.site,
                                    },
                                  }
                                )
                              : navigate(
                                  `${process.env.PUBLIC_URL}/addcertificates`,
                                  {
                                    state: { category: state.state.category },
                                  }
                                );
                          },
                        }}
                      >
                        <PlusCircle />
                        Uplaod Certificates
                      </Btn>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="card-block row">
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <Table className="table-striped  table-de">
                        <thead className="custom-table-head">
                          <tr>
                            <th>Added On</th>
                            <th>Certificate Name</th>
                            <th>Certificate Type</th>
                            <th>Certificate URL</th>
                            <th>Site</th>
                          </tr>
                        </thead>
                        <tbody className="trhover">
                          {collection.length > 0 ? (
                            collection.map((item) => (
                              <tr key={item.id}>
                                <td>
                                  {moment(item.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>{item.certificateName}</td>
                                <td>{item.certificateType}</td>
                                <td>
                                  {item.certificateURL !== null
                                    ? extractFileName(item.certificateURL).map(
                                        (fileName, index) => (
                                          <div key={index}>
                                            <button
                                              onClick={() => imageURL(fileName)}
                                              className="btn btn-link"
                                            >
                                              {fileName}
                                            </button>
                                          </div>
                                        )
                                      )
                                    : "No Certificate URL"}
                                </td>
                                <td>{getSiteName(item.sitedetailsID)}</td>
                              </tr>
                            ))
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan="5" className="text-center">
                                  No Certificates Available.
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </div>
              </CardBody>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={
                    value ? filteredData.length : certificateDetails.length
                  }
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
              <Col md="12" className="text-end">
                <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                  Back
                </Btn>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default Certificates;
