import React, { Fragment, useState, useEffect } from "react";
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mergedQueries from '../../graphql/mergedQueries';
import { toast } from 'react-toastify';
import { CardBody, CardHeader, Card, Row, Col, Media } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { H4 } from '../../AbstractElements';
import { useNavigate, useLocation, useParams , Link} from 'react-router-dom';

const NotificationConfigurationContain = () => {

    useEffect(() => {
    }, []);


    return (
        <Fragment>
            <Card className="custom-card">
                <CardHeader >
                    <H4>Notification Configuration</H4>
                </CardHeader>
            </Card>
        </Fragment>
    );
}
export default NotificationConfigurationContain;