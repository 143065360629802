import { Btn, P, H1 } from "../../AbstractElements";
import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Container,
  Col,
  Form,
  Label,
  Row,
  Card,
  CardBody,
  Input,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import awsExports from "../../aws-exports";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import {
  S3_BUCKET,
  REGION,
  CERTIFICATES_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
  limit,
} from "../../Config/Config";
import Select from "react-select";
import { aocontext } from "../../App";
import SiteData from "../../Data/Sites/index";
var base64 = require("base-64");

Amplify.configure(awsExports);

const AddCertificateContain = (props) => {
  const navigate = useNavigate();
  const state = useLocation();

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    getValues,
    ...form
  } = useForm();
  const [siteDetails, setSiteDetails] = useState(SiteData.getSiteData());
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [siteId, setSiteId] = useState([]);
  const [siteIdvalue, setSiteIdvalue] = useState("");
  const LoginUserGroup = localStorage.getItem("groups");
  const [fileSelected, setFileSelected] = useState(true);
  const [siteselected, setSiteselected] = useState(true);
  //const [limit, setLimit] = useState(100000);

  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);

  useEffect(async () => {
    if (localStorage.getItem("site") === null && siteDetails.length === 0) {
      LoginUserGroup === "site_user" || LoginUserGroup === "electrician"
        ? fetchSiteDetailsSPSU(LoginUserDetails.id)
        : fetchSiteDetailsAO();
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSiteDetails(activeSites.filter((item) => item.status === "Active"));
    }
  }, [selectedAssetOwnerGlobal]);

  async function fetchSiteDetailsAO() {
    try {
      let nextToken = "";
      let sites = [];
      const result = await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      // .then(async (result) => {
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        sites.push(assetDatas);
        while (nextToken !== null) {
          const results = await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            sites.push(results.data.siteDetailsByAssetownerID.items);
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setSiteDetails(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        } else {
          setSiteDetails([]);
          SiteData.setSiteData([]);
        }
      } else {
        setSiteDetails([]);
        SiteData.setSiteData([]);
      }
    } catch (error) {
      console.log(error);
      setSiteDetails([]);
    }
  }
  //--sites for sp/su
  async function fetchSiteDetailsSPSU(assetOwnerId) {
    let nextToken = "";
    let assets = [];
    const result = await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: assetOwnerId,
        limit: limit,
      },
    });
    if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
      nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
      assets.push(assetDatas);
      while (nextToken !== null) {
        const results = await API.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: assetOwnerId,
            limit: limit,
            nextToken: nextToken,
          },
        });
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
          assets.push(results.data.linkUsersAndSitesByUserdetailsID.items);
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        const siteDetails = mergeAssets;
        let nextToken1 = "";
        let assets1 = [];
        const results1 = await API.graphql({
          query: queries.listSiteDetails,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (results1.data.listSiteDetails.items.length > 0) {
          let assetDatas = results1.data.listSiteDetails.items;
          nextToken1 = results1.data.listSiteDetails.nextToken;
          assets1.push(assetDatas);
          while (nextToken1 !== null) {
            const results = await API.graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: limit,
                nextToken: nextToken1,
              },
              authMode: "API_KEY",
            });
            if (results.data.listSiteDetails.items.length > 0) {
              nextToken1 = results.data.listSiteDetails.nextToken;
              assets1.push(results.data.listSiteDetails.items);
            }
          }
          console.log(assets1);
          if (assets1.length > 0) {
            const mergeAssets1 = assets1.flat(1);
            const siteDetail = mergeAssets1.filter((item) => {
              const _matchingAssetTemplate = siteDetails?.find(
                (site) => site.sitedetailsID === item?.id
              );
              if (_matchingAssetTemplate) {
                return item;
              }
            });
            //fetchlistDistributionBox(siteDetail);
            setSiteDetails(
              siteDetail.filter((item) => item.status === "Active")
            );
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(siteDetail))
            );
            SiteData.setSiteData(siteDetail);
          }
        }
      }
    }
  }

  async function onSubmit(formData) {
    try {
      console.log(fileSelected);
      console.log(fileList);
      const certificateUrl = fileList.map((file) => ({
        fileName: file.name,
        type: file.type,
      }));

      console.log(certificateUrl);
      onSelectFilemultiple(fileList);
      if (siteIdvalue === "" && certificateUrl.length === 0) {
        setFileSelected(false);
        setSiteselected(false);
      }
      if (certificateUrl.length > 0) {
        setFileSelected(true);

        if (siteIdvalue !== "" || (state.state && state.state.site)) {
          setSiteselected(true);

          console.log(state.state.category);
          console.log("formData", formData);
          console.log("certificateUrl", certificateUrl);
          console.log("LoginUserDetails.id", LoginUserDetails.id);
          try {
            await API.graphql({
              query: mutations.createCertificates,
              variables: {
                input: {
                  certificateName: formData.certificateName,
                  certificateType: formData.certificateType,
                  certificateURL: JSON.stringify(certificateUrl),
                  categoriesID: state.state.category.id,
                  sitedetailsID:
                    state.state && state.state.site && state.state.category
                      ? state.state.site.id
                      : siteId,
                  userID: LoginUserDetails.id,
                },
              },
              authMode: "API_KEY",
            })
              .then((result) => {
                console.log("result", result);
                toast.success("Successfully Uploaded Certificates.", {
                  position: toast.POSITION.TOP_CENTER,
                });
                if (state.state.category && state.state.site) {
                  navigate(`${process.env.PUBLIC_URL}/catCertificates`, {
                    state: {
                      category: state.state.category,
                      site: state.state.site,
                    },
                  });
                } else {
                  navigate(`${process.env.PUBLIC_URL}/catCertificates`, {
                    state: {
                      category: state.state.category,
                    },
                  });
                }
              })
              .catch((error) => {
                toast.error("Error While Uploading Certificates : " + error, {
                  position: toast.POSITION.TOP_CENTER,
                });
              });
          } catch (e) {
            toast.error("Error While Uploading Certificates : " + e.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        } else {
          setSiteselected(false);
        }
      } else if (siteselected !== "" || (state.state && state.state.site)) {
        setSiteselected(true);

        if (certificateUrl.length > 0) {
          setFileSelected(true);

          console.log(state.state.category);
          console.log("formData", formData);
          console.log("certificateUrl", certificateUrl);
          console.log("LoginUserDetails.id", LoginUserDetails.id);
          try {
            await API.graphql({
              query: mutations.createCertificates,
              variables: {
                input: {
                  certificateName: formData.certificateName,
                  certificateType: formData.certificateType,
                  certificateURL: JSON.stringify(certificateUrl),
                  categoriesID: state.state.category.id,
                  sitedetailsID:
                    state.state && state.state.site && state.state.category
                      ? state.state.site.id
                      : siteId,
                  userID: LoginUserDetails.id,
                },
              },
              authMode: "API_KEY",
            })
              .then((result) => {
                console.log("result", result);
                toast.success("Successfully Uploaded Certificates.", {
                  position: toast.POSITION.TOP_CENTER,
                });
                if (state.state.category && state.state.site) {
                  navigate(`${process.env.PUBLIC_URL}/catCertificates`, {
                    state: {
                      category: state.state.category,
                      site: state.state.site,
                    },
                  });
                } else {
                  navigate(`${process.env.PUBLIC_URL}/catCertificates`, {
                    state: {
                      category: state.state.category,
                    },
                  });
                }
              })
              .catch((error) => {
                toast.error("Error While Uploading Certificates : " + error, {
                  position: toast.POSITION.TOP_CENTER,
                });
              });
          } catch (e) {
            toast.error("Error While Uploading Certificates : " + e.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        } else {
          setFileSelected(false);
        }
      } else {
        setFileSelected(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const cancelForm = () => {
    //navigate(`${process.env.PUBLIC_URL}/catCertificates`, {
    //  state: { category: state.state.category },
    //});

    if (state.state.category && state.state.site) {
      navigate(`${process.env.PUBLIC_URL}/catCertificates`, {
        state: {
          category: state.state.category,
          site: state.state.site,
        },
      });
    } else {
      navigate(`${process.env.PUBLIC_URL}/catCertificates`, {
        state: {
          category: state.state.category,
        },
      });
    }
  };

  // Image Upload
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [fileList, setFileList] = useState([]);
  const onSelectFile = (e) => {
    const selectedFiles = Array.from(e.target.files);
    console.log(selectedFiles);
    setFileList((prevFileList) => [...prevFileList, ...selectedFiles]);
  };

  const onSelectFilemultiple = (files) => {
    try {
      const filedetails = [];

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        const params = {
          ACL: "public-read",
          Body: file,
          Bucket: S3_BUCKET,
          Key: `public/${CERTIFICATES_FOLDER}/${file.name}`,
        };

        myBucket
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(evt);
            filedetails.push({ fileName: file.name, type: file.type });
            console.log(filedetails);
            //setCertificateUrl(filedetails);
          })
          .send((err) => {
            if (err) console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const optionsSites = [
    { value: "", label: "Select Site" },
    ...siteDetails.map((item) => ({
      value: item.id,
      label:
        item.siteName +
        " " +
        "(" +
        (item.unitNumber ? item.unitNumber : "") +
        ")",
    })),
  ];

  const handleSite = (event) => {
    console.log(event.value);
    setSiteId(event.value);
    setSiteIdvalue(event);
    setSiteselected(true);
  };

  return (
    <Fragment>
      <br></br>
      <br></br>
      <Container fluid={true} style={{ paddingLeft: "0px !important" }}>
        <br></br>
        <H1>{state.state.category.categoryName}</H1>
        <Card>
          <CardBody>
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col md="12">
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Certificate Name</Label>
                      <input
                        type="text"
                        className="form-control"
                        name="certificateName"
                        {...register("certificateName", { required: true })}
                      />
                      <span>
                        {errors.certificateName &&
                          "Certificate Name is required"}
                      </span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Certificate Type</Label>
                      <input
                        type="text"
                        className="form-control"
                        name="certificateType"
                        {...register("certificateType", { required: true })}
                      />
                      <span>
                        {errors.certificateType &&
                          "Certificate Type is required"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Sites</Label>
                      {state.state &&
                      state.state.site &&
                      state.state.category ? (
                        <>
                          <select
                            className="form-control"
                            name="site"
                            placeholder="Sites"
                            value={
                              state.state &&
                              state.state.site &&
                              state.state.category
                                ? state.state.site.id
                                : siteId
                            }
                            readonly={
                              state.state &&
                              state.state.site &&
                              state.state.category
                                ? true
                                : false
                            }
                            {...register("site", {
                              required:
                                state.state &&
                                state.state.site &&
                                state.state.category
                                  ? false
                                  : true,
                            })}
                            onChange={(event) => setSiteId(event.target.value)}
                          >
                            <option value="">Select Sites</option>
                            {siteDetails.map((item) => (
                              <option value={item.id} key={item.id}>
                                {(item?.siteName || "") +
                                  " (" +
                                  (item?.unitNumber || "") +
                                  ")"}
                              </option>
                            ))}
                          </select>

                          <span>{errors.site && "Site is required"}</span>
                        </>
                      ) : (
                        <>
                          <Select
                            options={optionsSites}
                            isSearchable={true}
                            value={siteIdvalue}
                            placeholder="Select Sites"
                            onChange={handleSite}
                            name="site"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />

                          <span>{!siteselected && "Site is required"}</span>
                        </>
                      )}
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Image</Label>
                      <Input
                        type="file"
                        className="form-control"
                        name="certificateUrl"
                        onChange={onSelectFile}
                        multiple
                      />
                      <span>
                        {!fileSelected && "Certificate Image is required"}
                      </span>
                      {/* <span>
                        {errors.certificateUrl &&
                          certificateUrlError &&
                          "Certificate URL is required"}
                      </span> */}
                    </Col>
                  </Row>
                </Col>
                <Col md="12" className="text-end">
                  <Btn attrBtn={{ color: "primary" }}>Upload Certificates</Btn>
                  <Btn attrBtn={{ color: "danger", onClick: cancelForm }}>
                    Cancel
                  </Btn>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <br></br>
        <br></br>
      </Container>
    </Fragment>
  );
};
export default AddCertificateContain;
