import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import SiteUserPanelDetails from '../../Component/SiteUserPanel';

const SiteUserPanel = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Site" title="User Panel" />
      <SiteUserPanelDetails />
    </Fragment>
  );
};
export default SiteUserPanel;

