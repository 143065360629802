var base64 = require("base-64");
var SPCompany = (function () {
  var spCompanyDetails = [];
  var SPUserNameDetails = [];

  var getSPCompany = function () {
    try {
      if (spCompanyDetails === "") {
        spCompanyDetails = JSON.parse(
          base64.decode(localStorage.getItem("spCompany"))
        );
      }
    } catch (error) {
      console.log(error);
    }
    return spCompanyDetails; // Or pull this from cookie/localStorage
  };

  var setSPCompany = function (spCompany) {
    try {
      if (spCompany === "") {
        spCompanyDetails = JSON.parse(
          base64.decode(localStorage.getItem("spCompany"))
        );
      } else {
        spCompanyDetails = spCompany;
      }
    } catch (error) {
      console.log(error);
    }

    // Also set this in cookie/localStorage
  };


  var getSPUserName = function () {
    try {
      if (SPUserNameDetails === "") {
        SPUserNameDetails = JSON.parse(
          base64.decode(localStorage.getItem("spUserName"))
        );
      }
    } catch (error) {
      console.log(error);
    }
    return SPUserNameDetails; // Or pull this from cookie/localStorage
  };

  var setSPUserName = function (SPUserName) {
    try {
      if (SPUserName === "") {
        SPUserNameDetails = JSON.parse(
          base64.decode(localStorage.getItem("spUserName"))
        );
      } else {
        SPUserNameDetails = SPUserName;
      }
    } catch (error) {
      console.log(error);
    }

    // Also set this in cookie/localStorage
  };

  return {
    getSPUserName: getSPUserName,
    setSPUserName: setSPUserName,
    getSPCompany: getSPCompany,
    setSPCompany: setSPCompany,
  };
})();

export default SPCompany;
