import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AddAssetsContain from '../../../Component/Assets/AddAsset/AddAsset';

const AddAssets = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Assets" title="Manage Assets" />
      <AddAssetsContain />
    </Fragment>
  );
};
export default AddAssets;