import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import LinkUserwithSiteDetails from '../../../Component/Site/AddSite/linkUserwithSite';

const LinkUserwithSite = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Site" title="Site Users Link" />
      <LinkUserwithSiteDetails />
    </Fragment>
  );
};
export default LinkUserwithSite;