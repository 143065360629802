import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import SubscriptionsDetails from '../../Component/Subscriptions/subscriptions';

const Subscriptions = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Asset Owner" title="Subscriptions" />
      <SubscriptionsDetails />
    </Fragment>
  );
};
export default Subscriptions;