import React, { Fragment, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Printer } from "react-feather";
import { Card, CardHeader } from "reactstrap";
import { CreatedByMe, Print } from "../../Constant";
import { H5 } from "../../AbstractElements";
import CreatedByme from "./CreatedByme";
import CreateTag from "./CreateTag";

const ListOfTask = ({ activeTab }) => {
  const componentRef = useRef();
  return (
    <Fragment>
      <Card className="mb-0">
        <CardHeader className="d-flex">
          <H5 attrH6={{ className: "mb-0" }}>
            Testing History for Fitting Number - {activeTab.fittingNumber}
          </H5>
        </CardHeader>
        <CreatedByme activeTab={activeTab} />
      </Card>
      <br />
      <Card className="mb-0">
        <CardHeader className="d-flex">
          <H5 attrH6={{ className: "mb-0" }}>
            Repair History for Fitting Number - {activeTab.fittingNumber}
          </H5>
        </CardHeader>
        <CreateTag activeTab={activeTab} />
      </Card>
    </Fragment>
  );
};

export default ListOfTask;
