import React, { useState, useEffect } from "react";
import { H4, Btn, H2 } from "../../AbstractElements";
import { Row, Table, CardFooter, Col, Card } from "reactstrap";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { useForm } from "react-hook-form";
import { Amplify, API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import awsExports from "../../aws-exports";
import AWS from "aws-sdk";
import moment from "moment";
import jsPDF from "jspdf";
import Pagination from "rc-pagination";
import cloneDeep from "lodash/cloneDeep";
import {
  S3_BUCKET,
  REGION,
  COMPANY_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../Config/Config";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SiteData from "../../Data/Sites/index";

Amplify.configure(awsExports);

const GenerateTestingReports = ({ data }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [fullSites, setFullSites] = useState(SiteData.getSiteData());
  const [details, setDetails] = useState([]);
  const [sites, setSites] = useState([]);
  const [assets, setAssets] = useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [assetDemo, setAssetDemo] = useState([]);
  const [company, setCompany] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [ao, setAo] = useState([]);
  const [selectedImg, setselectedImg] = useState(null);
  const [downloadedFile, setdownloadedFile] = useState(null);
  const [mc, setMc] = useState([]);
  const [distri, setDistri] = useState([]);
  const [loading, setLoading] = useState(false);
  const LoginUserGroup = localStorage.getItem("groups");
  const [asset, setAsset] = useState([]);
  const [testingPDF, setTestingPDF] = useState([]);
  const [spdata, setspdata] = useState([]);
  const [assetVal, setAssetVal] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  async function getCompanyByAssetId() {
    try {
      await API.graphql({
        query: queries.listAssetOwners,
        variables: {
          limit: 2000000,
          filter: {
            id: {
              eq: LoginUserDetails.id,
            },
          },
        },
        authMode: "API_KEY",
      })
        .then((result2) => {
          console.log(result2);
          if (result2.data.listAssetOwners.items.length > 0) {
            API.graphql({
              query: queries.listCompanyDetails,
              variables: {
                limit: 2000000,
                filter: {
                  id: {
                    eq: result2.data.listAssetOwners.items[0].companydetailsID,
                  },
                },
              },
              authMode: "API_KEY",
            })
              .then((result) => {
                console.log(result);
                //setCompany(result.data.listCompanyDetails.items[0]);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    console.log(data);
    setDetails(data);
    fetchspdata();
    getSite(data.sites);
    console.log(data.sites);
    // fetchAssets();
    if (
      data.sites === "allSites" &&
      data.distributionBox === "allDistribution"
    ) {
      console.log("All site  and all distribution boards selected");

      if (LoginUserGroup === "site_user") {
        fetchSiteDetailsSU();
      } else if (LoginUserGroup === "electrician") {
        fetchSiteDetailsSU();
      } else {
        fetchTestingDemo();
      }
      getCompanyByAssetId();
    } else if (
      data.sites !== "allSites" &&
      data.distributionBox === "allDistribution"
    ) {
      console.log("single site  and all distribution boards selected");
      fetchTestingDemo1(data.sites);
    } else {
      getAssets(data.sites, data.distributionBox);
    }
    if (LoginUserGroup === "site_user") {
      fetchSUByAo();
    } else if (LoginUserGroup === "electrician") {
      fetchSUByAo();
    } else {
      getAO();
    }
    //getAO();
    getUserdetails();
    getDistribution();
  }, []);

  async function getSite(siteId) {
    await API.graphql({
      query: queries.listSiteDetails,
      variables: {
        limit: 2000000,
        filter: {
          id: {
            eq: siteId,
          },
        },
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setSites(result.data.listSiteDetails.items[0]);
        const CompId = result.data.listSiteDetails.items[0].companydetailsID;
        console.log(CompId);
        API.graphql({
          query: queries.listCompanyDetails,
          variables: {
            limit: 2000000,
            filter: {
              id: {
                eq: CompId,
              },
            },
          },
          authMode: "API_KEY",
        })
          .then((result2) => {
            console.log(result2);
            //setCompany(result2.data.listCompanyDetails.items[0]);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ----site user invited by ao
  async function fetchSUByAo() {
    //---
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.listUserDetails,
      variables: {
        limit: 2000000,
        filter: {
          id: {
            eq: LoginUserDetails.id,
          },
        },
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.listUserDetails.items;
        nextToken = result.data.listUserDetails.nextToken;
        if (result.data.listUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listUserDetails,
            variables: {
              limit: 2000000,
              filter: {
                id: {
                  eq: LoginUserDetails.id,
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.listUserDetails.nextToken;
              if (results.data.listUserDetails.items.length > 0) {
                assets.push(results.data.listUserDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const aoid = mergeAssets[0].assetOwnerId;
          console.log(aoid);
          //--
          let nextToken1 = "";
          let assets1 = [];
          await API.graphql({
            query: queries.listAssetOwners,
            variables: {
              limit: 2000000,
              filter: {
                id: {
                  eq: aoid,
                },
              },
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              let assetDatas = result.data.listAssetOwners.items;
              nextToken1 = result.data.listAssetOwners.nextToken;
              if (result.data.listAssetOwners.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await API.graphql({
                  query: queries.listAssetOwners,
                  variables: {
                    limit: 2000000,
                    filter: {
                      id: {
                        eq: aoid,
                      },
                    },
                    nextToken: nextToken1,
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken1 = results.data.listAssetOwners.nextToken;
                    if (results.data.listAssetOwners.items.length > 0) {
                      assets1.push(results.data.listAssetOwners.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                console.log(mergeAssets1);
                //--
                setAo(mergeAssets1[0]);
                //getfilename(mergeAssets1[0].profilePicture);
                console.log(mergeAssets1[0].profilePicture);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // AO details
  async function getAO() {
    //--
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.listAssetOwners,
      variables: {
        limit: 2000000,
        filter: {
          id: {
            eq: LoginUserDetails.id,
          },
        },
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.listAssetOwners.items;
        nextToken = result.data.listAssetOwners.nextToken;
        if (result.data.listAssetOwners.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listAssetOwners,
            variables: {
              limit: 2000000,
              filter: {
                id: {
                  eq: LoginUserDetails.id,
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.listAssetOwners.nextToken;
              if (results.data.listAssetOwners.items.length > 0) {
                assets.push(results.data.listAssetOwners.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setAo(mergeAssets[0]);
          //getfilename(mergeAssets[0].profilePicture);
          console.log(mergeAssets[0].profilePicture);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // maintenance  contractor
  async function getUserdetails() {
    //--
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.listSPUserDetails,
      variables: {
        filter: {
          id: {
            eq: LoginUserDetails.id,
          },
        },
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.listSPUserDetails.items;
        nextToken = result.data.listSPUserDetails.nextToken;
        if (result.data.listSPUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listSPUserDetails,
            variables: {
              limit: 2000000,
              filter: {
                id: {
                  eq: LoginUserDetails.id,
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.listSPUserDetails.nextToken;
              if (results.data.listSPUserDetails.items.length > 0) {
                assets.push(results.data.listSPUserDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          //setMc(mergeAssets[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ---Distribution Board ----
  async function getDistribution() {
    //---
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.listDistributionBoxes,
      variables: {
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.listDistributionBoxes.items;
        nextToken = result.data.listDistributionBoxes.nextToken;
        if (result.data.listDistributionBoxes.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listDistributionBoxes,
            variables: {
              limit: 2000000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.listDistributionBoxes.nextToken;
              if (results.data.listDistributionBoxes.items.length > 0) {
                assets.push(results.data.listDistributionBoxes.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setDistri(mergeAssets);
        } else {
          setDistri([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getDB(id) {
    const db = distri.find((item) => item.id === id);
    return db ? db.distributionBoxName : "";
  }

  //------------------------------------------------------------------------
  const getImage = (filename) => {
    try {
      var params = {
        Bucket: S3_BUCKET,
        Key: `public/${COMPANY_FOLDER}/${filename}`,
      };
      var promise = myBucket.getSignedUrlPromise("getObject", params);
      promise.then(
        function (url) {
          console.log(url);
          setdownloadedFile(url);
          setselectedImg(url);
        },
        function (err) {
          console.log(err);
        }
      );
    } catch (error) {
      console.log("Error while fetching:", error);
    }
  };

  //-------------------------------------------------------------------------
  function getfilename(path) {
    try {
      //const files = JSON.parse(path);
      const files = path;
      console.log(files);
      if (files) {
        var newfilename = files;
        getImage(newfilename);
      }
    } catch (error) {
      console.log("Error parsing JSON input:", error);
    }
  }

  //---single site and single distribution board
  async function getAssets(siteId, distriButionBox) {
    console.log(siteId);
    setLoading(true);
    let nextToken = "";
    let assets = [];
    let filter = {};
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - data.date);
    if (data.date !== "") {
      if (data.date === "Custom") {
        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);

        filter = {
          testOn: {
            between: [
              moment(startDate).format("YYYY-MM-DD"),
              moment(endDate).format("YYYY-MM-DD"),
            ],
          },
        };
      } else {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - data.date);

        filter = {
          testOn: {
            between: [
              moment(thirtyDaysAgo).format("YYYY-MM-DD"),
              moment(today).format("YYYY-MM-DD"),
            ],
          },
        };
      }
    } else {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      filter = {
        testOn: {
          between: [
            moment(thirtyDaysAgo).format("YYYY-MM-DD"),
            moment(today).format("YYYY-MM-DD"),
          ],
        },
      };
    }

    await API.graphql({
      query: queries.assetsBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        filter: {
          status: {
            ne: "delete",
          },
        },
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.assetsBySitedetailsID.items;
        nextToken = result.data.assetsBySitedetailsID.nextToken;
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.assetsBySitedetailsID,
            variables: {
              sitedetailsID: siteId,
              filter: {
                status: {
                  ne: "delete",
                },
              },
              limit: 2000000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.assetsBySitedetailsID.nextToken;
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets.push(results.data.assetsBySitedetailsID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          const distri = mergeAssets.filter(
            (item) => item.distributionboxID === distriButionBox
          );
          console.log(distri);
          let assetFilter = [];
          let testing = distri.filter((item) => {
            const _matchingAssetTemplate = data.assetTemp?.find(
              (temp) => temp === item?.assettemplateID
            );
            if (_matchingAssetTemplate) {
              return item;
            }
          });
          console.log(testing);
          const asset = testing;
          console.log(data.warranty);
          if (data.warranty !== "") {
            if (data.warranty === "InWarranty") {
              console.log("In Warranty");
              const today = new Date();
              assetFilter = asset.filter((item) => {
                const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                return assetWarrantyEndDate > today;
              });
            } else {
              console.log("Out Warranty");
              const today = new Date();
              assetFilter = asset.filter((item) => {
                const assetWarrantyEndDate = new Date(item.assetWarrantyEnd);
                return assetWarrantyEndDate < today;
              });
            }
          } else {
            assetFilter = asset;
          }
          console.log(assetFilter);
          const testReport = [];
          if (assetFilter.length > 0) {
            const assetOptions = [{ value: null, label: "All Assets" }];
            const sort = assetFilter.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            const sortoption = sort.filter((item) => item.status === "Active");
            sortoption.map(async (item) => {
              assetOptions.push({
                value: item.id,
                label: item.fittingNumber,
              });
            });
            console.log(sort);
            //---
            let nextToken1 = "";
            sort.map(async (item) => {
              let assets1 = [];

              API.graphql({
                query: queries.testingsByAssetsID,
                variables: {
                  limit: 2000000,
                  filter,
                  assetsID: item.id,
                },
                authMode: "API_KEY",
              })
                .then(async (result) => {
                  console.log(result);
                  let assetDatas = result.data.testingsByAssetsID.items;
                  nextToken1 = result.data.testingsByAssetsID.nextToken;
                  if (result.data.testingsByAssetsID.items.length > 0) {
                    assets1.push(assetDatas);
                  }
                  while (nextToken1 !== null) {
                    await API.graphql({
                      query: queries.testingsByAssetsID,
                      variables: {
                        limit: 2000000,
                        filter,
                        assetsID: item.id,
                        nextToken: nextToken1,
                      },
                      authMode: "API_KEY",
                    })
                      .then((results) => {
                        nextToken1 = results.data.testingsByAssetsID.nextToken;
                        if (results.data.testingsByAssetsID.items.length > 0) {
                          assets1.push(results.data.testingsByAssetsID.items);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  console.log(assets1);
                  if (assets1.length > 0) {
                    const mergeAssets1 = assets1.flat(1);
                    console.log(mergeAssets1);
                    //---
                    const testing = mergeAssets1;
                    console.log(testing);
                    if (testing.length > 0) {
                      testing.map((test) => {
                        const custom = JSON.parse(test.customFields);
                        const customFormatted =
                          custom &&
                          custom.map((item) => {
                            const key = Object.keys(item)[0];
                            const formattedValue = item[key];
                            return `${key} : ${formattedValue}`;
                          });
                        console.log(custom);
                        console.log(customFormatted);
                        let customFieldsFormatted = [];
                        if (customFormatted !== null) {
                          customFieldsFormatted = customFormatted.join("\n");
                          console.log(customFieldsFormatted);
                        } else {
                          console.log("customFormatted is null.");
                        }
                        testReport.push({
                          id: test.id,
                          createdAt: test.createdAt,
                          asset: item.fittingNumber,
                          distributionBox: item.distributionboxID,
                          testOn: test.testOn,
                          testResult: test.testResult,
                          spuserid: test.spUserId,
                          warrantyEnd: item.assetWarrantyEnd,
                          nextTest: test.nextTestSchedule,
                          customfields: customFieldsFormatted,
                          remarks: test.additionalInformation,
                        });
                      });
                      const sort = testReport.sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      );
                      console.log(sort);
                      const uniqueCombinedArray = Array.from(
                        new Map(sort.map((item) => [item.id, item])).values()
                      );
                      let testResultData = [];
                      if (data.testResult !== "") {
                        testResultData = sort.filter(
                          (item) =>
                            item.testResult.toLowerCase() ===
                            data.testResult.toLowerCase()
                        );
                        console.log(testResultData);
                        setAssetDemo(testResultData);
                        setTestingPDF(testResultData);
                        setAssets(
                          cloneDeep(testResultData.slice(0, countPerPage))
                        );
                        //---
                        const spUserIds = new Set();

                        testResultData.forEach((item) => {
                          spUserIds.add(item.spuserid);
                        });

                        const areSpUserIdsUnique =
                          spUserIds.size === testResultData.length;

                        if (areSpUserIdsUnique) {
                          console.log(spUserIds);
                          console.log("All spuserid values are unique.");
                          //--
                          const filterdata = testResultData.filter((item) => {
                            return spdata.some(
                              (spuser) =>
                                spuser.id === item.spuserid &&
                                spuser.SPGroup === "admin"
                            );
                          });
                          console.log(filterdata);

                          if (filterdata.length > 0) {
                            fetchspusercomp(filterdata[0].spuserid);
                          } else {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          }
                        } else {
                          console.log(spUserIds);
                          console.log("Some spuserid values are not unique.");
                          if (spUserIds.size === 1) {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          } else {
                            const filterdata = testResultData.filter((item) => {
                              return spdata.some(
                                (spuser) =>
                                  spuser.id === item.spuserid &&
                                  spuser.SPGroup === "admin"
                              );
                            });
                            console.log(filterdata);

                            if (filterdata.length > 0) {
                              fetchspusercomp(filterdata[0].spuserid);
                            } else {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            }
                          }
                        }
                        setLoading(false);
                      } else {
                        setAssetDemo(sort);
                        setTestingPDF(sort);
                        setAssets(cloneDeep(sort.slice(0, countPerPage)));
                        //---
                        const spUserIds = new Set();

                        sort.forEach((item) => {
                          spUserIds.add(item.spuserid);
                        });

                        const areSpUserIdsUnique =
                          spUserIds.size === sort.length;

                        if (areSpUserIdsUnique) {
                          console.log(spUserIds);
                          console.log("All spuserid values are unique.");
                          //--
                          const filterdata = sort.filter((item) => {
                            return spdata.some(
                              (spuser) =>
                                spuser.id === item.spuserid &&
                                spuser.SPGroup === "admin"
                            );
                          });
                          console.log(filterdata);

                          if (filterdata.length > 0) {
                            fetchspusercomp(filterdata[0].spuserid);
                          } else {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          }
                        } else {
                          console.log(spUserIds);
                          console.log("Some spuserid values are not unique.");
                          if (spUserIds.size === 1) {
                            const myArray = Array.from(spUserIds);
                            const myValue = myArray[0];
                            console.log(myValue);
                            fetchspusercompAdmin(myValue);
                          } else {
                            const filterdata = sort.filter((item) => {
                              return spdata.some(
                                (spuser) =>
                                  spuser.id === item.spuserid &&
                                  spuser.SPGroup === "admin"
                              );
                            });
                            console.log(filterdata);

                            if (filterdata.length > 0) {
                              fetchspusercomp(filterdata[0].spuserid);
                            } else {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            }
                          }
                        }
                        setLoading(false);
                      }
                    } else {
                      setAssetDemo(testReport);
                      setTestingPDF(testReport);
                      setAssets(cloneDeep(testReport.slice(0, countPerPage)));
                      setLoading(false);
                    }
                    setAsset(assetOptions);
                  } else {
                    setAssetDemo([]);
                    setTestingPDF([]);
                    setAssets(cloneDeep([].slice(0, countPerPage)));
                    setLoading(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setLoading(false);
                });
            });
          } else {
            setAssetDemo(testReport);
            setTestingPDF(testReport);
            setAssets(cloneDeep(testReport.slice(0, countPerPage)));
            setLoading(false);
          }
        } else {
          setAssetDemo([]);
          setTestingPDF([]);
          setAssets(cloneDeep([].slice(0, countPerPage)));
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //----testing pdf ---
  async function fetchspusercomp(userid) {
    await API.graphql({
      query: queries.getUserDetails,
      variables: {
        id: userid,
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        console.log(result);
        const SpUser = result.data.getUserDetails;
        //const compid = result.data.getUserdetails.companyDetailsId;
        await API.graphql({
          query: queries.getSPCompanyDetails,
          variables: {
            id: SpUser.companyDetailsId,
            limit: 2000000,
          },
          authMode: "API_KEY",
        })
          .then(async (result1) => {
            console.log(result1);
            const spcomp = result1.data.getSPCompanyDetails;
            console.log(spcomp);
            getfilename(spcomp.logo);
            setCompany(spcomp);
            //---
            await API.graphql({
              query: queries.getSPUserDetails,
              variables: {
                id: userid,
                limit: 2000000,
              },
              authMode: "API_KEY",
            })
              .then(async (result2) => {
                console.log(result2);
                const spp = result2.data.getSPUserDetails;
                console.log(spp);
                setMc(spp);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //----testing pdf filtering spgroup admin---
  async function fetchspusercompAdmin(userid) {
    await API.graphql({
      query: queries.getUserDetails,
      variables: {
        id: userid,
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        console.log(result);
        const SpUser = result.data.getUserDetails;
        //const compid = result.data.getUserdetails.companyDetailsId;
        await API.graphql({
          query: queries.getSPCompanyDetails,
          variables: {
            id: SpUser.companyDetailsId,
            limit: 2000000,
          },
          authMode: "API_KEY",
        })
          .then(async (result1) => {
            console.log(result1);
            const spcomp = result1.data.getSPCompanyDetails;
            console.log(spcomp);
            getfilename(spcomp.logo);
            setCompany(spcomp);
            //---
            let nextToken = "";
            let assets = [];
            await API.graphql({
              query: queries.listUserDetails,
              variables: {
                limit: 2000000,
              },
              authMode: "API_KEY",
            })
              .then(async (result) => {
                let assetDatas = result.data.listUserDetails.items;
                nextToken = result.data.listUserDetails.nextToken;
                console.log(
                  "first token count",
                  result.data.listUserDetails.items.length
                );
                console.log("nextToken 1", nextToken);
                if (result.data.listUserDetails.items.length > 0) {
                  assets.push(assetDatas);
                }
                while (nextToken !== null) {
                  await API.graphql({
                    query: queries.listUserDetails,
                    variables: {
                      limit: 2000000,
                      nextToken: nextToken,
                    },
                    authMode: "API_KEY",
                  })
                    .then((results) => {
                      nextToken = results.data.listUserDetails.nextToken;
                      console.log("nextToken", nextToken);
                      console.log(results.data.listUserDetails.items.length);
                      if (results.data.listUserDetails.items.length > 0) {
                        assets.push(results.data.listUserDetails.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets);
                if (assets.length > 0) {
                  const mergeAssets = assets.flat(1);
                  console.log(mergeAssets);
                  //--
                  const filterusers = mergeAssets.filter(
                    (item) => item.companyDetailsId === SpUser.companyDetailsId
                  );
                  console.log(filterusers);
                  const siteFilterArray = [];
                  filterusers.map(async (item) => {
                    siteFilterArray.push(item.id);
                  });
                  //--spuser filtering

                  let nextToken1 = "";
                  let assets1 = [];
                  await API.graphql({
                    query: queries.listSPUserDetails,
                    variables: {
                      limit: 2000000,
                    },
                    authMode: "API_KEY",
                  })
                    .then(async (result) => {
                      let assetDatas = result.data.listSPUserDetails.items;
                      nextToken1 = result.data.listSPUserDetails.nextToken;
                      console.log(
                        "first token count",
                        result.data.listSPUserDetails.items.length
                      );
                      console.log("nextToken 1", nextToken1);
                      if (result.data.listSPUserDetails.items.length > 0) {
                        assets1.push(assetDatas);
                      }
                      while (nextToken !== null) {
                        await API.graphql({
                          query: queries.listSPUserDetails,
                          variables: {
                            limit: 2000000,
                            nextToken: nextToken1,
                          },
                          authMode: "API_KEY",
                        })
                          .then((results) => {
                            nextToken1 =
                              results.data.listSPUserDetails.nextToken;
                            console.log("nextToken", nextToken1);
                            console.log(
                              results.data.listSPUserDetails.items.length
                            );
                            if (
                              results.data.listSPUserDetails.items.length > 0
                            ) {
                              assets1.push(
                                results.data.listSPUserDetails.items
                              );
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                      console.log(assets1);
                      if (assets1.length > 0) {
                        const mergeAssets1 = assets1.flat(1);
                        console.log(mergeAssets1);
                        //--
                        const filterSpuser = mergeAssets1.filter((item) => {
                          const _matchSites = siteFilterArray?.find(
                            (id) => id === item?.id
                          );
                          if (_matchSites) {
                            return item;
                          }
                        });
                        console.log(filterSpuser);
                        const filteradmin = filterSpuser.filter(
                          (item) => item.SPGroup === "admin"
                        );
                        console.log(filteradmin);
                        setMc(filteradmin[0]);
                      } else {
                        setMc([]);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  setMc([]);
                }
              })
              .catch((error) => {
                console.log(error);
              });
            {
              /*
            await API.graphql({
              query: queries.getSPUserDetails,
              variables: {
                id: userid,
                limit: 2000000,
              },
              authMode: "API_KEY",
            })
              .then(async (result2) => {
                console.log(result2);
                const spp = result2.data.getSPUserDetails;
                console.log(spp);
                setMc(spp);
              })
              .catch((error) => {
                console.log(error);
              });
            */
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //-- sp user for filter spuserid spgroup
  async function fetchspdata() {
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.listSPUserDetails,
      variables: {
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.listSPUserDetails.items;
        nextToken = result.data.listSPUserDetails.nextToken;
        console.log(
          "first token count",
          result.data.listSPUserDetails.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listSPUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listSPUserDetails,
            variables: {
              limit: 2000000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.listSPUserDetails.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listSPUserDetails.items.length);
              if (results.data.listSPUserDetails.items.length > 0) {
                assets.push(results.data.listSPUserDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setspdata(mergeAssets);
        } else {
          setspdata([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getTechnicianName(technicianId) {
    const technicianName = spdata.find((item) => item.id === technicianId);
    // return technicianName ? technicianName.email : "-";
    return technicianName
      ? (technicianName.fname ? technicianName.fname : "-") +
          " " +
          (technicianName.lname ? technicianName.lname : "")
      : "";
  }

  function getLicense(technicianId) {
    const technicianName = spdata.find((item) => item.id === technicianId);
    return technicianName
      ? technicianName.license
        ? technicianName.license
        : "-"
      : "";
  }

  // single site and all distribuution boards
  async function fetchTestingDemo1(siteId) {
    setLoading(true);
    let nextToken = "";
    let logbook = [];
    let assets = [];
    let testReport = [];
    let filter = {};
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - data.date);
    if (data.date !== "") {
      if (data.date === "Custom") {
        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);

        filter = {
          testOn: {
            between: [
              moment(startDate).format("YYYY-MM-DD"),
              moment(endDate).format("YYYY-MM-DD"),
            ],
          },
        };
      } else {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - data.date);

        filter = {
          testOn: {
            between: [
              moment(thirtyDaysAgo).format("YYYY-MM-DD"),
              moment(today).format("YYYY-MM-DD"),
            ],
          },
        };
      }
    } else {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      filter = {
        testOn: {
          between: [
            moment(thirtyDaysAgo).format("YYYY-MM-DD"),
            moment(today).format("YYYY-MM-DD"),
          ],
        },
      };
    }
    await API.graphql({
      query: queries.logBooksBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.logBooksBySitedetailsID.items;
        nextToken = result.data.logBooksBySitedetailsID.nextToken;
        if (result.data.logBooksBySitedetailsID.items.length > 0) {
          logbook.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.logBooksBySitedetailsID,
            variables: {
              sitedetailsID: siteId,
              limit: 2000000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.logBooksBySitedetailsID.nextToken;
              if (results.data.logBooksBySitedetailsID.items.length > 0) {
                logbook.push(results.data.logBooksBySitedetailsID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log("assets by site id", logbook);
        if (logbook.length > 0) {
          const mergeLogbook = logbook.flat(1);
          console.log(mergeLogbook);
          let testingData = [];
          let count = 0;
          mergeLogbook.map(async (item) => {
            count = count + 1;
            await API.graphql({
              query: queries.testingsByLogbookID,
              variables: {
                logbookID: item.id,
                filter,
                limit: 2000000,
              },
              authMode: "API_KEY",
            })
              .then(async (results) => {
                let nextToken = results.data.testingsByLogbookID.nextToken;
                if (results.data.testingsByLogbookID.items.length > 0) {
                  testingData.push(results.data.testingsByLogbookID.items);
                }
                while (nextToken !== null) {
                  await API.graphql({
                    query: queries.testingsByLogbookID,
                    variables: {
                      logbookID: item.id,
                      limit: 2000000,
                      filter,
                      nextToken: nextToken,
                    },
                    authMode: "API_KEY",
                  })
                    .then((results) => {
                      nextToken = results.data.testingsByLogbookID.nextToken;
                      if (results.data.testingsByLogbookID.items.length > 0) {
                        testingData.push(
                          results.data.testingsByLogbookID.items
                        );
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                console.log(error);
              });

            if (count === mergeLogbook.length && testingData.length > 0) {
              let mergeTesting = testingData.flat(1);
              console.log("testing full report", mergeTesting);
              if (mergeTesting.length > 0) {
                const promises = mergeTesting.map((item) => {
                  return API.graphql({
                    query: queries.getAssets,
                    variables: {
                      id: item.assetsID,
                      filter: {
                        status: {
                          ne: "delete",
                        },
                      },
                    },
                    authMode: "API_KEY",
                  });
                });
                Promise.all(promises)
                  .then((results) => {
                    console.log(results);
                    results.map((item) => {
                      if (item.data.getAssets !== null) {
                        const assetss = item.data.getAssets;
                        console.log(assetss);
                        assets.push(assetss);
                      }
                    });

                    console.log(assets);
                    if (assets.length > 0) {
                      let assets1 = assets.filter((item) => {
                        const _matchingAssetTemplate = data.assetTemp?.find(
                          (temp) => temp === item?.assettemplateID
                        );
                        if (_matchingAssetTemplate) {
                          return item;
                        }
                      });
                      let assetFilter = [];
                      if (data.warranty !== "") {
                        if (data.warranty === "InWarranty") {
                          console.log("In Warranty");
                          const today = new Date();
                          assetFilter = assets1.filter((item) => {
                            const assetWarrantyEndDate = new Date(
                              item.assetWarrantyEnd
                            );
                            return assetWarrantyEndDate > today;
                          });
                        } else {
                          console.log("Out Warranty");
                          const today = new Date();
                          assetFilter = assets1.filter((item) => {
                            const assetWarrantyEndDate = new Date(
                              item.assetWarrantyEnd
                            );
                            return assetWarrantyEndDate < today;
                          });
                        }
                      } else {
                        assetFilter = assets1;
                      }
                      //---
                      const testingassetsFilter = mergeTesting.filter(
                        (item) => {
                          const _matchSites = assetFilter?.find(
                            (idee) => idee.id === item?.assetsID
                          );
                          if (_matchSites) {
                            return item;
                          }
                        }
                      );
                      console.log(testingassetsFilter);
                      const assetOptions = [
                        { value: null, label: "All Assets" },
                      ];
                      const sort = assetFilter.sort(
                        (a, b) => a.fittingNumber - b.fittingNumber
                      );
                      const sortoption = sort.filter(
                        (item) => item.status === "Active"
                      );
                      console.log(sortoption);
                      const removedups = Array.from(
                        new Map(
                          sortoption.map((item) => [item.id, item])
                        ).values()
                      );
                      console.log(removedups);
                      removedups.map(async (item) => {
                        assetOptions.push({
                          value: item.id,
                          label: item.fittingNumber,
                        });
                      });
                      setAsset(assetOptions);
                      if (testingassetsFilter.length > 0) {
                        testingassetsFilter.map((test) => {
                          let asset = assets.filter((item) => {
                            return item.id === test.assetsID;
                          });
                          console.log(asset);
                          const custom = JSON.parse(test.customFields);
                          const customFormatted =
                            custom &&
                            custom.map((item) => {
                              const key = Object.keys(item)[0];
                              const formattedValue = item[key];
                              return `${key} : ${formattedValue}`;
                            });
                          let customFieldsFormatted = [];
                          if (customFormatted !== null) {
                            customFieldsFormatted = customFormatted.join("\n");
                            console.log(customFieldsFormatted);
                          } else {
                            console.log("customFormatted is null.");
                          }
                          testReport.push({
                            id: test.id,
                            createdAt: test.createdAt,
                            asset:
                              asset.length > 0 ? asset[0].fittingNumber : "",
                            distributionBox:
                              asset.length > 0
                                ? asset[0].distributionboxID
                                : "",
                            testOn: test.testOn,
                            testResult: test.testResult,
                            spuserid: test.spUserId,
                            warrantyEnd:
                              asset.length > 0 ? asset[0].assetWarrantyEnd : "",
                            nextTest: test.nextTestSchedule,
                            customfields: customFieldsFormatted,
                            remarks: test.additionalInformation,
                          });
                        });
                        const sortTest = testReport.sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        );
                        const uniqueCombinedArray = Array.from(
                          new Map(
                            sortTest.map((item) => [item.id, item])
                          ).values()
                        );
                        let testResultData = [];
                        if (data.testResult !== "") {
                          testResultData = sortTest.filter(
                            (item) =>
                              item.testResult.toLowerCase() ===
                              data.testResult.toLowerCase()
                          );

                          setAssetDemo(testResultData);
                          setTestingPDF(testResultData);
                          setAssets(
                            cloneDeep(testResultData.slice(0, countPerPage))
                          );
                          //---
                          const spUserIds = new Set();

                          testResultData.forEach((item) => {
                            spUserIds.add(item.spuserid);
                          });

                          const areSpUserIdsUnique =
                            spUserIds.size === testResultData.length;

                          if (areSpUserIdsUnique) {
                            console.log(spUserIds);
                            console.log("All spuserid values are unique.");
                            //--
                            const filterdata = testResultData.filter((item) => {
                              return spdata.some(
                                (spuser) =>
                                  spuser.id === item.spuserid &&
                                  spuser.SPGroup === "admin"
                              );
                            });
                            console.log(filterdata);
                            if (filterdata.length > 0) {
                              fetchspusercomp(filterdata[0].spuserid);
                            } else {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            }
                          } else {
                            console.log(spUserIds);
                            console.log("Some spuserid values are not unique.");
                            if (spUserIds.size === 1) {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            } else {
                              const filterdata = testResultData.filter(
                                (item) => {
                                  return spdata.some(
                                    (spuser) =>
                                      spuser.id === item.spuserid &&
                                      spuser.SPGroup === "admin"
                                  );
                                }
                              );
                              console.log(filterdata);

                              if (filterdata.length > 0) {
                                fetchspusercomp(filterdata[0].spuserid);
                              } else {
                                const myArray = Array.from(spUserIds);
                                const myValue = myArray[0];
                                console.log(myValue);
                                fetchspusercompAdmin(myValue);
                              }
                            }
                          }
                          setLoading(false);
                        } else {
                          setAssetDemo(sortTest);
                          setTestingPDF(sortTest);
                          setAssets(cloneDeep(sortTest.slice(0, countPerPage)));
                          //---
                          const spUserIds = new Set();

                          sortTest.forEach((item) => {
                            spUserIds.add(item.spuserid);
                          });

                          const areSpUserIdsUnique =
                            spUserIds.size === sortTest.length;

                          if (areSpUserIdsUnique) {
                            console.log(spUserIds);
                            console.log("All spuserid values are unique.");
                            //--
                            const filterdata = sortTest.filter((item) => {
                              return spdata.some(
                                (spuser) =>
                                  spuser.id === item.spuserid &&
                                  spuser.SPGroup === "admin"
                              );
                            });
                            console.log(filterdata);

                            if (filterdata.length > 0) {
                              fetchspusercomp(filterdata[0].spuserid);
                            } else {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            }
                          } else {
                            console.log(spUserIds);
                            console.log("Some spuserid values are not unique.");
                            if (spUserIds.size === 1) {
                              const myArray = Array.from(spUserIds);
                              const myValue = myArray[0];
                              console.log(myValue);
                              fetchspusercompAdmin(myValue);
                            } else {
                              const filterdata = sortTest.filter((item) => {
                                return spdata.some(
                                  (spuser) =>
                                    spuser.id === item.spuserid &&
                                    spuser.SPGroup === "admin"
                                );
                              });
                              console.log(filterdata);

                              if (filterdata.length > 0) {
                                fetchspusercomp(filterdata[0].spuserid);
                              } else {
                                const myArray = Array.from(spUserIds);
                                const myValue = myArray[0];
                                console.log(myValue);
                                fetchspusercompAdmin(myValue);
                              }
                            }
                          }
                          setLoading(false);
                        }
                        setLoading(false);
                      } else {
                        setAssetDemo([]);
                        setTestingPDF([]);
                        setAssets(cloneDeep([].slice(0, countPerPage)));
                        setLoading(false);
                      }
                    } else {
                      setAssetDemo([]);
                      setTestingPDF([]);
                      setAssets(cloneDeep([].slice(0, countPerPage)));
                      setLoading(false);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                setAssetDemo([]);
                setTestingPDF([]);
                setAssets(cloneDeep([].slice(0, countPerPage)));
                setLoading(false);
              }
            } else {
              setAssetDemo([]);
              setTestingPDF([]);
              setAssets(cloneDeep([].slice(0, countPerPage)));
              setLoading(false);
            }
          });
        } else {
          setAssetDemo([]);
          setTestingPDF([]);
          setAssets(cloneDeep([].slice(0, countPerPage)));
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ----All Site And All Distribution For Site User
  async function fetchSiteDetailsSU() {
    setLoading(true);
    let nextToken = "";
    let assets = [];
    let filter = {};
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - data.date);
    if (data.date !== "") {
      if (data.date === "Custom") {
        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);

        filter = {
          testOn: {
            between: [
              moment(startDate).format("YYYY-MM-DD"),
              moment(endDate).format("YYYY-MM-DD"),
            ],
          },
        };
      } else {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - data.date);
        filter = {
          testOn: {
            between: [
              moment(thirtyDaysAgo).format("YYYY-MM-DD"),
              moment(today).format("YYYY-MM-DD"),
            ],
          },
        };
      }
    } else {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      filter = {
        testOn: {
          between: [
            moment(thirtyDaysAgo).format("YYYY-MM-DD"),
            moment(today).format("YYYY-MM-DD"),
          ],
        },
      };
    }
    if (fullSites.length > 0) {
      let nextToken1 = "";
      let assets = [];
      fullSites.map(async (item) => {
        await API.graphql({
          query: queries.assetsBySitedetailsID,
          variables: {
            sitedetailsID: item.sitedetailsID,
            filter: {
              status: {
                ne: "delete",
              },
            },
            limit: 2000000,
          },
          authMode: "API_KEY",
        })
          .then(async (result) => {
            let assetDatas = result.data.assetsBySitedetailsID.items;
            nextToken1 = result.data.assetsBySitedetailsID.nextToken;
            if (result.data.assetsBySitedetailsID.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken1 !== null) {
              await API.graphql({
                query: queries.assetsBySitedetailsID,
                variables: {
                  sitedetailsID: item.sitedetailsID,
                  filter: {
                    status: {
                      ne: "delete",
                    },
                  },
                  limit: 2000000,
                  nextToken: nextToken1,
                },
                authMode: "API_KEY",
              })
                .then((results) => {
                  nextToken1 = results.data.assetsBySitedetailsID.nextToken;
                  if (results.data.assetsBySitedetailsID.items.length > 0) {
                    assets.push(results.data.assetsBySitedetailsID.items);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            console.log(assets);
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              let testing = mergeAssets.filter((item) => {
                const _matchingAssetTemplate = data.assetTemp?.find(
                  (temp) => temp === item?.assettemplateID
                );
                if (_matchingAssetTemplate) {
                  return item;
                }
              });
              console.log(testing);
              const asset = testing;
              let assetFilter = [];
              console.log(data.warranty);
              if (data.warranty !== "") {
                if (data.warranty === "InWarranty") {
                  console.log("In Warranty");
                  const today = new Date();
                  assetFilter = asset.filter((item) => {
                    const assetWarrantyEndDate = new Date(
                      item.assetWarrantyEnd
                    );
                    return assetWarrantyEndDate > today;
                  });
                } else {
                  console.log("Out Warranty");
                  const today = new Date();
                  assetFilter = asset.filter((item) => {
                    const assetWarrantyEndDate = new Date(
                      item.assetWarrantyEnd
                    );
                    return assetWarrantyEndDate < today;
                  });
                }
              } else {
                assetFilter = asset;
              }
              console.log(assetFilter);
              const testReport = [];
              if (assetFilter.length > 0) {
                console.log("inside asset loop");
                const assetOptions = [{ value: null, label: "All Assets" }];
                const sort = assetFilter.sort(
                  (a, b) => a.fittingNumber - b.fittingNumber
                );
                console.log(sort);
                const sortoption = sort.filter(
                  (item) => item.status === "Active"
                );
                sortoption.map(async (item) => {
                  assetOptions.push({
                    value: item.id,
                    label: item.fittingNumber,
                  });
                });
                console.log(sort);
                let nextToken2 = "";
                sort.map(async (asset) => {
                  let assets2 = [];
                  API.graphql({
                    query: queries.testingsByAssetsID,
                    variables: {
                      limit: 2000000,
                      filter,
                      assetsID: asset.id,
                    },
                    authMode: "API_KEY",
                  })
                    .then(async (result) => {
                      let assetDatas = result.data.testingsByAssetsID.items;
                      nextToken2 = result.data.testingsByAssetsID.nextToken;
                      if (result.data.testingsByAssetsID.items.length > 0) {
                        assets2.push(assetDatas);
                      }
                      while (nextToken2 !== null) {
                        await API.graphql({
                          query: queries.testingsByAssetsID,
                          variables: {
                            limit: 2000000,
                            assetsID: asset.id,
                            filter,
                            nextToken: nextToken2,
                          },
                          authMode: "API_KEY",
                        })
                          .then((results) => {
                            nextToken2 =
                              results.data.testingsByAssetsID.nextToken;
                            if (
                              results.data.testingsByAssetsID.items.length > 0
                            ) {
                              assets2.push(
                                results.data.testingsByAssetsID.items
                              );
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                      console.log(assets2);
                      if (assets2.length > 0) {
                        const mergeAssets2 = assets2.flat(1);
                        console.log(mergeAssets2);
                        const testing = mergeAssets2;
                        console.log(testing);
                        if (testing.length > 0) {
                          testing.map((test) => {
                            const custom = JSON.parse(test.customFields);
                            const customFormatted =
                              custom &&
                              custom.map((item) => {
                                const key = Object.keys(item)[0];
                                const formattedValue = item[key];
                                return `${key} : ${formattedValue}`;
                              });
                            console.log(custom);
                            console.log(customFormatted);
                            let customFieldsFormatted = [];
                            if (customFormatted !== null) {
                              customFieldsFormatted =
                                customFormatted.join("\n");
                              console.log(customFieldsFormatted);
                            } else {
                              console.log("customFormatted is null.");
                            }
                            testReport.push({
                              id: test.id,
                              createdAt: test.createdAt,
                              asset: asset.fittingNumber,
                              distributionBox: asset.distributionboxID,
                              testOn: test.testOn,
                              testResult: test.testResult,
                              spuserid: test.spUserId,
                              warrantyEnd: asset.assetWarrantyEnd,
                              nextTest: test.nextTestSchedule,
                              customfields: customFieldsFormatted,
                              remarks: test.additionalInformation,
                            });
                          });
                          const sort1 = testReport.sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          );
                          console.log(sort1);
                          const uniqueCombinedArray = Array.from(
                            new Map(
                              sort1.map((item) => [item.id, item])
                            ).values()
                          );
                          let testResultData = [];
                          if (data.testResult !== "") {
                            testResultData = sort1.filter(
                              (item) => item.testResult === data.testResult
                            );
                            setAssetDemo(testResultData);
                            setTestingPDF(testResultData);
                            setAssets(
                              cloneDeep(testResultData.slice(0, countPerPage))
                            );
                            const spUserIds = new Set();
                            testResultData.forEach((item) => {
                              spUserIds.add(item.spuserid);
                            });
                            const areSpUserIdsUnique =
                              spUserIds.size === testResultData.length;
                            if (areSpUserIdsUnique) {
                              console.log(spUserIds);
                              console.log("All spuserid values are unique.");
                              const filterdata = testResultData.filter(
                                (item) => {
                                  return spdata.some(
                                    (spuser) =>
                                      spuser.id === item.spuserid &&
                                      spuser.SPGroup === "admin"
                                  );
                                }
                              );
                              console.log(filterdata);

                              if (filterdata.length > 0) {
                                fetchspusercomp(filterdata[0].spuserid);
                              } else {
                                const myArray = Array.from(spUserIds);
                                const myValue = myArray[0];
                                console.log(myValue);
                                fetchspusercompAdmin(myValue);
                              }
                            } else {
                              console.log(spUserIds);
                              console.log(
                                "Some spuserid values are not unique."
                              );
                              if (spUserIds.size === 1) {
                                const myArray = Array.from(spUserIds);
                                const myValue = myArray[0];
                                console.log(myValue);
                                fetchspusercompAdmin(myValue);
                              } else {
                                const filterdata = testResultData.filter(
                                  (item) => {
                                    return spdata.some(
                                      (spuser) =>
                                        spuser.id === item.spuserid &&
                                        spuser.SPGroup === "admin"
                                    );
                                  }
                                );
                                console.log(filterdata);

                                if (filterdata.length > 0) {
                                  fetchspusercomp(filterdata[0].spuserid);
                                } else {
                                  const myArray = Array.from(spUserIds);
                                  const myValue = myArray[0];
                                  console.log(myValue);
                                  fetchspusercompAdmin(myValue);
                                }
                              }
                            }
                            setLoading(false);
                          } else {
                            setAssetDemo(sort1);
                            setTestingPDF(sort1);
                            setAssets(cloneDeep(sort1.slice(0, countPerPage)));
                            const spUserIds = new Set();

                            sort1.forEach((item) => {
                              spUserIds.add(item.spuserid);
                            });

                            const areSpUserIdsUnique =
                              spUserIds.size === sort1.length;

                            if (areSpUserIdsUnique) {
                              console.log(spUserIds);
                              console.log("All spuserid values are unique.");
                              //--
                              const filterdata = sort1.filter((item) => {
                                return spdata.some(
                                  (spuser) =>
                                    spuser.id === item.spuserid &&
                                    spuser.SPGroup === "admin"
                                );
                              });
                              console.log(filterdata);

                              if (filterdata.length > 0) {
                                fetchspusercomp(filterdata[0].spuserid);
                              } else {
                                const myArray = Array.from(spUserIds);
                                const myValue = myArray[0];
                                console.log(myValue);
                                fetchspusercompAdmin(myValue);
                              }
                            } else {
                              console.log(spUserIds);
                              console.log(
                                "Some spuserid values are not unique."
                              );
                              if (spUserIds.size === 1) {
                                const myArray = Array.from(spUserIds);
                                const myValue = myArray[0];
                                console.log(myValue);
                                fetchspusercompAdmin(myValue);
                              } else {
                                const filterdata = sort1.filter((item) => {
                                  return spdata.some(
                                    (spuser) =>
                                      spuser.id === item.spuserid &&
                                      spuser.SPGroup === "admin"
                                  );
                                });
                                console.log(filterdata);

                                if (filterdata.length > 0) {
                                  fetchspusercomp(filterdata[0].spuserid);
                                } else {
                                  const myArray = Array.from(spUserIds);
                                  const myValue = myArray[0];
                                  console.log(myValue);
                                  fetchspusercompAdmin(myValue);
                                }
                              }
                            }
                            setLoading(false);
                          }
                          setLoading(false);
                        } else {
                          setAssetDemo(testReport);
                          setTestingPDF(testReport);
                          setAssets(
                            cloneDeep(testReport.slice(0, countPerPage))
                          );
                          setLoading(false);
                        }
                        setAsset(assetOptions);
                      } else {
                        setAssetDemo([]);
                        setTestingPDF([]);
                        setAssets(cloneDeep([].slice(0, countPerPage)));
                        setLoading(false);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              } else {
                console.log("outside asset loop");
                setAssetDemo(testReport);
                setTestingPDF(testReport);
                setAssets(cloneDeep(testReport.slice(0, countPerPage)));
                setLoading(false);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      setAssetDemo([]);
      setTestingPDF([]);
      setAssets(cloneDeep([].slice(0, countPerPage)));
      setLoading(false);
    }
  }

  // all sites and all distribution for AO
  async function fetchTestingDemo() {
    try {
      setLoading(true);
      let filter = {};
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - data.date);
      if (data.date !== "") {
        if (data.date === "Custom") {
          const startDate = new Date(data.startDate);
          const endDate = new Date(data.endDate);
          filter = {
            testOn: {
              between: [
                moment(startDate).format("YYYY-MM-DD"),
                moment(endDate).format("YYYY-MM-DD"),
              ],
            },
          };
        } else {
          const today = new Date();
          const thirtyDaysAgo = new Date();
          thirtyDaysAgo.setDate(today.getDate() - data.date);
          filter = {
            testOn: {
              between: [
                moment(thirtyDaysAgo).format("YYYY-MM-DD"),
                moment(today).format("YYYY-MM-DD"),
              ],
            },
          };
        }
      } else {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        filter = {
          testOn: {
            between: [
              moment(thirtyDaysAgo).format("YYYY-MM-DD"),
              moment(today).format("YYYY-MM-DD"),
            ],
          },
        };
      }
      console.log(filter);
      if (fullSites.length > 0) {
        let nextToken1 = "";
        let assets1 = [];
        fullSites.map(async (item) => {
          await API.graphql({
            query: queries.assetsBySitedetailsID,
            variables: {
              sitedetailsID: item.id,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: 2000000,
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              let assetDatas = result.data.assetsBySitedetailsID.items;
              nextToken1 = result.data.assetsBySitedetailsID.nextToken;
              if (result.data.assetsBySitedetailsID.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await API.graphql({
                  query: queries.assetsBySitedetailsID,
                  variables: {
                    sitedetailsID: item.id,
                    filter: {
                      status: {
                        eq: "Active",
                      },
                    },
                    limit: 2000000,
                    nextToken: nextToken1,
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken1 = results.data.assetsBySitedetailsID.nextToken;
                    if (results.data.assetsBySitedetailsID.items.length > 0) {
                      assets1.push(results.data.assetsBySitedetailsID.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                console.log(mergeAssets1);
                //--
                let testing = mergeAssets1.filter((item) => {
                  const _matchingAssetTemplate = data.assetTemp?.find(
                    (temp) => temp === item?.assettemplateID
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                });
                console.log(testing);
                const asset = testing;
                let assetFilter = [];
                console.log(data.warranty);
                if (data.warranty !== "") {
                  if (data.warranty === "InWarranty") {
                    console.log("In Warranty");
                    const today = new Date();
                    assetFilter = asset.filter((item) => {
                      const assetWarrantyEndDate = new Date(
                        item.assetWarrantyEnd
                      );
                      return assetWarrantyEndDate > today;
                    });
                  } else {
                    console.log("Out Warranty");
                    const today = new Date();
                    assetFilter = asset.filter((item) => {
                      const assetWarrantyEndDate = new Date(
                        item.assetWarrantyEnd
                      );
                      return assetWarrantyEndDate < today;
                    });
                  }
                } else {
                  assetFilter = asset;
                }
                console.log(assetFilter);
                const testReport = [];
                if (assetFilter.length > 0) {
                  console.log("inside asset loop");
                  const assetOptions = [{ value: null, label: "All Assets" }];
                  const sort = assetFilter.sort(
                    (a, b) => a.fittingNumber - b.fittingNumber
                  );
                  console.log(sort);
                  const sortoption = sort.filter(
                    (item) => item.status === "Active"
                  );
                  sortoption.map(async (item) => {
                    assetOptions.push({
                      value: item.id,
                      label: item.fittingNumber,
                    });
                  });
                  console.log(sort);
                  let nextToken2 = "";
                  sort.map(async (asset) => {
                    let assets2 = [];
                    API.graphql({
                      query: queries.testingsByAssetsID,
                      variables: {
                        limit: 2000000,
                        filter: filter,
                        assetsID: asset.id,
                      },
                      authMode: "API_KEY",
                    })
                      .then(async (result) => {
                        let assetDatas = result.data.testingsByAssetsID.items;
                        nextToken2 = result.data.testingsByAssetsID.nextToken;
                        if (result.data.testingsByAssetsID.items.length > 0) {
                          assets2.push(assetDatas);
                        }
                        while (nextToken2 !== null) {
                          await API.graphql({
                            query: queries.testingsByAssetsID,
                            variables: {
                              limit: 2000000,
                              filter: filter,
                              assetsID: asset.id,
                              nextToken: nextToken2,
                            },
                            authMode: "API_KEY",
                          })
                            .then((results) => {
                              nextToken2 =
                                results.data.testingsByAssetsID.nextToken;
                              if (
                                results.data.testingsByAssetsID.items.length > 0
                              ) {
                                assets2.push(
                                  results.data.testingsByAssetsID.items
                                );
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }
                        console.log(assets2);
                        if (assets2.length > 0) {
                          const mergeAssets2 = assets2.flat(1);
                          console.log(mergeAssets2);
                          //---
                          const testing = mergeAssets2;
                          console.log(testing);
                          if (testing.length > 0) {
                            testing.map((test) => {
                              const custom = JSON.parse(test.customFields);
                              const customFormatted =
                                custom &&
                                custom.map((item) => {
                                  const key = Object.keys(item)[0];
                                  const formattedValue = item[key];
                                  return `${key} : ${formattedValue}`;
                                });
                              console.log(custom);
                              console.log(customFormatted);
                              let customFieldsFormatted = [];
                              if (customFormatted !== null) {
                                customFieldsFormatted =
                                  customFormatted.join("\n");
                                console.log(customFieldsFormatted);
                              } else {
                                console.log("customFormatted is null.");
                              }
                              testReport.push({
                                id: test.id,
                                createdAt: test.createdAt,
                                asset: asset.fittingNumber,
                                distributionBox: asset.distributionboxID,
                                testOn: test.testOn,
                                testResult: test.testResult,
                                spuserid: test.spUserId,
                                warrantyEnd: asset.assetWarrantyEnd,
                                nextTest: test.nextTestSchedule,
                                customfields: customFieldsFormatted,
                                remarks: test.additionalInformation,
                              });
                            });
                            const sort1 = testReport.sort(
                              (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                            );
                            console.log(sort1);
                            const uniqueCombinedArray = Array.from(
                              new Map(
                                sort1.map((item) => [item.id, item])
                              ).values()
                            );
                            let testResultData = [];
                            if (data.testResult !== "") {
                              testResultData = sort1.filter(
                                (item) =>
                                  item.testResult.toLowerCase() ===
                                  data.testResult.toLowerCase()
                              );

                              setAssetDemo(testResultData);
                              setTestingPDF(testResultData);
                              setAssets(
                                cloneDeep(testResultData.slice(0, countPerPage))
                              );
                              //---
                              const spUserIds = new Set();

                              testResultData.forEach((item) => {
                                spUserIds.add(item.spuserid);
                              });

                              const areSpUserIdsUnique =
                                spUserIds.size === testResultData.length;

                              if (areSpUserIdsUnique) {
                                console.log(spUserIds);
                                console.log("All spuserid values are unique.");
                                //--
                                const filterdata = testResultData.filter(
                                  (item) => {
                                    return spdata.some(
                                      (spuser) =>
                                        spuser.id === item.spuserid &&
                                        spuser.SPGroup === "admin"
                                    );
                                  }
                                );
                                console.log(filterdata);

                                if (filterdata.length > 0) {
                                  fetchspusercomp(filterdata[0].spuserid);
                                } else {
                                  const myArray = Array.from(spUserIds);
                                  const myValue = myArray[0];
                                  console.log(myValue);
                                  fetchspusercompAdmin(myValue);
                                }
                              } else {
                                console.log(spUserIds);
                                console.log(
                                  "Some spuserid values are not unique."
                                );
                                if (spUserIds.size === 1) {
                                  const myArray = Array.from(spUserIds);
                                  const myValue = myArray[0];
                                  console.log(myValue);
                                  fetchspusercompAdmin(myValue);
                                } else {
                                  const filterdata = testResultData.filter(
                                    (item) => {
                                      return spdata.some(
                                        (spuser) =>
                                          spuser.id === item.spuserid &&
                                          spuser.SPGroup === "admin"
                                      );
                                    }
                                  );
                                  console.log(filterdata);

                                  if (filterdata.length > 0) {
                                    fetchspusercomp(filterdata[0].spuserid);
                                  } else {
                                    const myArray = Array.from(spUserIds);
                                    const myValue = myArray[0];
                                    console.log(myValue);
                                    fetchspusercompAdmin(myValue);
                                  }
                                }
                              }
                              setLoading(false);
                            } else {
                              setAssetDemo(sort1);
                              setTestingPDF(sort1);
                              setAssets(
                                cloneDeep(sort1.slice(0, countPerPage))
                              );
                              //---
                              const spUserIds = new Set();

                              sort1.forEach((item) => {
                                spUserIds.add(item.spuserid);
                              });

                              const areSpUserIdsUnique =
                                spUserIds.size === sort1.length;

                              if (areSpUserIdsUnique) {
                                console.log(spUserIds);
                                console.log("All spuserid values are unique.");
                                //--
                                const filterdata = sort1.filter((item) => {
                                  return spdata.some(
                                    (spuser) =>
                                      spuser.id === item.spuserid &&
                                      spuser.SPGroup === "admin"
                                  );
                                });
                                console.log(filterdata);

                                if (filterdata.length > 0) {
                                  fetchspusercomp(filterdata[0].spuserid);
                                } else {
                                  const myArray = Array.from(spUserIds);
                                  const myValue = myArray[0];
                                  console.log(myValue);
                                  fetchspusercompAdmin(myValue);
                                }
                              } else {
                                console.log(spUserIds);
                                console.log(
                                  "Some spuserid values are not unique."
                                );
                                if (spUserIds.size === 1) {
                                  const myArray = Array.from(spUserIds);
                                  const myValue = myArray[0];
                                  console.log(myValue);
                                  fetchspusercompAdmin(myValue);
                                } else {
                                  const filterdata = sort1.filter((item) => {
                                    return spdata.some(
                                      (spuser) =>
                                        spuser.id === item.spuserid &&
                                        spuser.SPGroup === "admin"
                                    );
                                  });
                                  console.log(filterdata);

                                  if (filterdata.length > 0) {
                                    fetchspusercomp(filterdata[0].spuserid);
                                  } else {
                                    const myArray = Array.from(spUserIds);
                                    const myValue = myArray[0];
                                    console.log(myValue);
                                    fetchspusercompAdmin(myValue);
                                  }
                                }
                              }
                              setLoading(false);
                            }
                            setLoading(false);
                          } else {
                            setAssetDemo(testReport);
                            setTestingPDF(testReport);
                            setAssets(
                              cloneDeep(testReport.slice(0, countPerPage))
                            );
                            setLoading(false);
                          }
                          setAsset(assetOptions);
                        } else {
                          setAssetDemo([]);
                          setTestingPDF([]);
                          setAssets(cloneDeep([].slice(0, countPerPage)));
                          setLoading(false);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                } else {
                  console.log("outside asset loop");
                  setAssetDemo(testReport);
                  setTestingPDF(testReport);
                  setAssets(cloneDeep(testReport.slice(0, countPerPage)));
                  setLoading(false);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
        //---
      } else {
        setAssetDemo([]);
        setTestingPDF([]);
        setAssets(cloneDeep([].slice(0, countPerPage)));
        setLoading(false);
      }
    } catch (error) {}
  }

  const generatePDF = () => {
    try {
      setLoading(true);
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3, or A4
      const orientation = "portrait"; // portrait or landscape

      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth =
        doc.getStringUnitWidth("Test Report") * doc.internal.getFontSize();
      const textOffset = (pageWidth - textWidth) / 2;

      const headingY = 20;
      doc.text("Test Report", textOffset, headingY);

      const boxWidth = 570;
      const boxHeight = 180;
      const boxX = (pageWidth - boxWidth) / 2.5;
      const boxY = headingY + 10;

      doc.rect(boxX, boxY, boxWidth, boxHeight);
      const dividingLineX = boxX + boxWidth / 2.5;
      const dividingLineStartY = boxY;
      const dividingLineEndY = boxY + boxHeight;

      doc.line(
        dividingLineX,
        dividingLineStartY,
        dividingLineX,
        dividingLineEndY
      );

      const spCompanyYCoordinate = boxY + 15;
      if (downloadedFile !== null) {
        doc.addImage(
          downloadedFile,
          "JPEG",
          boxX + 20,
          spCompanyYCoordinate,
          120,
          100
        );
      }
      const spcompanycordinateNew = spCompanyYCoordinate + 120;

      const aof = ao && ao.firstName ? ao.firstName : "N/A";
      const aol = ao && ao.lastName ? ao.lastName : "N/A";
      const aom = ao && ao.mobile ? ao.mobile : "N/A";
      const aoe = ao && ao.email ? ao.email : "N/A";

      const spCompanyInformation = ["", "Category: " + data.categoryName, ""];

      doc.setFontSize(7);
      doc.setFont("normal");
      spCompanyInformation.forEach((info, index) => {
        doc.text(info, boxX + 10, spcompanycordinateNew + index * 15 + 7.5);
      });

      doc.setFontSize(7);
      doc.setFont("normal");

      const siteName = sites && sites.siteName ? sites.siteName : "All Sites";
      const unitNumber = sites && sites.unitNumber ? sites.unitNumber : "-";
      const companyName =
        company && company.companyName ? company.companyName : "-";
      const maxCharactersPerLinename = 32;
      const firstLineName = companyName.substring(0, maxCharactersPerLinename);
      const secondLineName = companyName.substring(maxCharactersPerLinename);
      const companyLicence =
        company && company.companyLicense ? company.companyLicense : "-";
      const companyBN = company && company.companyBN ? company.companyBN : "-";
      const companyAddress =
        company && company.companyAddress ? company.companyAddress : "-";

      const maxCharactersPerLine = 32;
      const firstLine = companyAddress.substring(0, maxCharactersPerLine);
      const secondLine = companyAddress.substring(maxCharactersPerLine);

      const spf = mc && mc.fname ? mc.fname : " ";
      const spl = mc && mc.lname ? mc.lname : "";
      const electricianLicence = mc && mc.license ? mc.license : "-";

      const siteInformation = [
        "",
        "Site:" + " " + siteName,
        "Site Address: " + unitNumber,
        " ",
        "Company Name:" + " " + companyName,
        "Company Electrical Licence #: " + companyLicence,
        "Company BN#:" + " " + companyBN,
        "Company Address:" + " " + companyAddress,
      ];

      doc.setFont("normal");
      const siteInfoXCoordinate = dividingLineX + 10;
      const siteInfoYCoordinate = boxY + 15;
      let currentYCoordinate = siteInfoYCoordinate;

      //---
      siteInformation.forEach((info) => {
        const textLines = doc.splitTextToSize(info, boxWidth - 20);
        textLines.forEach((line) => {
          doc.text(line, siteInfoXCoordinate, currentYCoordinate);
          currentYCoordinate += 15;
        });
      });

      const tableStartY = dividingLineEndY - 150;
      const tableData = [];

      doc.setFontSize(7);
      doc.setFont("normal");

      const tableHeader = [
        "Fitting Number",
        "Distribution Boards",
        "Test Date",
        "Test Result",
        "Next Testing Date",
        "Warranty Upto",
        "Custom Fields",
        "Additional Info",
        "Tested By",
        "Tester License",
      ];
      tableData.push(tableHeader);

      assetDemo.forEach((item) => {
        const rowData = [
          item.asset,
          getDB(item.distributionBox),
          moment(item.testOn).format("DD/MM/YYYY"),
          item.testResult,
          item.nextTest ? moment(item.nextTest).format("DD/MM/YYYY") : "-",
          moment(item.warrantyEnd).format("DD/MM/YYYY"),
          item.customfields,
          item.remarks,
          getTechnicianName(item.spuserid),
          getLicense(item.spuserid),
        ];
        tableData.push(rowData);
      });

      const yCoordinate = tableStartY;

      const tableConfig = {
        startY: yCoordinate + siteInformation.length * 15 + 40,
        headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
        margin: { top: 20 },
        tableWidth: 570,
        styles: { fontSize: 7 },
      };

      doc.autoTable({
        head: [tableHeader],
        body: tableData.slice(1),
        startY: tableStartY,
        ...tableConfig,
        margin: { left: 10 },
      });

      doc.save("TestingReport.pdf");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setAssets(cloneDeep(assetDemo.slice(from, to)));
  };

  const filterAssets = (assetValue) => {
    console.log(assetValue);
    try {
      setAssetVal(assetValue);
      if (assetValue !== null && assetValue.value !== null) {
        var filterAssets = testingPDF.filter((item) => {
          return item.asset === assetValue.label;
        });
        if (dateRange[0] !== null) {
          const [newStartDate, newEndDate] = dateRange;
          var filterAssets1 = filterAssets.filter((item) => {
            return (
              moment(item.testOn).format("DD/MM/YYYY") >=
                moment(newStartDate).format("DD/MM/YYYY") &&
              moment(item.testOn).format("DD/MM/YYYY") <=
                moment(newEndDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets1.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          let testResultData = [];
          if (data.testResult !== "") {
            testResultData = sort.filter(
              (item) =>
                item.testResult.toLowerCase() === data.testResult.toLowerCase()
            );
          }
          testResultData.length > 0
            ? setAssetDemo(testResultData)
            : setAssetDemo(sort);
          testResultData.length > 0
            ? setAssets(cloneDeep(testResultData.slice(0, countPerPage)))
            : setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          const sort = filterAssets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          let testResultData = [];
          if (data.testResult !== "") {
            testResultData = sort.filter(
              (item) =>
                item.testResult.toLowerCase() === data.testResult.toLowerCase()
            );
          }
          testResultData.length > 0
            ? setAssetDemo(testResultData)
            : setAssetDemo(sort);
          testResultData.length > 0
            ? setAssets(cloneDeep(testResultData.slice(0, countPerPage)))
            : setAssets(cloneDeep(sort.slice(0, countPerPage)));
        }
      } else {
        if (dateRange[0] !== null) {
          const [newStartDate, newEndDate] = dateRange;
          var filterAssets = testingPDF.filter((item) => {
            return (
              moment(item.testOn).format("DD/MM/YYYY") >=
                moment(newStartDate).format("DD/MM/YYYY") &&
              moment(item.testOn).format("DD/MM/YYYY") <=
                moment(newEndDate).format("DD/MM/YYYY")
            );
          });
          const sort = filterAssets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          let testResultData = [];
          if (data.testResult !== "") {
            testResultData = sort.filter(
              (item) =>
                item.testResult.toLowerCase() === data.testResult.toLowerCase()
            );
          }
          testResultData.length > 0
            ? setAssetDemo(testResultData)
            : setAssetDemo(sort);
          testResultData.length > 0
            ? setAssets(cloneDeep(testResultData.slice(0, countPerPage)))
            : setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          setAssetDemo(testingPDF);
          setAssets(cloneDeep(testingPDF.slice(0, countPerPage)));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterDateRage = (value, value1) => {
    try {
      if (value !== null && value1 !== null) {
        var filterAssets = testingPDF.filter((item) => {
          return (
            moment(item.testOn).format("DD/MM/YYYY") >=
              moment(value).format("DD/MM/YYYY") &&
            moment(item.testOn).format("DD/MM/YYYY") <=
              moment(value1).format("DD/MM/YYYY")
          );
        });
        if (assetVal !== null && assetVal !== "" && assetVal.value !== null) {
          var filterAssets1 = filterAssets.filter((item) => {
            return item.asset === assetVal.label;
          });
          const sort = filterAssets1.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          let testResultData = [];
          if (data.testResult !== "") {
            testResultData = sort.filter(
              (item) =>
                item.testResult.toLowerCase() === data.testResult.toLowerCase()
            );
          }
          testResultData.length > 0
            ? setAssetDemo(testResultData)
            : setAssetDemo(sort);
          testResultData.length > 0
            ? setAssets(cloneDeep(testResultData.slice(0, countPerPage)))
            : setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          const sort = filterAssets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          let testResultData = [];
          if (data.testResult !== "") {
            testResultData = sort.filter(
              (item) =>
                item.testResult.toLowerCase() === data.testResult.toLowerCase()
            );
          }
          testResultData.length > 0
            ? setAssetDemo(testResultData)
            : setAssetDemo(sort);
          testResultData.length > 0
            ? setAssets(cloneDeep(testResultData.slice(0, countPerPage)))
            : setAssets(cloneDeep(sort.slice(0, countPerPage)));
        }
      } else {
        if (assetVal !== null && assetVal !== "" && assetVal.value !== null) {
          var filterAssets = testingPDF.filter((item) => {
            return item.asset === assetVal.label;
          });
          const sort = filterAssets.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          let testResultData = [];
          if (data.testResult !== "") {
            testResultData = sort.filter(
              (item) =>
                item.testResult.toLowerCase() === data.testResult.toLowerCase()
            );
          }
          testResultData.length > 0
            ? setAssetDemo(testResultData)
            : setAssetDemo(sort);
          testResultData.length > 0
            ? setAssets(cloneDeep(testResultData.slice(0, countPerPage)))
            : setAssets(cloneDeep(sort.slice(0, countPerPage)));
        } else {
          setAssetDemo(testingPDF);
          setAssets(cloneDeep(testingPDF.slice(0, countPerPage)));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //-----------------------------------------Display------------------------------------------
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row>
            <Col md="12" className="project-list">
              <Card className="custom-card">
                <Row>
                  <Col md="6">
                    <H2>Testing Report</H2>
                  </Col>
                  {assets.length > 0 &&
                    userPermission.includes("Download Reports") && (
                      <Col md="6" className="text-end">
                        <Btn
                          attrBtn={{ color: "primary", onClick: generatePDF }}
                        >
                          Download PDF
                        </Btn>
                      </Col>
                    )}
                </Row>
                <Row>
                  <H4>Filters</H4>
                  <Col md="4">
                    <Select
                      value={assetVal}
                      onChange={filterAssets}
                      options={asset}
                      isSearchable={true}
                      hideSelectedOptions={false}
                      placeholder="Choose Asset"
                      isClearable={true}
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  </Col>
                  <Col md="4">
                    <DatePicker
                      className="form-control"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(d) => {
                        setDateRange(d);
                        const [newStartDate, newEndDate] = d;
                        filterDateRage(newStartDate, newEndDate);
                      }}
                      isClearable={true}
                      placeholderText="Choose Date Range"
                      dateFormat="dd-MM-yyyy"
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="card-block row">
                    <Col sm="12" lg="12" xl="12">
                      <div className="table-responsive">
                        <Table className="table-striped  table-de" id="report">
                          <thead className="custom-table-head">
                            <tr>
                              <th>Fitting Number</th>
                              <th>Distribution Boards</th>
                              <th>Test Date</th>
                              <th>Test Result</th>
                              <th>Next Testing Date</th>
                              <th>Warranty Upto</th>
                              <th>Custom Fields</th>
                              <th>Additional Info</th>
                              <th>Tested By</th>
                              <th>Tester License</th>
                            </tr>
                          </thead>
                          <tbody className="trhover">
                            {assets.map((item) => (
                              <tr key={item.id}>
                                <td>{item.asset}</td>
                                <td>{getDB(item.distributionBox)}</td>
                                <td>
                                  {moment(item.testOn).format("DD/MM/YYYY")}
                                </td>
                                <td>{item.testResult}</td>
                                <td>
                                  {item.nextTest
                                    ? moment(item.nextTest).format("DD/MM/YYYY")
                                    : "-"}
                                </td>
                                <td>
                                  {item.warrantyEnd
                                    ? moment(item.warrantyEnd).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td className="custom-fields-cell">
                                  {item.customfields}
                                </td>
                                <td>{item.remarks}</td>
                                <td>{getTechnicianName(item.spuserid)}</td>
                                <td>{getLicense(item.spuserid)}</td>
                              </tr>
                            ))}
                            {assets.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan="12">
                                  No Reports Found for the selected Site and
                                  Distribution Board
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </div>
                </Row>
                <CardFooter className="row">
                  <Pagination
                    className="p-t-10"
                    pageSize={countPerPage}
                    onChange={updatePage}
                    current={currentPage}
                    total={assetDemo.length}
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    }
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default GenerateTestingReports;
