import React, { Fragment, useState, useCallback, useEffect } from "react";
import {
  Container,
  Row,
  Table,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  CardFooter,
  ModalFooter,
  ModalHeader,
  Form,
} from "reactstrap";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import moment from "moment";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { Btn, H2, H3, H6, H5, LI, P, UL } from "../../AbstractElements";
import { Subscribe } from "../../Constant";
import Pagination from "rc-pagination";
import cloneDeep from "lodash/cloneDeep";
import * as mutations from "../../graphql/mutations";
import { USERADDON, ASSETADDON } from "../../Config/SubscriptionPaymentLinks";

const TransactionDetails = () => {
  const [LoginUserDetails, setLoginUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [subscriptionsplansData, setSubscriptionsplansData] = useState([]);
  const [collection, setCollection] = useState([]);
  const history = useNavigate();
  const navigate = useNavigate();
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditHold = useCallback(
    (row) => navigate(`/ecommerce/invoice`, { state: { row } }),
    []
  );

  useEffect(() => {
    fetchSubscriptionList();
  }, []);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(subscriptionsplansData.slice(from, to)));
  };

  async function fetchSubscriptionList() {
    try {
      setIsLoading(true);
      const response = await API.graphql({
        query: queries.listSubscriptionLimits,
        authMode: "API_KEY",
        variables: {
          limit: 1000,
          filter: {
            subscriptionId: {
              eq: LoginUserDetails.paymentId,
            },
          },
        },
      });
      console.log(response);
      if (response.data.listSubscriptionLimits.items.length > 0) {
        const response1 = await API.graphql({
          query: queries.listSubscriptionAdds,
          authMode: "API_KEY",
          variables: {
            limit: 1000,
            filter: {
              userdetailsID: {
                eq: LoginUserDetails.id,
              },
            },
          },
        });
        if (response1.data.listSubscriptionAdds.items.length > 0) {
          const addons = response1.data.listSubscriptionAdds.items;
          console.log(addons);
          let plansData = [];
          for (let item of addons) {
            plansData.push({
              subscriptionId:
                item.subscriptionId == 1
                  ? "Lite"
                  : item.subscriptionId == 2
                  ? "Tier 1"
                  : item.subscriptionId == 3
                  ? "Tier 2"
                  : item.subscriptionId == 5
                  ? "Corporate"
                  : item.subscriptionId == 6
                  ? "Asset Add-On"
                  : "User Add-On",
              startDate: item.startDate,
              id: item.id,
              amount: item.amount,
              dueDate: item.dueDate,
              quantity: item.quantity,
              status: item.status,
              invoiceId: item.invoiceId,
            });
          }
          console.log(plansData);
          setSubscriptionsplansData(plansData);
          setCollection(cloneDeep(plansData.slice(0, countPerPage)));
          setIsLoading(false);
        } else {
          // setSubscriptionsplansData(response.data.listSubscriptionLimits.items);
          // setCollection(
          //   cloneDeep(
          //     response.data.listSubscriptionLimits.items.slice(0, countPerPage)
          //   )
          // );
          setSubscriptionsplansData([]);
          setCollection(cloneDeep([].slice(0, countPerPage)));
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        setSubscriptionsplansData([]);
        setCollection(cloneDeep([].slice(0, countPerPage)));
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setSubscriptionsplansData([]);
      setCollection(cloneDeep([].slice(0, countPerPage)));
    }
  }

  const renderList = (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <Row>
                <Col md="6">
                  <H2>Transactions</H2>
                </Col>
              </Row>
              {/* </CardHeader> */}
              <CardBody>
                <div className="card-block row">
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <Table className="table-striped  table-de">
                        <thead className="custom-table-head">
                          <tr>
                            <th>Subscription Type</th>
                            <th>Subscription Start Date</th>
                            <th>Subscription Due Date</th>
                            <th>Status</th>
                            <th>Invoice Id</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="trhover">
                          {collection.length > 0 ? (
                            collection.map((item) => (
                              <tr key={item.id}>
                                <td>{item.subscriptionId}</td>
                                <td>{item.startDate}</td>
                                <td>{item.dueDate}</td>
                                <td>{item.status}</td>
                                <td>{item.invoiceId}</td>
                                <td>
                                  <Btn
                                    attrBtn={{
                                      className: "btn-outline-* btn-xs m-r-5",
                                      color: "info",
                                      title: "Edit",
                                      onClick: () => handleEditHold(item),
                                    }}
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Btn>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan="5" className="text-center">
                                  No Data Available.
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </div>
              </CardBody>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={subscriptionsplansData.length}
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};
export default TransactionDetails;
