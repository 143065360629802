import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { H3, Btn, Image } from "../../AbstractElements";
import { useNavigate, useLocation } from "react-router-dom";
import imgg from "../../assets/images/logo/logo.png";
import {
  LITE,
  TIER1,
  TIER2,
  CORPORATE,
  DEVELOPERTESTING,
} from "../../Config/SubscriptionPaymentLinks";

const PaymentStatusContain = () => {
  const navigate = useNavigate();
  const state = useLocation();
  console.log(state);
  console.log(state);
  const linkToRegister = () => {
    //navigate(`${process.env.PUBLIC_URL}/paymentstatus`);
    console.log(state);
    console.log(state.state.site.paymentId);
    if (state.state.site.paymentId === "1") {
      window.location.href = LITE + state.state.site.id;
    } else if (state.state.site.paymentId === "2") {
      window.location.href = TIER1 + state.state.site.id;
    } else if (state.state.site.paymentId === "3") {
      window.location.href = TIER2 + state.state.site.id;
      // } else if (props.values.PaymentId === "4") {
      //   window.location.href = TIER3 + userSub;
    } else if (state.state.site.paymentId === "5") {
      window.location.href = CORPORATE + state.state.site.id;
    }
    //local bill plan
    // else {
    //   window.location.href = DEVELOPERTESTING + state.state.site.id;
    // }
  };
  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xl="12">
          <div className="login-card">
            <Card>
              <CardBody>
                <div className="login-header text-center">
                  <H3>
                    Your payment is not complete due to some technical issue.
                    please retry your payment
                  </H3>
                  <br></br>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "btn-block",
                      onClick: () => linkToRegister(),
                    }}
                  >
                    Retry Payment
                  </Btn>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default PaymentStatusContain;
