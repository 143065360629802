import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import ViewCompanyDetailsContain from '../../../Component/CompanyDetails/ViewCompanyDetails';

const ViewCompanyDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="CompanyAssetOwner" title="" />
      <ViewCompanyDetailsContain />
    </Fragment>
  );
};

export default ViewCompanyDetails;