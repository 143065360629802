import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AssetLogbook from '../../Component/Reports/assetsLogbook';

const AssetLogbookOwnerDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Logbook" title="Assets List" />
      <AssetLogbook />
    </Fragment>
  );
};
export default AssetLogbookOwnerDetails;