import React, { Fragment } from 'react';
import Register from '../../Component/SiteOwner/Register';

const RegisterOption = () => {
  return (
    <Fragment>
      <Register />
    </Fragment>
  );
};
export default RegisterOption;