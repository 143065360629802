import React, { Fragment } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { ForgotPassword, RememberPassword } from '../../../Constant';
import { Link } from 'react-router-dom';

const FormPassword = () => {
    const saveUser = () => {
        const rmCheck = document.getElementById("checkbox1"),
              username = document.getElementById("username");
        if (rmCheck.checked && username.value !== "") {
            localStorage.username = username.value;
            localStorage.checkbox = rmCheck.value;
        } else {
            localStorage.username = "";
            localStorage.checkbox = "";
        }
    }
    return (
        <Fragment>
            <FormGroup className="login-btn">
                <div className="checkbox">
                    <Input id="checkbox1" value="lsRememberMe" type="checkbox" onChange={saveUser} />
                    <Label for="checkbox1">{RememberPassword}</Label>
                </div>
                <Link to={`${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`} className="link">
                        {ForgotPassword}?
                    </Link>
            </FormGroup>
        </Fragment>
    );
};
export default FormPassword;