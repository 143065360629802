import { Btn, UL, LI } from '../../../AbstractElements';
import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Input, Table, CardFooter, Col, Card, InputGroup, Label, Media, Modal, ModalBody, ModalFooter, Form, ModalHeader } from 'reactstrap';
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import '../../../assets/scss/index.less';
import { LargeModal } from '../../../Constant/index';
import AddCategory from './AddCategory';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import * as mergedQueries from '../../../graphql/mergedQueries';
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner"
import { toast } from 'react-toastify';
import { useForm, Controller } from "react-hook-form";
import ScrollBar from 'react-perfect-scrollbar';
import awsExports from "../../../aws-exports";
import AWS from 'aws-sdk';
import { S3_BUCKET, REGION, CATEGORIES_FOLDER, SECRETACCESSKEY, ACCESSKEYID } from '../../../Config/Config';
var base64 = require("base-64");

Amplify.configure(awsExports);

const CategoriesDetailsContain = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () => setLarge(!Large);
  const [ViewLarge, setViewLarge] = useState(false);
  const ViewLargeModaltoggle = () => setViewLarge(!ViewLarge);
  const [serviceGroup, setServiceGroup] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [collection, setCollection] = useState([]);
  const [editData, setEditData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [formDetails, setFormDetails] = useState([]);
  const [formIds, setFormIds] = useState([]);
  const [formIdsSelected, setSelectedFormIds] = useState([]);
  const [categoryFormIds, setCategoryFormIds] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const globalSearch = (searchValue) => {
    setCurrentPage(1);
    const filteredData = categoryDetails.filter((item) => {
      const serviceGroup = getServiceGroup(item.servicegroupID) || '';
      return (
        item.categoryName?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        serviceGroup.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        item.formDescription?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        item.status?.toLowerCase().includes(searchValue.trim().toLowerCase())
      );
    });
    setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
  };

  const getFormIds = (e) => {
    const categoryId = editData.id;
    const selectedId = e.target.id;
  
    if (e.target.checked) {
      setCategoryFormIds((prev) => ({
        ...prev,
        [categoryId]: [...prev[categoryId], selectedId]
      }));
    } else {
      setCategoryFormIds((prev) => ({
        ...prev,
        [categoryId]: prev[categoryId].filter((id) => id !== selectedId)
      }));
    }
  }

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      globalSearch(value);
    }
  }, [value]);

  const updatePage = p => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(categoryDetails.slice(from, to)));
  };

  const onSubmit = async (data) => {
    const selectedFormIds = categoryFormIds[editData.id] || [];
    console.log(data);
    console.log(data.imageURL)
    console.log(ProfilePic)
    await API.graphql({
      query:mutations.updateCategories,
      variables:{
        input: {         
          categoryName: data.categoryName,
          servicegroupID: data.serviceGroup,
          formDescription: data.formDescription,
          assetNumberPrefix: data.assetNumberPrefix,
          imageURL: ProfilePic !== null ? ProfilePic : data.imageURL,
          formIds: selectedFormIds,
          id: editData.id       
        }},
        authMode: 'API_KEY'
      }).then( async (result) => {
        console.log(result);
        const addedsite = await API.graphql({
          query: queries.getCategories,
          variables: {
            id: editData.id,
            limit: 10000,
          },
          authMode: "API_KEY",
        });
        console.log(addedsite);
        if (addedsite.data.getCategories !== null) {
          let activeAssets = [];
          if (localStorage.getItem("category") !== null) {
            activeAssets = JSON.parse(base64.decode(localStorage.getItem("category")));
            console.log(activeAssets);
            const existingIndex = activeAssets.findIndex(
              (item) =>
                item.id === addedsite.data.getCategories.id
            );
            console.log(existingIndex);
            if (existingIndex > -1) {
              activeAssets[existingIndex] = addedsite.data.getCategories;
            } else {
              activeAssets.push(addedsite.data.getCategories);
            }
            console.log(activeAssets);
            localStorage.setItem("category", base64.encode(JSON.stringify(activeAssets)));
          }
        }
        toast.success('Category Detail Updated Successfully', {
          position: toast.POSITION.TOP_CENTER
        });
        setViewLarge(false);
        fetchCategoriesData();
        setProfilePic(null); 
      }).catch(error => {
        console.log(error);
        toast.error('Error While Updating Category Detail', {
          position: toast.POSITION.TOP_CENTER
        });
      });
    }  

  function handelAction(data) {
    const selectedFormIds = data.formIds || [];
  setCategoryFormIds((prev) => ({
    ...prev,
    [data.id]: selectedFormIds
  }));
    console.log(data);  
    const imageURL = getfilename(data.imageURL);
    const resetData = {
      categoryName: data.categoryName,
      serviceGroup: data.servicegroupID,
      formDescription: data.formDescription,
      formIds: data.formIds,
      assetNumberPrefix: data.assetNumberPrefix,
      imageURL: data.imageURL
    };
   // if (imageURL) {
     // resetData.imageURL = imageURL;
    //}
    reset(resetData);
    setSelectedFormIds(data.formIds);
    setEditData(data);
    if (edit) setEdit(!edit);
    ViewLargeModaltoggle();
  }

  //---Approve Status-----------------------------

  const ApproveStatus = (status) => {
    return (status.toLowerCase() === 'active') ? 'switch-state bg-success' : 'switch-state bg-danger';
  }

  const handleChange = async (item) => {
    var statusSp;

        if (item.status.toLowerCase() === 'active') {
            statusSp = 'Inactive';
            toast.success('Deactivated Successfully.', {
                position: toast.POSITION.TOP_CENTER
            });
        } else {
            statusSp = 'Active';
            toast.success('Activated Successfully.', {
                position: toast.POSITION.TOP_CENTER
            });
        }
    console.log(item);
    await API.graphql(graphqlOperation(mutations.updateCategories, {
      input: { id: item.id, status: statusSp }
    })).then(result => {
      console.log(result);
      fetchCategoriesData();
    }).catch(error => {
      console.log(error);
      toast.error('Error while Updating ', {
        position: toast.POSITION.TOP_CENTER
      });
    });
  }

  //------------------------list------

  async function fetchCategoriesData() {
    setIsLoading(true);
    await API.graphql({
      query: queries.listCategories,
      authMode: 'API_KEY',
      variables: {
        limit: 10000
      }
    }).then((response => {
      setIsLoading(false);
      const sortedItems = response.data.listCategories.items.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      console.log(sortedItems)
      const categoriesDetails = sortedItems;
      console.log(categoriesDetails);
      setCategoryDetails(categoriesDetails);
      setCollection(cloneDeep(categoriesDetails.slice(0, countPerPage)));
    })).catch(() => {
      setErrorMessage("Unable to fetch the list");
      setIsLoading(false);
    })
  }

  useEffect(() => {
    async function fetchFormDetailsData() {
      await API.graphql({
        query: queries.listFormDetails,
        authMode: 'API_KEY',
        variable:{
          limit: 10000
        }
      })
        .then((result) => {
          console.log(result);        
          const formDetails = result.data.listFormDetails.items;
          console.log(formDetails);
          const filteredFormDetails = formDetails.filter(item => item.formType !== 'Asset Template');
          setFormDetails(filteredFormDetails);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchFormDetailsData();
    fetchCategoriesData();
    async function fetchServiceGroups() {
      await API.graphql({
        query: queries.listServiceGroups,
        authMode: 'API_KEY',
        variables: {
          limit: 10000
        }
      }).then((result) => {
        const serviceGroups = result.data.listServiceGroups.items;
        console.log(serviceGroups);
        setServiceGroup(serviceGroups);
      }).catch((error) => {
        console.log(error);
      });
    }
    fetchServiceGroups();
  }, []);

  const getServiceGroup = (id) => {
    const groupName = serviceGroup.filter(item => { return item.id === id });
    if (groupName.length > 0) {
      return groupName[0].serviceName;
    }
    else {
      return '';
    }
  }
  // const getFormName = (row) => {
  //   formIdsSelected.map(item =>{
  //     if(item === row.id){
  //       return '<LI>'+row.formName+'</LI>';
  //     }
  //   })
  // }

  /*--- check Category is eligible for edit --*/
  const checkCategory = async (catid) => {
    console.log(catid);
    await API.graphql({
      query: mergedQueries.checkCategoryUsed,
      variables: {
        filter: {
          id: {
            eq: catid
          }
        }
      },
      authMode: 'API_KEY',
      limit: 10000
    }).then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  // image retrive

  const [downloadedFile, setdownloadedFile] = useState(null);
  const [selectedImg, setselectedImg] = useState(null);
  const [ProfilePic, setProfilePic] = useState([]);

  const onSelectFile = async (e) => {        
    const file = e.target.files[0];
    const params = {
        ACL: 'public-read',
        Body: file,
        Bucket: S3_BUCKET,
        Key: `public/${CATEGORIES_FOLDER}/${file.name}`
    };      
    myBucket.putObject(params).on('httpUploadProgress', (evt) => {
      console.log(evt);
      const filedetails = [];
      filedetails.push({'fileName':file.name, 'type': file.type});
      setProfilePic(JSON.stringify(filedetails));
    })
    .send((err) => {
      if (err) console.log(err)
    })
  }

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const getImage = (filename) => {
    var params = { Bucket: S3_BUCKET, Key: `public/${CATEGORIES_FOLDER}/${filename}` };
    var promise = myBucket.getSignedUrlPromise('getObject', params);
    promise.then(function (url) {
      setdownloadedFile(url);
      setselectedImg(url);
    }, function (err) { console.log(err) });
  }
  //-------------------------------------------------------------------------
  function getfilename(path) {
    if (!path || path.trim() === '') {
      setdownloadedFile(null); 
      return null;
    }
    const files = JSON.parse(path);
    if (files.length > 0) {
      var newfilename = files[0].fileName;
      getImage(newfilename);
    } else {
      setdownloadedFile(null); 
    }
  }


  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <Row>
              <Col md="6">
                <InputGroup><span className="input-group-text"><i className="icon-search"></i></span>
                  <Input type="search" placeholder="Search Category.." value={value} onChange={e => setValue(e.target.value)} />
                </InputGroup>
              </Col>
              <Col md="6">
                <Btn attrBtn={{ color: 'primary', onClick: LargeModaltoggle }}><i className="icofont icofont-plus"></i> Add Category</Btn>
              </Col>
            </Row>
            <Row>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table className='table-striped  table-de'>
                      <thead className="custom-table-head">
                        <tr>
                          <th>Cat. No.</th>
                          <th>Category Name</th>
                          <th>Service Group</th>
                          <th>Status</th>
                          <th>Active</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                      {collection.length > 0 ? (
                          collection.map((catItem, i) =>
                            <tr key={catItem.id}>
                              <td>
                                {catItem.categoryNumber}
                              </td>
                              <td>
                                {catItem.categoryName}
                              </td>
                              <td>
                                {getServiceGroup(catItem.servicegroupID)}
                              </td>
                              <td>
                                {catItem.status}
                              </td>
                              <td>
                                <Media body className="icon-state switch-sm">
                                  <Label className="switch">
                                    <input type="checkbox" onChange={(e) => handleChange((catItem), e)} key={`active${i}`} />
                                    <span className={ApproveStatus(catItem.status)}></span>
                                  </Label>
                                </Media>
                              </td>
                              <td>
                                <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5", color: "primary", title: "View", onClick: () => handelAction(catItem) }} ><i className="fa fa-eye"></i></Btn>
                                {/*   <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'info', title: 'Edit',  onClick: () => handelAction(catItem)}} key={`edit${i}`}><i className="fa fa-edit "></i></Btn>
                                      <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'primary', title: 'View', onClick: ViewLargeModaltoggle }} key={`view${i}`}><i className="fa fa-eye"></i> </Btn>
                                      <ViewCompanyDetailsContain isOpen={ViewLarge} title={LargeModal} toggler={ViewLargeModaltoggle} size="lg">...</ViewCompanyDetailsContain> */}
                              </td>
                            </tr>
                          )
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan="5" className="text-center">
                                  No Data Available.
                                </td>
                              </tr>
                            </tbody>
                          )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </div>
            </Row>
            <CardFooter className="row">
              <Pagination
                className='p-t-10'
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={(!value) ? categoryDetails.length : collection.length}
                showTotal={(total, range) =>
                  `${range[0]} - ${range[1]} of ${total} items`
                }
              />
            </CardFooter>
          </Card>
          <AddCategory isOpen={Large} title={LargeModal} toggler={LargeModaltoggle} categoriesData={fetchCategoriesData} size="lg">...</AddCategory>
        </Col>
        <Modal isOpen={ViewLarge} toggle={ViewLargeModaltoggle} size="lg" centered>
          <ModalHeader toggle={ViewLargeModaltoggle}>
            {edit ? 'Edit' : 'View'} Category
          </ModalHeader>
          <ModalBody className="modal-body">
            <Row>
              <Col md="12 mb-3" className="text-end">
                {/* {checkCategory(editData.id)} */}
                <Btn attrBtn={{ color: 'primary', onClick: () => setEdit(!edit) }}>{edit ? 'View' : 'Edit'}</Btn>
              </Col>
            </Row>
            {
              edit &&
              <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)} >
                <Row>
                  <Col md="6 mb-3">
                    <Label>Category Name</Label>
                    <input type="text" className="form-control" placeholder="Category Name" name="categoryName" {...register('categoryName', { required: true })} />
                    <span>{errors.categoryName && 'Please Enter Category Name.'}</span>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Service Group</Label>
                    <select className="form-control" placeholder="Service Group" name="serviceGroup" disabled={edit} {...register('serviceGroup', { required: true })}>
                      <option value="">Choose Service Group</option>
                      {
                        serviceGroup.map((item) =>
                          <option value={item.id}>{item.serviceName}</option>
                        )
                      }
                    </select>
                    <span>{errors.serviceGroup && 'Please Select Service Group.'}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-3">
                      <Label>Prefix Text</Label>
                      <input type="text" className="form-control" placeholder="Prefix Text" name="assetNumberPrefix" {...register('assetNumberPrefix', { required: true })} />
                      <span>{errors.assetNumberPrefix && 'Please Enter Prefix Text.'}</span>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Form Description</Label>
                    <input type="textarea" className="form-control" placeholder="Form Description" name="formDescription" {...register('formDescription', { required: false })} />
                  </Col>
                </Row>
                <br></br>
            <Row>
              <Col md="6 mb-3">
                <Label>Picture</Label>
              </Col>
            </Row>
            <Row><Col md="6 mb-3">
              <Input type="file" className="form-control" name="profilePic"   onChange={onSelectFile}  />
            </Col> </Row>
            <Row><Col md="6 mb-3">
            {downloadedFile ? <img src={downloadedFile} width="50px" height="50px" /> : "No Profile Pic"}
            </Col></Row>
            <br></br>
                <Row>
                  <Col md="12 mb-3">
                    <Label>Include Forms</Label>
                    <ScrollBar className='vertical-scroll ps-container ps-theme-default ps-active-y' style={{ "height": "300px", "border": "1px solid #efefef", "padding": "5px", "borderRadius": "5px" }}>
                      {
                        formDetails.map((item) =>
                          <Label className="d-block" for={item.id}>
                            <input
                              className="checkbox_animated"
                              id={item.id}
                              type="checkbox"
                              checked={categoryFormIds[editData.id]?.includes(item.id)}
                              onChange={e => getFormIds(e)} />
                            {item.formName}
                          </Label>
                        )
                      }
                    </ScrollBar>
                  </Col>
                </Row>
                <Btn attrBtn={{ color: 'primary' }}>Update</Btn>
              </Form>
            }
            {
              !edit &&
              <div>
                <Row>
                  <Col md="6 mb-3">
                    <Label>Category Name : {editData.categoryName}</Label>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Service Group : {getServiceGroup(editData.servicegroupID)}</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-3">
                    <Label>Form Description : {editData.formDescription}</Label>
                  </Col>
                  <Col md="6 mb-3">
                      <Label>Prefix Text : {editData.assetNumberPrefix}</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-3">
                    <Label>Picture</Label>
                  </Col>
                </Row>
                <br></br>
                <Row>< Col md="6 mb-3">
                  {downloadedFile ? <img src={downloadedFile} width="50px" height="50px" /> : "No Profile Pic"}
                </Col>
                </Row>
              </div>
            }
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default CategoriesDetailsContain;