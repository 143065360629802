import React, { Fragment, useState, useEffect } from "react";
import { API, DataStore } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mergedQueries from '../../graphql/mergedQueries';
import { toast } from 'react-toastify';
import { CardBody, CardHeader, Card, Row, Col, Media, Container, Label } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { H4, UL, LI, Btn } from '../../AbstractElements';
import { useNavigate, useLocation, useParams , Link} from 'react-router-dom';
import { AssetTemplate } from "../../models/index";
import ScrollBar from 'react-perfect-scrollbar';

const UserPanelContain = () => {
    const navigate = useNavigate();
    const [assetCategories, setAssetCategories] = useState([]);
    const [categories, setCategory] = useState([]);
    const [siteDetails, setSiteDetails] = useState([]);
    const [distributionBox, setDistributionBox] = useState([]);
    const [distributionBoxFilter, setDistributionBoxFilter] = useState([]);
    const [assetTemplate, setAssetTemplate] = useState([]);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const LoginUserGroup = localStorage.getItem("groups");
    const [siteId, setSiteId] = useState('');
    const [dBId, setDBId] = useState('');
    const [assets, setAssets] = useState([]);
    const [assetsFilter, setAssetsFilter] = useState([]);
    const [assetSelected, setAssetSelected] = useState([]);
    const [result, setResult] = useState('');
    const [LoginUserDetails, setLoginUserDetails] = useState(JSON.parse(localStorage.getItem("userDetails")));

    useEffect(async () => {
        //getSiteUsers();
        getDistributionBox();
        getAssets();
        getSite();
        //await getCategories();        
    }, []);

    async function getCategories(){
        await API.graphql({
            query: mergedQueries.getLoggedInUserCategories,
            variables:{
                filter:{
                    serviceProvider:{
                        eq: (LoginUserGroup === "electrician" ? "Electrician" : "")
                    }
                },
                limit:10000
            },
            authMode: 'API_KEY'
        }).then(result => {
            console.log(result);
            const category = result.data.listServiceGroups.items;
            if(category.length > 0){
                const categoryDatas = [];
                category.map(item => {
                    if(item.Categories.items.length > 0){
                        item.Categories.items.map(catItem => {
                            categoryDatas.push(catItem);
                        });
                    }
                });
                setCategory(categoryDatas);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    async function getSiteUsers(){
        await API.graphql({ 
            query: queries.getUserDetails, 
            variables: {
                id: userDetails.id
            }
        }).then(result => {
            const sites = result.data.getUserDetails.LinkUsersAndSites.items;
            if(sites.length > 0){
               // getSite(sites);
            }
        }).catch(error => {
            toast.error("error loading getUserDetails -> "+error.message,{
                position: toast.POSITION.TOP_CENTER
            });
        });
    }

    async function getSite() {
        await API.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: 10000,
          },
        })
          .then((dataDetails) => {
            const siteDetails =
              dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
            if (siteDetails.length > 0) {
              const siteFilterArray = [];
              siteDetails.map(async (item) => {
                siteFilterArray.push({
                  id: {
                    eq: item.sitedetailsID,
                  },
                });
              });
              API.graphql({
                query: queries.listSiteDetails,
                variables: {
                  filter: {
                    or: siteFilterArray,
                  },
                  limit: 10000,
                },
              })
                .then((result) => {
                    console.log(result)
                    setSiteDetails(result.data.listSiteDetails.items);
                })
                .catch((error2) => {
                  console.log(error2);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

    async function getSitedupe(sites){
        const siteArray = [];
        sites.map(item => {
            siteArray.push({
                id:{
                    eq:item.sitedetailsID
                }
            })
        });
        await API.graphql({
            query: queries.listSiteDetails,
            variables: {
                filter:{
                or: siteArray
                },            
            limit:10000
            }
        }).then(result => {
            console.log(result);
            setSiteDetails(result.data.listSiteDetails.items);
        }).catch(error => {
            toast.error("error loading Site Details -> "+error.message,{
                position: toast.POSITION.TOP_CENTER
            });
        });
    }

    async function getDistributionBox(){
        await API.graphql({
            query: queries.listDistributionBoxes,
            variables:{
                limit: 10000
            },
            authMode: 'API_KEY'
        }).then(result => {
            console.log(result);
            setDistributionBox(result.data.listDistributionBoxes.items);
        }).catch(error => {
            console.log(error);
        })
    }

    async function getAssets(){
        await API.graphql({
            query: queries.listAssets,
            variables:{
                filter:{
                    siteId:{
                        eq: siteId
                    }
                },
                limit: 10000
            },
            authMode: 'API_KEY'
        }).then(result => {
            console.log(result);
            setAssets(result.data.listAssets.items);
        }).catch(error => {
            console.log(error);
        })
    }

    async function getAssetsBYDB(dbIds){
        await API.graphql({
            query: queries.listAssets,
            variables:{
                filter:{
                    distributionboxID:{
                        eq: dbIds
                    }
                },
                limit: 10000
            },
            authMode: 'API_KEY'
        }).then(result => {
            console.log(result);
            setAssetsFilter(result.data.listAssets.items);
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        if (siteId !== '') {
            setDistributionBoxFilter(distributionBox.filter(item => {return item.sitedetailsID === siteId}));
        }
        else{
            setDistributionBoxFilter(distributionBox);
        }
    }, [siteId]);

    const chooseDB = (dbId) => {
        if (dbId !== '') {
            setDBId(dbId);
            getAssetsBYDB(dbId);//setAssetsFilter(assets.filter(item => {return item.distributionboxID === dbId}));
        }
        else{
            setAssetsFilter(assets);
        }
    }

    const sendEmail = () => {
        navigate(`${process.env.PUBLIC_URL}/testing`);
    }
    let assetArray = [];
    const selectAsset = (data) => {
        if(data.target.checked){
            assetArray.push(data.target.id);
            setAssetSelected(...assetArray);
        }
        else{
            var index = assetSelected.indexOf(data.target.id);
            if (index !== -1) {
                assetSelected.splice(index, 1);
            }
        }
    }

    const createTesting = () =>{
        console.log(assetSelected);
        console.log(result);
        navigate(`${process.env.PUBLIC_URL}/testing`, {state: {sites: siteId, db: dBId, assets: assetSelected, testResult: result}});
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col md="12" className="project-list">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <Label>Sites</Label>
                                        <select className="form-control" style={{height: '200px'}} placeholder="Choose Site..." multiple={true} onChange={event => setSiteId(event.target.value)}>
                                            <option>Choose Site...</option>
                                            {
                                                siteDetails.map((item) =>
                                                    <option value={item.id} key={item.id}>{(item?.siteName || "") +
                                                    " (" +
                                                    (item?.unitNumber || "") +
                                                    ")"}</option>
                                                )
                                            }
                                        </select>
                                    </Col>
                                    <Col md="6">
                                        <Label>Distribution Board</Label>
                                        <select className="form-control" style={{height: '200px'}} placeholder="Choose Distribution Board..." multiple={true} onChange={event => chooseDB(event.target.value)}>
                                            <option>Choose Distribution Board...</option>
                                            {
                                                distributionBoxFilter.map((item) =>
                                                    <option value={item.id} key={item.id}>{item.distributionBoxName}</option>
                                                )
                                            }
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="2">
                                    </Col>
                                    <Col md="2">
                                    </Col>
                                    <Col md="2">
                                    </Col>
                                    <Col md="2">
                                    </Col>
                                    <Col md="2">
                                    </Col>
                                    <Col md="2" className="text-end">
                                        <select className="form-control" placeholder="Testing Result" onChange={(e) => setResult(e.target.value)}>
                                            <option>Testing Result</option>
                                            <option value="pass">Pass</option>
                                            <option value="fail">Fail</option>
                                            <option value="skip">Skip</option>
                                        </select>
                                    </Col>
                                    <Col md="12">
                                        <Label>Asset Lists</Label>
                                        <ScrollBar className='vertical-scroll ps-container ps-theme-default ps-active-y' style={{"height": "300px","border": "1px solid #efefef","padding": "5px","borderRadius": "5px"}}>
                                            <UL>
                                                {
                                                    assetsFilter.map((item, id) =>
                                                        <LI key={id}>
                                                            <input
                                                                type="checkbox"
                                                                id={item.id}
                                                                name={item.id}
                                                                value={item.assetModel}
                                                                onChange={e => selectAsset(e)}
                                                                className="checkbox_animated"
                                                            />
                                                            <label htmlFor={`custom-checkbox-${id}`}>{item.assetModel}</label>
                                                        </LI>
                                                    )
                                                }                  
                                            </UL>
                                        </ScrollBar>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" className="text-end">
                                        <Btn attrBtn={{ color: 'primary', onClick: createTesting }} >Update</Btn>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    {
                                        categories.map((item) =>
                                            <Col md="3" key={item.id}>
                                                <Card>
                                                    <CardHeader>
                                                        <Link to={`/assetsLists`} state ={{id:`${item.id}`, serviceName: `${item.servicegroupID}`}}><H4>{item.categoryName}</H4></Link>
                                                    </CardHeader>
                                                    <CardBody>
                                                        0
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    }
                                </Row> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
export default UserPanelContain;