import { Btn, H3 } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Col,
  Form,
  Label,
  Row,
  Card,
  Input,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Amplify, API, Storage } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as mergedQueries from "../../../graphql/mergedQueries";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import uuid from "react-native-uuid";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import {
  S3_BUCKET,
  REGION,
  ASSET_OWNER_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
  limit,
} from "../../../Config/Config";
var base64 = require("base-64");

Amplify.configure(awsExports);

const AddAssetTemplateContain = () => {
  const navigate = useNavigate();
  const state = useLocation();

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  // const [isRequired, setIsRequired] = useState(false);
  const [step, setStep] = useState(1);
  const [templateName, setTemplateName] = useState("");
  const [formFieldData, setFormData] = useState([]);
  const [seviceGroup, setSeviceGroup] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [edit, setEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [fieldEdit, setFieldEdit] = useState("");
  const [version, setVersion] = useState("");
  const [seviceGroupValue, setSeviceGroupValue] = useState("");
  const [submitData, setSubmitData] = useState([]);
  const [fieldlabel, setfieldlabel] = useState("");
  const [isRequired, setisRequired] = useState("");
  const [chooseType, setchooseType] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [catId, setCatId] = useState();
  const [maxNum, setmaxNum] = useState();
  const [dropdownField, setddField] = useState(false);
  const [optionData, setOptionData] = useState("");
  const [assetTemplate, setAssetTemplate] = useState([]);
  //const [limit, setLimit] = useState(100000);

  useEffect(() => {
    console.log(state);
    if (state.state && state.state.assetTemplates) {
      setAssetTemplate(state.state.assetTemplates);
    }
    async function fetchlistServiceGroup() {
      await API.graphql({
        query: queries.listServiceGroups,
        authMode: "API_KEY",
        variables: {
          limit: limit,
          filter: {
            status: {
              eq: "Active",
            },
          },
        },
      })
        .then((result) => {
          const serviceGroupDetails = result.data.listServiceGroups.items;
          setSeviceGroup(serviceGroupDetails);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function fetchlistCategories() {
      await API.graphql({
        query: queries.listCategories,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      })
        .then((result) => {
          const serviceGroupDetails = result.data.listCategories.items;
          setCategories(serviceGroupDetails);
          setCategoriesFilter(serviceGroupDetails);
          if (state.state !== null) {
            async function fetchGetAssetTemplate() {
              await API.graphql({
                query: queries.getFormDetails,
                variables: {
                  id: state.state.formId,
                },
                authMode: "API_KEY",
              })
                .then((formDetailsResult) => {
                  console.log(formDetailsResult);
                  const assetTemplateArray = {
                    id: state.state.id,
                    templateName: state.state.templatename,
                    version: state.state.version,
                    createdAt: state.state.createdAt,
                    formId: state.state.formId,
                    description: state.state.description,
                    status: state.state.status,
                    category: state.state.categoriesID,
                    serviceGroup: state.state.servicegroupID,
                  };
                  setEdit(true);
                  console.log(assetTemplateArray);
                  reset({ ...assetTemplateArray });
                  setFormData(
                    JSON.parse(formDetailsResult.data.getFormDetails.formFields)
                  );
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            fetchGetAssetTemplate();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function fetchFormDetailsDataMaxId() {
      await API.graphql({
        query: queries.listFormDetails,
        authMode: "API_KEY",
      })
        .then((result) => {
          console.log(result);
          const formDetails = result.data.listFormDetails.items;
          let idArry = [];
          let maxnumber = 0;
          formDetails.map((item) => {
            if (item.formNumber !== "" && item.formNumber !== null) {
              if (isNaN(Number(item.formNumber.replace(/^\D+/g, "")))) {
                idArry.push(1);
              } else {
                idArry.push(Number(item.formNumber.replace(/^\D+/g, "")));
              }
            }
          });
          maxnumber = Math.max(...idArry);
          if (idArry.length != 0) {
            if (maxnumber !== 0 && maxnumber !== null) {
              const res = digits_count(Number(maxnumber + 1));
              if (res === 1) {
                setCatId("000");
                setmaxNum(maxnumber + 1);
              } else if (res === 2) {
                setCatId("00");
                setmaxNum(maxnumber + 1);
              } else if (res === 3) {
                setCatId("0");
                setmaxNum(maxnumber + 1);
              } else if (res === 4) {
                setCatId("");
                setmaxNum(maxnumber + 1);
              }
            } else {
              setCatId("000");
              setmaxNum(1);
            }
          } else {
            setCatId("000");
            setmaxNum(1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchlistCategories();
    fetchlistServiceGroup();
    fetchFormDetailsDataMaxId();
  }, []);
  const digits_count = (n) => {
    var count = 0;
    if (n >= 1) ++count;
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  };

  async function onSubmit(formData) {
    try {
      console.log(formData);
      setSubmitData(formData);
      setTemplateName(formData.templateName);
      if (!edit) {
        if (step === 2) {
          await API.graphql({
            query: mutations.createFormDetails,
            variables: {
              input: {
                formName: submitData.templateName,
                servicegroupID: seviceGroupValue,
                serviceGroup: seviceGroupValue,
                formDescription: submitData.description,
                enableSchedule: "false",
                formFields: JSON.stringify(formFieldData),
                status: "Active",
                formType: "Asset Template",
                formNumber: "FORM" + catId + maxNum,
              },
            },
            authMode: "API_KEY",
          })
            .then((result) => {
              console.log(result);
              var matchTemp = assetTemplate.filter((item) => {
                if (item.categoriesID === submitData.category) {
                  return item;
                }
              });
              API.graphql({
                query: mutations.createAssetTemplate,
                variables: {
                  input: {
                    templatename:
                      matchTemp.length > 0
                        ? `Custom - ${submitData.templateName}`
                        : submitData.templateName,
                    servicegroupID: seviceGroupValue,
                    description: submitData.description,
                    formId: result.data.createFormDetails.id,
                    categoriesID: submitData.category,
                    status: "Active",
                  },
                },
                authMode: "API_KEY",
              })
                .then(async (result) => {
                  const addedsite = await API.graphql({
                    query: queries.getAssetTemplate,
                    variables: {
                      id: result.data.createAssetTemplate.id,
                    },
                    authMode: "API_KEY",
                  });
                  if (addedsite.data.getAssetTemplate !== null) {
                    if (localStorage.getItem("assetTemp") !== null) {
                      let activeDB = JSON.parse(
                        base64.decode(localStorage.getItem("assetTemp"))
                      );
                      console.log(activeDB);
                      activeDB.push(addedsite.data.getAssetTemplate);
                      console.log(activeDB);
                      localStorage.setItem(
                        "assetTemp",
                        base64.encode(JSON.stringify(activeDB))
                      );
                    }
                  }
                  toast.success("Successfully Added Asset Template.", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  console.log(result);
                  navigate(`${process.env.PUBLIC_URL}/assettemplates`);
                })
                .catch((error) => {
                  console.log(error);
                  toast.error("Error While Adding Asset Template: " + error, {
                    position: toast.POSITION.TOP_CENTER,
                  });
                });
            })
            .catch((error) => {
              console.log(error);
              toast.error("Error While Adding Asset Template: " + error, {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
        setStep(2);
      } else {
        console.log(state.state.formId);
        if (step === 2) {
          console.log("Edit");
          const result = await API.graphql({
            query: mutations.updateAssetTemplate,
            variables: {
              input: {
                id: state.state.id,
                templatename: submitData.templateName,
                servicegroupID: submitData.serviceGroup,
                categoriesID: submitData.category,
                description: submitData.description,
                formId: state.state.formId,
              },
            },
            authMode: "API_KEY",
          });
          if (result) {
            console.log(result);
            const addedsite = await API.graphql({
              query: queries.getAssetTemplate,
              variables: {
                id: state.state.id,
                limit: 10000,
              },
              authMode: "API_KEY",
            });
            console.log(addedsite);
            if (addedsite.data.getAssetTemplate !== null) {
              let activeAssets = [];
              if (localStorage.getItem("assetTemp") !== null) {
                activeAssets = JSON.parse(
                  base64.decode(localStorage.getItem("assetTemp"))
                );
                console.log(activeAssets);
                const existingIndex = activeAssets.findIndex(
                  (item) => item.id === addedsite.data.getAssetTemplate.id
                );
                console.log(existingIndex);
                if (existingIndex > -1) {
                  activeAssets[existingIndex] = addedsite.data.getAssetTemplate;
                } else {
                  activeAssets.push(addedsite.data.getAssetTemplate);
                }
                console.log(activeAssets);
                localStorage.setItem(
                  "assetTemp",
                  base64.encode(JSON.stringify(activeAssets))
                );
              }
            }
            API.graphql({
              query: mutations.updateFormDetails,
              variables: {
                input: {
                  id: state.state.formId,
                  formFields: JSON.stringify(formFieldData),
                },
              },
              authMode: "API_KEY",
            })
              .then((result) => {
                toast.success("Successfully Updated Asset Template.", {
                  position: toast.POSITION.TOP_CENTER,
                });
                console.log(result);
                navigate(`${process.env.PUBLIC_URL}/assettemplates`);
              })
              .catch((error) => {
                console.log(error);
                toast.error("Error While Updated Asset Template: " + error, {
                  position: toast.POSITION.TOP_CENTER,
                });
              });
          } else {
            toast.error("Error While Updating Asset Template: ", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }
        setStep(2);
      }
    } catch (e) {
      toast.error("Error While Adding Asset Template: " + e.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  async function onAssetTemplateSubmit() {
    try {
      if (fieldlabel !== "" && chooseType !== "") {
        if (fieldEdit !== "") {
          console.log(chooseType);
          let id = fieldlabel.toLowerCase().replace(/\s/g, "");
          const data = [];
          data.push({
            label: fieldlabel,
            type: chooseType,
            required: isRequired,
            errorMessage: errorMessage,
            options: chooseType === "dropdown" ? optionData.split(/\r?\n/) : "",
          });
          console.log(formFieldData);
          setFormData((formFieldData) => ({
            ...formFieldData,
            [fieldEdit]: data[0],
          }));
          setFieldEdit("");
          setfieldlabel("");
          setchooseType("");
          setisRequired("");
          seterrorMessage("");
          setOptionData("");
          setddField(false);
          const value = {
            fieldlabel: "",
            chooseType: "",
            isRequired: false,
            errorMessage: "",
            options: "",
          };
          reset({ ...value });
        } else {
          let id = fieldlabel.toLowerCase().replace(/\s/g, "");
          const data = [];
          data.push({
            label: fieldlabel,
            type: chooseType,
            required: isRequired,
            errorMessage: errorMessage,
            options: chooseType === "dropdown" ? optionData.split(/\r?\n/) : "",
          });
          console.log(formFieldData);
          setFormData((formFieldData) => ({
            ...formFieldData,
            [id]: data[0],
          }));
          setFieldEdit("");
          setfieldlabel("");
          setchooseType("");
          setisRequired(false);
          seterrorMessage("");
          setOptionData("");
          setddField(false);
          const value = {
            fieldlabel: "",
            chooseType: "",
            isRequired: false,
            errorMessage: "",
            options: "",
          };
          reset({ ...value });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const choosseTemplateName = (event) => {
    setTemplateName(event.target[event.target.selectedIndex].text);
  };

  const chooseValue = (event) => {
    const cat = categories.filter((item) => {
      return item.servicegroupID === event.target.value;
    });
    cat.length > 0 ? setCategoriesFilter(cat) : setCategoriesFilter(categories);
    setSeviceGroupValue(event.target.value);
  };

  const editValue = (name, data) => {
    try {
      // const value = {
      //     'fieldlabel': data.label,
      //     'chooseType': data.type,
      //     'isRequired': data.required,
      //     'errorMessage': data.errorMessage,
      //     'options': data.options.join("\n")
      // }
      setfieldlabel(data.label);
      setchooseType(data.type);
      setisRequired(data.required);
      seterrorMessage(data.errorMessage);
      setOptionData(data.options.join("\n"));
      setddField(true);
      // reset({...value});
      setFieldEdit(name);
    } catch (e) {
      console.log(e);
    }
  };

  const formInputs = Object.keys(formFieldData).map((e) => {
    const { label, type, mandatory, errorMessage, options } = formFieldData[e];
    return (
      <Col md="6 mb-3" key={e}>
        <label>{label}</label>
        <a
          style={{ float: "right" }}
          onClick={() => editValue(e, formFieldData[e])}
        >
          <i className="fa fa-edit"></i>
        </a>
        {/* <Btn style={{backgroundColor: "#fff !important",border: "0px !important",color: "#000 !important"}} attrBtn={{ onClick: () => editValue(e, formFieldData[e])}} ><i className="fa fa-edit"></i></Btn> */}
        {type !== "dropdown" && (
          <input
            placeholder={label}
            className="form-control disabled"
            type={type}
            {...register(e, { required: mandatory })}
          />
        )}
        {type === "dropdown" && (
          <select
            className="form-control disabled"
            name={e}
            {...register(e, { required: mandatory })}
          >
            <option value="">Choose {label}...</option>
            {options.map((item) => {
              <option value={item} key={item}>
                {item}
              </option>;
            })}
          </select>
        )}
        {errors[e] && <p className="invalid-feedback">{errorMessage}</p>}
      </Col>
    );
  });

  const chooseTypeDD = (e) => {
    e.target.value === "dropdown" ? setddField(true) : setddField(false);
    setchooseType(e.target.value);
  };

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/assettemplates`);
  };

  return (
    <Fragment>
      <br></br>
      <Container fluid={true} style={{ paddingLeft: "0px !important" }}>
        <Card className="custom-card">
          <CardBody>
            <Form
              id="assetTemplateForm"
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              {step === 1 && (
                <Row>
                  <Col md="12">
                    <Row>
                      <Col md="6 mb-3">
                        <Label>Template Name</Label>
                        <input
                          className="form-control"
                          name="templateName"
                          type="text"
                          placeholder="Template Name"
                          {...register("templateName", { required: true })}
                        />
                        <span>
                          {errors.templateName && "Template Name is required"}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <Label>Service Group</Label>
                        <select
                          className="form-control"
                          name="serviceGroup"
                          placeholder="Service Group"
                          disabled={edit}
                          {...register("serviceGroup", { required: true })}
                          onChange={(e) => chooseValue(e)}
                        >
                          <option value="">Choose Service Group</option>
                          {seviceGroup.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.serviceName}
                            </option>
                          ))}
                        </select>
                        <span>
                          {errors.serviceGroup && "Service Group is required"}
                        </span>
                      </Col>
                      <Col md="6 mb-3">
                        <Label>Choose Category Name</Label>
                        <select
                          className="form-control"
                          name="category"
                          placeholder="Category Name"
                          disabled={edit}
                          {...register("category", { required: true })}
                          onChange={(e) => choosseTemplateName(e)}
                        >
                          <option value="">Choose Category Name</option>
                          {categoriesFilter.map((category) => (
                            <option value={category.id} key={category.id}>
                              {category.categoryName}
                            </option>
                          ))}
                        </select>
                        <span>
                          {errors.category && "Category Name is required"}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <Label>Description</Label>
                        <input
                          className="form-control"
                          name="description"
                          type="textarea"
                          rows="3"
                          placeholder="Description"
                          {...register("description", { required: false })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="text-end">
                        <Btn attrBtn={{ color: "primary", className: "m-r5" }}>
                          {edit ? "Update" : "Save & Add Asset Template"}
                        </Btn>
                        <Btn attrBtn={{ color: "danger", onClick: cancelForm }}>
                          Cancel
                        </Btn>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {step === 2 && (
                <Row>
                  <Row>
                    <Col md="6 mb-3">
                      <H3>
                        {edit
                          ? `Edit Form Fields for ${templateName}`
                          : `Add Form Fields for ${templateName}`}
                      </H3>
                      <hr className="m-t5 m-b5"></hr>
                      <Row>
                        <Col md="6 mb-3">
                          <Label>Field label</Label>
                          <input
                            type="text"
                            className="form-control"
                            name="fieldlabel"
                            id="fieldlabel"
                            placeholder="Field label"
                            {...register("fieldlabel", { required: false })}
                            value={fieldlabel}
                            onChange={(val) => setfieldlabel(val.target.value)}
                          />
                          <span>
                            {errors.fieldlabel && "Please Select Field label."}
                          </span>
                        </Col>
                        <Col md="6 mb-3">
                          <Label>Field Type</Label>
                          <select
                            className="form-control"
                            name="chooseType"
                            id="chooseType"
                            {...register("chooseType", { required: false })}
                            value={chooseType}
                            onChange={chooseTypeDD}
                          >
                            <option value="">Choose Field Type</option>
                            <option value="text">Text</option>
                            <option value="date">Date</option>
                            <option value="textarea">Text Area</option>
                            <option value="dropdown">DropDown</option>
                            {/* <option value="file">File Upload</option>
                                                    <option value="checkbox">Checkbox</option>
                                                    <option value="radio">Radio</option> */}
                          </select>
                          <span>
                            {errors.chooseType &&
                              "Please Select Choose Field Type."}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        {dropdownField && (
                          <Col md="12">
                            <Label>Add Options, one per line</Label>
                            <Input
                              type="textarea"
                              className="form-control"
                              name="options"
                              id="options"
                              {...register("options", { required: false })}
                              rows="5"
                              value={optionData}
                              onChange={(e) => setOptionData(e.target.value)}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md="12 mb-3">
                          <Label className="d-block" for="isRequired">
                            <Input
                              className="checkbox_animated"
                              name="isRequired"
                              id="isRequired"
                              {...register("isRequired", { required: false })}
                              value={isRequired}
                              onChange={(e) => setisRequired(e.target.value)}
                              type="checkbox"
                            />
                            Is Required
                          </Label>
                        </Col>
                        <Col md="12 mb-3">
                          <Label>Error Message</Label>
                          <input
                            type="text"
                            className="form-control"
                            name="errorMessage"
                            id="errorMessage"
                            placeholder="Error Message"
                            {...register("errorMessage", { required: false })}
                            value={errorMessage}
                            onChange={(e) => seterrorMessage(e.target.value)}
                          />
                          {isRequired ? (
                            <span>
                              {errors.errorMessage &&
                                "Please Enter Error Message."}
                            </span>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Btn
                        attrBtn={{
                          color: "primary",
                          onClick: onAssetTemplateSubmit,
                        }}
                      >
                        {fieldEdit !== "" ? "Update Field" : "Add More Fields"}
                      </Btn>
                    </Col>
                    <Col md="6 mb-3">
                      <H3>Form Preview</H3>
                      <hr className="m-t5 m-b5"></hr>
                      <Row>{formInputs}</Row>
                    </Col>
                  </Row>
                  <Col md="12" className="text-end">
                    <Btn attrBtn={{ color: "primary" }}>
                      {edit ? "Update Form Field" : "Save Form Field"}
                    </Btn>
                    <Btn attrBtn={{ color: "danger", onClick: cancelForm }}>
                      Cancel
                    </Btn>
                  </Col>
                </Row>
              )}
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};
export default AddAssetTemplateContain;
