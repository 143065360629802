import React, { Fragment, useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import { H4, H1, Btn } from "../../../AbstractElements";
import { useNavigate, useLocation, Link } from "react-router-dom";

const CategoriesSubListContain = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const LoginUserGroup = localStorage.getItem("groups");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    console.log(state);
    setIsLoading(false);
  }, []);

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/catassetlist`, {
      state: {
        category: state.state.category,
        site: state.state.site,
      },
    });
  };

  return (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <H1>{state.state.category.categoryName}</H1>
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <Col md="12" className="text-end">
              <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                Back
              </Btn>
            </Col>
            <Row>
              {state.state.category.categoryName !== "Thermal Imaging" && (
                <Col md="3">
                  <Link
                    to={`${process.env.PUBLIC_URL}/assets`}
                    state={{
                      category: state.state.category,
                      site: state.state.site,
                    }}
                  >
                    <Card>
                      <CardBody>
                        <Media className="static-widget">
                          <Media body>
                            <H4
                              id="status"
                              attrH6={{ className: "font-roboto" }}
                            >
                              Logbook
                            </H4>
                          </Media>
                        </Media>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              )}
              <Col md="3">
                <Link
                  to={`${process.env.PUBLIC_URL}/catCertificates`}
                  state={{
                    category: state.state.category,
                    site: state.state.site,
                  }}
                >
                  <Card>
                    <CardBody>
                      <Media className="static-widget">
                        <Media body>
                          <H4 id="status" attrH6={{ className: "font-roboto" }}>
                            Certificates
                          </H4>
                        </Media>
                      </Media>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              {state.state.category.categoryName === "Thermal Imaging" && (
                <Col md="3">
                  <Link
                    to={`${process.env.PUBLIC_URL}/thermalReport`}
                    state={{
                      category: state.state.category,
                      site: state.state.site,
                    }}
                  >
                    <Card>
                      <CardBody>
                        <Media className="static-widget">
                          <Media body>
                            <H4
                              id="status"
                              attrH6={{ className: "font-roboto" }}
                            >
                              Reports
                            </H4>
                          </Media>
                        </Media>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              )}
              {state.state.category.categoryName !== "Thermal Imaging" && (
                <Col md="3">
                  <Link
                    to={`${process.env.PUBLIC_URL}/testResult`}
                    state={{
                      category: state.state.category,
                      site: state.state.site,
                    }}
                  >
                    <Card>
                      <CardBody>
                        <Media className="static-widget">
                          <Media body>
                            <H4
                              id="status"
                              attrH6={{ className: "font-roboto" }}
                            >
                              Test Results
                            </H4>
                          </Media>
                        </Media>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              )}
              <Col md="3">
                <Link
                  to={
                    LoginUserGroup === "spao"
                      ? `${process.env.PUBLIC_URL}/tasksaosp`
                      : `${process.env.PUBLIC_URL}/tasksao`
                  }
                  state={{
                    category: state.state.category,
                    site: state.state.site,
                  }}
                >
                  <Card>
                    <CardBody>
                      <Media className="static-widget">
                        <Media body>
                          <H4 id="status" attrH6={{ className: "font-roboto" }}>
                            Tasks
                          </H4>
                        </Media>
                      </Media>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              {state.state.category.categoryName !== "Thermal Imaging" && (
                <Col md="3">
                  <Link
                    to={`${process.env.PUBLIC_URL}/tasks`}
                    state={{
                      category: state.state.category,
                      site: state.state.site,
                    }}
                  >
                    <Card>
                      <CardBody>
                        <Media className="static-widget">
                          <Media body>
                            <H4
                              id="status"
                              attrH6={{ className: "font-roboto" }}
                            >
                              Asset History & Repairs
                            </H4>
                          </Media>
                        </Media>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default CategoriesSubListContain;
