import { Btn } from "../../AbstractElements";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Container,
  FormGroup,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Label,
  Media,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { PlusCircle } from "react-feather";
import { LargeModal } from "../../Constant/index";
import AddAssetOwnerContain from "./AddAssetOwners";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { toast } from "react-toastify";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ScrollBar from "react-perfect-scrollbar";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import DateRangePicker from "../DateRangePicker/DateRangePicker.jsx";
import DatePicker from "react-datepicker";
import { SiteContext } from "../../App";
import { useContext } from "react";
import { limit } from "../../Config/Config";

const AssetOwnerContain = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [filteredData, setFilteredData] = React.useState([]);
  const [siteGlobalAOID, setSiteGlobalAOID] = React.useState([]);
  //const [limit, setLimit] = useState(100000);

  const [companyNames, setcompanyNames] = useState([]);
  const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () => setLarge(!Large);
  const [ViewLarge, setViewLarge] = useState(false);
  const ViewLargeModaltoggle = () => setViewLarge(!ViewLarge);
  const [assetOwnerDetailsList, setAssetOwnerDetailsList] = useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [value, setValue] = useState("");
  const [collection, setCollection] = React.useState([]);
  const [permissions, setPermissions] = useState([]);
  const [username, setusername] = useState([]);
  const permissionsList = [
    "Create Assets",
    "Import Assets",
    "Edit Assets",
    "View Assets",
    "Create Tasks",
    "Assign Tasks",
    "View Tasks",
    "Export Tasks",
    "Create Sites",
    "Edit Sites",
    "View Sites",
    "Import Sites",
    "Create Certificates",
    "View Certificates",
    "Download Reports",
    "View Reports",
  ];

  const [itemforPermission, setItemforPermission] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = (item) => {setShow(true); setItemforPermission(item)};
  const handleShow = (item) => {
    setShow(true);
    setItemforPermission(item);
    fnPermissionPopup(item);
  };

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  console.log(selectedSiteGlobal);

  const range = "abcd";
  const date1 = "xtz";
  const date2 = "pqr";

  const fnPermissionPopup = (item) => {
    if (item.permission) {
      setPermissions(JSON.parse(item.permission));
    } else {
      setPermissions([]);
    }
  };
  const navigate = useNavigate();

  //---  site global -----
  async function fetchSitesforglobal() {
    setIsLoading(true);
    let nextToken = "";
    let assets = [];
    const result = await API.graphql({
      query: queries.listSiteDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listSiteDetails.items.length > 0) {
      let assetDatas = result.data.listSiteDetails.items;
      nextToken = result.data.listSiteDetails.nextToken;
      console.log(
        "first token count",
        result.data.listSiteDetails.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listSiteDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await API.graphql({
          query: queries.listSiteDetails,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listSiteDetails.items.length > 0) {
          nextToken = results.data.listSiteDetails.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listSiteDetails.items.length);
          if (results.data.listSiteDetails.items.length > 0) {
            assets.push(results.data.listSiteDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        const filtersite = mergeAssets.filter(
          (item) => item.id === selectedSiteGlobal
        );
        console.log(filtersite);
        console.log(filtersite[0].assetownerID);
        setSiteGlobalAOID(filtersite[0].assetownerID);
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (selectedSiteGlobal) {
      fetchSitesforglobal();
    }
  }, [selectedSiteGlobal]);

  // Add/Remove checked item from list

  const handleCheck = (event) => {
    var updatedList = [...permissions];
    if (event.target.checked) {
      updatedList = [...permissions, event.target.value];
    } else {
      updatedList.splice(permissions.indexOf(event.target.value), 1);
    }
    setPermissions(updatedList);
  };

  // Generate string of checked items

  const checkedItems = permissions.length
    ? permissions.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    permissions.includes(item) ? "checked-item" : "not-checked-item";

  //---------------------------------------------------------

  const handleDelete = async (id, version, name) => {
    if (
      window.confirm(
        "Are you sure?? Do you want to delete " + " " + name + "..?"
      )
    ) {
      await API.graphql(
        graphqlOperation(mutations.updateAssetOwner, {
          input: { id: id, delete: true },
        })
      )
        .then((result) => {
          toast.success(name + " deleted successfully.", {
            position: toast.POSITION.TOP_CENTER,
          });
          // window.location = `/details/`;
          fetchAssetOwnerDetailsDataDate("All Time");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error while deleting " + name + " Site", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  //-------------------------------------------------------------------------

  const editPermissions = async () => {
    console.log("inside editPermission function");
    console.log(itemforPermission);

    let otherPerm = ["View Logbooks", "View Service Providers"];
    let perm = permissions.concat(otherPerm);
    console.log(perm);
    API.graphql(
      graphqlOperation(mutations.updateUserDetails, {
        input: {
          id: itemforPermission.id,
          permissions: JSON.stringify(perm),
        },
      })
    )
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });

    await API.graphql(
      graphqlOperation(mutations.updateAssetOwner, {
        input: {
          id: itemforPermission.id,
          permission: JSON.stringify(perm),
        },
      })
    )
      .then((result) => {
        toast.success(" Updated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
        window.location = `/viewAssetOwnerdetails/${itemforPermission.id}/view`;
      })
      .catch((error) => {
        console.log(error);
        toast.error(" Error while Updating ", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //---Approve Status----------------------------------------------------------

  const ApproveStatus = (status) => {
    return status === "Active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  const handleChange = async (item) => {
    var statusSp;

    if (item.status.toLowerCase() === "active") {
      statusSp = "Inactive";
      toast.success("Deactivated successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      statusSp = "Active";
      toast.success("Activated successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    console.log(item);
    await API.graphql({
      query: mutations.updateAssetOwner,
      variables: {
        input: {
          id: item.id,
          status: statusSp,
        },
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        await API.graphql({
          query: mutations.updateUserDetails,
          variables: {
            input: {
              id: item.id,
              status: statusSp,
            },
          },
          authMode: "API_KEY",
        });
        console.log("update AO Active/Inactive", result);
        fetchAssetOwnerDetailsDataDate("All Time");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error while Updating ", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  //-----------------------------------------------------------------------

  const handelPermission = async (id, version) => {
    const handleShow = () => setShow(true);
  };

  const handleEdit = useCallback(
    (row) => navigate(`/viewAssetOwnerdetails/${row.id}`),
    []
  );
  const handleView = useCallback(
    (row) => navigate(`/viewAssetOwnerdetails/${row.id}/view`),
    []
  );

  //-----------------------------------------------------------------------------

  useEffect(() => {
    fetchcompanyNames();
    fetchUser();
    //fetchAssetOwnerDetailsData();
  }, []);

  //---------------------------------------------------------------------------------

  async function fetchAssetOwnerDetailsData1() {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const assetOwnerId = userDetails.assetOwnerId;
    const dataDetails = await API.graphql({
      query: queries.listAssetOwners,
      authMode: "API_KEY",
      variables: {
        limit: limit,
      },
    })
      .then((response) => {
        const assetOwnerDetails =
          localStorage.getItem("groups") === "site_owner"
            ? response.data.listAssetOwners.items.filter(
                (item) => item.id === assetOwnerId
              )
            : response.data.listAssetOwners.items;
        setIsLoading(false);
        setAssetOwnerDetailsList(assetOwnerDetails);
        setCollection(cloneDeep(assetOwnerDetails.slice(0, countPerPage)));
      })
      .catch(() => {
        setErrorMessage("Unable to fetch the list");
        setIsLoading(false);
      });
  }
  //  date range start----//---Week------------------------------------------

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const startOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    )
  ).format("DD/MM/YYYY");
  const endOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    )
  ).format("DD/MM/YYYY");

  // ---Month---------------------------

  const startOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  ).format("DD/MM/YYYY");
  const endOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  ).format("DD/MM/YYYY");

  // ---year------------------------------

  const startOfThisYear = moment(
    new Date(currentDate.getFullYear(), 0, 1)
  ).format("DD/MM/YYYY");
  const endOfThisYear = moment(
    new Date(currentDate.getFullYear(), 11, 31)
  ).format("DD/MM/YYYY");

  //---custom -------------------------------------------------

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  //--------------------------------------------------------------

  useEffect(() => {
    fetchAssetOwnerDetailsDataDate("All Time");
  }, [selectedSiteGlobal, siteGlobalAOID]);

  async function fetchAssetOwnerDetailsDataDate(DateRange) {
    let assetOwnerDetails = [];
    setIsLoading(true);
    //--
    let nextToken = "";
    let assets = [];
    const result = await API.graphql({
      query: queries.listAssetOwners,
      authMode: "API_KEY",
      variables: {
        limit: limit,
      },
    });
    if (result.data.listAssetOwners.items.length > 0) {
      let assetDatas = result.data.listAssetOwners.items;
      nextToken = result.data.listAssetOwners.nextToken;
      console.log(
        "first token count",
        result.data.listAssetOwners.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listAssetOwners.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await API.graphql({
          query: queries.listAssetOwners,
          authMode: "API_KEY",
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listAssetOwners.items.length > 0) {
          nextToken = results.data.listAssetOwners.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listAssetOwners.items.length);
          if (results.data.listAssetOwners.items.length > 0) {
            assets.push(results.data.listAssetOwners.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        const filterAO = mergeAssets.filter(
          (item) => item.id === siteGlobalAOID
        );
        console.log(filterAO);
        const sortedItemsNew = mergeAssets.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.log(sortedItemsNew);
        const sortedItems = selectedSiteGlobal ? filterAO : sortedItemsNew;

        if (DateRange === "This Week") {
          assetOwnerDetails = sortedItems.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return (
              createdAtDate.isSameOrAfter(
                moment(startOfWeek, "DD/MM/YYYY"),
                "day"
              ) &&
              createdAtDate.isSameOrBefore(
                moment(endOfWeek, "DD/MM/YYYY"),
                "day"
              )
            );
          });
        } else if (DateRange === "This Month") {
          assetOwnerDetails = sortedItems.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return createdAtDate.isBetween(
              moment(startOfMonth, "DD/MM/YYYY"),
              moment(endOfMonth, "DD/MM/YYYY"),
              null,
              "[]"
            );
          });
        } else if (DateRange === "Today") {
          assetOwnerDetails = sortedItems.filter(
            (item) =>
              moment(item.createdAt).format("DD/MM/YYYY") ===
              moment(new Date()).format("DD/MM/YYYY")
          );
        } else if (DateRange === "This Year") {
          assetOwnerDetails = sortedItems.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return createdAtDate.isBetween(
              moment(startOfThisYear, "DD/MM/YYYY"),
              moment(endOfThisYear, "DD/MM/YYYY"),
              null,
              "[]"
            );
          });
        } else if (DateRange === "Custom") {
          assetOwnerDetails = sortedItems.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return createdAtDate.isBetween(
              moment(startDate, "DD/MM/YYYY"),
              moment(endDate, "DD/MM/YYYY"),
              null,
              "[]"
            );
          });
        } else if (DateRange === "All Time") {
          assetOwnerDetails = sortedItems.filter((item) => !item._delete);
        }
        setIsLoading(false);
        setAssetOwnerDetailsList(assetOwnerDetails);
        setCollection(cloneDeep(assetOwnerDetails.slice(0, countPerPage)));
      }
    }
  }

  //---------------date range end-------------------------------------------------------------------

  async function fetchcompanyNames() {
    let nextToken = "";
    let assets = [];
    const result = await API.graphql({
      query: queries.listCompanyDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listCompanyDetails.items.length > 0) {
      let assetDatas = result.data.listCompanyDetails.items;
      nextToken = result.data.listCompanyDetails.nextToken;
      console.log(
        "first token count",
        result.data.listCompanyDetails.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listCompanyDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await API.graphql({
          query: queries.listCompanyDetails,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listCompanyDetails.items.length > 0) {
          nextToken = results.data.listCompanyDetails.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listCompanyDetails.items.length);
          if (results.data.listCompanyDetails.items.length > 0) {
            assets.push(results.data.listCompanyDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        const companyNames = mergeAssets;
        console.log("company names");
        console.log(companyNames);
        setcompanyNames(
          companyNames.filter((item) => {
            return !item._deleted && item.status === "Active";
          })
        );
      }
    }
  }

  async function fetchUser() {
    let nextToken = "";
    let assets = [];
    const result = await API.graphql({
      query: queries.listUserDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listUserDetails.items.length > 0) {
      let assetDatas = result.data.listUserDetails.items;
      nextToken = result.data.listUserDetails.nextToken;
      console.log(
        "first token count",
        result.data.listUserDetails.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listUserDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await API.graphql({
          query: queries.listUserDetails,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listUserDetails.items.length > 0) {
          nextToken = results.data.listUserDetails.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listUserDetails.items.length);
          if (results.data.listUserDetails.items.length > 0) {
            assets.push(results.data.listUserDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        const sites = mergeAssets;
        setusername(sites);
      }
    }
  }

  function getUsername(spid) {
    const name = username.find((item) => item.id === spid);
    return name ? name.username : "";
  }

  const getCompanyName = (id) => {
    const companyNames1 = companyNames.filter((item) => {
      return item.id === id;
    });
    console.log(id);
    if (companyNames1.length > 0) {
      return companyNames1[0].companyName;
    } else {
      return "";
    }
  };

  //---------------------------Search-------------------------------------

  const globalSearch = (searchValue) => {
    let filteredData = assetOwnerDetailsList.filter((row) => {
      const username = getUsername(row.id);
      return (
        row.firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.lastName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.companyName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
        moment(row.createdAt)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        username?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(assetOwnerDetailsList.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;

    if (!value) {
      setCollection(cloneDeep(assetOwnerDetailsList.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      const pageData = filteredData.slice(from, to);
      setCollection(cloneDeep(pageData));
    }
  };

  //----------------------------------------------------------------------------
  const [selectedOption, setSelectedOption] = useState("");
  const [dateRange1, setDateRange1] = useState([new Date(), new Date()]);

  const handleDateRangeSelected = (range) => {
    setDateRange1(range);
    console.log(range);
  };

  const onDateRangeSelected = (range) => {
    //setSelectedOption('');
    handleDateRangeSelected(range);
  };

  function DateRangePicker({ onDateRangeSelected }) {
    // const [selectedOption, setSelectedOption] = useState();

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption);

      switch (event.target.value) {
        case "Today":
          onDateRangeSelected(getTodayRange());
          break;
        case "This Week":
          onDateRangeSelected(getWeekRange());
          break;
        case "This Month":
          onDateRangeSelected(getMonthRange());
          break;
        case "This Year":
          onDateRangeSelected(getThisYearRange());
          break;
        case "All Time":
          onDateRangeSelected(getAllTimeRange());
          break;
        case "Custom":
          // onDateRangeSelected(getCustomRange());
          break;
        default:
          break;
      }
    };
    //----------------------------------------------------------------------------------------

    const getTodayRange = () => {
      fetchAssetOwnerDetailsDataDate("Today");
    };

    const getAllTimeRange = () => {
      fetchAssetOwnerDetailsDataDate("All Time");
    };

    const getWeekRange = () => {
      fetchAssetOwnerDetailsDataDate("This Week");
    };

    const getMonthRange = () => {
      console.log("inside the month range function........");
      fetchAssetOwnerDetailsDataDate("This Month");
    };

    const getThisYearRange = () => {
      fetchAssetOwnerDetailsDataDate("This Year");
    };

    //   const getCustomRange = () => {
    //     fetchAssetOwnerDetailsDataDate('Custom');
    // };

    //----------------------------------------------------------------------------------------

    const handleDateRangeChange = (newStartDate, newEndDate) => {
      console.log(newStartDate);
      console.log(newEndDate);
      setDateRange([newStartDate, newEndDate]);
    };

    return (
      <div className="daterange-card">
        <Row>
          <Col xs="6">
            <FormGroup>
              <Input
                type="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="All Time">All Time</option>
                <option value="Today">Today</option>
                <option value="This Week">This Week</option>
                <option value="This Month">This Month</option>
                <option value="This Year">This Year</option>
                <option value="Custom">Custom</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              {selectedOption === "Custom" && (
                <Fragment>
                  <Row className="d-flex justify-content-end">
                    <Col xl="6">
                      <div className="daterange-card">
                        <div className="mb-3">
                          <FormGroup>
                            <DatePicker
                              className="form-control digits"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              // onSelect={(d)=>{ setDateRange(d);
                              //    console.log(d);
                              //    console.log(startDate);
                              //    console.log(endDate);
                              //   fetchAssetOwnerDetailsDataDate('Custom');
                              // }}
                              onChange={(d) => {
                                const newStartDate = d[0];
                                const newEndDate = d[1];
                                handleDateRangeChange(newStartDate, newEndDate);
                                console.log(startDate);
                                console.log(endDate);
                                fetchAssetOwnerDetailsDataDate("Custom");
                              }}
                              isClearable={true}
                              placeholderText="Enter Date Range"
                              dateFormat="dd-MM-yyyy"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Row>
          <DateRangePicker
            onDateRangeSelected={onDateRangeSelected}
          ></DateRangePicker>
        </Row>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <Col md="4">
                    <InputGroup>
                      <span className="input-group-text">
                        <i className="icon-search"></i>
                      </span>
                      <Input
                        type="search"
                        placeholder="Search .."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col></Col>
                  <Col md="4">
                    <Link to={`/viewAssetOwnerdetails/`}>
                      <Button variant="primary">Add Asset Owner</Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>UserName</th>
                          <th>Company</th>
                          <th>Added On</th>
                          <th>Status</th>
                          <th>Active</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {collection.length > 0 ? (
                          collection.map((item) => (
                            <tr key={item.id}>
                              <td>{item.firstName}</td>
                              <td>{item.lastName}</td>
                              <td>{getUsername(item.id)}</td>
                              <td>{item.companyName}</td>
                              <td>
                                {moment(item.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>{item.status}</td>
                              <td>
                                <Media body className="icon-state switch-sm">
                                  <Label className="switch">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handleChange(item, e)}
                                    />
                                    <span
                                      className={ApproveStatus(item.status)}
                                    ></span>
                                  </Label>
                                </Media>
                              </td>
                              <td>
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "secondary",
                                    title: "Permissions",
                                    onClick: () => handleShow(item),
                                  }}
                                >
                                  <i className="fa fa-check-square"></i>
                                </Btn>
                                {/* <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete',  onClick: () => handleDelete(`${item.id}`, `${item._version}`,`${item.name}`)}} ><i className="fa fa-trash-o"></i></Btn> */}
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "info",
                                    title: "Edit",
                                    onClick: () => handleEdit(item),
                                  }}
                                >
                                  <i className="fa fa-edit "></i>
                                </Btn>
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "primary",
                                    title: "View",
                                    onClick: () => handleView(item),
                                  }}
                                >
                                  <i className="fa fa-eye"></i>
                                </Btn>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="5" className="text-center">
                                No Data Available.
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </div>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={
                    value ? filteredData.length : assetOwnerDetailsList.length
                  }
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
            </Card>
            <Modal show={show} size="lg" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Edit Permissions for{" "}
                  {" " +
                    itemforPermission.firstName +
                    " " +
                    itemforPermission.lastName}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md="6 mb-3">
                    Choose Permissions
                    <br></br>
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "300px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {permissionsList.map((item, index) => (
                        <Label className="d-block" key={index}>
                          {/* <input value={item} type="checkbox" onChange={handleCheck} /> */}
                          <input
                            value={item}
                            type="checkbox"
                            onChange={handleCheck}
                            checked={permissions.includes(item)}
                          />
                          <span className={isChecked(item)}>{item}</span>
                        </Label>
                      ))}
                    </ScrollBar>
                  </Col>
                  <Col md="6 mb-3">
                    Selected Permissions
                    <br></br>
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "300px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {permissions.map((item, index) => (
                        <li>{item}</li>
                      ))}
                    </ScrollBar>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={editPermissions}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default AssetOwnerContain;
