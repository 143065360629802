import { Btn, H5 } from "../../../AbstractElements";
import { BillingDetails } from "../../../Constant";
import CheckoutTableData from "./CheckoutTableData";
import ProductPlaceOrder from "./ProductPlaceOrder";
import { useNavigate } from "react-router-dom";
import {
  Label,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51NM5Y7IeIZdDV3cdT1Li68nTCWYwP2da8HqTiNlnVojV0POHEKq3SbcOG4mKEhm4NMHOfA70MA2useZiQsnrUdTm0094DEVNb0"
);

const stripe = require("stripe")(
  "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"
);

const CheckOutContain = () => {
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const payment = async (formData) => {
    try {
      // Create Checkout Sessions from body params.
      const session = await stripe.checkout.sessions.create({
        line_items: [
          {
            // Provide the exact Price ID (for example, pr_1234) of the product you want to sell
            price: "price_1PrvtJIeIZdDV3cdeOyBgdXJ",
            quantity: formData.quantity,
          },
        ],
        client_reference_id: LoginUserDetails.id,
        metadata: {
          addOn: "User Addon",
          quantity: formData.quantity,
        },
        mode: "subscription",
        success_url: `http://localhost:3000/subscriptions?success=true`,
        cancel_url: `http://localhost:3000/subscriptions?canceled=true`,
      });
      console.log("session", JSON.stringify(session));
      window.location = session.url;
    } catch (err) {
      console.log("err", JSON.stringify(err));
    }
  };
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="checkout">
              <CardHeader className="pb-0">
                <H5>{BillingDetails}</H5>
              </CardHeader>
              <CardBody>
                <Row>
                  <form
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmit(payment)}
                  >
                    <Row>
                      <Col sm="4">
                        <Label>Quantity</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Quantity"
                          name="quantity"
                          min={1}
                          max={10}
                          {...register("quantity", { required: false })}
                        />
                        <span>
                          {errors.quantity &&
                            "Please Enter Number Of Users you want to add."}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-end">
                        <Btn attrBtn={{ color: "primary" }}>Pay</Btn>
                      </Col>
                    </Row>
                  </form>
                  {/* <CheckoutTableData /> */}
                  {/* <ProductPlaceOrder /> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CheckOutContain;
