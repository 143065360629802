import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AddTasksContain from '../../Component/TasksAO/AddTasks';

const AddTasksAOContain = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Tasks" title="Add Tasks" />
      <AddTasksContain />
    </Fragment>
  );
};
export default AddTasksAOContain;