import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import Certificates from '../../Component/Certificates/catrelatedcertficates';

const CatRelatedCertiDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Certficates" title="Certificates" />
      <Certificates />
    </Fragment>
  );
};
export default CatRelatedCertiDetails;