import React, { Fragment, useState, useEffect } from "react";
import { Btn, H5, H3, H6, LI, P, UL } from "../../AbstractElements";
import {
  Col,
  Container,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  S3_BUCKET,
  REGION,
  CATEGORIES_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../Config/Config";
import Papa from "papaparse";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import AWS from "aws-sdk";
import SampleTemplate from "./SampleTemplate";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Dropzone from "react-dropzone-uploader";
import SiteDBData from "../../Data/DistributionBoard/index";
import SiteData from "../../Data/Sites/index";
var base64 = require("base-64");

const XLSX = require("xlsx");
AWS.config.update({ region: REGION });
const dynamoDb = new AWS.DynamoDB({
  region: REGION,
  accessKeyId: ACCESSKEYID,
  secretAccessKey: SECRETACCESSKEY,
  apiVersion: "2012-08-10",
});

const SiteImport = (props) => {
  const [fileData, setFileData] = useState([]);
  const [fileHeader, setFileHeader] = useState(null);
  const [siteImports, setSiteImports] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableColumnLength, setTableColumnLength] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [distributionBoard, setDistributionBoard] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [assetOwner, setAssetOwner] = useState([]);
  const [assetTemplate, setAssetTemplate] = useState([]);
  const [logbooks, setLogbooks] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  var userId = JSON.parse(localStorage.getItem("userDetails"));
  const handleExport = (tblName) => {
    console.log(tblName);
    var elt = document.getElementById(tblName);
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    return XLSX.writeFile(wb, tblName + ".xlsx");
  };

  const getSiteDetails = async () => {
    const assets = await API.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: userId.id,
        limit: 10000,
      },
      authMode: "API_KEY",
    });
    if (assets.data.siteDetailsByAssetownerID.items.length > 0) {
      const sites = assets.data.siteDetailsByAssetownerID.items;
      let nextToken = assets.data.siteDetailsByAssetownerID.nextToken;
      let siteData = [];
      siteData.push(sites);
      while (nextToken !== null) {
        const assets1 = await API.graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: userId.id,
            limit: 10000,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (assets1.data.siteDetailsByAssetownerID.items.length > 0) {
          nextToken = assets1.data.siteDetailsByAssetownerID.nextToken;
          siteData.push(assets1.data.siteDetailsByAssetownerID.items);
        }
      }
      if (siteData.length > 0) {
        let mergeSites = siteData.flat(1);
        let activeSites = mergeSites.filter((item) => item.status === "Active");
        setSiteDetails(activeSites);
        getAssetTemplate(activeSites);
        localStorage.setItem("site", base64.encode(JSON.stringify(mergeSites)));
        SiteData.setSiteData(mergeSites);
      } else {
        setIsLoading(false);
        setErrorMessage("No Assets to fetch");
      }
    }
  };
  async function getDistributionBoard() {
    let nextToken = "";
    let dbb = [];
    let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
    let activeSites = sites.filter((item) => item.status === "Active");
    if (activeSites.length > 0) {
      for (let site of activeSites) {
        const DBResult = await API.graphql({
          query: queries.distributionBoxesBySitedetailsID,
          variables: {
            sitedetailsID: site.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
          },
          authMode: "API_KEY",
        });
        let assetDatas = DBResult.data.distributionBoxesBySitedetailsID.items;
        nextToken = DBResult.data.distributionBoxesBySitedetailsID.nextToken;
        if (DBResult.data.distributionBoxesBySitedetailsID.items.length > 0) {
          dbb.push(assetDatas);
        }
        while (nextToken !== null) {
          const DBResult1 = await API.graphql({
            query: queries.distributionBoxesBySitedetailsID,
            variables: {
              sitedetailsID: site.id,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          nextToken = DBResult1.data.distributionBoxesBySitedetailsID.nextToken;
          if (
            DBResult1.data.distributionBoxesBySitedetailsID.items.length > 0
          ) {
            dbb.push(DBResult1.data.distributionBoxesBySitedetailsID.items);
          }
        }
      }
      if (dbb.length > 0) {
        const mergeDB = dbb.flat(1);
        console.log(mergeDB);
        setDistributionBoard(mergeDB);
        SiteDBData.setSiteDBData(mergeDB);
        localStorage.setItem("siteDB", base64.encode(JSON.stringify(mergeDB)));
      }
    } else {
      setDistributionBoard([]);
    }
  }
  const getAssetTemplate = async () => {
    await API.graphql({
      query: queries.listAssetTemplates,
      variables: {
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setAssetTemplate(result.data.listAssetTemplates.items);
      })
      .catch((error) => {
        console.log(error);
        setAssetTemplate([]);
      });
  };
  const getAssetOwner = async () => {
    await API.graphql({
      query: queries.listAssetOwners,
      variables: {
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setAssetOwner(result.data.listAssetOwners.items);
      })
      .catch((error) => {
        console.log(error);
        setAssetOwner([]);
      });
  };
  const getCompanyDetails = async () => {
    await API.graphql({
      query: queries.listCompanyDetails,
      variables: {
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setCompanyDetails(result.data.listCompanyDetails.items);
      })
      .catch((error) => {
        console.log(error);
        setCompanyDetails([]);
      });
  };
  const getLogbooks = async () => {
    await API.graphql({
      query: queries.listLogBooks,
      variables: {
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setLogbooks(result.data.listLogBooks.items);
      })
      .catch((error) => {
        console.log(error);
        setLogbooks([]);
      });
  };
  useEffect(() => {
    if (localStorage.getItem("site") === null && siteDetails.length === 0) {
      getSiteDetails();
    } else {
      let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
      let activeSites = sites.filter((item) => item.status === "Active");
      setSiteDetails(activeSites);
    }
    if (
      localStorage.getItem("siteDB") === null &&
      distributionBoard.length === 0
    ) {
      getDistributionBoard();
    } else {
      let DB = JSON.parse(base64.decode(localStorage.getItem("siteDB")));
      setDistributionBoard(DB);
    }
    getAssetOwner();
    getCompanyDetails();
    getAssetTemplate();
    getLogbooks();
  }, []);

  let siteColumn = [
    "siteName",
    "assetowner",
    "companydetails",
    "country",
    "state",
    "street",
    "suburb",
    "postCode",
    "address",
  ];
  const handleFileUpload = (event) => {
    try {
      setIsLoading(true);
      const file = event.target.files[0];
      if (file !== undefined) {
        const extension = file.name.split(".").pop().toLowerCase();

        if (extension === "csv") {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              console.log(result);
              const rows = result.data.map((row) => Object.values(row));
              const headers = Object.keys(result.data[0]);
              var matchHeaderWithColumn = true;
              headers.map((item) => {
                console.log(item);
                if (item !== null && !siteColumn.includes(item)) {
                  matchHeaderWithColumn = false;
                }
              });
              console.log(matchHeaderWithColumn);
              console.log(headers);
              console.log(rows);
              if (!matchHeaderWithColumn) {
                setErrorMessage(!errorMessage);
                setIsLoading(false);
                setFileData([]);
                setFileHeader([]);
                setTableColumnLength([]);
              } else {
                setFileData(
                  rows.filter((o) => !Object.keys(o).every((k) => !o[k]))
                );
                setFileHeader(headers);
                setTableColumnLength(headers);
                if (errorMessage) {
                  setErrorMessage(!errorMessage);
                }
                setIsLoading(false);
              }
            },
            error: (error) => {
              setIsLoading(false);
              console.log(error);
            },
          });
        } else if (extension === "xls" || extension === "xlsx") {
          const jsonOpts = {
            header: 1,
            defval: null,
            raw: false,
            dateNF: "yyyy-m-d",
            blankrows: false,
          };
          const reader = new FileReader();
          reader.onload = (event) => {
            let data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            data = XLSX.utils.sheet_to_json(worksheet, jsonOpts);
            console.log(data);
            const headers = data.splice(0, 1)[0];
            var matchHeaderWithColumn = true;
            headers.map((item) => {
              console.log(item);
              if (item !== null && !siteColumn.includes(item)) {
                matchHeaderWithColumn = false;
              }
            });
            console.log(headers);
            console.log(matchHeaderWithColumn);
            if (!matchHeaderWithColumn) {
              setFileData([]);
              setFileHeader([]);
              setTableColumnLength([]);
              setErrorMessage(!errorMessage);
              setIsLoading(false);
            } else {
              setFileData(
                data.filter((o) => !Object.keys(o).every((k) => !o[k]))
              );
              setFileHeader(headers);
              setTableColumnLength(headers);
              if (errorMessage) {
                setErrorMessage(!errorMessage);
              }
              setIsLoading(false);
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
          setIsLoading(false);
          alert("Unsupported file format");
        }
      } else {
        setIsLoading(false);
        toast.info("File not selected", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getAssetOwnerIds = (assetowner) => {
    try {
      const dbCount = assetOwner.filter((item) => {
        return item.id === assetowner;
      });
      console.log(dbCount);
      return dbCount.length > 0 ? dbCount[0] : "";
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (tableColumnLength.length === 0) {
      toast.error("Please choose to import the selected file.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
      setIsLoading(false);
      return;
    }

    const insetArray = [];
    tableColumnLength.map((val) => {
      insetArray.push({ col: val, data: val });
    });

    console.log(insetArray);

    if (fileData && fileData.length > 0) {
      let successCount = 0;
      let errorCount = 0;
      let errorData = [];
      let csvArray = {};
      let dataCount = 0;
      fileData.map(async (row, i) => {
        dataCount = dataCount + 1;
        const columnDetails = {};
        var siteName = "";
        let customFields = {};
        insetArray.forEach((itemData) => {
          if (row[0] !== null && row[1] !== null) {
            //var assetId = getAssetOwnerIds(userId.id);
            columnDetails["companydetailsID"] = userId.companyDetailsId;
            columnDetails["assetownerID"] = userId.id;

            if (itemData.col === "siteName") {

              siteName = row[fileHeader.indexOf(itemData.data)].trim();
            }
            if (
              itemData.col === "address" &&
              row[fileHeader.indexOf(itemData.data)] !== null
            ) {
              row["unitNumber"] = row[fileHeader.indexOf(itemData.data)].trim();
              columnDetails["unitNumber"] =
                row[fileHeader.indexOf(itemData.data)].trim();
            }
            columnDetails["status"] = "Active";
            if (
              itemData.col !== "assetowner" &&
              itemData.col !== "companydetails" &&
              itemData.col !== "address"
            ) {
              columnDetails[itemData.col] =
                row[fileHeader.indexOf(itemData.data)].trim();
            }
            customFields[itemData.col] =
              row[fileHeader.indexOf(itemData.data)] !== null
                ? row[fileHeader.indexOf(itemData.data)].trim()
                : "";
          }
        });
        console.log("columnDetails", columnDetails);

        var sites = siteDetails.filter((site) => {
          return (
            site.companydetailsID === columnDetails["companydetailsID"] &&
            site.assetownerID === columnDetails["assetownerID"] && site.siteName.toLowerCase() === siteName.toLowerCase().trim()
          );
        });
        if (sites.length === 0 &&
          siteName !== "" &&
          columnDetails["companydetailsID"] !== ""
        ) {
          await API.graphql({
            query: mutations.createSiteDetails,
            variables: { input: columnDetails },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              console.log(result);
              const siteId = result.data.createSiteDetails.id;
              //---updating to localStorage
              const addedsite = await API.graphql({
                query: queries.getSiteDetails,
                variables: {
                  id: result.data.createSiteDetails.id,
                },
                authMode: "API_KEY",
              });
              if (addedsite.data.getSiteDetails !== null) {
                if (localStorage.getItem("site") !== null) {
                  let activeSites = JSON.parse(
                    base64.decode(localStorage.getItem("site"))
                  );
                  console.log(activeSites);
                  activeSites.push(addedsite.data.getSiteDetails);
                  console.log(activeSites);
                  localStorage.setItem("site", base64.encode(JSON.stringify(activeSites)));
                }
              }
              //--
              successCount = successCount + 1;
              API.graphql({
                query: mutations.createLogBook,
                variables: {
                  input: {
                    siteId: siteId,
                    sitedetailsID: siteId,
                    status: "Active",
                    logbookName: siteName + " - Default Logbook",
                  },
                },
                authMode: "API_KEY",
              })
                .then((result2) => {
                  console.log("createLogBook", result2);
                })
                .catch((error) => {
                  console.log(error);
                });
              if (fileData.length === successCount + errorCount) {
                getSiteDetails();
                if (errorCount > 0 && successCount > 0) {
                  toast.error(
                    successCount +
                    " rows imported successfully, with " +
                    errorCount +
                    " rows failed to import.Check the Error File in Downloads.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: false,
                    }
                  );
                }
                if (errorCount === 0 && successCount > 0) {
                  toast.success(successCount + " rows imported successfully", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                }
                if (errorCount > 0 && successCount === 0) {
                  toast.error(
                    "Error while importing, please check the data and import again.Check the Error File in Downloads.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: false,
                    }
                  );
                }
                if (errorCount > 0) {
                  try {
                    const csv = Papa.unparse(errorData);
                    // Create a Blob containing the CSV data
                    const csvBlob = new Blob([csv], {
                      type: "text/csv",
                    });
                    // Create a URL for the Blob
                    const csvUrl = URL.createObjectURL(csvBlob);
                    // Create an invisible link element to trigger the download
                    const link = document.createElement("a");
                    link.href = csvUrl;
                    link.download = "siteImportErrorData.csv";
                    link.click();
                    // Clean up by revoking the URL to release resources
                    URL.revokeObjectURL(csvUrl);
                    // const link = document.createElement("a");
                    // document.body.appendChild(link);
                    // let csvContent = tableColumnLength.join(";") + "\n";
                    // csvContent += errorData.map((e) => e.join(";")).join("\n");
                    // const encodedUri =
                    //   "data:text/csv;charset=utf-8," +
                    //   encodeURIComponent(csvContent);

                    // link.setAttribute("href", encodedUri);
                    // link.setAttribute("download", "siteImportErrorData.csv");

                    // link.click();
                  } catch (error) {
                    console.log(error);
                  }
                }
                setFileData([]);
                setFileHeader([]);
                setTableColumnLength([]);
                setTableColumns([]);
                setIsLoading(false);
                props.nextStep();
              }
            })
            .catch((error) => {
              errorCount = errorCount + 1;
              csvArray["siteName"] = row[0];
              csvArray["Company"] = row[1];
              csvArray["Address"] = row[2];
              csvArray["error"] = error;
              errorData.push(csvArray);
              if (fileData.length === successCount + errorCount) {
                if (errorCount > 0 && successCount > 0) {
                  toast.error(
                    successCount +
                    " rows imported successfully, with " +
                    errorCount +
                    " rows failed to import.Check the Error File in Downloads.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: false,
                    }
                  );
                }
                if (errorCount === 0 && successCount > 0) {
                  toast.success(successCount + " rows imported successfully", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                }
                if (errorCount > 0 && successCount === 0) {
                  toast.error(
                    "Error while importing, please check the data and import again.Check the Error File in Downloads.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: false,
                    }
                  );
                }
                if (errorCount > 0) {
                  try {
                    const csv = Papa.unparse(errorData);
                    // Create a Blob containing the CSV data
                    const csvBlob = new Blob([csv], {
                      type: "text/csv",
                    });
                    // Create a URL for the Blob
                    const csvUrl = URL.createObjectURL(csvBlob);
                    // Create an invisible link element to trigger the download
                    const link = document.createElement("a");
                    link.href = csvUrl;
                    link.download = "siteImportErrorData.csv";
                    link.click();
                    // Clean up by revoking the URL to release resources
                    URL.revokeObjectURL(csvUrl);
                    // const link = document.createElement("a");
                    // document.body.appendChild(link);
                    // let csvContent = tableColumnLength.join(";") + "\n";
                    // csvContent += errorData.map((e) => e.join(";")).join("\n");
                    // const encodedUri =
                    //   "data:text/csv;charset=utf-8," +
                    //   encodeURIComponent(csvContent);

                    // link.setAttribute("href", encodedUri);
                    // link.setAttribute("download", "siteImportErrorData.csv");

                    // link.click();
                  } catch (error) {
                    console.log(error);
                  }
                }
                setFileData([]);
                setFileHeader([]);
                setTableColumnLength([]);
                setTableColumns([]);
                setIsLoading(false);
              }
              console.log(error);
            });
        } else {
          csvArray["siteName"] = row[0];
          csvArray["Company"] = row[1];
          csvArray["Address"] = row[2];
          csvArray["duplicate"] = "Duplicate Site for Asset Owner & Company.";
          errorData.push(csvArray);
          errorCount = errorCount + 1;
          if (fileData.length === successCount + errorCount) {
            setIsLoading(false);
            toast.error(
              "Please check the data before importing.Check the Error File in Downloads.",
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
              }
            );
            if (errorCount > 0) {
              try {
                // Convert the data to CSV format using PapaParse
                console.log(errorData);
                const csv = Papa.unparse(errorData);
                // Create a Blob containing the CSV data
                const csvBlob = new Blob([csv], {
                  type: "text/csv",
                });
                // Create a URL for the Blob
                const csvUrl = URL.createObjectURL(csvBlob);
                // Create an invisible link element to trigger the download
                const link = document.createElement("a");
                link.href = csvUrl;
                link.download = "siteImportErrorData.csv";
                link.click();
                // Clean up by revoking the URL to release resources
                URL.revokeObjectURL(csvUrl);
                // const link = document.createElement("a");
                // document.body.appendChild(link);
                // let csvContent = tableColumnLength.join(";") + "\n";
                // csvContent += errorData.map((e) => e.join(";")).join("\n");
                // const encodedUri =
                //   "data:text/csv;charset=utf-8," +
                //   encodeURIComponent(csvContent);

                // link.setAttribute("href", encodedUri);
                // link.setAttribute("download", "siteImportErrorData.csv");

                // link.click();
              } catch (error) {
                console.log(error);
              }
            }
          }
        }
      });
    } else {
      setIsLoading(false);
      toast.error("No Data To Save", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
    }
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <CardHeader>
              <Row>
                <div className="u-pearl current col-4">
                  <span className="u-pearl-number">1</span>
                  <span className="u-pearl-title">Site Details Import</span>
                </div>
                <div className="u-pearl  col-4">
                  <span className="u-pearl-number">2</span>
                  <span className="u-pearl-title">
                    Distribution Board Import
                  </span>
                </div>
                {/* <div className="u-pearl col-4">
                  <span className="u-pearl-number">3</span>
                  <span className="u-pearl-title">Category Import</span>
                </div> */}
                <div className="u-pearl col-4">
                  <span className="u-pearl-number">3</span>
                  <span className="u-pearl-title">Logbooks Import</span>
                </div>
              </Row>
              <Row>
                <Col md="4">
                  <span className="d-block" style={{ textAlign: "center" }}>
                    Import Site
                    <a
                      class="downloadLink"
                      onClick={() => handleExport("siteTable")}
                    >
                      {" "}
                      Template
                    </a>
                  </span>
                  {/* <Btn
                    attrBtn={{
                      color: "downloadLink",  
                      onClick: () => handleExport("siteTable"),
                    }}
                  >
                    Download Site Import File Template
                  </Btn> */}
                  <Table
                    className="table-striped  table-de"
                    style={{ display: "none" }}
                    id="siteTable"
                  >
                    <thead className="custom-table-head">
                      <tr>
                        <th>siteName</th>
                        <th>companydetails</th>
                        {/* <th>country</th>
                        <th>state</th>
                        <th>street</th>
                        <th>suburb</th>
                        <th>postCode</th> */}
                        <th>address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Balga Senior High School</td>
                        <td>Compnany Name</td>
                        {/* <td>Australia</td>
                        <td>WA</td>
                        <td>23</td>
                        <td>Perth</td>
                        <td>6000</td> */}
                        <td>23 st, Perth 6000, WA, Australia</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!siteImports && (
                <Row>
                  <Col md="5"></Col>
                  <Col md="3">
                    <div className="social-network theme-form">
                      <Btn
                        attrBtn={{
                          color: "social-btn importCol btn-fb  mb-2",
                          onClick: () => setSiteImports(!siteImports),
                        }}
                      >
                        Bulk Import New Site Data
                      </Btn>
                      <Btn
                        attrBtn={{ color: "social-btn text-center orbtn mb-2" }}
                      >
                        OR
                      </Btn>
                      <Btn
                        attrBtn={{
                          color: "social-btn importCol btn-fb",
                          onClick: props.nextStep,
                        }}
                      >
                        Import other data for Existing Sites
                      </Btn>
                    </div>
                  </Col>
                  <Col md="5"></Col>
                </Row>
              )}
              {siteImports && (
                <>
                  <Row>
                    <Col md="4">
                      {/* <div className="dz-message needsclick">
                        <Dropzone
                          onChangeStatus={handleFileUpload}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                          styles={{
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </div> */}
                      <input
                        type="file"
                        className="form-control"
                        accept=".csv,.xls,.xlsx"
                        onChange={handleFileUpload}
                      />
                    </Col>
                    {fileData.length > 0 && (
                      <Col md="4">
                        <span>Total records in file - {fileData.length}.</span>
                      </Col>
                    )}
                    <Col md="4">
                      <Btn attrBtn={{ color: "primary", onClick: handleSave }}>
                        Import Site Details
                      </Btn>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {errorMessage && (
                        <span className="txt-danger">
                          Data Columns are not matching the sample import
                          template. Please check the sample import template by
                          downloading.
                        </span>
                      )}
                    </Col>
                  </Row>
                  {fileData.length > 0 && (
                    <Row>
                      <Col md="12">
                        <Table className="table-striped  table-de">
                          <thead className="custom-table-head">
                            <tr key={"header"}>
                              {tableColumnLength.map((key) => (
                                <th>{key}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {fileData.map((item, i) => (
                              <tr key={i}>
                                {Object.values(item).map((val) => (
                                  <td>{val}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};

export default SiteImport;
