import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AddEditRepairDetails from '../../../Component/UserPanel/Repair/AddEditRepair';

const AddEditRepair = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="User panel"/>
      <AddEditRepairDetails />
    </Fragment>
  );
};
export default AddEditRepair;

