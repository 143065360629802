import { Btn, H4, H5, H1, H2 } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Container,
  Col,
  Form,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Collapse,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Input,
  TabPane,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { PlusCircle, Download } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import Select from "react-select";
import Pagination from "rc-pagination";
import jsPDF from "jspdf";
import {
  S3_BUCKET,
  REGION,
  ASSETS,
  Reports,
  SECRETACCESSKEY,
  COMPANY_FOLDER,
  ACCESSKEYID,
} from "../../../Config/Config";
import { SiteContext } from "../../../App";
import { aocontext } from "../../../App";
import SiteData from "../../../Data/Sites/index";

Amplify.configure(awsExports);

const ReportImaging = () => {
  const navigate = useNavigate();
  const state = useLocation();
  console.log(state);

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);
  console.log(selectedSiteGlobal);

  const [sites, setsites] = React.useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoggedInUserId = LoginUserDetails.id;
  const LoginUserGroup = localStorage.getItem("groups");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedSearchSiteData, setSelectedSearchSiteData] = useState("");
  const [selectedSearchDBData, setSelectedSearchDBData] = useState("");
  const [selectedSite, setSelectedSite] = useState("");

  const [distributionBoxList, setDistributionBoxList] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [value, setValue] = useState("");
  const [reports, setReports] = useState([]);
  const [collection, setCollection] = useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedSiteData, setSelectedSiteData] = useState("");
  const [selectedBox, setSelectedBox] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [downloadedFile, setdownloadedFile] = useState(null);

  const [spCompany, setSPCOmpany] = useState([]);
  const [sitesss, setSitesss] = useState(SiteData.getSiteData());

  const LargeModaltoggle = () =>
    navigate(`${process.env.PUBLIC_URL}/addReport`, {
      state: { category: state.state.category },
    });

  useEffect(() => {
    fetchSites();
    fetchDb();
    fetchSPCompnay();
    if (state.state && state.state.category && state.state.site) {
      fetchListReportssitecat(state.state.category.id, state.state.site.id);
    } else {
      //-
      if (LoginUserGroup === "electrician") {
        if (
          state &&
          state.state &&
          state.state.category &&
          state.state.category.id
        ) {
          fetchListCertificatesSP(state.state.category.id);
        } else {
          console.error("State or its properties are null or undefined.");
        }
      } else if (LoginUserGroup === "site_user") {
        if (
          state &&
          state.state &&
          state.state.category &&
          state.state.category.id
        ) {
          fetchListCertificatesSU(state.state.category.id);
        } else {
          console.error("State or its properties are null or undefined.");
        }
      } else {
        console.log("Asset Owner");
        if (
          state &&
          state.state &&
          state.state.category &&
          state.state.category.id
        ) {
          fetchListReports(state.state.category.id);
        } else {
          console.error("State or its properties are null or undefined.");
        }
      }
    }
  }, []);

  async function fetchSPCompnay() {
    try {
      await API.graphql({
        query: queries.listSPCompanyDetails,
        authMode: "API_KEY",
      })
        .then(async (response1) => {
          const allAssets = response1.data.listSPCompanyDetails.items;
          let spCompany = [];
          let nextToken = response1.data.listSPCompanyDetails.nextToken;
          if (allAssets.length > 0) {
            spCompany.push(allAssets);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.listSPCompanyDetails,
              variables: {
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            }).then((response) => {
              if (response.data.listSPCompanyDetails.length > 0) {
                spCompany.push(response.data.listSPCompanyDetails.items);
              }
              nextToken = response.data.listSPCompanyDetails.nextToken;
            });
          }
          if (spCompany.length > 0) {
            let mergeSPCompany = spCompany.flat(1);
            console.log(mergeSPCompany);
            setSPCOmpany(mergeSPCompany);
          } else {
            setSPCOmpany([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  }

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    const data = value || selectedSite || selectedBox ? filteredData : reports;
    setCollection(cloneDeep(data.slice(from, to)));
  };

  const getSiteName = (siteId) => {
    const siteName = sites.filter((item) => {
      return item.id === siteId;
    });
    return siteName.length > 0
      ? siteName[0].siteName + " " + "(" + siteName[0].unitNumber + ")"
      : "";
  };

  const getDBName = (siteId) => {
    const siteName = distributionBoxList.filter((item) => {
      return item.id === siteId;
    });
    return siteName.length > 0 ? siteName[0].distributionBoxName : "";
  };

  async function fetchSites() {
    await API.graphql({
      query: queries.listSiteDetails,
      authMode: "API_KEY",
    })
      .then(async (dataDetails) => {
        console.log(dataDetails);
        const sites = dataDetails.data.listSiteDetails.items;
        let nextToken = dataDetails.data.listSiteDetails.nextToken;
        let siteData = [];
        if (sites.length > 0) {
          siteData.push(sites);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listSiteDetails,
            variables: {
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((result) => {
              nextToken = result.data.listSiteDetails.nextToken;
              if (result.data.listSiteDetails.items.length > 0) {
                siteData.push(result.data.listSiteDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (siteData.length > 0) {
          let mergeSites = siteData.flat(1);
          if (LoginUserGroup === "admin") {
            setsites(mergeSites);
          } else if (LoginUserGroup === "site_user") {
            await API.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: LoginUserDetails.id,
                limit: 100000,
              },
            })
              .then((dataDetails) => {
                console.log(dataDetails);
                const siteDetails =
                  dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
                if (siteDetails.length > 0) {
                  const siteDetailsu = mergeSites.filter((item) => {
                    const _matchingSites = siteDetails?.find(
                      (site) => site.sitedetailsID === item?.id
                    );
                    if (_matchingSites) {
                      return item;
                    }
                  });
                  console.log(siteDetailsu);
                  setsites(siteDetailsu);
                  //setIsLoading(false);
                } else {
                  setsites([]);
                  //setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                //setIsLoading(false);
              });
          } else if (LoginUserGroup === "electrician") {
            await API.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: LoginUserDetails.id,
                limit: 100000,
              },
            })
              .then((dataDetails) => {
                console.log(dataDetails);
                const siteDetails =
                  dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
                if (siteDetails.length > 0) {
                  const siteDetailsu = mergeSites.filter((item) => {
                    const _matchingSites = siteDetails?.find(
                      (site) => site.sitedetailsID === item?.id
                    );
                    if (_matchingSites) {
                      return item;
                    }
                  });
                  console.log(siteDetailsu);

                  console.log(siteDetailsu);
                  setsites(siteDetailsu);
                  //setIsLoading(false);
                } else {
                  setsites([]);
                  //setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                //setIsLoading(false);
              });
          } else {
            setsites(
              mergeSites.filter((item) => {
                return item.assetownerID === LoginUserDetails.id;
              })
            );
          }
        } else {
          setsites([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const cancelForm = () => {
    if (state.state && state.state.category && state.state.site) {
      navigate(`${process.env.PUBLIC_URL}/sites/listsubcat`, {
        state: {
          category: state.state.category,
          site: state.state.site,
        },
      });
    } else {
      navigate(`${process.env.PUBLIC_URL}/catassetlist`);
    }
  };

  const optionsDB = [
    { value: "", label: "All Distribution Board" },
    ...distributionBoxFilter.map((item) => ({
      value: item.id,
      label: item.distributionBoxName,
    })),
  ];

  const sitess = [
    { value: "", label: "All Sites" },
    ...sites.map((item) => ({
      value: item.id,
      label: item.siteName + " " + "(" + item.unitNumber + ")",
    })),
  ];

  const handleSiteDDChange = (selectedOption) => {
    setSelectedSite(selectedOption.value);
    setSelectedSearchSiteData(selectedOption);
    //console.log(`Option selected:`, selectedOption);
  };

  const handleSiteDB = (selectedOption) => {
    setSelectedBox(selectedOption.value);
    setSelectedSearchDBData(selectedOption);
  };

  async function fetchDb() {
    //-
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.listDistributionBoxes,
      variables: {
        limit: 2000000,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.listDistributionBoxes.items;
        nextToken = result.data.listDistributionBoxes.nextToken;
        console.log(
          "first token count",
          result.data.listDistributionBoxes.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listDistributionBoxes.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listDistributionBoxes,
            variables: {
              limit: 2000000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.listDistributionBoxes.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listDistributionBoxes.items.length);
              if (results.data.listDistributionBoxes.items.length > 0) {
                assets.push(results.data.listDistributionBoxes.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          mergeAssets.length > 0
            ? setDistributionBoxList(mergeAssets)
            : setDistributionBoxList([]);

          if (state.state && state.state.site && state.state.site.id) {
            const filtered = mergeAssets.filter(
              (item) => item.sitedetailsID === state.state.site.id
            );
            setDistributionBoxfilter(filtered);
          }
        } else {
          setDistributionBoxfilter([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log("selected Site");
    if (selectedSite !== "") {
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === selectedSite;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [selectedSite]);

  async function fetchListReports(catId) {
    try {
      setIsLoading(true);
      {
        /*
      await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      })
        .then(async (sites) => {
        */
      }
      console.log(sitesss);
      //---

      let nextToken = "";
      let assets = [];
      await API.graphql({
        query: queries.listReports,
        variables: {
          filter: {
            categoriesID: {
              eq: catId,
            },
          },
          limit: 200000,
        },
        authMode: "API_KEY",
      })
        .then(async (result) => {
          let assetDatas = result.data.listReports.items;
          nextToken = result.data.listReports.nextToken;
          console.log(
            "first token count",
            result.data.listReports.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.listReports.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.listReports,
              variables: {
                filter: {
                  categoriesID: {
                    eq: catId,
                  },
                },
                limit: 200000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.listReports.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.listReports.items.length);
                if (results.data.listReports.items.length > 0) {
                  assets.push(results.data.listReports.items);
                }
              })
              .catch((error) => {
                console.log(error);
                setCollection([]);
                setIsLoading(false);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            var certiData = "";
            if (sitesss.length > 0) {
              certiData = mergeAssets.filter((certificate) => {
                const matchCeri = sitesss.find(
                  (site) => site.id === certificate.sitedetailsID
                );

                if (matchCeri) {
                  return certificate;
                }
              });
            } else {
              certiData = mergeAssets;
            }
            // certiData = response.data.listCertificates.items;

            const sortItems = certiData.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            console.log(sortItems);
            console.log(LoggedInUserId);
            const certificateDetails = sortItems;
            console.log(certificateDetails);
            const filterCerti = sortItems.filter(
              (item) => item.sitedetailsID === selectedSiteGlobal
            );
            console.log(filterCerti);
            const newCertificates = selectedSiteGlobal
              ? filterCerti
              : certificateDetails;
            setReports(newCertificates);
            setCollection(cloneDeep(newCertificates.slice(0, countPerPage)));
            setIsLoading(false);
          } else {
            setCollection([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setCollection([]);
          setIsLoading(false);
        });
      {
        /*
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
      */
      }
    } catch (error) {
      console.log(error);
    }
  }
  //--sp login reports ----

  async function fetchListCertificatesSP(catId) {
    try {
      setIsLoading(true);
      {
        /*
      await API.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        variables: {
          userdetailsID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      })
        .then((sites) => {
          console.log(sites);
          const siteDetails = sites.data.linkUsersAndSitesByUserdetailsID.items;
          let sitedetailsID = [];
          if (sites.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            const siteFilterArray = [];
            siteDetails.map(async (item) => {
              siteFilterArray.push(item.sitedetailsID);
            });
            API.graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: 100000,
              },
              authMode: "API_KEY",
            })
              .then(async (result) => {
                console.log(result);
                let siteIds = [];
                const siteDetail = result.data.listSiteDetails.items.filter(
                  (item) => {
                    const _matchSites = siteFilterArray?.find(
                      (assetTemp) => assetTemp === item?.id
                    );
                    if (_matchSites) {
                      return item;
                    }
                  }
                );
              */
      }
      let sitedetailsID = [];
      const data = sitesss.filter(
        (item) => item.assetownerID === selectedAssetOwnerGlobal
      );
      const filterdata = selectedAssetOwnerGlobal ? data : sitesss;
      console.log(filterdata);
      //---
      filterdata.map((item) => {
        sitedetailsID.push({
          sitedetailsID: {
            eq: item.id,
          },
        });
      });
      console.log(sitedetailsID);
      //---
      let nextToken = "";
      let assets = [];
      await API.graphql({
        query: queries.listReports,
        variables: {
          filter: {
            categoriesID: {
              eq: catId,
            },
          },
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
        .then(async (result) => {
          let assetDatas = result.data.listReports.items;
          nextToken = result.data.listReports.nextToken;
          console.log(
            "first token count",
            result.data.listReports.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.listReports.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.listReports,
              variables: {
                filter: {
                  categoriesID: {
                    eq: catId,
                  },
                },
                limit: 2000000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.listReports.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.listReports.items.length);
                if (results.data.listReports.items.length > 0) {
                  assets.push(results.data.listReports.items);
                }
              })
              .catch((error) => {
                console.log(error);
                setCollection([]);
                setIsLoading(false);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            const certiData = mergeAssets.filter((certificate) => {
              const matchCeri = filterdata.find(
                (site) => site.id === certificate.sitedetailsID
              );

              if (matchCeri) {
                return certificate;
              }
            });
            const sortItems = certiData.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            console.log(sortItems);
            const certificateDetails = sortItems;
            console.log(certificateDetails);
            const filterCerti = sortItems.filter(
              (item) => item.sitedetailsID === selectedSiteGlobal
            );
            console.log(filterCerti);
            const newCertificates = selectedSiteGlobal
              ? filterCerti
              : certificateDetails;
            setReports(newCertificates);
            setCollection(cloneDeep(newCertificates.slice(0, countPerPage)));
            setIsLoading(false);
          } else {
            setCollection([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setCollection([]);
          setIsLoading(false);
        });
      {
        /*
              })
              .catch((error1) => {
                console.log(error1);
                setIsLoading(false);
              });
          } else {
            setCollection([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setCollection([]);
          setIsLoading(false);
        });
      */
      }
    } catch (error) {
      console.log(error);
    }
  }

  //--su login reports ----

  async function fetchListCertificatesSU(catId) {
    try {
      setIsLoading(true);
      {
        /*
      await API.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        variables: {
          userdetailsID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      })
        .then((sites) => {
          console.log(sites);
          const siteDetails = sites.data.linkUsersAndSitesByUserdetailsID.items;
          if (sites.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            const siteFilterArray = [];
            siteDetails.map(async (item) => {
              siteFilterArray.push(item.sitedetailsID);
            });
            API.graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: 100000,
              },
              authMode: "API_KEY",
            })
              .then(async (result) => {
                console.log(result);
                let siteIds = [];
                const siteDetail = result.data.listSiteDetails.items.filter(
                  (item) => {
                    const _matchSites = siteFilterArray?.find(
                      (assetTemp) => assetTemp === item?.id
                    );
                    if (_matchSites) {
                      return item;
                    }
                  }
                );
              */
      }
      let sitedetailsID = [];
      const filterdata = sitesss;
      console.log(filterdata);
      //---
      filterdata.map((item) => {
        sitedetailsID.push({
          sitedetailsID: {
            eq: item.id,
          },
        });
      });
      console.log(sitedetailsID);
      //---
      let nextToken = "";
      let assets = [];
      await API.graphql({
        query: queries.listReports,
        variables: {
          filter: {
            categoriesID: {
              eq: catId,
            },
          },
          limit: 2000000,
        },
        authMode: "API_KEY",
      })
        .then(async (result) => {
          let assetDatas = result.data.listReports.items;
          nextToken = result.data.listReports.nextToken;
          console.log(
            "first token count",
            result.data.listReports.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.listReports.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.listReports,
              variables: {
                filter: {
                  categoriesID: {
                    eq: catId,
                  },
                },
                limit: 2000000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.listReports.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.listReports.items.length);
                if (results.data.listReports.items.length > 0) {
                  assets.push(results.data.listReports.items);
                }
              })
              .catch((error) => {
                console.log(error);
                setCollection([]);
                setIsLoading(false);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            const certiData = mergeAssets.filter((certificate) => {
              const matchCeri = filterdata.find(
                (site) => site.id === certificate.sitedetailsID
              );

              if (matchCeri) {
                return certificate;
              }
            });
            const sortItems = certiData.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            console.log(sortItems);
            const certificateDetails = sortItems;
            console.log(certificateDetails);
            const filterCerti = sortItems.filter(
              (item) => item.sitedetailsID === selectedSiteGlobal
            );
            console.log(filterCerti);
            const newCertificates = selectedSiteGlobal
              ? filterCerti
              : certificateDetails;
            setReports(newCertificates);
            setCollection(cloneDeep(newCertificates.slice(0, countPerPage)));
            setIsLoading(false);
          } else {
            setCollection([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setCollection([]);
          setIsLoading(false);
        });
      {
        /*
              })
              .catch((error1) => {
                console.log(error1);
                setIsLoading(false);
              });
          } else {
            setIsLoading(false);
            setReports([]);
            setCollection(
              cloneDeep([].slice(0, countPerPage))
            );
          }
        
    })
        .catch ((error) => {
  console.log(error);
  setIsLoading(false);
});
*/
      }
    } catch (error) {
      console.log(error);
    }
  }

  //site cat filter for site view
  async function fetchListReportssitecat(catId, siteId) {
    try {
      setIsLoading(true);
      let nextToken = "";
      let assets = [];
      await API.graphql({
        query: queries.listReports,
        variables: {
          filter: {
            categoriesID: {
              eq: catId,
            },
            sitedetailsID: {
              eq: siteId,
            },
          },
          limit: 200000,
        },
        authMode: "API_KEY",
      })
        .then(async (result) => {
          let assetDatas = result.data.listReports.items;
          nextToken = result.data.listReports.nextToken;
          console.log(
            "first token count",
            result.data.listReports.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.listReports.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.listReports,
              variables: {
                filter: {
                  categoriesID: {
                    eq: catId,
                  },
                  sitedetailsID: {
                    eq: siteId,
                  },
                },
                limit: 200000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.listReports.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.listReports.items.length);
                if (results.data.listReports.items.length > 0) {
                  assets.push(results.data.listReports.items);
                }
              })
              .catch((error) => {
                console.log(error);
                setCollection([]);
                setIsLoading(false);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--

            // certiData = response.data.listCertificates.items;

            const sortItems = mergeAssets.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            console.log(sortItems);
            console.log(LoggedInUserId);
            const certificateDetails = sortItems;
            console.log(certificateDetails);
            const filterCerti = sortItems.filter(
              (item) => item.sitedetailsID === selectedSiteGlobal
            );

            console.log(filterCerti);
            const newCertificates = selectedSiteGlobal
              ? filterCerti
              : certificateDetails;
            setReports(newCertificates);
            setCollection(cloneDeep(newCertificates.slice(0, countPerPage)));
            setIsLoading(false);
          } else {
            setCollection([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setCollection([]);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getReportImageURLs = (filename) => {
    return new Promise((resolve, reject) => {
      try {
        var params = {
          Bucket: S3_BUCKET,
          Key: `public/${Reports}/${filename}`,
        };
        myBucket.getSignedUrl("getObject", params, function (err, url) {
          if (err) {
            console.error("Error getting signed URL: ", err);
            reject(err);
          } else {
            console.log("Got signed URL: ", url);
            resolve(url);
          }
        });
      } catch (error) {
        console.error("Error while fetching:", error);
        reject(error);
      }
    });
  };

  function getReportFileName(path) {
    try {
      const files = JSON.parse(path);
      if (files.length > 0) {
        const promises = files.map((file) => getReportImageURLs(file.fileName));
        return Promise.all(promises);
      } else {
        return Promise.resolve([]);
      }
    } catch (error) {
      console.error("Error parsing JSON input:", error);
      return Promise.reject(error);
    }
  }

  const handleSearch = () => {
    let filteredAssets = reports;
    if (value) {
      filteredAssets = filteredAssets.filter((asset) => {
        let siteName = getSiteName(asset.sitedetailsID);
        let dbName =
          asset.distributionBoxId !== null &&
          asset.distributionBoxId !== undefined
            ? getDBName(asset.distributionBoxId)
            : "";
        return (
          (asset.reportName !== null &&
            asset.reportName.toLowerCase().includes(value.toLowerCase())) ||
          (dbName !== "" &&
            dbName.toLowerCase().includes(value.toLowerCase())) ||
          siteName.toLowerCase().includes(value.toLowerCase()) ||
          moment(asset.createdAt)
            .format("DD/MM/YYYY")
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      });
    }
    if (selectedSite) {
      filteredAssets = filteredAssets.filter(
        (asset) => asset.sitedetailsID === selectedSite
      );
      setDistributionBoxfilter(
        distributionBoxList.filter(
          (item) => item.sitedetailsID === selectedSite
        )
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
    if (selectedBox) {
      filteredAssets = filteredAssets.filter(
        (asset) => asset.distributionBoxId === selectedBox
      );
    }
    //console.log(filteredAssets);
    const sort = filteredAssets.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    setFilteredData(sort);
    setCollection(cloneDeep(sort.slice(0, countPerPage)));
    setCurrentPage(1);
  };

  async function preparePDF(item) {
    try {
      setIsLoading(true);
      console.log(item.reportName);
      console.log(item);
      const compdata = await fetchCompdetails(item.userID);
      console.log(compdata);
      const file = getReportFileName(item.reportURL);
      console.log(file);

      file
        .then((urls) => {
          console.log("URLs:", urls);
          console.log(compdata);
          const objectInsideArray = compdata.selectedTests[0];
          console.log(objectInsideArray);
          console.log(urls);
          generatePDF(item, objectInsideArray, compdata.url, urls);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function fetchCompdetails(selectedItems) {
    try {
      const response = await API.graphql({
        query: queries.getUserDetails,
        variables: {
          id: selectedItems,
        },
        authMode: "API_KEY",
      });
      const SpUser = response.data.getUserDetails;
      console.log(SpUser);
      console.log(spCompany);
      const selectedTests = spCompany.filter(
        (item) => item.id === SpUser.companyDetailsId
      );
      console.log(selectedTests);
      const files = selectedTests[0].logo;
      const newfilename = files;
      return getImageUrl(newfilename, selectedTests);
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  function getImageUrl(filename, selectedTests) {
    return new Promise((resolve, reject) => {
      try {
        const params = {
          Bucket: S3_BUCKET,
          Key: `public/${COMPANY_FOLDER}/${filename}`,
        };
        myBucket
          .getSignedUrlPromise("getObject", params)
          .then((url) => {
            console.log(url);
            setdownloadedFile(url);
            resolve({ selectedTests, url }); // Return both selectedTests and URL
          })
          .catch((error) => {
            console.log("Error while fetching:", error);
            reject(error);
          });
      } catch (error) {
        console.log("Error while fetching:", error);
        reject(error);
      }
    });
  }

  function generatePDF(item, obj, urll, reportUrl) {
    setIsLoading(true);
    try {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3, or A4
      const orientation = "portrait"; // portrait or landscape

      var doc = new jsPDF();

      if (urll !== null) {
        doc.addImage(urll, "JPEG", 0 + 10, 10, 50, 50);
      }

      const aof = obj && obj.companyName ? obj.companyName : "N/A";
      const address = obj && obj.companyAddress ? obj.companyAddress : "N/A";
      const addeddate = reports.filter((itemm) => itemm.id === item.id);
      const datee =
        addeddate && addeddate[0].createdAt
          ? moment(addeddate[0].createdAt).format("DD/MM/YYYY")
          : "-";

      const spCompanyInformation = [
        "",
        "" + aof,
        "",
        "" + address,
        "",
        "Date of test: " + datee,
      ];
      doc.setFontSize(12);
      doc.setFont("normal");
      spCompanyInformation.forEach((info, index) => {
        doc.text(info, 20, 60 + index * 5 + 7.5);
      });

      doc.addPage();

      if (reportUrl && reportUrl.length > 0) {
        let yPos = 10;
        reportUrl.forEach((url, index) => {
          setTimeout(() => {
            console.log(url);
            if (url !== null) {
              const extension = url.split(".").pop().toLowerCase();
              if (extension === "pdf") {
                // Skip URLs with ".pdf" extension
                return;
              }
              let currentYPos = yPos;
              if (index !== 0) {
                currentYPos += 60;
              }
              doc.addImage(url, "JPEG", 0 + 10, currentYPos, 50, 50);
            }
          }, index * 8000); // 5000 milliseconds = 5 seconds
        });
      }

      doc.save(item.reportName + ".pdf");
      setIsLoading(false);
    } catch (error) {
      console.log("Error PDF:", error);
      setIsLoading(false);
    }
  }

  const imageURL = async (fileurl) => {
    try {
      //const parsedJSON = JSON.parse(fileurl);
      //console.log(parsedJSON);
      const fileName = fileurl;
      console.log(fileName);

      var params = {
        Bucket: S3_BUCKET,
        Key: `public/${Reports}/${fileName}`,
      };
      var promise = myBucket.getSignedUrlPromise("getObject", params);
      promise.then(
        function (url) {
          console.log(url);
          window.open(url, "_blank");
        },
        function (err) {
          console.log(err);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const extractFileNames = (path) => {
    try {
      const parsedPath = JSON.parse(path);
      const fileNames = parsedPath.map((file) => file.fileName);
      return fileNames;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const checkIfPDF = (fileName) => {
    return fileName.toLowerCase().endsWith(".pdf");
  };

  const renderList = (
    <Fragment>
      <br></br>
      <Container fluid={true} className="p-0 project-list">
        <Card className="custom-card">
          <Row>
            <Col md="3">
              <InputGroup>
                <span className="input-group-text">
                  <i className="icon-search"></i>
                </span>
                <Input
                  type="search"
                  placeholder="Search Assets.."
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md="3">
              {(state.state &&
                state.state.site &&
                state.state.site.id !== "") ||
              selectedSiteGlobal ? (
                <select
                  className="form-select"
                  onChange={(e) => setSelectedSite(e.target.value)}
                  disabled={
                    state.state.site ? true : selectedSiteGlobal ? true : false
                  }
                  value={
                    selectedSiteGlobal
                      ? selectedSiteGlobal
                      : state.state.site.id
                  }
                  menuPlacement="auto"
                  menuPosition="fixed"
                >
                  {sites.map((site, index) => (
                    <option value={site.id} key={index}>
                      {site?.siteName + " " + "(" + site.unitNumber + ")"}
                    </option>
                  ))}
                </select>
              ) : (
                <Select
                  value={selectedSearchSiteData}
                  onChange={handleSiteDDChange}
                  options={sitess}
                  isSearchable={true}
                  hideSelectedOptions={false}
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              )}
            </Col>
            <Col md="3">
              <Select
                value={selectedSearchDBData}
                onChange={handleSiteDB}
                options={optionsDB}
                isSearchable={true}
                hideSelectedOptions={false}
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md="2" className="d-flex">
              <Btn attrBtn={{ color: "primary", onClick: handleSearch }}>
                Search
              </Btn>
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () => {
                    setSelectedSite("");
                    setSelectedBox("");
                    setValue("");
                    setSelectedSearchSiteData("");
                    setSelectedSearchDBData("");
                    updatePage(1);
                    setCollection(cloneDeep(reports.slice(0, countPerPage)));
                  },
                }}
              >
                Reset
              </Btn>
            </Col>
            <Col md="10" className="text-end">
              {/*
               <Button
                outline
                color="primary"
                onClick={() => LargeModaltoggle()}
              >
                <PlusCircle />
                Add Report
              </Button> 
              */}
              <div className="text-end">
                <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                  Back
                </Btn>
              </div>
            </Col>
          </Row>
          {/* </CardHeader> */}
          <CardBody>
            <div className="card-block row">
              <Col sm="12" lg="12" xl="12">
                <div className="table-responsive">
                  <Table className="table-striped  table-de">
                    <thead className="custom-table-head">
                      <tr>
                        <th>Added On</th>
                        <th>Report Name</th>
                        <th>Site</th>
                        <th>Distribution Board</th>
                        <th>Report</th>
                      </tr>
                    </thead>
                    <tbody className="trhover">
                      {collection.length > 0 ? (
                        collection.map((item) => (
                          <tr key={item.id}>
                            <td>
                              {moment(item.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>{item.reportName}</td>
                            <td>{getSiteName(item.sitedetailsID)}</td>
                            <td>{getDBName(item.distributionBoxId)}</td>
                            <td>
                              <ul>
                                {extractFileNames(item.reportURL).reduce(
                                  (info, fileName, index) => {
                                    const reportName = checkIfPDF(fileName)
                                      ? fileName.replace(".pdf", "")
                                      : item.reportName + ".pdf";
                                    if (checkIfPDF(fileName)) {
                                      return [
                                        ...info,
                                        <li key={index}>
                                          <button
                                            onClick={() => imageURL(fileName)}
                                            className="btn btn-link"
                                          >
                                            {fileName}
                                          </button>
                                        </li>,
                                      ];
                                    } else if (
                                      !info
                                        .map((item) => item.props.children)
                                        .includes(reportName)
                                    ) {
                                      return [
                                        ...info,
                                        <li
                                          key={index}
                                          className="link-style"
                                          onClick={() => preparePDF(item)}
                                        >
                                          {reportName}
                                        </li>,
                                      ];
                                    } else {
                                      return info;
                                    }
                                  },
                                  []
                                )}
                              </ul>
                            </td>

                            {/*                              
                            <td>
                                {item.reportURL !== null ? (
                                  <button
                                    onClick={() =>
                                      imageURL(item.reportURL)
                                    }
                                    className="btn btn-link"
                                  >
                                    {extractFileName(item.reportURL)}
                                  </button>
                                ) : (
                                  "No Report"
                                )}
                              </td>
                                */}
                          </tr>
                        ))
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Reports Available.
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </div>
          </CardBody>
          <CardFooter className="row">
            <Pagination
              className="p-t-10"
              pageSize={countPerPage}
              onChange={updatePage}
              current={currentPage}
              total={
                value || selectedBox || selectedSite
                  ? filteredData.length
                  : reports.length
              }
              showTotal={(total, range) =>
                `${range[0]} - ${range[1]} of ${total} items`
              }
            />
          </CardFooter>
        </Card>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};
export default ReportImaging;
