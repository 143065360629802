import React, { Fragment } from 'react';
import ThankYouContain from '../../Component/SiteOwner/ThankYou';

const ThankYou = () => {

  return (
    <Fragment>
      <ThankYouContain />
    </Fragment>
  );
};
export default ThankYou;