import React, { Fragment, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { H3, Btn, Image } from "../../AbstractElements";
import { useNavigate } from "react-router-dom";
import imgg from "../../assets/images/logo/logo.png";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";

const ThankYouContain = () => {
  useEffect(async () => {
    console.log(localStorage.getItem("data"));
    // const updateUser = await API.graphql(
    //   graphqlOperation(mutations.updateUserDetails, {
    //     input: { id: props.values.UserId, paymentId: bilId },
    //   })
    // );
    // console.log(updateUser);
    // toast.success("Plan " + data.title + " has been Choosen Succesfully!..", {
    //   position: toast.POSITION.TOP_CENTER,
    // });
  }, []);
  const navigate = useNavigate();
  const linkToRegister = () => {
    debugger;
    navigate(`${process.env.PUBLIC_URL}/login`);
  };
  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xl="12">
          <div className="login-card">
            <Card>
              <CardHeader>
                <div className="logo-wrapper">
                  <a href="#javascript">
                    <Image
                      attrImage={{
                        className: "img-fluid",
                        src: `${imgg}`,
                        alt: "",
                      }}
                    />
                  </a>
                </div>
              </CardHeader>
              <CardBody>
                <div className="login-header text-center">
                  <H3>Thank You For Registering</H3>
                  <br></br>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "btn-block",
                      onClick: () => linkToRegister(),
                    }}
                  >
                    Go To Login Page
                  </Btn>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ThankYouContain;
