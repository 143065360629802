// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { ServiceGroupUserMap, CognitoGroup, Assets, Repair, Testing, LogBook, AssetTemplate, LinkUsersAndSite, StaticForm, Categories, ServiceGroup, FormDetails, DistributionBox, LampType, BatteryType, FittingType, SPUserDetails, UserDetails, BillingPlan, CompanyDetails, SiteDetails, AssetOwner, SPCompanyDetails } = initSchema(schema);

export {
  ServiceGroupUserMap,
  CognitoGroup,
  Assets,
  Repair,
  Testing,
  LogBook,
  AssetTemplate,
  LinkUsersAndSite,
  StaticForm,
  Categories,
  ServiceGroup,
  FormDetails,
  DistributionBox,
  LampType,
  BatteryType,
  FittingType,
  SPUserDetails,
  UserDetails,
  BillingPlan,
  CompanyDetails,
  SiteDetails,
  AssetOwner,
  SPCompanyDetails
};