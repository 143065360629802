import { Btn, P, H1 } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Form,
  Label,
  Row,
  Card,
  CardBody,
  Input,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import Select from "react-select";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import {
  S3_BUCKET,
  REGION,
  ASSETS,
  SECRETACCESSKEY,
  ACCESSKEYID,
  limit,
} from "../../../Config/Config";
import SiteDBData from "../../../Data/DistributionBoard/index";

var base64 = require("base-64");
Amplify.configure(awsExports);

const AddAssetsContain = (props) => {
  const navigate = useNavigate();
  const state = useLocation();

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [distributionBoxes, setDistributionBoxes] = useState(
    SiteDBData.getSiteDBData()
  );
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [formFieldData, setFormData] = useState([]);
  const [seviceGroup, setSeviceGroup] = useState([]);
  const [assetTemplate, setAssetTemplate] = useState([]);
  const [siteIdvalue, setSiteIdvalue] = useState("");
  const [siteDetails, setSiteDetails] = useState([]);
  const [seviceGroupValue, setSeviceGroupValue] = useState("");
  const [assetLocation, setAssetLocation] = useState("");
  const [address, setAddress] = useState("");
  const [batteryType, setBatteryType] = useState([]);
  const [lampType, setLampType] = useState([]);
  const [fittingType, setFittingType] = useState([]);
  const [siteId, setSiteId] = useState("");
  const [tempId, setTempId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [siteselected, setSiteselected] = useState(true);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [assetLimit, setassetLimit] = useState(0);
  const [assetsCountOftheAO, setAssetsCountOftheAO] = useState(0);
  const [assetTemplateCount, setAssetTemplateCount] = useState("");
  const [aoId, setAoId] = useState("");
  const [maxNum, setmaxNum] = useState(0);
  const [fittingNum, setFittingNum] = useState(0);
  const [fittingNumData, setFittingNumData] = useState([]);
  const [ProfilePic, setProfilePic] = useState([]);
  const [logbookData, setLogbookData] = useState([]);
  const [logbookDataSiteFilter, setLogbookDataSiteFilter] = useState([]);
  const [logbookAssetLink, setLogbookAssetLink] = useState([]);
  const [appliance, setAppliance] = useState([]);
  const LoginUserGroup = localStorage.getItem("groups");
  const [counter, setCounter] = useState(0);

  useEffect(async () => {
    setAppliance(state.state.category.categoryName);
    fetchSubscriptionLimit();
    if (localStorage.getItem("site") === null && siteDetails.length === 0) {
      LoginUserGroup === "site_user" || LoginUserGroup === "electrician"
        ? fetchSiteDetailsSU()
        : fetchSiteDetails();
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSiteDetails(activeSites.filter((item) => item.status === "Active"));
    }

    fetchLogbookAssetLink();
    fetchlistlistBatteryType();
    fetchlistlistLampType();
    fetchlistlistFittingType();
    fetchAssetTemplate();
    fetchlistServiceGroup();
    fetchAssetsData();
    fetchLogbooksSites();
  }, []);
  async function fetchAssetsData() {
    await API.graphql({
      query: queries.assetsByTypeAndAssetNum,
      authMode: "API_KEY",
      variables: {
        type: "Assets",
        sortDirection: "DESC",
      },
    })
      .then((response) => {
        console.log(response);
        let maxnumber = 0;
        let assetsDetails = response.data.assetsByTypeAndAssetNum.items;
        if (assetsDetails.length > 0) {
          maxnumber = assetsDetails[0].assetNum;
          setmaxNum(parseInt(maxnumber) + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchSiteDetails() {
    await API.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    }).then((result) => {
      const sites = result.data.siteDetailsByAssetownerID.items;
      if (sites.length > 0) {
        const siteFilterArray = [];
        localStorage.setItem("site", base64.encode(JSON.parse(sites)));
        let activeSites = sites.filter((item) => item.status === "Active");
        setSiteDetails(activeSites);
        activeSites.map(async (item) => {
          siteFilterArray.push(item.id);
        });
        API.graphql({
          query: queries.listAssets,
          variables: {
            limit: limit,
          },
        })
          .then((result) => {
            console.log(result);
            const Assets = result.data.listAssets.items.filter((item) => {
              const _matchSites = siteFilterArray?.find(
                (id) => id === item?.siteId
              );
              if (_matchSites) {
                return item;
              }
            });
            setAssetsCountOftheAO(Assets.length);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setAssetsCountOftheAO(0);
      }
    });
  }
  async function fetchSiteDetailsSU() {
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: limit,
      },
    })
      .then((dataDetails) => {
        const siteDetails =
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
        if (siteDetails.length > 0) {
          const siteFilterArray = [];
          siteDetails.map(async (item) => {
            siteFilterArray.push({
              id: {
                eq: item.sitedetailsID,
              },
            });
          });
          API.graphql({
            query: queries.listSiteDetails,
            variables: {
              //filter: {
              //  or: siteFilterArray,
              //},
              limit: limit,
            },
          })
            .then((result) => {
              const sites = result.data.listSiteDetails.items.filter((item) => {
                const _matchSites = siteFilterArray?.find(
                  (id) => id === item?.id
                );
                if (_matchSites) {
                  return item;
                }
              });
              console.log(sites);
              localStorage.setItem("site", base64.encode(JSON.parse(sites)));
              setSiteDetails(sites.filter((item) => item.status === "Active"));
            })
            .catch((error2) => {
              console.log(error2);
            });
        } else {
          setSiteDetails([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchSubscriptionLimit() {
    await API.graphql({
      query: queries.listSubscriptionLimits,
      variables: {
        filter: {
          subscriptionId: {
            eq: LoginUserDetails.paymentId,
          },
        },
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        let assetLimitdata = result.data.listSubscriptionLimits.items;
        setassetLimit(assetLimitdata[0].AssetLimit);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchAssetTemplate() {
    await API.graphql({
      query: queries.listAssetTemplates,
      variables: {
        filter: {
          categoriesID: {
            eq: state.state.category.id,
          },
        },
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        if (result.data.listAssetTemplates.items.length > 0) {
          const assetTemp = result.data.listAssetTemplates.items;
          setAssetTemplate(assetTemp);
          setAssetTemplateCount(assetTemp.length);
        } else {
          setAssetTemplateCount(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchlistServiceGroup() {
    await API.graphql({
      query: queries.listServiceGroups,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setSeviceGroup(result.data.listServiceGroups.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchlistDistributionBox() {
    // await API.graphql({
    //   query: queries.listDistributionBoxes,
    //  variables: {
    //     limit: limit,
    //   },
    //   authMode: "API_KEY",
    //  })
    //   .then((result) => {
    //    console.log(result);
    //    setDistributionBoxes(result.data.listDistributionBoxes.items);
    //  })
    //  .catch((error) => {
    //    console.log(error);
    //  });
    await API.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    }).then(async (result) => {
      const sites = result.data.siteDetailsByAssetownerID.items;
      if (sites.length > 0) {
        const siteFilterArray = [];
        //setSiteDetails(result.data.siteDetailsByAssetownerID.items);
        sites.map(async (item) => {
          siteFilterArray.push(item.id);
        });
        let nextToken = "";
        let assets = [];
        await API.graphql({
          query: queries.listDistributionBoxes,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
          .then(async (result) => {
            let assetDatas = result.data.listDistributionBoxes.items;
            nextToken = result.data.listDistributionBoxes.nextToken;
            console.log(
              "first token count",
              result.data.listDistributionBoxes.items.length
            );
            console.log("nextToken 1", nextToken);
            if (result.data.listDistributionBoxes.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              await API.graphql({
                query: queries.listDistributionBoxes,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
                .then((results) => {
                  nextToken = results.data.listDistributionBoxes.nextToken;
                  console.log("nextToken", nextToken);
                  console.log(results.data.listDistributionBoxes.items.length);
                  if (results.data.listDistributionBoxes.items.length > 0) {
                    assets.push(results.data.listDistributionBoxes.items);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setDistributionBoxes([]);
                });
            }
            console.log(assets);
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              //--
              const Assets = mergeAssets.filter((item) => {
                const _matchSites = siteFilterArray?.find(
                  (id) => id === item?.sitedetailsID
                );
                if (_matchSites) {
                  return item;
                }
              });
              console.log(Assets);
              setDistributionBoxes(Assets);
            }
          })
          .catch((error) => {
            console.log(error);
            setDistributionBoxes([]);
          });
        {
          /*
          API.graphql({
            query: queries.listDistributionBoxes,
            variables: {
              limit: limit,
            },
          })
            .then((result) => {
              console.log(result);
              const Assets = result.data.listDistributionBoxes.items.filter(
                (item) => {
                  const _matchSites = siteFilterArray?.find(
                    (id) => id === item?.sitedetailsID
                  );
                  if (_matchSites) {
                    return item;
                  }
                }
              );
              console.log(Assets);
              setDistributionBoxes(Assets);
            })
            .catch((error) => {
              console.log(error);
            });
          */
        }
      } else {
        setDistributionBoxes([]);
      }
    });
  }
  async function fetchlistDistributionBoxSU() {
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: limit,
      },
    })
      .then((dataDetails) => {
        const siteDetails =
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
        if (siteDetails.length > 0) {
          const siteFilterArray = [];
          siteDetails.map(async (item) => {
            siteFilterArray.push({
              id: {
                eq: item.sitedetailsID,
              },
            });
          });
          API.graphql({
            query: queries.listSiteDetails,
            variables: {
              //filter: {
              //  or: siteFilterArray,
              //},
              limit: limit,
            },
          })
            .then(async (result) => {
              //const sites = result.data.listSiteDetails.items;
              const sites = result.data.listSiteDetails.items.filter((item) => {
                const _matchSites = siteFilterArray?.find(
                  (id) => id === item?.id
                );
                if (_matchSites) {
                  return item;
                }
              });
              console.log(sites);
              if (sites.length > 0) {
                const siteFilterArray = [];
                sites.map(async (item) => {
                  siteFilterArray.push(item.id);
                });
                //---
                let nextToken = "";
                let assets = [];
                await API.graphql({
                  query: queries.listDistributionBoxes,
                  variables: {
                    limit: limit,
                  },
                  authMode: "API_KEY",
                })
                  .then(async (result) => {
                    let assetDatas = result.data.listDistributionBoxes.items;
                    nextToken = result.data.listDistributionBoxes.nextToken;
                    console.log(
                      "first token count",
                      result.data.listDistributionBoxes.items.length
                    );
                    console.log("nextToken 1", nextToken);
                    if (result.data.listDistributionBoxes.items.length > 0) {
                      assets.push(assetDatas);
                    }
                    while (nextToken !== null) {
                      await API.graphql({
                        query: queries.listDistributionBoxes,
                        variables: {
                          limit: limit,
                          nextToken: nextToken,
                        },
                        authMode: "API_KEY",
                      })
                        .then((results) => {
                          nextToken =
                            results.data.listDistributionBoxes.nextToken;
                          console.log("nextToken", nextToken);
                          console.log(
                            results.data.listDistributionBoxes.items.length
                          );
                          if (
                            results.data.listDistributionBoxes.items.length > 0
                          ) {
                            assets.push(
                              results.data.listDistributionBoxes.items
                            );
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          setDistributionBoxes([]);
                        });
                    }
                    console.log(assets);
                    if (assets.length > 0) {
                      const mergeAssets = assets.flat(1);
                      console.log(mergeAssets);
                      //--
                      const Assets = mergeAssets.filter((item) => {
                        const _matchSites = siteFilterArray?.find(
                          (id) => id === item?.sitedetailsID
                        );
                        if (_matchSites) {
                          return item;
                        }
                      });
                      console.log(Assets);
                      setDistributionBoxes(Assets);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setDistributionBoxes([]);
                  });

                {
                  /*
                  API.graphql({
                    query: queries.listDistributionBoxes,
                    variables: {
                      limit: limit,
                    },
                  })
                    .then((result) => {
                      console.log(result);
                      const Assets =
                        result.data.listDistributionBoxes.items.filter(
                          (item) => {
                            const _matchSites = siteFilterArray?.find(
                              (id) => id === item?.sitedetailsID
                            );
                            if (_matchSites) {
                              return item;
                            }
                          }
                        );
                      console.log(Assets);
                      setDistributionBoxes(Assets);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  */
                }
              } else {
                setDistributionBoxes([]);
              }
            })
            .catch((error2) => {
              console.log(error2);
            });
        } else {
          setDistributionBoxes([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchlistlistBatteryType() {
    await API.graphql({
      query: queries.listBatteryTypes,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setBatteryType(result.data.listBatteryTypes.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchlistlistLampType() {
    await API.graphql({
      query: queries.listLampTypes,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setLampType(result.data.listLampTypes.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchlistlistFittingType() {
    await API.graphql({
      query: queries.listFittingTypes,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setFittingType(result.data.listFittingTypes.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchLogbooksSites() {
    {
      /*
      await API.graphql({
        query: queries.listLogBooks,
        variables: {
          limit: limit,
        },
        authMode: "API_KEY",
      })
        .then((result) => {
          console.log(result);
          setLogbookData(result.data.listLogBooks.items);
        })
        .catch((error) => {
          console.log(error);
        });
      */
    }
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.listLogBooks,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.listLogBooks.items;
        nextToken = result.data.listLogBooks.nextToken;
        if (result.data.listLogBooks.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listLogBooks,
            variables: {
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.listLogBooks.nextToken;
              if (results.data.listLogBooks.items.length > 0) {
                assets.push(results.data.listLogBooks.items);
              }
            })
            .catch((error) => {
              console.log(error);
              setLogbookData([]);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setLogbookData(mergeAssets);
        } else {
          setLogbookData([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLogbookData([]);
      });
  }

  async function fetchLogbookAssetLink() {
    await API.graphql({
      query: queries.listLogbookAssetLinks,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setLogbookAssetLink(result.data.listLogbookAssetLinks.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const digits_count = (n) => {
    var count = 0;
    if (n >= 1) ++count;
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  };

  async function onSubmit(formData) {
    console.log(appliance);
    console.log(state.state.category);
    console.log(formData);
    console.log(siteId);
    try {
      if (siteIdvalue !== "" || (state.state && state.state.site)) {
        setSiteselected(true);
        const formValues = [];
        Object.keys(formFieldData).map((e) => {
          formValues.push({ [e]: formData[e] });
        });
        //--
        {
          /*
        const filters = {};
  
        filters.fittingNumber = {
          eq: fittingNum + 1,
        };
  
        filters.sitedetailsID = {
          eq: state.state && state.state.site ? state.state.site.id : formData.site,
        };
  
        if (formData.distributionBox) {
          filters.distributionboxID = {
            eq: formData.distributionBox,
          };
        }
  
        console.log(filters);
      */
        }

        await API.graphql({
          query: queries.assetsByAssettemplateID,
          variables: {
            limit: limit,
            assettemplateID: formData.assetType,
            filter: {
              fittingNumber: {
                eq: fittingNum + 1,
              },
              sitedetailsID: {
                eq:
                  state.state && state.state.site
                    ? state.state.site.id
                    : siteId,
              },
            },
          },
          authMode: "API_KEY",
        })
          .then(async (response) => {
            console.log(response);
            if (response.data.assetsByAssettemplateID.items.length > 0) {
              toast.error(
                "Fitting Number for the selected category already exsists",
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );
            } else {
              console.log("create...");
              console.log(formData);
              let tempSiteId =
                state.state && state.state.site
                  ? formData.assetType + "_" + state.state.site.id
                  : formData.assetType + "_" + siteId;
              await API.graphql({
                query: mutations.createAssets,
                variables: {
                  input: {
                    assettemplateID: formData.assetType,
                    assetOwnerID: aoId,
                    assetType: templateName,
                    distributionboxID: formData.distributionBox,
                    formFieldValues: JSON.stringify(formValues),
                    siteId:
                      state.state && state.state.site
                        ? state.state.site.id
                        : siteId,
                    sitedetailsID:
                      state.state && state.state.site
                        ? state.state.site.id
                        : siteId,
                    assetModel: formData.assetModel,
                    assetDescription: formData.assetDescription,
                    assetWarrantyStart: formData.assetWarrantyStart,
                    assetWarrantyEnd: formData.assetWarrantyEnd,
                    assetMake: formData.assetMake,
                    installedOn: formData.installedOn,
                    assetSerialNumber: formData.assetSerialNumber,
                    fittingNumber: fittingNum + 1,
                    assetLocation: assetLocation,
                    status: "Active",
                    assetImage: JSON.stringify(ProfilePic),
                    assetNumber:
                      state.state.category.assetNumberPrefix + maxNum,
                    logbookId: formData.logbook,
                    type: "Assets",
                    assetNum: maxNum,
                    createdBy: LoginUserDetails.id,
                    source:
                      LoginUserGroup === "site_owner"
                        ? "AO"
                        : LoginUserGroup === "site_user"
                        ? "SU"
                        : "SP",
                    assettemplateSiteID: tempSiteId,
                  },
                },
                authMode: "API_KEY",
              })
                .then(async (result) => {
                  console.log(result);
                  await API.graphql({
                    query: queries.listAssetCounts,
                    variables: {
                      limit: limit,
                      filter: {
                        categoriesID: {
                          eq: state.state.category.id,
                        },
                        sitedetailsID: {
                          eq:
                            state.state && state.state.site
                              ? state.state.site.id
                              : siteId,
                        },
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then(async (result) => {
                      console.log(result);
                      if (result.data.listAssetCounts.items.length > 0) {
                        await API.graphql({
                          query: mutations.updateAssetCounts,
                          variables: {
                            input: {
                              id: result.data.listAssetCounts.items[0].id,
                              assetCount:
                                1 +
                                parseInt(
                                  result.data.listAssetCounts.items[0]
                                    .assetCount
                                ),
                            },
                          },
                          authMode: "API_KEY",
                        });
                        const addedsite = await API.graphql({
                          query: queries.getAssetCounts,
                          variables: {
                            id: result.data.listAssetCounts.items[0].id,
                            limit: 10000,
                          },
                          authMode: "API_KEY",
                        });
                        console.log(addedsite);
                        if (addedsite.data.getAssetCounts !== null) {
                          let activeAssets = [];
                          if (
                            localStorage.getItem("categoryAssetCount") !== null
                          ) {
                            activeAssets = JSON.parse(
                              base64.decode(
                                localStorage.getItem("categoryAssetCount")
                              )
                            );
                            console.log(activeAssets);
                            const existingIndex = activeAssets.findIndex(
                              (item) =>
                                item.id === addedsite.data.getAssetCounts.id
                            );
                            console.log(existingIndex);
                            if (existingIndex > -1) {
                              activeAssets[existingIndex] =
                                addedsite.data.getAssetCounts;
                            } else {
                              activeAssets.push(addedsite.data.getAssetCounts);
                            }
                            console.log(activeAssets);
                            localStorage.setItem(
                              "categoryAssetCount",
                              base64.encode(JSON.stringify(activeAssets))
                            );
                          }
                        }
                      } else {
                        await API.graphql({
                          query: mutations.createAssetCounts,
                          variables: {
                            input: {
                              assetCount: 1,
                              categoriesID: state.state.category.id,
                              assetownerID: LoginUserDetails.id,
                              sitedetailsID:
                                state.state && state.state.site
                                  ? state.state.site.id
                                  : siteId,
                            },
                          },
                          authMode: "API_KEY",
                        });
                        //---localstorage set
                        const addedsite = await API.graphql({
                          query: queries.assetCountsBySitedetailsID,
                          variables: {
                            sitedetailsID:
                              state.state && state.state.site
                                ? state.state.site.id
                                : siteId,
                            filter: {
                              categoriesID: {
                                eq: state.state.category.id,
                              },
                            },
                            limit: 10000,
                          },
                          authMode: "API_KEY",
                        });
                        if (
                          addedsite.data.assetCountsBySitedetailsID.items > 0
                        ) {
                          if (
                            localStorage.getItem("categoryAssetCount") !== null
                          ) {
                            let activeAssets = JSON.parse(
                              base64.decode(
                                localStorage.getItem("categoryAssetCount")
                              )
                            );
                            console.log(activeAssets);
                            activeAssets.push(
                              addedsite.data.assetCountsBySitedetailsID.items
                            );
                            console.log(activeAssets);
                            localStorage.setItem(
                              "categoryAssetCount",
                              base64.encode(JSON.stringify(activeAssets))
                            );
                          }
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  toast.success("Successfully Added Assets.", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  const assetId = result.data.createAssets.id;
                  const filteredLogbook = logbookData.filter(
                    (item) => item.id === formData.logbook
                  );
                  if (filteredLogbook.length > 0) {
                    console.log(
                      "Some Logbooks Are Found That Are Related To Selected SiteId"
                    );
                    const AssetLogbookId = filteredLogbook.assetsID;
                    console.log(AssetLogbookId);
                    if (AssetLogbookId == null || AssetLogbookId === "") {
                      API.graphql({
                        query: mutations.updateLogBook,
                        variables: {
                          limit: limit,
                          input: {
                            id: formData.logbook,
                            assetsID: assetId,
                          },
                        },
                        authMode: "API_KEY",
                      }).then((response) => {
                        console.log(response);
                      });
                    }
                  } else {
                    console.log(
                      "No Logbooks Found That Are Related To Selected SiteId"
                    );
                  }
                  //navigate(`${process.env.PUBLIC_URL}/assets`, {
                  //  state: { category: state.state.category },
                  //});
                  if (state.state.category && state.state.site) {
                    navigate(`${process.env.PUBLIC_URL}/assets`, {
                      state: {
                        category: state.state.category,
                        site: state.state.site,
                      },
                    });
                  } else {
                    navigate(`${process.env.PUBLIC_URL}/assets`, {
                      state: { category: state.state.category },
                    });
                  }
                })
                .catch((error) => {
                  toast.error("Error While Adding Assets : " + error, {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            toast.error(
              "Fitting Number for the selected category already exsists",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          });
      } else {
        setSiteselected(false);
      }
    } catch (e) {
      toast.error("Error While Adding Assets : " + e.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  const formInputs = Object.keys(formFieldData).map((e) => {
    const { label, type, mandatory, errorMessage, options } = formFieldData[e];

    return (
      <Col md="6 mb-3" key={e}>
        <label>{label}</label>
        {type !== "dropdown" && (
          <input
            placeholder={label}
            className="form-control"
            type={type}
            name={e}
            {...register(e, { required: mandatory })}
          />
        )}
        {type === "dropdown" && (
          <select
            className="form-control"
            name={e}
            {...register(e, { required: mandatory })}
          >
            <option value="">Choose {label}...</option>
            {options.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        )}
        <span>{errors.e && { errorMessage }}</span>
      </Col>
    );
  });

  async function getForms(ids) {
    const dataDetails = await API.graphql(
      graphqlOperation(queries.getFormDetails, { id: ids })
    );

    console.log(dataDetails);

    const data = dataDetails.data.getFormDetails;
    const formFieldsDetails = data?.formFields;

    console.log(formFieldsDetails);

    if (formFieldsDetails) {
      try {
        const parsedFormData = JSON.parse(formFieldsDetails);
        console.log(parsedFormData);
        setFormData(parsedFormData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
    }
  }

  const selectedValue = (e) => {
    setTempId(e.target.value);
    // assetTemplate.map((item) => {
    //   if (item.id === e.target.value) {
    //     setTemplateName(item.templatename);
    //     seviceGroup.map((item1) => {
    //       if (item1.id === item.servicegroupID) {
    //         setSeviceGroupValue(item1.serviceName);
    //         getForms(item.formId);
    //       }
    //     });
    //   }
    // });
    // if (fittingNumData.length > 0) {
    //   let datas = fittingNumData.filter((item) => {
    //     return item.assettemplateID === e.target.value;
    //   });
    //   if (datas.length > 0) {
    //     const sort = datas.sort((a, b) => b.fittingNumber - a.fittingNumber);
    //     const firstFittingNumber = sort[0].fittingNumber;

    //     {
    //       /*
    //     if (firstFittingNumber !== "" && firstFittingNumber !== null) {
    //         setFittingNum(parseInt(firstFittingNumber));
    //     } else {
    //         setFittingNum(0);
    //     }
    //   */
    //     }

    //     for (let i = 0; i < datas.length; i++) {
    //       if (!isNaN(parseInt(datas[i].fittingNumber))) {
    //         setFittingNum(parseInt(datas[i].fittingNumber));
    //         break;
    //       }
    //     }
    //   } else {
    //     setFittingNum(0);
    //     setFittingNumData(fittingNumData);
    //   }
    // } else {
    //   //---
    //   let nextToken = "";
    //   let assets = [];
    //   API.graphql({
    //     query: queries.assetsByAssettemplateID,
    //     variables: {
    //       limit: limit,
    //       assettemplateID: e.target.value,
    //       // filter: {
    //       //   sitedetailsID: {
    //       //     eq: siteId,
    //       //   },
    //       // },
    //     },
    //     authMode: "API_KEY",
    //   })
    //     .then(async (result) => {
    //       let assetDatas = result.data.assetsByAssettemplateID.items;
    //       nextToken = result.data.assetsByAssettemplateID.nextToken;
    //       console.log(
    //         "first token count",
    //         result.data.assetsByAssettemplateID.items.length
    //       );
    //       console.log("nextToken 1", nextToken);
    //       if (result.data.assetsByAssettemplateID.items.length > 0) {
    //         assets.push(assetDatas);
    //       }
    //       while (nextToken !== null) {
    //         await API.graphql({
    //           query: queries.assetsByAssettemplateID,
    //           variables: {
    //             limit: limit,
    //             assettemplateID: e.target.value,
    //             // filter: {
    //             //   sitedetailsID: {
    //             //     eq: siteId,
    //             //   },
    //             // },
    //             nextToken: nextToken,
    //           },
    //           authMode: "API_KEY",
    //         })
    //           .then((results) => {
    //             nextToken = results.data.assetsByAssettemplateID.nextToken;
    //             console.log("nextToken", nextToken);
    //             console.log(results.data.assetsByAssettemplateID.items.length);
    //             if (results.data.assetsByAssettemplateID.items.length > 0) {
    //               assets.push(results.data.assetsByAssettemplateID.items);
    //             }
    //           })
    //           .catch((error) => {
    //             console.log(error);
    //           });
    //       }
    //       console.log(assets);
    //       if (assets.length > 0) {
    //         const mergeAssets = assets.flat(1);
    //         console.log(mergeAssets);
    //         const sort = mergeAssets.sort(
    //           (a, b) => b.fittingNumber - a.fittingNumber
    //         );
    //         console.log(sort);
    //         if (sort.length > 0) {
    //           const firstFittingNumber = sort[0].fittingNumber;
    //           setFittingNumData(sort);

    //           for (let i = 0; i < sort.length; i++) {
    //             if (!isNaN(parseInt(sort[i].fittingNumber))) {
    //               setFittingNum(parseInt(sort[i].fittingNumber));
    //               break;
    //             }
    //           }
    //         } else {
    //           setFittingNum(0);
    //           setFittingNumData(fittingNumData);
    //         }
    //       } else {
    //         setFittingNum(0);
    //         setFittingNumData(fittingNumData);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    //   {
    //     /*
    //   API.graphql({
    //     query: queries.assetsByAssettemplateID,
    //     variables: {
    //       limit: limit,
    //       assettemplateID: e.target.value,
    //       // filter: {
    //       //   sitedetailsID: {
    //       //     eq: siteId,
    //       //   },
    //       // },
    //     },
    //     authMode: "API_KEY",
    //   })
    //     .then(async (response) => {
    //       if (response.data.assetsByAssettemplateID.items.length > 0) {
    //         const sort = response.data.assetsByAssettemplateID.items.sort(
    //           (a, b) => b.fittingNumber - a.fittingNumber
    //         );
    //         console.log(sort);
    //         if (sort.length > 0) {
    //           const firstFittingNumber = sort[0].fittingNumber;
    //           setFittingNumData(sort);

    //           for (let i = 0; i < sort.length; i++) {
    //             if (!isNaN(parseInt(sort[i].fittingNumber))) {
    //               setFittingNum(parseInt(sort[i].fittingNumber));
    //               break;
    //             }
    //           }
    //         } else {
    //           setFittingNum(0);
    //           setFittingNumData(fittingNumData);
    //         }
    //       } else {
    //         setFittingNum(0);
    //         setFittingNumData(fittingNumData);
    //       }
    //     })
    //     .catch((error) => {});
    //   */
    //   }
    // }
  };

  const siteSelect = async (event) => {
    setSiteId(event.value);
    console.log(event.value);
    setSiteId(event.value);
    setSiteselected(true);
    setSiteIdvalue(event);
    const siteidd = event.value;
    let siteAO = siteDetails.filter((item) => {
      return item.id === event.value;
    });
    setAoId(siteAO[0].assetownerID);
    console.log(fittingNumData);

    // if (fittingNumData.length > 0) {
    //   let datas = fittingNumData.filter((item) => {
    //     return item.sitedetailsID === e.target.value;
    //   });
    //   console.log(datas);
    //   const sort = datas.sort((a, b) => b.fittingNumber - a.fittingNumber);
    //   if (sort.length > 0) {
    //     //const firstFittingNumber = sort[0].fittingNumber;

    //     {
    //       /*
    //     if (firstFittingNumber !== "" && firstFittingNumber !== null) {
    //         setFittingNum(parseInt(firstFittingNumber));
    //     } else {
    //         setFittingNum(0);
    //     }
    //   */
    //     }

    //     console.log(sort);
    //     for (let i = 0; i < sort.length; i++) {
    //       console.log(sort[i].fittingNumber);
    //       if (sort[i].fittingNumber !== "" && sort[i].fittingNumber !== null) {
    //         if (!isNaN(parseInt(sort[i].fittingNumber))) {
    //           setFittingNum(parseInt(sort[i].fittingNumber));
    //           break;
    //         }
    //       } else {
    //         setFittingNum(0);
    //       }
    //     }
    //   } else {
    //     setFittingNum(0);
    //     setFittingNumData(fittingNumData);
    //   }
    // } else {
    let assetSiteTemp = tempId + "_" + siteidd;
    // API.graphql({
    //   query: queries.assetsByAssettemplateSiteIDAndAssetNum,
    //   variables: {
    //     assettemplateSiteID: assetSiteTemp,
    //     limit: 10000,
    //   },
    //   authMode: "API_KEY",
    //   })
    //     .then(async (response) => {
    //       if (response.data.assetsByAssettemplateSiteIDAndAssetNum.items.length > 0) {
    //         const sort = response.data.assetsByAssettemplateSiteIDAndAssetNum.items.sort(
    //           (a, b) => b.fittingNumber - a.fittingNumber
    //         );
    //         console.log(sort);
    //         setFittingNumData(sort);

    //         {
    //           /*
    //         setFittingNum(
    //           sort[0].fittingNumber !== "" ? parseInt(sort[0].fittingNumber) : 0
    //         );
    //         */
    //         }

    //         for (let i = 0; i < sort.length; i++) {
    //           console.log(sort[i].fittingNumber);
    //           if (
    //             sort[i].fittingNumber !== "" &&
    //             sort[i].fittingNumber !== null
    //           ) {
    //             if (!isNaN(parseInt(sort[i].fittingNumber))) {
    //               setFittingNum(parseInt(sort[i].fittingNumber));
    //               break;
    //             }
    //           } else {
    //             setFittingNum(0);
    //           }
    //         }
    //       } else {
    //         setFittingNum(0);
    //         setFittingNumData(fittingNumData);
    //       }
    //     })
    //     .catch((error) => { });

    let nextToken = "";
    let db = [];
    const DBResult = await API.graphql({
      query: queries.assetsByAssettemplateSiteIDAndAssetNum,
      variables: {
        assettemplateSiteID: assetSiteTemp,
        limit: 10000,
      },
      authMode: "API_KEY",
    });
    if (DBResult.data.assetsByAssettemplateSiteIDAndAssetNum.items.length > 0) {
      let assetDatas =
        DBResult.data.assetsByAssettemplateSiteIDAndAssetNum.items;
      nextToken =
        DBResult.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
      if (
        DBResult.data.assetsByAssettemplateSiteIDAndAssetNum.items.length > 0
      ) {
        db.push(assetDatas);
      }
      while (nextToken !== null) {
        const DBResult1 = await API.graphql({
          query: queries.assetsByAssettemplateSiteIDAndAssetNum,
          variables: {
            assettemplateSiteID: assetSiteTemp,
            limit: 10000,
          },
          authMode: "API_KEY",
        });
        nextToken =
          DBResult1.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
        if (
          DBResult1.data.assetsByAssettemplateSiteIDAndAssetNum.items.length > 0
        ) {
          db.push(DBResult1.data.assetsByAssettemplateSiteIDAndAssetNum.items);
        }
      }
      if (db.length > 0) {
        const mergeDB = db.flat(1);
        console.log(mergeDB);
        if (mergeDB.length > 0) {
          const sort = mergeDB.sort(
            (a, b) => b.fittingNumber - a.fittingNumber
          );
          console.log(sort);
          setFittingNumData(sort);

          {
            /*
          setFittingNum(
            sort[0].fittingNumber !== "" ? parseInt(sort[0].fittingNumber) : 0
          );
          */
          }

          for (let i = 0; i < sort.length; i++) {
            console.log(sort[i].fittingNumber);
            if (
              sort[i].fittingNumber !== "" &&
              sort[i].fittingNumber !== null
            ) {
              if (!isNaN(parseInt(sort[i].fittingNumber))) {
                setFittingNum(parseInt(sort[i].fittingNumber));
                break;
              }
            } else {
              setFittingNum(0);
            }
          }
        } else {
          setFittingNum(0);
          setFittingNumData(fittingNumData);
        }
      }
    } else {
      setFittingNum(0);
      setFittingNumData(fittingNumData);
    }
  };

  React.useEffect(() => {
    if (siteId !== "") {
      setLogbookDataSiteFilter(
        logbookData.filter((item) => {
          return item.siteId === siteId && item.logbookName !== null;
        })
      );
      setDistributionBoxfilter(
        distributionBoxes.filter((item) => {
          return item.sitedetailsID === siteId;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxes);
      setLogbookDataSiteFilter(logbookData);
    }
  }, [siteId]);

  const cancelForm = () => {
    // navigate(`${process.env.PUBLIC_URL}/assets`, {
    //  state: { category: state.state.category },
    // });
    if (state.state.category && state.state.site) {
      navigate(`${process.env.PUBLIC_URL}/assets`, {
        state: {
          category: state.state.category,
          site: state.state.site,
        },
      });
    } else {
      navigate(`${process.env.PUBLIC_URL}/assets`, {
        state: { category: state.state.category },
      });
    }
  };

  // Image Upload
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${ASSETS}/${file.name}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(evt);
        const filedetails = [];
        filedetails.push({ fileName: file.name, type: file.type });
        setProfilePic(filedetails);
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  React.useEffect(() => {
    if (state.state.site && counter < 500) {
      setSiteId(state.state.site.id);
      if (state.state.site.id !== "") {
        setLogbookDataSiteFilter(
          logbookData.filter((item) => {
            return (
              item.siteId === state.state.site.id && item.logbookName !== null
            );
          })
        );
        console.log(distributionBoxes);
        const db = distributionBoxes.filter((item) => {
          return item.sitedetailsID === state.state.site.id;
        });
        console.log(db);
        setDistributionBoxfilter(
          distributionBoxes.filter((item) => {
            return item.sitedetailsID === state.state.site.id;
          })
        );
      } else {
        setDistributionBoxfilter(distributionBoxes);
        setLogbookDataSiteFilter(logbookData);
      }
      setCounter((prevCounter) => prevCounter + 1);
    }
  }, [state.state.site, distributionBoxes, logbookData]);

  const optionsSites = [
    { value: "", label: "Select Site" },
    ...siteDetails.map((item) => ({
      value: item.id,
      label:
        item.siteName +
        " " +
        "(" +
        (item.unitNumber ? item.unitNumber : "") +
        ")",
    })),
  ];

  const handleSite = (event) => {
    console.log(event.value);
    setSiteId(event.value);
    setSiteIdvalue(event);
    //setSiteselected(true);
  };

  return (
    <Fragment>
      <br></br>
      <Container
        fluid={true}
        style={{ paddingLeft: "0px !important" }}
        className="p-0 project-list"
      >
        <H1>{state.state.category.categoryName}</H1>
        <Card className="custom-card">
          <CardBody>
            {assetTemplateCount !== 0 ? (
              ""
            ) : (
              <P attrPara={{ className: "h4 txt-warning" }}>
                No Asset Templates for the selected Category. Please add new
                asset template and come back for asset creation.
              </P>
            )}
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              {assetsCountOftheAO !== 0 && assetsCountOftheAO >= assetLimit ? (
                <>
                  <Row>
                    <Col>
                      <div>
                        <p className="errorSubscription">
                          Sorry!.. Your Asset Limit is Exceeded, Please Upgrade
                          your Subscription
                        </p>
                      </div>
                      <br></br>
                      <Btn attrBtn={{ color: "warning", onClick: cancelForm }}>
                        Close
                      </Btn>
                    </Col>
                  </Row>
                </>
              ) : (
                <Row>
                  <Col md="12">
                    <Row>
                      <Col md="4 mb-3">
                        <Label>Asset Type</Label>
                        <select
                          className="form-control"
                          name="assetType"
                          {...register("assetType", { required: true })}
                          onChange={selectedValue}
                        >
                          <option value="">Select Asset Type</option>
                          {assetTemplate.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.templatename}
                            </option>
                          ))}
                        </select>
                        <span>
                          {errors.assetType && "Asset Type is required"}
                        </span>
                      </Col>

                      <Col md="4 mb-3">
                        <Label>Sites</Label>
                        {state.state && state.state.site ? (
                          <>
                            <select
                              className="form-control"
                              name="site"
                              value={siteId}
                              placeholder="Sites"
                              {...register("site", {
                                required:
                                  state.state && state.state.site
                                    ? false
                                    : true,
                              })}
                              onChange={siteSelect}
                            >
                              <option value="">Select Sites</option>
                              {siteDetails.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {(item?.siteName || "") +
                                    " (" +
                                    (item?.unitNumber || "") +
                                    ")"}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : (
                          <>
                            <Select
                              options={optionsSites}
                              isSearchable={true}
                              value={siteIdvalue}
                              placeholder="Select Sites"
                              onChange={siteSelect}
                              name="site"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />

                            <span>{!siteselected && "Site is required"}</span>
                          </>
                        )}
                        <span>{errors.site && "Site is required"}</span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label>
                          Distribution Board{" "}
                          {appliance !== "Emergency Lights" ? false : true}
                        </Label>
                        <select
                          className="form-control"
                          name="distributionBox"
                          placeholder="Distribution Board"
                          {...register("distributionBox", {
                            required:
                              appliance !== "Emergency Lights" ? false : true,
                          })}
                        >
                          <option value="">Select Distribution Board</option>
                          {distributionBoxFilter.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.distributionBoxName}
                            </option>
                          ))}
                        </select>
                        <span>
                          {errors.distributionBox &&
                            "Distribution Board is required"}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4 mb-3">
                        <Label>Fitting Number</Label>
                        <input
                          type="text"
                          className="form-control"
                          name="fittingNumber"
                          placeholder="Fitting Number"
                          value={fittingNum !== null ? fittingNum + 1 : "-"}
                          {...register("fittingNumber", { required: true })}
                        />
                        <span>
                          {errors.fittingNumber && "Fitting Number is required"}
                        </span>
                      </Col>

                      <Col md="4 mb-3">
                        <Label>Asset Model</Label>
                        <input
                          className="form-control"
                          name="assetModel"
                          type="text"
                          placeholder="Asset Model"
                          {...register("assetModel", { required: false })}
                        />
                      </Col>

                      <Col md="4 mb-3">
                        <Label>Asset Description</Label>
                        <input
                          type="textarea"
                          name="assetDescription"
                          className="form-control"
                          rows="3"
                          {...register("assetDescription", { required: false })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4 mb-3">
                        <Label>Asset Warranty Start Date</Label>
                        <input
                          className="form-control"
                          name="assetWarrantyStart"
                          type="date"
                          {...register("assetWarrantyStart", {
                            required: true,
                          })}
                          onChange={(dt) => setStartDate(dt.target.value)}
                        />
                        <span>
                          {errors.assetWarrantyStart &&
                            "Asset Warranty Start Date is required"}
                        </span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label>Asset Warranty End Date</Label>
                        <input
                          className="form-control"
                          name="assetWarrantyEnd"
                          min={startDate}
                          type="date"
                          {...register("assetWarrantyEnd", { required: true })}
                        />
                        <span>
                          {errors.assetWarrantyEnd &&
                            "Asset Warranty End Date is required"}
                        </span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label>Installed On</Label>
                        <input
                          className="form-control"
                          name="installedOn"
                          type="date"
                          {...register("installedOn", { required: true })}
                        />
                        <span>
                          {errors.installedOn && "Installed On is required"}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4 mb-3">
                        <Label>Asset Make</Label>
                        <input
                          className="form-control"
                          name="assetMake"
                          type="text"
                          placeholder="Asset Make"
                          {...register("assetMake", { required: false })}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label>Asset Serial Number</Label>
                        <input
                          className="form-control"
                          name="assetSerialNumber"
                          type="text"
                          placeholder="Asset Serial Number"
                          {...register("assetSerialNumber", {
                            required: false,
                          })}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label>Asset Location</Label>
                        <input
                          className="form-control"
                          name="assetLocation"
                          placeholder="Asset Location"
                          defaultValue={address}
                          {...register("assetLocation", { required: false })}
                          onChange={(event) =>
                            setAssetLocation(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <Label>Choose Logbook</Label>
                        <select
                          className="form-control"
                          name="logbook"
                          placeholder="Choose Logbook"
                          {...register("logbook", { required: true })}
                        >
                          <option value="">Select Logbook</option>
                          {logbookDataSiteFilter.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.logbookName}
                            </option>
                          ))}
                        </select>
                        <span>{errors.logbook && "Logbook is required"}</span>
                      </Col>
                      <Col md="6 mb-3">
                        <Label>Image</Label>
                        <Input
                          type="file"
                          className="form-control"
                          name="profilePic"
                          onChange={onSelectFile}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-3">
                        <hr className="m-t5 m-b5"></hr>
                      </Col>
                      {formInputs}
                    </Row>
                  </Col>
                  <Col md="12" className="text-end">
                    <Btn attrBtn={{ color: "primary" }}>Add Assets</Btn>
                    <Btn attrBtn={{ color: "danger", onClick: cancelForm }}>
                      Cancel
                    </Btn>
                  </Col>
                </Row>
              )}
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};
export default AddAssetsContain;
