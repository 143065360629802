import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AddEditTestingDetails from '../../../Component/UserPanel/Testing/AddEditTesting';

const AddEditTesting = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="User panel"/>
      <AddEditTestingDetails />
    </Fragment>
  );
};
export default AddEditTesting;

