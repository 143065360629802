import React, { Fragment,useState,useEffect,useCallback } from 'react';
import { H5,H4,H6, P } from '../../../AbstractElements';
import { Container, Row, Input, Table, CardHeader, CardFooter, Col, Card, InputGroup } from 'reactstrap';
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import '../../../assets/scss/index.less';
import { PlusCircle } from 'react-feather';
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Btn } from '../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FormGroup, Label, InputGroupAddon, InputGroupText} from 'reactstrap';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { toast } from 'react-toastify';
import awsExports from "../../../aws-exports";
import uuid from 'react-native-uuid';

Amplify.configure(awsExports);

const Testing = () => {

  const navigate = useNavigate();
  const { category } = useParams();
  const { register, handleSubmit, reset, formState: { errors }, e, ...form } = useForm();
  const [listofTesting, setlistofTesting] = useState([]);

const handleEdit = useCallback((row) => navigate(`/userpanel/addeditTesting/${category}/${row.id}`), []);

const handleView = useCallback((row) => navigate(`/userpanel/addeditTesting/${category}/${row.id}/view`), []);

//-----------------------Cancel-----------------------------------------------
  const handleCancel = (id) =>{
    if (window.confirm(`Are you sure?? Do you want to delete the Testing..?`)) {      
      try{
        const dataTesting = API.graphql({
        query: mutations.updateTesting,
        variables: {input: {
          id:id
        }},
        authMode: 'API_KEY'
        });
        toast.success('The Testing is Deleted Succesfully!..', {
          position: toast.POSITION.TOP_CENTER 
        });
        console.log(dataTesting);
        const refreshPage = () => {
          navigate(0);
        }      
      }
      catch(e){
          toast.error('Error While Deleting the Testing... '+ e.message, {
              position: toast.POSITION.TOP_CENTER
          });
      } 
    }
  };

//----------------------------List--------------------------------------

  useEffect(() => {

      async function listTestingDetails() {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const companyId = userDetails.companyDetailsId;
      const dataDetails = await API.graphql({
        query: queries.listTestings,
        authMode: 'API_KEY'
      });
      //const TestingDetails = dataDetails.data.listTestings.items;
      const TestingDetails = dataDetails.data.listTestings.items.filter(item => item.status ==='Active');

      setlistofTesting(TestingDetails);
    }
    listTestingDetails();
  }, []);

  //----------------------------Table--------------------------------------

  const tableHead = {
    Distributionbox: "Distributionbox",
    FittingId: "FittingId",
    Technician: "Technician",
    model:"model",
    serial:"serial",   
    Worrantyupto: "Worrantyupto",
    Action: "Action"
  };
      const [modal, setModal] = useState(false);
      const toggle = () => setModal(!modal);

      const [modalUpdate, setModalUpdate] = useState(false);
      const toggleUpdate = () => setModal(!modalUpdate);

      const countPerPage = 10;
      const [value, setValue] = React.useState("");
      const [currentPage, setCurrentPage] = React.useState(1);
      const [collection, setCollection] = React.useState(
        cloneDeep(listofTesting.slice(0, countPerPage))
      );
      const searchData = React.useRef(
        throttle(val => {
          const query = val.toLowerCase();
          setCurrentPage(1);
          const data = cloneDeep(
            listofTesting
              .filter(item => item.Distributionbox.toLowerCase().indexOf(query) > -1)
              .slice(0, countPerPage)
          );
          setCollection(data);
        }, 400)
      );
  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = p => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(listofTesting.slice(from, to)));
  };

  const tableRows = rowData => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      if(keyD === 'Action'){
        return <td key={i}> 
            <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete'}} ><i className="fa fa-trash-o"></i></Btn>
            <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'info', title: 'Edit'}} ><i className="fa fa-edit "></i></Btn>
            <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'primary', title: 'Activate'}} ><i className="fa fa-eye"></i> </Btn>
          </td>;
      }
      else{
        return <td key={i}>{key[keyD]}</td>;
      }
    });

    return <tr key={index}>{columnData}</tr>;
  };
  
  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };
  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };

//-----------------------------------------Display------------------------------------------
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader >
                <Row>
                  <Col md="6">
                    <InputGroup><span className="input-group-text"><i className="icon-search"></i></span>
                      <Input  type="search" placeholder="Search.." value={value}
                          onChange={e => setValue(e.target.value)} />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                              Import Testing
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Bulk Upload</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Download Asset Template</Dropdown.Item>
                            </Dropdown.Menu>
                    </Dropdown>
                    <Link  to={`/userpanel/addeditTesting/${category}`}> <Button>Add Testing</Button></Link>   
                 </Col>
                </Row>
              </CardHeader>  
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">        
                  <div className="table-responsive">       
                    <Table className='table-striped  table-de'>
                      <thead className='table-dark'>
                        <tr>
                          <th scope="col">Distribution Board</th>
                          <th scope="col">FittingId</th>
                          <th scope="col">electrician</th>
                          <th scope="col">testResult</th>
                          <th scope="col">warranty</th>
                          <th scope="col">testOn</th>
                          <th scope="col">nextTestSchedule</th>
                          <th scope="col">Action</th>
                      </tr>

                      </thead>
                      <tbody className="trhover" > 
                        {
                          listofTesting.map((item) => 
                            <tr>
                              <td>
                                {item.distributionBox}
                              </td>
                              <td>
                                {item.fittingId}
                              </td>
                              <td>
                                {item.electrician}
                              </td>
                              <td>
                                {item.testResult}
                              </td>
                              <td>
                                {item.warranty}
                              </td>
                              <td>
                                {item.testOn}
                              </td>
                              <td>
                                {item.nextTestSchedule}
                              </td>
                              <td>
                                <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete',  onClick: () => handleCancel(item.id)}} ><i className="fa fa-trash-o"></i></Btn>
                                <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'info', title: 'Edit',  onClick: () => handleEdit(item)}} ><i className="fa fa-edit "></i></Btn>
                                <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'primary', title: 'View',  onClick: () => handleView(item)}} ><i className="fa fa-eye"></i> </Btn>
                              </td>
                            </tr>
                          )
                        }
                        </tbody>
                    </Table>
                </div>
              </Col>
            </div>
              <CardFooter className="row">
                  <Pagination
                    className='p-t-10'
                    pageSize={countPerPage}
                    onChange={updatePage}
                    current={currentPage}
                    total={listofTesting.length} 
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    } 
                  />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Testing;