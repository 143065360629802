import React, { Fragment, useState, useEffect } from "react";
import { Btn, H3 } from "../../AbstractElements";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  CardHeader,
  Table,
  Button,
} from "reactstrap";
import Papa from "papaparse";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import moment from "moment";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  S3_BUCKET,
  REGION,
  IMPORT,
  FAILED,
  FILES,
  SECRETACCESSKEY,
  ACCESSKEYID,
  limit,
} from "../../Config/Config";
import uuid from "react-native-uuid";
const XLSX = require("xlsx");
var base64 = require("base-64");

const LogbookAssetDetails = (props) => {
  const navigate = useNavigate();
  const state = useLocation();
  const [fileData, setFileData] = useState([]);
  const [fileHeader, setFileHeader] = useState(null);
  const [tableColumnLength, setTableColumnLength] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [distributionBoard, setDistributionBoard] = useState([]);
  const [assetTemplateId, setAssetTemplateId] = useState([]);
  const [assetByTemplates, setAssetByTemplates] = useState([]);
  const [logbooks, setLogbooks] = useState([]);
  const [error, setError] = useState([]);
  const [filename, setFilename] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dbError, setDBError] = useState("");
  const [fileExt, setFileExt] = useState("");
  const [maxNum, setmaxNum] = useState(0);
  const [email, setEmail] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  async function fetchSiteDetailsAO() {
    try {
      let nextToken = "";
      let sites = [];
      const result = await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setSiteDetails(mergeSites.filter((item) => item.status === "Active"));
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setSiteDetails([]);
    }
  }

  useEffect(() => {
    console.log(state);
    console.log(props);

    async function fetchSiteDetailsSU() {
      await API.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: LoginUserDetails.id,
          limit: limit,
        },
      })
        .then(async (dataDetails) => {
          const siteDetails =
            dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
          if (siteDetails.length > 0) {
            const promises = siteDetails.map((item) => {
              return API.graphql({
                query: queries.getSiteDetails,
                variables: {
                  id: item.sitedetailsID,
                },
                authMode: "API_KEY",
              });
            });
            Promise.all(promises).then((results) => {
              console.log(results);
              if (results.length > 0) {
                const siteDetail = results.map((items) => {
                  if (items.data.getSiteDetails !== null) {
                    return items.data.getSiteDetails;
                  }
                });
                console.log(siteDetail);
                setSiteDetails(siteDetail);
                getAssetTemplate(siteDetail);
              } else {
                setIsLoading(false);
                setSiteDetails([]);
              }
            });
          } else {
            setIsLoading(false);
            setSiteDetails([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }

    const getAssetTemplate = async (siteData) => {
      await API.graphql({
        query: queries.assetTemplatesByCategoriesID,
        variables: {
          categoriesID: state.state.category.id,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
        authMode: "API_KEY",
      })
        .then((result) => {
          console.log(result.data.assetTemplatesByCategoriesID.items);
          setAssetTemplateId(result.data.assetTemplatesByCategoriesID.items);
          if (result.data.assetTemplatesByCategoriesID.items.length > 0) {
            console.log("siteDetails", siteDetails);
            getAssetsByTemplates(
              result.data.assetTemplatesByCategoriesID.items,
              siteData
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setAssetTemplateId([]);
        });
    };

    const getLogbooks = async () => {
      await API.graphql({
        query: queries.listLogBooks,
        variables: {
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
        authMode: "API_KEY",
      })
        .then((result) => {
          console.log(result);
          setLogbooks(result.data.listLogBooks.items);
        })
        .catch((error) => {
          console.log(error);
          setLogbooks([]);
        });
    };

    //--- email id to send mail
    const fetchEmailId = async () => {
      if (LoginUserGroup === "site_user") {
        await API.graphql({
          query: queries.getSPUserDetails,
          variables: {
            id: LoginUserDetails.id,
            limit: limit,
          },
          authMode: "API_KEY",
        })
          .then((result) => {
            console.log(result);
            const emaill = result.data.getSPUserDetails;
            console.log(emaill);
            setEmail(emaill.email);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await API.graphql({
          query: queries.getAssetOwner,
          variables: {
            id: LoginUserDetails.id,
            limit: limit,
          },
          authMode: "API_KEY",
        })
          .then((result) => {
            console.log(result);
            const emaill = result.data.getAssetOwner;
            console.log(emaill);
            setEmail(emaill.email);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    if (localStorage.getItem("site") === null && siteDetails.length === 0) {
      LoginUserGroup === "site_user"
        ? fetchSiteDetailsSU()
        : fetchSiteDetailsAO();
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSiteDetails(activeSites.filter((item) => item.status === "Active"));
      getAssetTemplate(activeSites.filter((item) => item.status === "Active"));
    }
    if (
      localStorage.getItem("siteDB") === null &&
      distributionBoard.length === 0
    ) {
      getDistributionBoard();
    } else {
      let DB = JSON.parse(base64.decode(localStorage.getItem("siteDB")));
      setDistributionBoard(DB);
    }
    getDistributionBoard();
    getLogbooks();
    fetchEmailId();
    fetchAssetsData();
  }, []);

  async function getDistributionBoard() {
    try {
      let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
      let activeSites = sites.filter((item) => item.status === "Active");
      if (activeSites.length > 0) {
        let nextToken = "";
        let db = [];
        for (let site of activeSites) {
          const DBResult = await API.graphql({
            query: queries.distributionBoxesBySitedetailsID,
            variables: {
              sitedetailsID: site.id,
            },
            authMode: "API_KEY",
          });
          let assetDatas = DBResult.data.distributionBoxesBySitedetailsID.items;
          nextToken = DBResult.data.distributionBoxesBySitedetailsID.nextToken;
          if (DBResult.data.distributionBoxesBySitedetailsID.items.length > 0) {
            db.push(assetDatas);
          }
          while (nextToken !== null) {
            const DBResult1 = await API.graphql({
              query: queries.distributionBoxesBySitedetailsID,
              variables: {
                sitedetailsID: site.id,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            nextToken =
              DBResult1.data.distributionBoxesBySitedetailsID.nextToken;
            if (
              DBResult1.data.distributionBoxesBySitedetailsID.items.length > 0
            ) {
              db.push(DBResult1.data.distributionBoxesBySitedetailsID.items);
            }
          }
        }
        if (db.length > 0) {
          const mergeDB = db.flat(1);
          console.log(mergeDB);
          setDistributionBoard(mergeDB);
          localStorage.setItem(
            "siteDB",
            base64.encode(JSON.stringify(mergeDB))
          );
        }
      } else {
        setDistributionBoard([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAssetsData() {
    await API.graphql({
      query: queries.assetsByTypeAndAssetNum,
      authMode: "API_KEY",
      variables: {
        limit: limit,
        type: "Assets",
        sortDirection: "DESC",
      },
    })
      .then((response) => {
        console.log(response);
        let maxnumber = 0;
        let assetsDetails = response.data.assetsByTypeAndAssetNum.items;
        if (assetsDetails.length > 0) {
          maxnumber = assetsDetails[0].assetNum;
          setmaxNum(parseInt(maxnumber) + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAssetsByTemplates(assetTemplates, siteData) {
    try {
      setIsLoading(true);
      let nextToken = "";
      let assets = [];
      let count = 0;
      assetTemplates.map((assetTemplate) => {
        count = count + 1;
        siteData.map(async (sites) => {
          let tempSiteId = assetTemplate.id + "_" + sites.id;
          await API.graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              limit: limit,
              assettemplateSiteID: tempSiteId,
              filetr: {
                status: {
                  ne: "delete",
                },
              },
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              let assetDatas =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
              nextToken =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
              console.log(
                "first token count",
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items.length
              );
              if (
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                  .length > 0
              ) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    limit: limit,
                    filetr: {
                      status: {
                        ne: "delete",
                      },
                    },
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken =
                      results.data.assetsByAssettemplateSiteIDAndAssetNum
                        .nextToken;
                    console.log(
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length
                    );
                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      assets.push(
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setAssetByTemplates([]);
                    setIsLoading(false);
                  });
              }
              console.log(assets);
            })
            .catch((error) => {
              console.log(error);
              setAssetByTemplates([]);
            });
          if (count === assetTemplates.length) {
            const mergeAssets = assets.flat(1); //The depth level specifying how deep a nested array structure should be flattened. Defaults to 1.
            console.log(mergeAssets);
            setAssetByTemplates(mergeAssets);
            setIsLoading(false);
          }
        });
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  let assetColumn = [
    "siteName",
    "distributionboard",
    "assetDescription",
    "assetLocation",
    "assetMake",
    "assetModel",
    "fittingNumber",
    "assettemplate",
    "assetWarrantyStart",
    "assetWarrantyEnd",
    "installedOn",
    "FittingId",
    "duplicate",
  ];

  const handleFileUpload = (event) => {
    try {
      setIsLoading(true);
      const file = event.target.files[0];
      if (file !== undefined) {
        setFilename(file);
        console.log(file);
        const extension = file.name.split(".").pop().toLowerCase();
        setFileExt(extension);
        var matchHeaderWithColumn = true;
        if (extension === "csv") {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              const rows = result.data;
              rows.map((obj, index) => {
                if (
                  index !==
                  rows.findIndex(
                    (o) =>
                      obj.fittingNumber === o.fittingNumber &&
                      obj.distributionboard === o.distributionboard &&
                      obj.siteName === o.siteName
                  )
                ) {
                  obj.duplicate =
                    "Duplicate Fitting Number for Site and Distribution Board.";
                }
              });
              console.log(rows);
              let assetData = rows;
              console.log(assetData);
              let db = [];
              try {
                setDBError("");
                assetData.map((item) => {
                  if (
                    item.distributionboard !== "" &&
                    item.distributionboard !== null
                  ) {
                    if (item.distributionboard.toLowerCase() !== "unknown") {
                      item.assetWarrantyStart = moment(
                        item.assetWarrantyStart
                      ).format("DD/MM/YYYY");
                      item.assetWarrantyEnd = moment(
                        item.assetWarrantyEnd
                      ).format("DD/MM/YYYY");
                      item.installedOn = moment(item.installedOn).format(
                        "DD/MM/YYYY"
                      );
                      db.push({
                        db: item.distributionboard.trim(),
                        site: item.siteName.trim(),
                      });
                    }
                  }
                });
                let uniqueChars = db
                  .map(JSON.stringify)
                  .reverse() // convert to JSON string the dbay content, then reverse it (to check from end to begining)
                  .filter(function (item, index, db) {
                    return db.indexOf(item, index + 1) === -1;
                  }) // check if there is any occurence of the item in whole array
                  .reverse()
                  .map(JSON.parse);
                console.log(uniqueChars);
                try {
                  const promises = uniqueChars.map((db) => {
                    var siteId = siteDetails.filter((site) => {
                      return (
                        site.siteName.toLowerCase().trim() ===
                        db.site.toLowerCase().trim()
                      );
                    });
                    let dbData = distributionBoard.filter((item) => {
                      return item.distributionBoxName !== null
                        ? item.distributionBoxName.toLowerCase() ===
                        db.db.toLowerCase() &&
                        siteId[0].id === item.sitedetailsID
                        : "";
                    });
                    console.log(dbData);
                    if (dbData.length === 0) {
                      var siteId = siteDetails.filter((site) => {
                        return (
                          site.siteName.toLowerCase().trim() ===
                          db.site.toLowerCase().trim()
                        );
                      });
                      return API.graphql({
                        query: mutations.createDistributionBox,
                        variables: {
                          input: {
                            distributionBoxName: db.db,
                            sitedetailsID: siteId[0].id,
                            status: "Active",
                          },
                        },
                        authMode: "API_KEY",
                      });
                    }
                  });
                  Promise.all(promises)
                    .then(async (results) => {
                      console.log(results);
                      await appendDistributionBoard(results);
                      // results.forEach(async (element) => {
                      //   const addedsite = await API.graphql({
                      //     query: queries.getDistributionBox,
                      //     variables: {
                      //       id: element.data.createDistributionBox.id,
                      //     },
                      //     authMode: "API_KEY",
                      //   });
                      //   if (addedsite.data.getDistributionBox !== null) {
                      //     if (localStorage.getItem("siteDB") !== null) {
                      //       let activeDB = JSON.parse(
                      //         base64.decode(localStorage.getItem("siteDB"))
                      //       );
                      //       console.log(activeDB);
                      //       activeDB.push(addedsite.data.getDistributionBox);
                      //       console.log(activeDB);
                      //       localStorage.setItem(
                      //         "siteDB",
                      //         base64.encode(JSON.stringify(activeDB))
                      //       );
                      //     }
                      //   }
                      // });
                      //getDistributionBoard();
                    })
                    .catch((error) => {
                      console.log(error);
                      setErrorMessage("Unable to fetch the list");
                    });
                } catch (e) {
                  console.log(e);
                  if (
                    state.state.category.categoryName === "Emergency Lights"
                  ) {
                    setDBError("disabled");
                    toast.error(
                      "Distribution Board Missing!!!!. Please check the data and import again.",
                      {
                        position: toast.POSITION.TOP_CENTER,
                      }
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              const headers = Object.keys(result.data[0]);
              headers.map((item) => {
                if (item !== null) {
                  if (!assetColumn.includes(item)) {
                    if (!item.includes("custom-")) {
                      matchHeaderWithColumn = false;
                    }
                  }
                }
                // else {
                //   matchHeaderWithColumn = false;
                // }
              });
              console.log(headers);
              console.log(rows);
              console.log(matchHeaderWithColumn);
              if (!matchHeaderWithColumn) {
                setFileData([]);
                setFileHeader([]);
                setTableColumnLength([]);
                setErrorMessage(!errorMessage);
                setIsLoading(false);
              } else {
                setFileData(assetData);
                setFileHeader(headers);
                setTableColumnLength(headers);
                if (errorMessage) {
                  setErrorMessage(!errorMessage);
                }
                setIsLoading(false);
              }
            },
            error: (error) => {
              console.log(error);
            },
          });
        } else if (extension === "xls" || extension === "xlsx") {
          const jsonOpts = {
            header: 1,
            blankrows: false,
            defval: null,
            raw: false,
            cellDates: true,
            dateNF: "mm/dd/YYYY",
          };
          const reader = new FileReader();
          reader.onload = (event) => {
            let data = new Uint8Array(event.target.result);
            // let data = event.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const rows = XLSX.utils.sheet_to_json(worksheet, jsonOpts);
            console.log("rows", rows);
            const excelData = rows.slice(1);
            const headers = rows.splice(0, 1)[0];
            headers.push("duplicate");
            console.log(headers);
            let importData = [];
            excelData.map((item) => {
              let excelObject = {};
              item.map((val, i) => {
                excelObject[headers[i]] = val;
              });
              if (Object.keys(excelObject).length > 0) {
                importData.push(excelObject);
              }
            });
            console.log("importData", importData);
            importData = importData.filter(
              (o) => !Object.keys(o).every((k) => !o[k])
            );
            console.log(
              importData.filter((o) => !Object.keys(o).every((k) => !o[k]))
            );
            importData.map((obj, index) => {
              if (
                index !==
                importData.findIndex(
                  (o) =>
                    obj.fittingNumber === o.fittingNumber &&
                    obj.distributionboard === o.distributionboard &&
                    obj.siteName === o.siteName
                )
              ) {
                obj.duplicate =
                  "Duplicate Fitting Number for Site and Distribution Board.";
              } else {
                obj.duplicate = "";
              }
            });
            console.log(importData);
            let assetData = [];
            assetData.push(Object.keys(importData[0]));
            importData.map((item) => {
              assetData.push(Object.values(item));
            });
            console.log(assetData);
            console.log(
              rows.filter((o) => !Object.keys(o).every((k) => !o[k]))
            );
            let db = [];
            try {
              setDBError("");
              assetData.map((item) => {
                if (
                  item[assetData[0].indexOf("distributionboard")] !==
                  "distributionboard" &&
                  item[assetData[0].indexOf("distributionboard")] !== null
                ) {
                  item[assetData[0].indexOf("assetWarrantyStart")] = moment(
                    item[assetData[0].indexOf("assetWarrantyStart")]
                  ).format("DD/MM/YYYY");
                  item[assetData[0].indexOf("assetWarrantyEnd")] = moment(
                    item[assetData[0].indexOf("assetWarrantyEnd")]
                  ).format("DD/MM/YYYY");
                  item[assetData[0].indexOf("installedOn")] = moment(
                    item[assetData[0].indexOf("installedOn")]
                  ).format("DD/MM/YYYY");

                  if (
                    item[assetData[0].indexOf("distributionboard")] !== null &&
                    item[
                      assetData[0].indexOf("distributionboard")
                    ].toLowerCase() !== "unknown"
                  ) {
                    db.push({
                      db: item[
                        assetData[0].indexOf("distributionboard")
                      ].trim(),
                      site:
                        item[assetData[0].indexOf("distributionboard") - 1] !==
                          null
                          ? item[
                            assetData[0].indexOf("distributionboard") - 1
                          ].trim()
                          : "",
                      cat:
                        item[assetData[0].indexOf("assettemplate")] !== null
                          ? item[assetData[0].indexOf("assettemplate")]
                            .toLowerCase()
                            .trim()
                          : "",
                    });
                  }
                }
              });
              let uniqueChars = db
                .map(JSON.stringify)
                .reverse()
                .filter(function (item, index, db) {
                  return db.indexOf(item, index + 1) === -1;
                })
                .reverse()
                .map(JSON.parse);
              console.log(uniqueChars);
              try {
                const promises = uniqueChars.map((db) => {
                  var siteId = siteDetails.filter((site) => {
                    return (
                      site.siteName.toLowerCase().trim() ===
                      db.site.toLowerCase().trim()
                    );
                  });
                  if (siteId.length > 0) {
                    let dbData = distributionBoard.filter((item) => {
                      return item.distributionBoxName !== null
                        ? item.distributionBoxName.toLowerCase() ===
                        db.db.toLowerCase() &&
                        siteId[0].id === item.sitedetailsID
                        : "";
                    });
                    console.log(dbData);
                    if (dbData.length === 0) {
                      var siteId = siteDetails.filter((site) => {
                        return (
                          site.siteName.toLowerCase().trim() ===
                          db.site.toLowerCase().trim()
                        );
                      });
                      return API.graphql({
                        query: mutations.createDistributionBox,
                        variables: {
                          input: {
                            distributionBoxName: db.db,
                            sitedetailsID: siteId[0].id,
                            status: "Active",
                          },
                        },
                        authMode: "API_KEY",
                      });
                    }
                  }
                });
                Promise.all(promises)
                  .then(async (results) => {
                    console.log(results);
                    await appendDistributionBoard(results);
                  })
                  .catch((error) => {
                    console.log(error);
                    setErrorMessage("Unable to fetch the list");
                  });
              } catch (e) {
                console.log(e);
                if (state.state.category.categoryName === "Emergency Lights") {
                  setDBError("disabled");
                  toast.error(
                    "Distribution Board Missing!!!!. Please check the data and import again.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                    }
                  );
                }
              }
            } catch (error) {
              console.log(error);
            }
            var matchHeaderWithColumn = true;
            headers.map((item) => {
              if (item !== null) {
                if (!assetColumn.includes(item)) {
                  if (!item.includes("custom-")) {
                    matchHeaderWithColumn = false;
                  }
                }
              }
              // else {
              //   matchHeaderWithColumn = false;
              // }
            });
            if (!matchHeaderWithColumn) {
              setFileData([]);
              setFileHeader([]);
              setTableColumnLength([]);
              setErrorMessage(!errorMessage);
              setIsLoading(false);
            } else {
              console.log(assetData.slice(1));
              setFileData(assetData.slice(1));
              setFileHeader(headers);
              setTableColumnLength(headers);
              if (errorMessage) {
                setErrorMessage(!errorMessage);
              }
              setIsLoading(false);
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
          setIsLoading(false);
          alert("Unsupported file format");
        }
      } else {
        setFileData([]);
        setFileHeader([]);
        setTableColumnLength([]);
        setErrorMessage(!errorMessage);
        setIsLoading(false);
        toast.info("File not selected", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  async function appendDistributionBoard(params) {
    try {
      for (let db of params) {
        const addedsite = await API.graphql({
          query: queries.getDistributionBox,
          variables: {
            id: db.data.createDistributionBox.id,
          },
          authMode: "API_KEY",
        });
        if (addedsite.data.getDistributionBox !== null) {
          if (localStorage.getItem("siteDB") !== null) {
            let activeDB = JSON.parse(
              base64.decode(localStorage.getItem("siteDB"))
            );
            console.log(activeDB);
            activeDB.push(addedsite.data.getDistributionBox);
            console.log(activeDB);
            setDistributionBoard(activeDB);
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(activeDB))
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getDistributionBoardId = (db, siteId) => {
    try {
      const dbCount = distributionBoard.filter((item) => {
        return item.distributionBoxName !== null
          ? item.distributionBoxName.toLowerCase() === db.toLowerCase() &&
          item.sitedetailsID === siteId
          : "";
      });
      console.log(dbCount);
      if (dbCount.length > 0) {
        return dbCount[0].id;
      } else {
        return "";
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  };
  const [importlogid, setImportlogid] = useState("");
  const handleSave = async () => {
    let siteIDs = "";
    setIsLoading(true);
    //-- appending todays date and time to error file
    const dateTime = new Date().toISOString();
    console.log(dateTime);

    let errorfileurl = [];

    //--save file to s3 bucket--
    console.log(filename);

    //const file = e.target.files[0];
    const params = {
      ACL: "public-read",
      Body: filename,
      Bucket: S3_BUCKET,
      Key: `public/import/${FILES}/${filename.name}`,
    };
    await myBucket
      .putObject(params)
      .on("httpUploadProgress", async (evt) => {
        console.log(evt);
        const sourcefilelocation = [];
        sourcefilelocation.push({
          fileName: filename.name,
          type: filename.type,
        });
        console.log(sourcefilelocation);

        //---cretating import log table
        await API.graphql({
          query: mutations.createImportLogs,
          variables: {
            input: {
              dataType: "Assets",
              sourceFileLocation: sourcefilelocation,
              impotedBy: LoginUserDetails.id,
              //siteId: data.dueDate,
              categoryId: state.state.category.id,
            },
          },
          authMode: "API_KEY",
        })
          .then((result) => {
            console.log(result);
            setImportlogid(result.data.createImportLogs.id);
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error While Adding Task: " + error, {
              position: toast.POSITION.TOP_CENTER,
            });
          });
        //---file save to s3 bucket
      })
      .send((err) => {
        if (err) console.log(err);
      });

    try {
      if (tableColumnLength.length === 0) {
        toast.error("Please choose to import the selected file.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
        setIsLoading(false);
        return;
      }
      const insetArray = [];
      tableColumnLength.map((val) => {
        if (!assetColumn.includes(val)) {
          insetArray.push({ col: "formFieldValues", data: val });
        } else {
          insetArray.push({ col: val, data: val });
        }
      });
      console.log(insetArray);
      console.log("fileData.length", fileData.length);
      if (fileData && fileData.length > 0) {
        let errorCount = 0;
        let successCount = 0;
        let dataCount = 0;
        let errorData = [];
        let incrementnum = maxNum;
        let num = 0;
        let batchRecord = [];
        fileData.map(async (row, i) => {
          console.log("fileData.length i", i);
          const columnDetails = {};
          let csvArray = {};
          const customFieldsArray = [];
          if (incrementnum !== 0 && i === 0) {
            console.log("incrementnum", incrementnum);
          } else {
            num = parseInt(incrementnum) + 1;
            incrementnum = num;
          }
          if (fileExt === "csv") {
            insetArray.forEach(async (itemData) => {
              var columnName = itemData.data;
              if (row.siteName !== null) {
                columnDetails["id"] = uuid.v4();
                // columnDetails["createdAt"] = new Date().toISOString();
                // columnDetails["updatedAt"] = new Date().toISOString();
                // columnDetails["__typename"] = "Assets";
                let customFields = {};
                if (itemData.col === "siteName" && row[columnName] !== null) {
                  var siteId = siteDetails.filter((site) => {
                    return (
                      site.siteName.toLowerCase().trim() ===
                      row[columnName].toLowerCase().trim()
                    );
                  });
                  if (siteId.length > 0) {
                    columnDetails["siteId"] = siteId[0].id;
                    columnDetails["sitedetailsID"] = siteId[0].id;
                    siteIDs = siteId[0].id;

                    var logbookId = logbooks.filter((item) => {
                      return item.sitedetailsID === siteId[0].id;
                    });
                    columnDetails["logbookId"] = logbookId[0].id;
                    columnDetails["assetNumber"] =
                      state.state.category.assetNumberPrefix + incrementnum;
                    columnDetails["assetOwnerID"] = siteId[0].assetownerID;
                  }
                }
                if (
                  itemData.col === "assettemplate" &&
                  row[columnName] !== null
                ) {
                  var assets = assetTemplateId.filter((temp) => {
                    return (
                      temp.templatename.toLowerCase() ===
                      row[columnName].toLowerCase().trim()
                    );
                  });
                  columnDetails["assettemplateID"] =
                    assets.length > 0 ? assets[0].id : "";
                  columnDetails["assetType"] = row[columnName].trim();
                  columnDetails["assettemplateSiteID"] =
                    columnDetails["assettemplateID"] +
                    "_" +
                    columnDetails["siteId"];
                }
                if (itemData.col === "distributionboard") {
                  const dbCount = distributionBoard.filter((item) => {
                    return item.distributionBoxName !== null
                      ? item.distributionBoxName.toLowerCase() ===
                      row[columnName].toLowerCase().trim() &&
                      item.sitedetailsID === columnDetails["siteId"]
                      : "";
                  });
                  if (dbCount.length > 0) {
                    columnDetails["distributionboxID"] = dbCount[0].id;
                  } else {
                    columnDetails["distributionboxID"] = "";
                  }
                }
                if (itemData.col === "formFieldValues") {
                  var label = itemData.data.split("-");
                  customFields[label[1].toLowerCase().replace(/\s/g, "")] =
                    row[columnName];
                }

                if (Object.keys(customFields).length > 0) {
                  customFieldsArray.push(customFields);
                }
                if (
                  itemData.col === "assetWarrantyStart" ||
                  itemData.col === "assetWarrantyEnd" ||
                  itemData.col === "installedOn"
                ) {
                  if (
                    row[columnName] !== null &&
                    row[columnName] !== "Invalid Date"
                  ) {
                    let newDt = new Date(row[columnName]);
                    if (moment(newDt).format("YYYY-MM-DD") === "Invalid date") {
                      newDt = new Date("01/01/1970");
                      row[columnName] = moment(newDt).format("YYYY-MM-DD");
                    } else {
                      row[columnName] = moment(row[columnName]).format(
                        "YYYY-MM-DD"
                      );
                    }
                  } else {
                    let newDt = new Date("01/01/1970");
                    row[columnName] = moment(newDt).format("YYYY-MM-DD");
                  }
                }
                if (
                  itemData.col !== "siteName" &&
                  itemData.col !== "distributionboard" &&
                  itemData.col !== "assettemplate"
                ) {
                  columnDetails[itemData.col] =
                    itemData.col === "formFieldValues"
                      ? JSON.stringify(customFieldsArray)
                      : row[columnName];
                }
                columnDetails["status"] = "Active";
                columnDetails["type"] = "Assets";
                columnDetails["assetNum"] = incrementnum;
                columnDetails["createdBy"] = LoginUserDetails.id;
                columnDetails["source"] =
                  LoginUserGroup === "site_owner"
                    ? "AO" + "-import"
                    : LoginUserGroup === "site_user"
                      ? "SU" + "-import"
                      : "SP" + "-import";
              }
            });
          } else {
            insetArray.forEach(async (itemData) => {
              if (row[0] !== null && row[1] !== null) {
                columnDetails["id"] = uuid.v4();
                // columnDetails["createdAt"] = new Date().toISOString();
                // columnDetails["updatedAt"] = new Date().toISOString();
                // columnDetails["__typename"] = "Assets";
                let customFields = {};
                if (
                  itemData.col === "siteName" &&
                  row[fileHeader.indexOf(itemData.data)] !== null
                ) {
                  var siteId = siteDetails.filter((site) => {
                    return (
                      site.siteName.toLowerCase().trim() ===
                      row[fileHeader.indexOf(itemData.data)]
                        .toLowerCase()
                        .trim()
                    );
                  });
                  if (siteId.length > 0) {
                    columnDetails["siteId"] = siteId[0].id;
                    columnDetails["sitedetailsID"] = siteId[0].id;
                    siteIDs = siteId[0].id;

                    var logbookId = logbooks.filter((item) => {
                      return item.sitedetailsID === siteId[0].id;
                    });
                    columnDetails["logbookId"] = logbookId[0].id;
                    columnDetails["assetNumber"] =
                      state.state.category.assetNumberPrefix + incrementnum;
                    columnDetails["assetOwnerID"] = siteId[0].assetownerID;
                  }
                }
                if (itemData.col === "assettemplate") {
                  if (row[fileHeader.indexOf(itemData.data)] !== null) {
                    var assets = assetTemplateId.filter((temp) => {
                      return (
                        temp.templatename.toLowerCase() ===
                        row[fileHeader.indexOf(itemData.data)]
                          .toLowerCase()
                          .trim()
                      );
                    });
                    columnDetails["assettemplateID"] =
                      assets.length > 0 ? assets[0].id : "";
                    columnDetails["assetType"] =
                      row[fileHeader.indexOf(itemData.data)].trim();
                    columnDetails["assettemplateSiteID"] =
                      columnDetails["assettemplateID"] +
                      "_" +
                      columnDetails["siteId"];
                  } else {
                    columnDetails["assettemplateID"] = "";
                    columnDetails["assetType"] = "";
                  }
                }
                if (itemData.col === "distributionboard") {
                  columnDetails["distributionboxID"] = "";
                  if (
                    row[fileHeader.indexOf(itemData.data)] !== null &&
                    row[fileHeader.indexOf(itemData.data)]
                      .toLowerCase()
                      .trim() !== "unknown"
                  ) {
                    const dbCount = distributionBoard.filter((item) => {
                      return item.distributionBoxName !== null
                        ? item.distributionBoxName.toLowerCase() ===
                        row[fileHeader.indexOf(itemData.data)]
                          .toLowerCase()
                          .trim() &&
                        item.sitedetailsID === columnDetails["siteId"]
                        : "";
                    });
                    if (dbCount.length > 0) {
                      columnDetails["distributionboxID"] = dbCount[0].id;
                    }
                  } else {
                    columnDetails["distributionboxID"] = "";
                  }
                }
                if (itemData.col === "formFieldValues") {
                  var label = itemData.data.split("-");
                  customFields[label[1].toLowerCase().replace(/\s/g, "")] =
                    row[fileHeader.indexOf(itemData.data)];
                }

                if (Object.keys(customFields).length > 0) {
                  customFieldsArray.push(customFields);
                }
                if (
                  itemData.col === "assetWarrantyStart" ||
                  itemData.col === "assetWarrantyEnd" ||
                  itemData.col === "installedOn"
                ) {
                  if (
                    row[fileHeader.indexOf(itemData.data)] !== null &&
                    row[fileHeader.indexOf(itemData.data)] !== "Invalid Date"
                  ) {
                    let newDt = new Date(
                      row[fileHeader.indexOf(itemData.data)]
                    );
                    if (moment(newDt).format("YYYY-MM-DD") === "Invalid date") {
                      newDt = new Date("01/01/1970");
                      row[fileHeader.indexOf(itemData.data)] =
                        moment(newDt).format("YYYY-MM-DD");
                    } else {
                      row[fileHeader.indexOf(itemData.data)] = moment(
                        row[fileHeader.indexOf(itemData.data)]
                      ).format("YYYY-MM-DD");
                    }
                  } else {
                    let newDt = new Date("01/01/1970");
                    row[fileHeader.indexOf(itemData.data)] =
                      moment(newDt).format("YYYY-MM-DD");
                  }
                }
                if (
                  itemData.col !== "siteName" &&
                  itemData.col !== "distributionboard" &&
                  itemData.col !== "assettemplate"
                ) {
                  columnDetails[itemData.col] =
                    itemData.col === "formFieldValues"
                      ? JSON.stringify(customFieldsArray)
                      : row[fileHeader.indexOf(itemData.data)] !== null
                        ? row[fileHeader.indexOf(itemData.data)].trim()
                        : "";
                }
                columnDetails["status"] = "Active";
                columnDetails["type"] = "Assets";
                columnDetails["assetNum"] = incrementnum;
                columnDetails["createdBy"] = LoginUserDetails.id;
                columnDetails["source"] =
                  LoginUserGroup === "site_owner"
                    ? "AO" + "-import"
                    : LoginUserGroup === "site_user"
                      ? "SU" + "-import"
                      : "SP" + "-import";
              }
            });
          }
          dataCount = dataCount + 1;
          if (Object.keys(columnDetails).length > 0) {
            if (
              columnDetails["siteId"] !== null &&
              columnDetails["siteId"] !== undefined
            ) {
              if (
                columnDetails["assetType"] !== undefined &&
                columnDetails["assetType"].toLowerCase().trim() ===
                "emergency lights" &&
                columnDetails["distributionboxID"] !== "" &&
                columnDetails["assettemplateID"] !== ""
              ) {
                console.log("after fitting number check", columnDetails);
                if (
                  columnDetails["fittingNumber"] !== null &&
                  columnDetails["fittingNumber"] !== undefined
                ) {
                  batchRecord.push(columnDetails);
                } else {
                  //fitting number not defined
                  if (fileExt === "csv") {
                    csvArray = row;
                    csvArray["Error Message"] =
                      "Missing or undefined fitting number";
                    console.log("csvArray", csvArray);
                    errorData.push(csvArray);
                  } else {
                    tableColumnLength.map((key, index) => {
                      csvArray[key] = row[index];
                    });
                    csvArray["Error Message"] =
                      "Missing or undefined fitting number";
                    console.log("csvArray", csvArray);
                    errorData.push(csvArray);
                  }
                  setError(errorData);
                  console.log(errorData);
                  errorCount = errorCount + 1;
                  if (fileData.length === successCount + errorCount) {
                    setIsLoading(false);
                    toast.error(
                      "No Data To SavePlease check the data before importing.Check the Error File in Downloads.",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: false,
                      }
                    );
                    //--updating success count and error count to importlog table
                    API.graphql({
                      query: mutations.updateImportLogs,
                      variables: {
                        limit: 10000,
                        input: {
                          id: importlogid,
                          //siteId: columnDetails["siteId"],
                          successCount: successCount,
                          failedCount: errorCount,
                        },
                      },
                      authMode: "API_KEY",
                    })
                      .then((response) => {
                        console.log(response);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                    if (errorCount > 0) {
                      try {
                        // Convert the data to CSV format using PapaParse
                        const csv = Papa.unparse(errorData);
                        // Create a Blob containing the CSV data
                        const csvBlob = new Blob([csv], {
                          type: "text/csv",
                        });
                        // Create a URL for the Blob
                        const csvUrl = URL.createObjectURL(csvBlob);
                        // Create an invisible link element to trigger the download
                        const link = document.createElement("a");
                        link.href = csvUrl;
                        link.download = "logbookImportErrorData.csv";
                        link.click();
                        // Clean up by revoking the URL to release resources
                        URL.revokeObjectURL(csvUrl);

                        const logbookImportErrorData = "logbookImportErrorData";
                        const filenameWithDateTime = `${logbookImportErrorData}_${dateTime}.csv`;
                        //--- saving failed file to s3 bucket
                        const params = {
                          ACL: "public-read",
                          Body: csv,
                          Bucket: S3_BUCKET,
                          Key: `public/import/${FAILED}/${filenameWithDateTime}`,
                        };
                        myBucket
                          .putObject(params)
                          .on("httpUploadProgress", (evt) => {
                            console.log(evt);
                            const filedetails = [];
                            filedetails.push({
                              fileName: filenameWithDateTime,
                              type: ".csv",
                            });

                            errorfileurl = `https://${S3_BUCKET}.s3.amazonaws.com/public/import/${FAILED}/${filenameWithDateTime}`;
                            //---sending mail to email
                            const emailData = {
                              successCount: successCount,
                              errorCount: errorCount,
                              category: state.state.category.categoryName,
                            };

                            console.log(emailData);
                            sendEmail(email, filename, errorfileurl, emailData);
                            //---end of mail sending

                            //--updating errorFileLocation to importlog table
                            API.graphql({
                              query: mutations.updateImportLogs,
                              variables: {
                                limit: limit,
                                input: {
                                  id: importlogid,
                                  errorFileLocation: filedetails,
                                },
                              },
                              authMode: "API_KEY",
                            })
                              .then((response) => {
                                console.log(response);
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          })
                          .send((err) => {
                            if (err) console.log(err);
                          });
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }
                }
              } else if (
                columnDetails["assetType"] !== undefined &&
                columnDetails["assetType"].toLowerCase().trim() !==
                "emergency lights" &&
                columnDetails["assetType"] !== "" &&
                columnDetails["assettemplateID"] !== ""
              ) {
                let checkFittingNumberDuplicate = [];
                if (columnDetails["distributionboxID"] !== "") {
                  checkFittingNumberDuplicate = assetByTemplates?.filter(
                    (item) =>
                      item.fittingNumber === columnDetails["fittingNumber"] &&
                      item.sitedetailsID === columnDetails["siteId"] &&
                      item.distributionboxID ===
                      columnDetails["distributionboxID"] &&
                      item.status !== "delete"
                  );
                } else {
                  checkFittingNumberDuplicate = assetByTemplates?.filter(
                    (item) =>
                      item.fittingNumber === columnDetails["fittingNumber"] &&
                      item.sitedetailsID === columnDetails["siteId"] &&
                      item.status !== "delete"
                  );
                }
                if (checkFittingNumberDuplicate.length === 0) {
                  console.log("after fitting number check", columnDetails);
                  if (
                    columnDetails["fittingNumber"] !== null &&
                    columnDetails["fittingNumber"] !== undefined
                  ) {
                    batchRecord.push(columnDetails);
                  } else {
                    //fitting number not defined
                    if (fileExt === "csv") {
                      csvArray = row;
                      csvArray["Error Message"] =
                        "Missing or undefined fitting number";
                      console.log("csvArray", csvArray);
                      errorData.push(csvArray);
                    } else {
                      tableColumnLength.map((key, index) => {
                        csvArray[key] = row[index];
                      });
                      csvArray["Error Message"] =
                        "Missing or undefined fitting number";
                      console.log("csvArray", csvArray);
                      errorData.push(csvArray);
                    }
                    setError(errorData);
                    console.log(errorData);
                    errorCount = errorCount + 1;
                    if (fileData.length === successCount + errorCount) {
                      setIsLoading(false);
                      toast.error(
                        "No Data To SavePlease check the data before importing.Check the Error File in Downloads.",
                        {
                          position: toast.POSITION.TOP_CENTER,
                          autoClose: false,
                        }
                      );
                      //--updating success count and error count to importlog table
                      API.graphql({
                        query: mutations.updateImportLogs,
                        variables: {
                          limit: limit,
                          input: {
                            id: importlogid,
                            //siteId: columnDetails["siteId"],
                            successCount: successCount,
                            failedCount: errorCount,
                          },
                        },
                        authMode: "API_KEY",
                      })
                        .then((response) => {
                          console.log(response);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                      if (errorCount > 0) {
                        try {
                          // Convert the data to CSV format using PapaParse
                          const csv = Papa.unparse(errorData);
                          // Create a Blob containing the CSV data
                          const csvBlob = new Blob([csv], {
                            type: "text/csv",
                          });
                          // Create a URL for the Blob
                          const csvUrl = URL.createObjectURL(csvBlob);
                          // Create an invisible link element to trigger the download
                          const link = document.createElement("a");
                          console.log(link);
                          link.href = csvUrl;
                          link.download = "logbookImportErrorData.csv";
                          link.click();
                          console.log(link);
                          // Clean up by revoking the URL to release resources
                          URL.revokeObjectURL(csvUrl);

                          const logbookImportErrorData =
                            "logbookImportErrorData";
                          const filenameWithDateTime = `${logbookImportErrorData}_${dateTime}.csv`;
                          //--- saving failed file to s3 bucket
                          const params = {
                            ACL: "public-read",
                            Body: csv,
                            Bucket: S3_BUCKET,
                            Key: `public/import/${FAILED}/${filenameWithDateTime}`,
                          };
                          myBucket
                            .putObject(params)
                            .on("httpUploadProgress", (evt) => {
                              console.log(evt);
                              const filedetails = [];
                              filedetails.push({
                                fileName: filenameWithDateTime,
                                type: ".csv",
                              });

                              errorfileurl = `https://${S3_BUCKET}.s3.amazonaws.com/public/import/${FAILED}/${filenameWithDateTime}`;
                              //---sending mail to email
                              const emailData = {
                                successCount: successCount,
                                errorCount: errorCount,
                                category: state.state.category.categoryName,
                              };

                              console.log(emailData);
                              sendEmail(
                                email,
                                filename,
                                errorfileurl,
                                emailData
                              );
                              //---end of mail sending

                              //--updating errorFileLocation to importlog table
                              API.graphql({
                                query: mutations.updateImportLogs,
                                variables: {
                                  limit: limit,
                                  input: {
                                    id: importlogid,
                                    errorFileLocation: filedetails,
                                  },
                                },
                                authMode: "API_KEY",
                              })
                                .then((response) => {
                                  console.log(response);
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            })
                            .send((err) => {
                              if (err) console.log(err);
                            });
                        } catch (error) {
                          console.log(error);
                        }
                      }
                    }
                  }
                } else {
                  //duplicate fitting number
                  if (fileExt === "csv") {
                    csvArray = row;
                    csvArray["Error Message"] = "duplicate fitting number";
                    console.log("csvArray", csvArray);
                    errorData.push(csvArray);
                  } else {
                    tableColumnLength.map((key, index) => {
                      csvArray[key] = row[index];
                    });
                    csvArray["Error Message"] = "duplicate fitting number";
                    console.log("csvArray", csvArray);
                    errorData.push(csvArray);
                  }
                  setError(errorData);
                  console.log(errorData);
                  errorCount = errorCount + 1;
                  if (fileData.length === successCount + errorCount) {
                    setIsLoading(false);
                    toast.error(
                      "No Data To SavePlease check the data before importing.Check the Error File in Downloads.",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: false,
                      }
                    );
                    //--updating success count and error count to importlog table
                    API.graphql({
                      query: mutations.updateImportLogs,
                      variables: {
                        limit: limit,
                        input: {
                          id: importlogid,
                          //siteId: columnDetails["siteId"],
                          successCount: successCount,
                          failedCount: errorCount,
                        },
                      },
                      authMode: "API_KEY",
                    })
                      .then((response) => {
                        console.log(response);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                    if (errorCount > 0) {
                      try {
                        // Convert the data to CSV format using PapaParse
                        const csv = Papa.unparse(errorData);
                        // Create a Blob containing the CSV data
                        const csvBlob = new Blob([csv], {
                          type: "text/csv",
                        });
                        // Create a URL for the Blob
                        const csvUrl = URL.createObjectURL(csvBlob);
                        // Create an invisible link element to trigger the download
                        const link = document.createElement("a");
                        console.log(link);
                        link.href = csvUrl;
                        link.download = "logbookImportErrorData.csv";
                        link.click();
                        console.log(link);
                        // Clean up by revoking the URL to release resources
                        URL.revokeObjectURL(csvUrl);

                        //--- saving failed file to s3 bucket
                        const logbookImportErrorData = "logbookImportErrorData";
                        const filenameWithDateTime = `${logbookImportErrorData}_${dateTime}.csv`;
                        const params = {
                          ACL: "public-read",
                          Body: csv,
                          Bucket: S3_BUCKET,
                          Key: `public/import/${FAILED}/${filenameWithDateTime}`,
                        };
                        myBucket
                          .putObject(params)
                          .on("httpUploadProgress", (evt) => {
                            console.log(evt);
                            const filedetails = [];
                            filedetails.push({
                              fileName: filenameWithDateTime,
                              type: ".csv",
                            });

                            errorfileurl = `https://${S3_BUCKET}.s3.amazonaws.com/public/import/${FAILED}/${filenameWithDateTime}`;
                            //---sending mail to email
                            const emailData = {
                              successCount: successCount,
                              errorCount: errorCount,
                              category: state.state.category.categoryName,
                            };

                            console.log(emailData);
                            sendEmail(email, filename, errorfileurl, emailData);
                            //---end of mail sending

                            //--updating errorFileLocation to importlog table
                            API.graphql({
                              query: mutations.updateImportLogs,
                              variables: {
                                limit: limit,
                                input: {
                                  id: importlogid,
                                  errorFileLocation: filedetails,
                                },
                              },
                              authMode: "API_KEY",
                            })
                              .then((response) => {
                                console.log(response);
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          })
                          .send((err) => {
                            if (err) console.log(err);
                          });
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }
                }
              } else { //missing / wrong DB               
                if (fileExt === "csv") {
                  csvArray = row;
                  csvArray["Error Message"] =
                    columnDetails["assettemplateID"] === ""
                      ? "missing / wrong Asset Template"
                      : "missing / wrong Distribution Board";
                  console.log("csvArray", csvArray);
                  errorData.push(csvArray);
                } else {
                  tableColumnLength.map((key, index) => {
                    csvArray[key] = row[index];
                  });
                  csvArray["Error Message"] =
                    columnDetails["assettemplateID"] === ""
                      ? "missing / wrong Asset Template"
                      : "missing / wrong Distribution Board";
                  console.log("csvArray", csvArray);
                }
                errorData.push(csvArray);
                setError(errorData);
                console.log(errorData);
                errorCount = errorCount + 1;
                if (fileData.length === successCount + errorCount) {
                  setIsLoading(false);
                  toast.error(
                    "No Data To SavePlease check the data before importing.Check the Error File in Downloads.",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: false,
                    }
                  );
                  //--updating success count and error count to importlog table
                  API.graphql({
                    query: mutations.updateImportLogs,
                    variables: {
                      limit: limit,
                      input: {
                        id: importlogid,
                        //siteId: columnDetails["siteId"],
                        successCount: successCount,
                        failedCount: errorCount,
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then((response) => {
                      console.log(response);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  if (errorCount > 0) {
                    try {
                      // Convert the data to CSV format using PapaParse
                      const csv = Papa.unparse(errorData);
                      // Create a Blob containing the CSV data
                      const csvBlob = new Blob([csv], {
                        type: "text/csv",
                      });
                      // Create a URL for the Blob
                      const csvUrl = URL.createObjectURL(csvBlob);
                      // Create an invisible link element to trigger the download
                      const link = document.createElement("a");
                      console.log(link);
                      link.href = csvUrl;
                      link.download = "logbookImportErrorData.csv";
                      link.click();
                      console.log(link);
                      // Clean up by revoking the URL to release resources
                      URL.revokeObjectURL(csvUrl);

                      //--- saving failed file to s3 bucket
                      const logbookImportErrorData = "logbookImportErrorData";
                      const filenameWithDateTime = `${logbookImportErrorData}_${dateTime}.csv`;
                      const params = {
                        ACL: "public-read",
                        Body: csv,
                        Bucket: S3_BUCKET,
                        Key: `public/import/${FAILED}/${filenameWithDateTime}`,
                      };
                      myBucket
                        .putObject(params)
                        .on("httpUploadProgress", (evt) => {
                          console.log(evt);
                          const filedetails = [];
                          filedetails.push({
                            fileName: filenameWithDateTime,
                            type: ".csv",
                          });

                          errorfileurl = `https://${S3_BUCKET}.s3.amazonaws.com/public/import/${FAILED}/${filenameWithDateTime}`;
                          //---sending mail to email
                          const emailData = {
                            successCount: successCount,
                            errorCount: errorCount,
                            category: state.state.category.categoryName,
                          };

                          console.log(emailData);
                          sendEmail(email, filename, errorfileurl, emailData);
                          //---end of mail sending

                          //--updating errorFileLocation to importlog table
                          API.graphql({
                            query: mutations.updateImportLogs,
                            variables: {
                              limit: limit,
                              input: {
                                id: importlogid,
                                errorFileLocation: filedetails,
                              },
                            },
                            authMode: "API_KEY",
                          })
                            .then((response) => {
                              console.log(response);
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        })
                        .send((err) => {
                          if (err) console.log(err);
                        });
                    } catch (error) {
                      console.log(error);
                    }
                  }
                }
              }
            } else {
              //missing / wrong site name
              if (fileExt === "csv") {
                csvArray = row;
                csvArray["Error Message"] = "missing / wrong site name";
                console.log("csvArray", csvArray);
                errorData.push(csvArray);
              } else {
                tableColumnLength.map((key, index) => {
                  csvArray[key] = row[index];
                });
                csvArray["Error Message"] = "missing / wrong site name";
                console.log("csvArray", csvArray);
              }
              errorData.push(csvArray);
              setError(errorData);
              console.log(errorData);
              errorCount = errorCount + 1;
              if (fileData.length === successCount + errorCount) {
                setIsLoading(false);
                toast.error(
                  "No Data To SavePlease check the data before importing.Check the Error File in Downloads.",
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                  }
                );

                console.log("site", columnDetails);
                //--updating success count and error count to importlog table
                API.graphql({
                  query: mutations.updateImportLogs,
                  variables: {
                    limit: limit,
                    input: {
                      id: importlogid,
                      //siteId: columnDetails["siteId"],
                      successCount: successCount,
                      failedCount: errorCount,
                    },
                  },
                  authMode: "API_KEY",
                })
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                if (errorCount > 0) {
                  try {
                    // Convert the data to CSV format using PapaParse
                    const csv = Papa.unparse(errorData);
                    console.log(csv);
                    // Create a Blob containing the CSV data
                    const csvBlob = new Blob([csv], { type: "text/csv" });
                    // Create a URL for the Blob
                    const csvUrl = URL.createObjectURL(csvBlob);
                    console.log(csvUrl);
                    // Create an invisible link element to trigger the download
                    const link = document.createElement("a");
                    console.log(link);
                    link.href = csvUrl;
                    link.download = "logbookImportErrorData.csv";
                    link.click();
                    console.log(link);
                    // Clean up by revoking the URL to release resources
                    URL.revokeObjectURL(csvUrl);

                    const logbookImportErrorData = "logbookImportErrorData";
                    const filenameWithDateTime = `${logbookImportErrorData}_${dateTime}.csv`;
                    //--- saving failed file to s3 bucket
                    const params = {
                      ACL: "public-read",
                      Body: csv,
                      Bucket: S3_BUCKET,
                      Key: `public/import/${FAILED}/${filenameWithDateTime}`,
                    };
                    myBucket
                      .putObject(params)
                      .on("httpUploadProgress", (evt) => {
                        console.log(evt);
                        const filedetails = [];
                        filedetails.push({
                          fileName: filenameWithDateTime,
                          type: ".csv",
                        });

                        errorfileurl = `https://${S3_BUCKET}.s3.amazonaws.com/public/import/${FAILED}/${filenameWithDateTime}`;
                        //---sending mail to email
                        const emailData = {
                          successCount: successCount,
                          errorCount: errorCount,
                          category: state.state.category.categoryName,
                        };

                        console.log(emailData);
                        sendEmail(email, filename, errorfileurl, emailData);
                        //---end of mail sending

                        //--updating errorFileLocation to importlog table
                        API.graphql({
                          query: mutations.updateImportLogs,
                          variables: {
                            limit: limit,
                            input: {
                              id: importlogid,
                              errorFileLocation: filedetails,
                            },
                          },
                          authMode: "API_KEY",
                        })
                          .then((response) => {
                            console.log(response);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      })
                      .send((err) => {
                        if (err) console.log(err);
                      });
                  } catch (error) {
                    console.log(error);
                  }
                }
              }
            }
          } else {
            //missing fiiting number / site name
            if (fileExt === "csv") {
              csvArray = row;
              csvArray["Error Message"] = "missing fitting number / site name";
              console.log("csvArray", csvArray);
              errorData.push(csvArray);
            } else {
              tableColumnLength.map((key, index) => {
                csvArray[key] = row[index];
              });
              csvArray["Error Message"] = "missing fitting number / site name";
              console.log("csvArray", csvArray);
            }
            errorData.push(csvArray);
            setError(errorData);
            console.log(errorData);
            errorCount = errorCount + 1;
            if (fileData.length === successCount + errorCount) {
              setIsLoading(false);
              toast.error(
                "No Data To SavePlease check the data before importing.Check the Error File in Downloads.",
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: false,
                }
              );
              //--updating success count and error count to importlog table
              API.graphql({
                query: mutations.updateImportLogs,
                variables: {
                  limit: limit,
                  input: {
                    id: importlogid,
                    //siteId: columnDetails["siteId"],
                    successCount: successCount,
                    failedCount: errorCount,
                  },
                },
                authMode: "API_KEY",
              })
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  console.log(error);
                });
              if (errorCount > 0) {
                try {
                  // Convert the data to CSV format using PapaParse
                  const csv = Papa.unparse(errorData);
                  // Create a Blob containing the CSV data
                  const csvBlob = new Blob([csv], { type: "text/csv" });
                  // Create a URL for the Blob
                  const csvUrl = URL.createObjectURL(csvBlob);
                  // Create an invisible link element to trigger the download
                  const link = document.createElement("a");
                  console.log(link);
                  link.href = csvUrl;
                  link.download = "logbookImportErrorData.csv";
                  link.click();
                  console.log(link);
                  // Clean up by revoking the URL to release resources
                  URL.revokeObjectURL(csvUrl);

                  const logbookImportErrorData = "logbookImportErrorData";
                  const filenameWithDateTime = `${logbookImportErrorData}_${dateTime}.csv`;
                  //--- saving failed file to s3 bucket
                  const params = {
                    ACL: "public-read",
                    Body: csv,
                    Bucket: S3_BUCKET,
                    Key: `public/import/${FAILED}/${filenameWithDateTime}`,
                  };
                  myBucket
                    .putObject(params)
                    .on("httpUploadProgress", (evt) => {
                      console.log(evt);
                      const filedetails = [];
                      filedetails.push({
                        fileName: filenameWithDateTime,
                        type: ".csv",
                      });

                      errorfileurl = `https://${S3_BUCKET}.s3.amazonaws.com/public/import/${FAILED}/${filenameWithDateTime}`;
                      //---sending mail to email
                      const emailData = {
                        successCount: successCount,
                        errorCount: errorCount,
                        category: state.state.category.categoryName,
                      };

                      console.log(emailData);
                      sendEmail(email, filename, errorfileurl, emailData);
                      //---end of mail sending

                      //--updating errorFileLocation to importlog table
                      API.graphql({
                        query: mutations.updateImportLogs,
                        variables: {
                          limit: limit,
                          input: {
                            id: importlogid,
                            errorFileLocation: filedetails,
                          },
                        },
                        authMode: "API_KEY",
                      })
                        .then((response) => {
                          console.log(response);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    })
                    .send((err) => {
                      if (err) console.log(err);
                    });
                } catch (error) {
                  console.log(error);
                }
              }
            }
          }
          console.log(batchRecord);
          if (i === fileData.length - 1) {
            const multupleArrayAssets = [];
            while (batchRecord.length) {
              multupleArrayAssets.push(batchRecord.splice(0, 25));
            }
            console.log("multupleArrayAssets", multupleArrayAssets);
            Promise.all(
              multupleArrayAssets.map(async (chunk) => {
                return await API.graphql({
                  query: mutations.batchAssetAdd,
                  variables: {
                    assetImport: chunk,
                  },
                  authMode: "API_KEY",
                });
              })
            )
              .then(async (result) => {
                console.log("done", result);
                result.map((res) => {
                  if (res.data !== undefined && res.data !== null) {
                    successCount = successCount + res.data.batchAssetAdd.length;
                  }
                  if (res.errors !== undefined && res.errors !== null) {
                    errorCount = errorCount + Object.keys(res.errors).length;
                  }
                });
                if (fileData.length === successCount + errorCount) {
                  await API.graphql({
                    query: queries.listAssetCounts,
                    variables: {
                      limit: 100000,
                      filter: {
                        categoriesID: {
                          eq: state.state.category.id,
                        },
                        sitedetailsID: {
                          eq: siteIDs,
                        },
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then(async (result) => {
                      console.log(result);
                      if (result.data.listAssetCounts.items.length > 0) {
                        await API.graphql({
                          query: mutations.updateAssetCounts,
                          variables: {
                            input: {
                              id: result.data.listAssetCounts.items[0].id,
                              assetCount:
                                parseInt(successCount) +
                                parseInt(
                                  result.data.listAssetCounts.items[0]
                                    .assetCount
                                ),
                            },
                          },
                          authMode: "API_KEY",
                        });
                      } else {
                        await API.graphql({
                          query: mutations.createAssetCounts,
                          variables: {
                            input: {
                              assetCount: successCount,
                              categoriesID: state.state.category.id,
                              assetownerID: LoginUserDetails.id,
                              sitedetailsID: siteIDs,
                            },
                          },
                          authMode: "API_KEY",
                        });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  if (errorCount > 0 && successCount > 0) {
                    toast.error(
                      successCount +
                      " rows imported successfully, with " +
                      errorCount +
                      " rows failed to import.Check the Error File in Downloads.",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: false,
                      }
                    );
                  }
                  if (errorCount === 0 && successCount > 0) {
                    toast.success(
                      successCount + " rows imported successfully",
                      {
                        position: toast.POSITION.TOP_CENTER,
                      }
                    );
                    //---sending mail to email
                    const emailData = {
                      successCount: successCount,
                      errorCount: errorCount,
                      category: state.state.category.categoryName,
                    };
                    console.log(emailData);
                    sendEmail(email, filename, errorfileurl, emailData);
                    //---end of mail sending
                  }
                  if (errorCount > 0 && successCount === 0) {
                    toast.error(
                      "Error while importing, please check the data and import again.Check the Error File in Downloads.",
                      {
                        position: toast.POSITION.TOP_CENTER,
                      }
                    );
                  }
                  if (errorCount > 0) {
                    try {
                      // Convert the data to CSV format using PapaParse
                      const csv = Papa.unparse(errorData);
                      // Create a Blob containing the CSV data
                      const csvBlob = new Blob([csv], {
                        type: "text/csv",
                      });
                      // Create a URL for the Blob
                      const csvUrl = URL.createObjectURL(csvBlob);
                      // Create an invisible link element to trigger the download
                      const link = document.createElement("a");
                      console.log(link);
                      link.href = csvUrl;
                      link.download = "logbookImportErrorData.csv";
                      link.click();
                      console.log(link);
                      // Clean up by revoking the URL to release resources
                      URL.revokeObjectURL(csvUrl);
                      const logbookImportErrorData = "logbookImportErrorData";
                      const filenameWithDateTime = `${logbookImportErrorData}_${dateTime}.csv`;
                      //--- saving failed file to s3 bucket
                      const params = {
                        ACL: "public-read",
                        Body: csv,
                        Bucket: S3_BUCKET,
                        Key: `public/import/${FAILED}/${filenameWithDateTime}`,
                      };
                      myBucket
                        .putObject(params)
                        .on("httpUploadProgress", (evt) => {
                          console.log(evt);
                          const filedetails = [];
                          filedetails.push({
                            fileName: filenameWithDateTime.csv,
                            type: ".csv",
                          });
                          errorfileurl = `https://${S3_BUCKET}.s3.amazonaws.com/public/import/${FAILED}/${filenameWithDateTime}`;
                          //---sending mail to email
                          const emailData = {
                            successCount: successCount,
                            errorCount: errorCount,
                            category: state.state.category.categoryName,
                          };
                          console.log(emailData);
                          sendEmail(email, filename, errorfileurl, emailData);
                          //---end of mail sending
                          //--updating errorFileLocation to importlog table
                          API.graphql({
                            query: mutations.updateImportLogs,
                            variables: {
                              input: {
                                id: importlogid,
                                errorFileLocation: filedetails,
                              },
                            },
                            authMode: "API_KEY",
                          })
                            .then((response) => {
                              console.log(response);
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        })
                        .send((err) => {
                          if (err) console.log(err);
                        });
                    } catch (error) {
                      console.log(error);
                    }
                  }
                  setFileData([]);
                  setFileHeader([]);
                  setTableColumnLength([]);
                  setIsLoading(false);
                  cancelForm();
                }
                setFileData([]);
                setFileHeader([]);
                setTableColumnLength([]);
                setIsLoading(false);
                cancelForm();
              })
              .catch((error) => {
                console.log("error", error);
              });
          }
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error("No Data To Save", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
      }
    } catch (error) { }
  };

  const handleExport = (tblName) => {
    console.log(tblName);
    if (tblName === "Logbook") {
      var elt = document.getElementById(tblName);
      var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
      return XLSX.writeFile(wb, tblName + ".xlsx");
    } else {
      API.graphql({
        query: queries.listStaticForms,
        variables: {
          limit: limit,
          filter: {
            formName: {
              eq: tblName,
            },
          },
        },
        authMode: "API_KEY",
      })
        .then((result) => {
          console.log(result.data.listStaticForms.items[0].formFields);
          console.log(typeof result.data.listStaticForms.items[0].formFields);
          var dt = JSON.parse(result.data.listStaticForms.items[0].formFields);
          //console.log(JSON.parse(dt[0]));
          var elt = dt[0];
          var data = XLSX.utils.json_to_sheet(elt);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, data, tblName);
          return XLSX.writeFile(wb, tblName + ".xlsx");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/assets`, {
      state: {
        category: state.state.category,
        site: state.state.site,
      },
    });
  };

  // mail function----
  const sendEmail = (recipientEmail, file, errorfile, taskData) => {
    const { successCount, errorCount, category } = taskData;
    const senderEmail = "noreply@knowyourasset.com.au";
    const subject = "Assets Import";

    const fileUrl = `https://${S3_BUCKET}.s3.amazonaws.com/public/import/${FILES}/${file.name}`;
    const errorFileName = "logbookImportErrorData.csv";

    // Construct email message
    const message = `
    <p>Dear User,</p>
    <p>Your assets import for category "${category}" has been processed.</p>
    <p>Summary:</p>
    <ul>
      <li>Successfully imported assets: ${successCount}</li>
      <li>Assets not imported due to errors: ${errorCount}</li>
      <li>Uploaded File Url: <a href="${fileUrl}">${file.name}</a></li>
      ${errorCount > 0
        ? `<li>Error File Url: <a href="${errorfile}">${errorFileName}</a></li>`
        : ""
      }
    </ul>
    <p>Thank you.</p>
  `;

    // Create email parameters
    const params = {
      Destination: {
        ToAddresses: [recipientEmail],
      },
      Message: {
        Subject: {
          Data: subject,
        },
        Body: {
          Html: {
            Data: message,
          },
        },
      },
      Source: senderEmail,
    };

    // Send email
    const sendPromise = new AWS.SES({ apiVersion: "2010-12-01" })
      .sendEmail(params)
      .promise();

    sendPromise
      .then((data) => {
        console.log("Email sent:", data.MessageId);
      })
      .catch((error) => {
        console.log("Error sending email:", error);
      });
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <CardHeader>
              <Col md="12" className="text-end">
                {state.state && state.state.category && state.state.site ? (
                  <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                    Back
                  </Btn>
                ) : (
                  ""
                )}
              </Col>
              {state.state && state.state.category ? (
                <H3>Bulk Import For {state.state.category.categoryName}</H3>
              ) : (
                ""
              )}
              {state.state && state.state.category ? (
                <span className="d-block">
                  Import Logbook for{" "}
                  {state.state.category.categoryName === "Emergency Lights" ? (
                    <a
                      class="downloadLink"
                      onClick={() => handleExport("emergencylights")}
                    >
                      {" "}
                      Emergency Lights
                    </a>
                  ) : state.state.category.categoryName === "RCD's" ? (
                    <a class="downloadLink" onClick={() => handleExport("rcd")}>
                      {" "}
                      RCD's
                    </a>
                  ) : state.state.category.categoryName ===
                    "Appliance Testing" ? (
                    <a
                      class="downloadLink"
                      onClick={() => handleExport("appliancetesting")}
                    >
                      {" "}
                      Appliance Testing
                    </a>
                  ) : (
                    <a
                      class="downloadLink"
                      onClick={() => handleExport("Logbook")}
                    >
                      {" "}
                      {state.state.category.categoryName}
                    </a>
                  )}
                </span>
              ) : (
                " "
              )}
              <Table
                className="table-striped  table-de"
                style={{ display: "none" }}
                id="Logbook"
              >
                <thead className="custom-table-head">
                  <tr>
                    <th>siteName</th>
                    <th>distributionboard</th>
                    <th>assetDescription</th>
                    <th>assetLocation</th>
                    <th>assetMake</th>
                    <th>assetModel</th>
                    <th>fittingNumber</th>
                    <th>assettemplate</th>
                    <th>assetWarrantyStart</th>
                    <th>assetWarrantyEnd</th>
                    <th>installedOn</th>
                    <th>
                      Any custom label for the{" "}
                      {state.state && state.state.category && state.state.site
                        ? state.state.category.categoryName
                        : "Assets"}{" "}
                      Template with "custom-" as the prefix
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </Table>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="5">
                  <input
                    type="file"
                    className="form-control"
                    accept=".csv,.xls,.xlsx"
                    onChange={handleFileUpload}
                  />
                </Col>
                {fileData.length > 0 && (
                  <Col md="3">
                    <span>Total records in file - {fileData.length}.</span>
                  </Col>
                )}
                <Col md="4">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      onClick: handleSave,
                      disabled: dbError,
                    }}
                  >
                    Import Logbook Details
                  </Btn>
                  {!state.state && (
                    <Btn
                      attrBtn={{ color: "primary", onClick: props.prevStep }}
                    >
                      Back
                    </Btn>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {errorMessage && (
                    <span className="txt-danger">
                      Data Columns are not matching the sample import template.
                      Please check the sample import template by downloading.
                    </span>
                  )}
                </Col>
              </Row>
              {fileData.length > 0 && (
                <Row>
                  <Col md="12">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr key={"header"}>
                          {tableColumnLength.map((key) => (
                            <th>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {fileData.map((item, i) => (
                          <tr key={i}>
                            {Object.values(item).map((val) => (
                              <td>{val}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};

export default LogbookAssetDetails;
