import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AddFormContain from '../../Component/AssetCategory/Form/AddForm/index';

const AddForm = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="AssetCategory" title="Manage Forms" />
      <AddFormContain />
    </Fragment>
  );
};
export default AddForm;