import { Btn } from "../../../AbstractElements";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, Label, InputGroup, InputGroupText, Row } from "reactstrap";
import { H5, H4, H6, Image } from "../../../AbstractElements";
import { API } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import {
  ASSET_OWNER_FOLDER,
  S3_BUCKET,
  REGION,
  ACCESSKEYID,
  SECRETACCESSKEY,
} from "../../../Config/Config";

const Aosp = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    e,
    reset,
  } = useForm();
  const [ProfilePic, setProfilePic] = useState("");

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  useEffect(() => {
    console.log(props);
  }, []);

  const onSubmit = async (data) => {
    console.log(props);
    try {
      // API.graphql({
      // query: mutations.createAssetOwner,
      // variables: {
      // input: {
      // id: props.values.UserId,
      //   firstName: data.firstname,
      // lastName: data.lastname,
      // email: props.values.Email,
      // mobile: data.ownermobilenumber,
      // companydetailsID: props.values.CompanyId,
      //companyName: props.values.CompanyName,
      // profilePicture: ProfilePic,
      // status: "Active",
      // },
      //},
      //authMode: "API_KEY",
      // })
      // .then((result) => {
      //    console.log(result);
      props.values.FirstName = data.firstname;
      props.values.LastName = data.lastname;
      props.values.MObileAssetOwner = data.ownermobilenumber;
      props.values.Aomail = data.owneremail;
      props.values.aoPic = ProfilePic;
      props.values.splicence = data.license;
      //   props.values.AssetOwnerId = props.values.UserId;
      //   API.graphql({
      //     query: mutations.updateUserDetails,
      //    variables: {
      //      input: {
      //        id: props.values.UserId,
      //       assetOwnerId: props.values.UserId,
      //     },
      //  },
      //  authMode: "API_KEY",
      // })
      //  .then((userUpdate) => {
      //     console.log(userUpdate);
      //  })
      //  .catch((error) => {
      //   console.log(error);
      // });
      props.nextStep();
      //toast.success(" Created successfully.", {
      //   position: toast.POSITION.TOP_CENTER,
      //  });
      // })
      // .catch((error) => {
      //   console.log(error);
      // toast.error(" Error while Creating ", {
      //    position: toast.POSITION.TOP_CENTER,
      //  });
      // });
    } catch (err) {
      console.log(err);
    }
  };

  //--------------------------------------------------------------------------------------
  const onSelectFile = (e) => {
    const file = e.target.files[0];
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${ASSET_OWNER_FOLDER}/${file.name}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        const filedetails = [];
        filedetails.push({ fileName: file.name, type: file.type });
        console.log(filedetails[0].fileName);
        setProfilePic(JSON.stringify(filedetails));
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  return (
    <Fragment>
      <H4>Enter Your Personal Details</H4>
      <Form
        className="needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col md="6 mb-3">
            <Label>First Name</Label>
            <input
              className="form-control"
              name="firstname"
              type="text"
              placeholder="First Name"
              {...register("firstname", { required: true })}
              defaultValue={props.values.FirstName}
            />
            <span>{errors.firstname && "First Name is required"}</span>
            <div className="valid-feedback">{"Looks good!"}</div>
          </Col>
          <Col md="6 mb-3">
            <Label>Last Name</Label>
            <input
              className="form-control"
              name="lastname"
              type="text"
              placeholder="Last Name"
              {...register("lastname", { required: true })}
              defaultValue={props.values.LastName}
            />
            <span>{errors.lastname && "Last Name is required"}</span>
            <div className="valid-feedback">{"Looks good!"}</div>
          </Col>
        </Row>
        <Row>
          <Col md="6 mb-3">
            <Label htmlFor="validationCustomUsername">E-Mail</Label>
            <input
              className="form-control"
              name="owneremail"
              type="email"
              placeholder="E-mail"
              {...register("owneremail", { required: true })}
            />
            <span>{errors.owneremail && "E-mail Address is required"}</span>
          </Col>
          <Col md="6 mb-3">
            <Label>Mobile Number</Label>
            <input
              className="form-control"
              name="ownermobilenumber"
              type="Number"
              placeholder="Mobile number"
              {...register("ownermobilenumber", { required: true })}
              defaultValue={props.values.MobileAssetOwner}
            />
            <span>
              {errors.ownermobilenumber &&
                "Please provide a valid Mobile Number"}
            </span>
            <div className="invalid-feedback">
              {"Please provide a valid Mobile Number."}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="5 mb-3">
            <Label>License #</Label>
            <input
              className="form-control"
              type="text"
              name="license"
              placeholder="License #"
              {...register(`license`, {
                required: true,
              })}
            />
            <span>{errors.license && "Licence is required"}</span>
          </Col>
        </Row>
        <Row>
          <Col md="6 mb-3">
            <Label>Profile Picture</Label>
            <input
              className="form-control"
              name="ownerpp"
              type="file"
              placeholder="Logo"
              onChange={onSelectFile}
            />
            <span>{errors.ownerpp && "Please provide a Profile Picture"}</span>
            <div className="invalid-feedback">
              {"Please provide a Profile Picture."}
            </div>
          </Col>
        </Row>
        <Btn attrBtn={{ color: "primary", onClick: props.prevStep }}>Back</Btn>
        <Btn attrBtn={{ color: "primary" }}>Continue</Btn>
      </Form>
      <br></br>
    </Fragment>
  );
};

export default Aosp;
