import React, { Fragment } from 'react';
import RegisterFrom from '../../Component/Pages/Auth/RegisterFrom';

const RegisterFromContain = () => {
  return (
    <Fragment>
      <RegisterFrom />
    </Fragment>
  );
};
export default RegisterFromContain;