import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import TransactionsDetails from "../../Component/Subscriptions/transaction";

const Transactions = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Asset Owner" title="Transactions" />
      <TransactionsDetails />
    </Fragment>
  );
};
export default Transactions;
