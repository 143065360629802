import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import TasksContainAoSp from '../../Component/TasksAoSp/index';

const TasksAOContainAoSp = () => {
  return (
    <Fragment>
      {/* <Breadcrumbs parent="Tasks" title="Manage Tasks" />
      <hr></hr> */}
      <TasksContainAoSp />
    </Fragment>
  );
};
export default TasksAOContainAoSp;