import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AssetsTemplateDetails from '../../../Component/Assets/AssetTemplate/index';

const AssetsTemplateList = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Assets" title="Assets Template" />
      <AssetsTemplateDetails />
    </Fragment>
  );
};
export default AssetsTemplateList;