import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import GenerateReportAsset from '../../Component/Reports/GenerateReportsAsset';

const ReportAssetOwnerReportDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Logbook" title="Logbook" />
      <GenerateReportAsset />
    </Fragment>
  );
};
export default ReportAssetOwnerReportDetails;