import React from "react";
import { H4, Btn, P, Image } from "../../AbstractElements";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { SignIn } from "../../Constant";
import { useNavigate } from "react-router-dom";
import imgg from "../../assets/images/logo/logo.png";

const Register = () => {
  const navigate = useNavigate();
  const linkToRegister = (val) => {
    console.log(val);
    navigate(`${process.env.PUBLIC_URL}/registerassetowner`, {
      state: val,
    });
  };
  const linkElectrician = (val) => {
    console.log("--- to company details page ----");
    navigate(`${process.env.PUBLIC_URL}/registerserviceprovider`, {
      state: val,
    });
  };
  const linkToRegisterAoSp = (val) => {
    console.log(val);
    navigate(`${process.env.PUBLIC_URL}/registeraosp`, {
      state: val,
    });
  };
  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xl="12">
          <div className="login-card">
            <div>
              <div className="login-main1 login-tab1 text-center">
                <div className="login-header text-center">
                  <div className="logo-wrapper">
                    <a>
                      <Image
                        attrImage={{
                          className: "img-fluid",
                          src: `${imgg}`,
                          alt: "",
                        }}
                      />
                    </a>
                  </div>
                </div>
                <Btn
                  attrBtn={{
                    color: "primary",
                    className: "btn-block",
                    onClick: () => linkToRegister("site_owner"),
                  }}
                >
                  Register as Asset Owner&nbsp;
                  <i
                    className="fa fa-info-circle"
                    title="I am a building (facility) owner or manager and am responsible for the upkeep and testing of electrical assets"
                  ></i>
                </Btn>
                <br></br>
                <br></br>
                <Btn
                  attrBtn={{
                    color: "primary",
                    className: "btn-block",
                    onClick: () => linkElectrician("electrician"),
                  }}
                >
                  Register As Service Provider&nbsp;
                  <i
                    className="fa fa-info-circle"
                    title="I am an electrical contractor (service provider) who has been invited by an asset owner to register in order to be assigned tasks"
                  ></i>
                </Btn>
                <br></br>
                <br></br>
                <Btn
                  attrBtn={{
                    color: "primary",
                    className: "btn-block",
                    onClick: () => linkToRegisterAoSp("spao"),
                  }}
                >
                  Register As AssetOwner and ServiceProvider&nbsp;
                  <i
                    className="fa fa-info-circle"
                    title="I am an electrical contractor (service provider) and I am going to use Know Youir Asset as a platform for my own electrical business. I will therefore be both the asset owner and the service provider"
                  ></i>
                </Btn>
                <br></br>
                <br></br>
                <br></br>
                <P>
                  Already have an password?
                  <Link to={`${process.env.PUBLIC_URL}/login`}>{SignIn}</Link>
                </P>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Register;
