import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../AbstractElements';
import AoSpRegistrationContain from '../../Component/SiteOwner/RegistrationAoSp/AoSpRegistration';

const AoSpRegistrationPage = () => {
  return (
    <Fragment>
      <AoSpRegistrationContain />
    </Fragment>
  );
};
export default AoSpRegistrationPage;