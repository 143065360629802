import React, { forwardRef, Fragment } from 'react';
import { Col, Label, Row, Table, Container, CardBody, Card } from 'reactstrap';
import { Price, ProductName, Quantity, Sub_total } from '../../../Constant';
import { H6, P } from '../../../AbstractElements';
import InvoiceHeader from './InvoiceHeader';
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import InvoiceDescription from './InvoiceDescription';
import PrintInvoice from './PrintInvoice';

const ItemDescription = forwardRef(({ cart, symbol }, ref) => {
  const state = useLocation();
  return (
    <Fragment>
      <Container>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="invoice" ref={ref}>
                  <div>
                    <InvoiceHeader />
                    <InvoiceDescription />
                    <div className="table-responsive invoice-table" id="table">
                      <Table className="table-bordered table-striped">
                        <tbody>
                          <tr>
                            <td className="item">
                              <H6 attrH6={{ className: 'mb-0' }}>{ProductName}</H6>
                            </td>
                            <td className="Hours">
                              <H6 attrH6={{ className: 'mb-0' }}>{Quantity}</H6>
                            </td>
                            <td className="Rate">
                              <H6 attrH6={{ className: 'mb-0' }}>{Price}</H6>
                            </td>
                            <td className="subtotal">
                              <H6 attrH6={{ className: 'mb-0' }}>{Sub_total}</H6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Label>{state.state.row.subscriptionId}</Label>
                            </td>
                            <td>
                              <P attrPara={{ className: 'itemtext' }}>
                                {state.state.row.quantity}
                              </P>
                            </td>
                            <td>
                              <P attrPara={{ className: 'itemtext' }}>
                                {symbol} {state.state.row.subscriptionId === "User Add-On" ? 15 : 30}
                              </P>
                            </td>
                          </tr>
                          <tr>
                            <td className="payment">
                              <P attrPara={{ className: 'itemtext' }}>
                                {symbol} {state.state.row.amount}
                              </P>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <PrintInvoice />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
});

export default ItemDescription;
