import React, { Fragment, useState, useEffect } from "react";
import { Lock, Mail, User, Key } from "react-feather";
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { Btn, H3, Image, P } from "../../../AbstractElements";
import { SignUp, SignIn } from "../../../Constant";
import { Auth, Amplify, API } from "aws-amplify";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import imgg from "../../../assets/images/logo/KYASP logo.png";
import imggao from "../../../assets/images/logo/KYAAO logo.png";
import * as mutations from "../../../graphql/mutations";
import awsExports from "../../../aws-exports";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import AWS from "aws-sdk";
import {
  User_FOLDER,
  S3_BUCKET,
  REGION,
  ACCESSKEYID,
  SECRETACCESSKEY,
  USERPOOLID,
} from "../../../Config/Config";
import {
  LITE,
  TIER1,
  TIER2,
  TIER3,
  CORPORATE,
  DEVELOPERTESTING,
} from "../../../Config/SubscriptionPaymentLinks";
import { SPCompanyDetails } from "../../../models";
var base64 = require("base-64");
Amplify.configure(awsExports);

const RegisterFrom = (props) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [userSub, setUserSub] = useState("");
  const [statenewval, setstatenewval] = useState("");
  const [catId, setCatId] = useState();
  const [maxNum, setmaxNum] = useState();

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
    apiVersion: "2022-10-03",
    region: REGION,
  });

  const param = {
    userId: "",
    username: "",
    email: "",
  };
  let cdId;

  const digits_count = (n) => {
    var count = 0;
    if (n >= 1) ++count;
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  };

  useEffect(async () => {
    SerialId();
    async function SerialId() {
      await API.graphql({
        query: queries.listDistributionBoxes,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
        },
      })
        .then((response) => {
          const DistributionBoxesData =
            response.data.listDistributionBoxes.items;

          let idArry = [];
          let maxnumber = 0;

          DistributionBoxesData.map((item) => {
            if (
              item.distributionBoxNumber !== "" &&
              item.distributionBoxNumber !== null
            ) {
              if (
                isNaN(Number(item.distributionBoxNumber.replace(/^\D+/g, "")))
              ) {
                idArry.push(1);
              } else {
                idArry.push(
                  Number(item.distributionBoxNumber.replace(/^\D+/g, ""))
                );
              }
            }
          });

          maxnumber = Math.max(...idArry);
          if (idArry.length > 0) {
            if (maxnumber !== 0 && maxnumber !== null) {
              const res = digits_count(Number(maxnumber + 1));
              if (res === 1) {
                setCatId("000");
                setmaxNum(maxnumber + 1);
              } else if (res === 2) {
                setCatId("00");
                setmaxNum(maxnumber + 1);
              } else if (res === 3) {
                setCatId("0");
                setmaxNum(maxnumber + 1);
              } else if (res === 4) {
                setCatId("");
                setmaxNum(maxnumber + 1);
              }
            } else {
              setCatId("000");
              setmaxNum(1);
            }
          } else {
            setCatId("000");
            setmaxNum(1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  async function onSubmit(data) {
    console.log(props.values);
    console.log(state);
    setLoading(true);
    setUsername(data.username);
    setstatenewval(state);
    console.log(data);
    try {
      await Auth.signUp({
        username: data.username,
        password: data.password,
        attributes: {
          email:
            state === "electrician"
              ? props.values.spemail
              : props.values.Aomail,
          "custom:userType": state,
        },
      })
        .then((result) => {
          setStep(1);
          console.log(result);
          toast.success(
            "Confirmation code sent successfully to the registered email address.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          setUserSub(result.userSub);
          if (state === "site_owner") {
            const siteDetailsId = [];
            console.log("asset owner");
            console.log("---Ao saving ----");
            API.graphql({
              query: mutations.createCompanyDetails,
              variables: {
                input: {
                  companyName: props.values.CompanyName,
                  companyBN: props.values.BusinessNumber,
                  companyAddress: props.values.CompanyAddress,
                  managerName: props.values.ManagerName,
                  email: props.values.EmailManager,
                  mobile: props.values.MobileNumber,
                  logo: props.values.CompanyLogo,
                  status: "Active",
                },
              },
              authMode: "API_KEY",
            }).then(async (result4) => {
              const cdId = result4.data.createCompanyDetails.id;
              console.log(cdId);
              console.log(result4);
              const permissionsList = [
                "Create Assets",
                "Import Assets",
                "Edit Assets",
                "View Assets",
                "Create Tasks",
                "Assign Tasks",
                "View Tasks",
                "Export Tasks",
                "Create Sites",
                "Edit Sites",
                "View Sites",
                "Import Sites",
                "Create Certificates",
                "View Certificates",
                "Download Reports",
                "View Reports",
                "View Logbooks",
                "View Service Providers",
              ];
              API.graphql({
                query: mutations.createAssetOwner,
                variables: {
                  input: {
                    id: result.userSub,
                    firstName: props.values.FirstName,
                    lastName: props.values.LastName,
                    email: props.values.Aomail,
                    mobile: props.values.MObileAssetOwner,
                    companydetailsID: cdId,
                    companyName: props.values.CompanyName,
                    profilePicture: props.values.aoPic,
                    status: "Active",
                    permission: JSON.stringify(permissionsList),
                  },
                },
                authMode: "API_KEY",
              })
                .then((result1) => {
                  console.log(result1);
                  const inputArray = props.values.siteDetails;
                  console.log(inputArray);
                  inputArray.forEach(function (d, idx) {
                    console.log(cdId);
                    console.log(result.userSub);
                    API.graphql({
                      query: mutations.createSiteDetails,
                      variables: {
                        input: {
                          unitNumber: d.SiteAddress,
                          siteName: d.siteName,
                          assetownerID: result.userSub,
                          companydetailsID: cdId,
                          status: "Active",
                        },
                      },
                      authMode: "API_KEY",
                    })
                      .then((result2) => {
                        const siteId = result2.data.createSiteDetails.id;
                        siteDetailsId.push(siteId);
                        console.log(siteDetailsId);
                        API.graphql({
                          query: mutations.createLogBook,
                          variables: {
                            limit: 10000,
                            input: {
                              siteId: result2.data.createSiteDetails.id,
                              sitedetailsID: result2.data.createSiteDetails.id,
                              status: "Active",
                              logbookName: d.siteName + " - Default Logbook",
                            },
                          },
                          authMode: "API_KEY",
                        }).then((result1) => {
                          console.log(result1);
                        });
                        //});
                        API.graphql({
                          query: mutations.createLinkUsersAndSite,
                          variables: {
                            input: {
                              sitedetailsID: result2.data.createSiteDetails.id,
                              userdetailsID: result.userSub,
                              status: "Active",
                            },
                          },
                          authMode: "API_KEY",
                        });
                        console.log(result);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                  //---site saving ends ---
                  // ---userdetails
                  console.log(siteDetailsId);
                  API.graphql({
                    query: mutations.createUserDetails,
                    variables: {
                      input: {
                        id: result.userSub,
                        username: data.username.toLowerCase(),
                        email: props.values.Aomail,
                        companyDetailsId: cdId,
                        assetOwnerId: result.userSub,
                        siteDetailsId: siteDetailsId,
                        paymentId: props.values.PaymentId,
                        status: "Active",
                        permissions: JSON.stringify(permissionsList),
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then((userUpdate) => {
                      console.log(userUpdate);
                    })
                    .catch((error) => {
                      console.log(error);
                      toast.error("Error while Creating ", {
                        position: toast.POSITION.TOP_CENTER,
                      });
                    });
                  //---userdetails
                })
                .catch((error) => {
                  console.log(error);
                  toast.error(" Error while Creating ", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                });
            });
            console.log("---Ao saving ends ----");
          } else if (state === "electrician") {
            console.log("electrician");
            console.log(userSub);
            console.log(data);
            const permissionsList = [
              //"Create Assets",
              //"Import Assets",
              //"Edit Assets",
              //"View Assets",
              "Create Tasks",
              "Assign Tasks",
              "View Tasks",
              "Export Tasks",
              //"Create Sites",
              //"Edit Sites",
              //"View Sites",
              //"Import Sites",
              "Create Certificates",
              "View Certificates",
              "Download Reports",
              "View Reports",
              "View Logbooks",
              "View Service Providers",
            ];
            setLoading(true);
            if (props.values.CompanyIdSelected) {
              //----
              API.graphql({
                query: queries.listUserDetails,
                variables: {
                  filter: {
                    companyDetailsId: {
                      eq: props.values.CompanyIdSelected,
                    },
                  },
                  limit: 10000,
                },
                authMode: "API_KEY",
              })
                .then((uid) => {
                  console.log(uid);
                  const cmpp = uid.data.listUserDetails.items;
                  console.log(cmpp);
                  if (cmpp.length > 0) {
                    console.log(
                      "company selected from dropdown  --- saving data"
                    );
                    API.graphql({
                      query: mutations.createSPUserDetails,
                      variables: {
                        input: {
                          id: result.userSub,
                          fname: props.values.firstName,
                          lname: props.values.lastName,
                          email: props.values.spemail,
                          mobile: props.values.spmobileNumber,
                          license: props.values.splicence,
                          profilepic: props.values.spProfilepic,
                          SPGroup: "",
                          status: "Active",
                          userGroup: "electrician",
                          permission: JSON.stringify(permissionsList),
                        },
                      },
                      authMode: "API_KEY",
                    })
                      .then((response2) => {
                        console.log(response2);
                        API.graphql({
                          query: mutations.createUserDetails,
                          variables: {
                            input: {
                              id: result.userSub,
                              username: data.username.toLowerCase(),
                              email: props.values.spemail,
                              companyDetailsId: props.values.CompanyIdSelected,
                              assetOwnerId: "",
                              paymentId: "",
                              siteDetailsId: "",
                              permissions: JSON.stringify(permissionsList),
                              status: "Active",
                            },
                          },
                          authMode: "API_KEY",
                        })
                          .then((response3) => {
                            console.log(response3);
                          })
                          .catch((error) => {
                            console.log(error);
                            setLoading(false);
                          });
                      })
                      .catch((error) => {
                        console.log(error);
                        setLoading(false);
                      });
                  } else {
                    console.log(
                      "company selected from dropdown  --- saving data"
                    );
                    API.graphql({
                      query: mutations.createSPUserDetails,
                      variables: {
                        input: {
                          id: result.userSub,
                          fname: props.values.firstName,
                          lname: props.values.lastName,
                          email: props.values.spemail,
                          mobile: props.values.spmobileNumber,
                          license: props.values.splicence,
                          profilepic: props.values.spProfilepic,
                          SPGroup: "admin",
                          status: "Active",
                          userGroup: "electrician",
                          permission: JSON.stringify(permissionsList),
                        },
                      },
                      authMode: "API_KEY",
                    })
                      .then((response2) => {
                        console.log(response2);
                        API.graphql({
                          query: mutations.createUserDetails,
                          variables: {
                            input: {
                              id: result.userSub,
                              username: data.username.toLowerCase(),
                              email: props.values.spemail,
                              companyDetailsId: props.values.CompanyIdSelected,
                              assetOwnerId: "",
                              paymentId: "",
                              siteDetailsId: "",
                              permissions: JSON.stringify(permissionsList),
                              status: "Active",
                            },
                          },
                          authMode: "API_KEY",
                        })
                          .then((response3) => {
                            console.log(response3);
                          })
                          .catch((error) => {
                            console.log(error);
                            setLoading(false);
                          });
                      })
                      .catch((error) => {
                        console.log(error);
                        setLoading(false);
                      });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              //----
              {
                /*
              console.log("company selected from dropdown  --- saving data");
              API.graphql({
                query: mutations.createSPUserDetails,
                variables: {
                  input: {
                    id: result.userSub,
                    fname: props.values.firstName,
                    lname: props.values.lastName,
                    email: props.values.spemail,
                    mobile: props.values.spmobileNumber,
                    license: props.values.splicence,
                    profilepic: props.values.spProfilepic,
                    SPGroup: "admin",
                    status: "Active",
                    userGroup: "electrician",
                  },
                },
                authMode: "API_KEY",
              })
                .then((response2) => {
                  console.log(response2);
                  API.graphql({
                    query: mutations.createUserDetails,
                    variables: {
                      input: {
                        id: result.userSub,
                        username: data.username.toLowerCase(),
                        email: props.values.spemail,
                        companyDetailsId: props.values.CompanyIdSelected,
                        assetOwnerId: "",
                        paymentId: "",
                        siteDetailsId: "",
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then((response3) => {
                      console.log(response3);
                    })
                    .catch((error) => {
                      console.log(error);
                      setLoading(false);
                    });
                })
                .catch((error) => {
                  console.log(error);
                  setLoading(false);
                });
              */
              }
            } else {
              console.log("new company creating --- ");
              API.graphql({
                query: mutations.createSPCompanyDetails,
                variables: {
                  input: {
                    companyName: props.values.CompanyName,
                    companyBN: props.values.businessNumber,
                    companyAddress: props.values.companyaddress,
                    companyLicense: props.values.licencenumb,
                    logo: props.values.logo,
                    status: "Active",
                    email: props.values.compEmail,
                    phone: props.values.phone,
                  },
                },
                authMode: "API_KEY",
              })
                .then(async (response1) => {
                  console.log(response1);

                  cdId = response1.data.createSPCompanyDetails.id;
                  const addedsite = await API.graphql({
                    query: queries.getSPCompanyDetails,
                    variables: {
                      id: response1.data.createSPCompanyDetails.id,
                    },
                    authMode: "API_KEY",
                  });
                  if (addedsite.data.getSPCompanyDetails !== null) {
                    if (localStorage.getItem("spCompany") !== null) {
                      let activeDB = JSON.parse(
                        base64.decode(localStorage.getItem("spCompany"))
                      );
                      console.log(activeDB);
                      activeDB.push(addedsite.data.getSPCompanyDetails);
                      console.log(activeDB);
                      localStorage.setItem(
                        "spCompany",
                        base64.encode(JSON.stringify(activeDB))
                      );
                    }
                  }

                  API.graphql({
                    query: mutations.createLinkAOAPCompany,
                    variables: {
                      input: {
                        companyName: props.values.CompanyName,
                        companyBN: props.values.businessNumber,
                        companyAddress: props.values.companyaddress,
                        companyLicense: props.values.licencenumb,
                        logo: props.values.logo,
                        status: "Active",
                        email: props.values.compEmail,
                        phone: props.values.phone,
                        spaoId: result.userSub,
                        spcomapnyId: cdId,
                      },
                    },
                    authMode: "API_KEY",
                  });

                  API.graphql({
                    query: mutations.createSPUserDetails,
                    variables: {
                      input: {
                        id: result.userSub,
                        fname: props.values.firstName,
                        lname: props.values.lastName,
                        email: props.values.spemail,
                        mobile: props.values.spmobileNumber,
                        license: props.values.splicence,
                        profilepic: props.values.spProfilepic,
                        SPGroup: "admin",
                        status: "Active",
                        userGroup: "electrician",
                        permission: JSON.stringify(permissionsList),
                        spcompanydetailsID: cdId,
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then((response2) => {
                      console.log(response2);
                    })
                    .catch((error) => {
                      console.log("error in spuserdetails insertion - ", error);
                    });

                  API.graphql({
                    query: mutations.createUserDetails,
                    variables: {
                      input: {
                        id: result.userSub,
                        username: data.username.toLowerCase(),
                        email: props.values.spemail,
                        companyDetailsId: cdId,
                        assetOwnerId: "",
                        paymentId: "",
                        siteDetailsId: "",
                        status: "Active",
                        permissions: JSON.stringify(permissionsList),
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then((response3) => {
                      console.log(response3);
                    })
                    .catch((error) => {
                      console.log("error in userdetails insertion - ", error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            console.log(" Ao Sp Registration");
            {
              /*
            if (props.values.CompanyIdSelected) {
              API.graphql({
                query: queries.listUserDetails,
                variables: {
                  filter: {
                    companyDetailsId: {
                      eq: props.values.CompanyIdSelected,
                    },
                  },
                  limit: 10000,
                },
                authMode: "API_KEY",
              })
                .then((uid) => {
                  console.log(uid);
                  const cmpp = uid.data.listUserDetails.items;
                  console.log(cmpp);
                  if (cmpp.length > 0) {
                    console.log(
                      "company selected from dropdown  ---has value in userdetails"
                    );
                    //---
                    const siteDetailsId = [];
                    console.log("asset owner");
                    console.log("---Ao saving ----");
                    API.graphql({
                      query: mutations.createCompanyDetails,
                      variables: {
                        input: {
                          companyName: props.values.CompanyName,
                          companyBN: props.values.BusinessNumber,
                          companyAddress: props.values.CompanyAddress,
                          managerName: props.values.ManagerName,
                          email: props.values.EmailManager,
                          mobile: props.values.MobileNumber,
                          logo: props.values.CompanyLogo,
                          status: "Active",
                        },
                      },
                      authMode: "API_KEY",
                    }).then(async (result4) => {
                      const cdId = result4.data.createCompanyDetails.id;
                      console.log(cdId);
                      console.log(result4);
                      const permissionsList = [
                        "Create Assets",
                        "Import Assets",
                        "Edit Assets",
                        "View Assets",
                        "Create Tasks",
                        "Assign Tasks",
                        "View Tasks",
                        "Export Tasks",
                        "Create Sites",
                        "Edit Sites",
                        "View Sites",
                        "Import Sites",
                        "Create Certificates",
                        "View Certificates",
                        "Download Reports",
                        "View Reports",
                        "View Logbooks",
                        "View Service Providers",
                      ];
                      API.graphql({
                        query: mutations.createAssetOwner,
                        variables: {
                          input: {
                            id: result.userSub,
                            firstName: props.values.FirstName,
                            lastName: props.values.LastName,
                            email: props.values.Aomail,
                            mobile: props.values.MObileAssetOwner,
                            companydetailsID: cdId,
                            companyName: props.values.CompanyName,
                            profilePicture: props.values.aoPic,
                            status: "Active",
                            permission: JSON.stringify(permissionsList),
                          },
                        },
                        authMode: "API_KEY",
                      })
                        .then((result1) => {
                          console.log(result1);
                          const inputArray = props.values.siteDetails;
                          console.log(inputArray);
                          inputArray.forEach(function (d, idx) {
                            console.log(cdId);
                            console.log(result.userSub);
                            API.graphql({
                              query: mutations.createSiteDetails,
                              variables: {
                                input: {
                                  unitNumber: d.SiteAddress,
                                  siteName: d.siteName,
                                  assetownerID: result.userSub,
                                  companydetailsID: cdId,
                                  status: "Active",
                                },
                              },
                              authMode: "API_KEY",
                            })
                              .then((result2) => {
                                const siteId = result2.data.createSiteDetails.id;
                                siteDetailsId.push(siteId);
                                console.log(siteDetailsId);
                                API.graphql({
                                  query: mutations.createLogBook,
                                  variables: {
                                    limit: 10000,
                                    input: {
                                      siteId: result2.data.createSiteDetails.id,
                                      sitedetailsID: result2.data.createSiteDetails.id,
                                      status: "Active",
                                      logbookName: d.siteName + " - Default Logbook",
                                    },
                                  },
                                  authMode: "API_KEY",
                                }).then((result1) => {
                                  console.log(result1);
                                });
                                //});
                                API.graphql({
                                  query: mutations.createLinkUsersAndSite,
                                  variables: {
                                    input: {
                                      sitedetailsID: result2.data.createSiteDetails.id,
                                      userdetailsID: result.userSub,
                                      status: "Active",
                                    },
                                  },
                                  authMode: "API_KEY",
                                });
                                console.log(result);
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          });
                          //---site saving ends ---
                          // ---userdetails
                          console.log(siteDetailsId);

                          //---sp saving    


                          API.graphql({
                            query: mutations.createSPUserDetails,
                            variables: {
                              input: {
                                id: result.userSub,
                                fname: props.values.firstName,
                                lname: props.values.lastName,
                                email: props.values.spemail,
                                mobile: props.values.spmobileNumber,
                                license: props.values.splicence,
                                profilepic: props.values.spProfilepic,
                                SPGroup: "",
                                status: "Active",
                                userGroup: "electrician",
                                permission: JSON.stringify(permissionsList),
                              },
                            },
                            authMode: "API_KEY",
                          })
                            .then((response2) => {
                              console.log(response2);
                              //---userdetails table
                              API.graphql({
                                query: mutations.createUserDetails,
                                variables: {
                                  input: {
                                    id: result.userSub,
                                    username: data.username.toLowerCase(),
                                    email: props.values.Aomail,
                                    companyDetailsId: props.values.CompanyIdSelected,
                                    assetOwnerId: result.userSub,
                                    siteDetailsId: siteDetailsId,
                                    paymentId: props.values.PaymentId,
                                    permissions: JSON.stringify(permissionsList),
                                  },
                                },
                                authMode: "API_KEY",
                              })
                                .then((userUpdate) => {
                                  console.log(userUpdate);

                                })
                                .catch((error) => {
                                  console.log(error);
                                  toast.error("Error while Creating ", {
                                    position: toast.POSITION.TOP_CENTER,
                                  });
                                });

                            })
                            .catch((error) => {
                              console.log("error in spuserdetails insertion - ", error);
                            });

                          //---userdetails
                          //--
                        })
                        .catch((error) => {
                          console.log(error);
                          toast.error(" Error while Creating ", {
                            position: toast.POSITION.TOP_CENTER,
                          });
                        });
                    });
                    console.log("---Ao saving ends ----");

                  }
                  else {
                    //---
                    const siteDetailsId = [];
                    console.log("asset owner");
                    console.log("---Ao saving ----");
                    API.graphql({
                      query: mutations.createCompanyDetails,
                      variables: {
                        input: {
                          companyName: props.values.CompanyName,
                          companyBN: props.values.BusinessNumber,
                          companyAddress: props.values.CompanyAddress,
                          managerName: props.values.ManagerName,
                          email: props.values.EmailManager,
                          mobile: props.values.MobileNumber,
                          logo: props.values.CompanyLogo,
                          status: "Active",
                        },
                      },
                      authMode: "API_KEY",
                    }).then(async (result4) => {
                      const cdId = result4.data.createCompanyDetails.id;
                      console.log(cdId);
                      console.log(result4);
                      const permissionsList = [
                        "Create Assets",
                        "Import Assets",
                        "Edit Assets",
                        "View Assets",
                        "Create Tasks",
                        "Assign Tasks",
                        "View Tasks",
                        "Export Tasks",
                        "Create Sites",
                        "Edit Sites",
                        "View Sites",
                        "Import Sites",
                        "Create Certificates",
                        "View Certificates",
                        "Download Reports",
                        "View Reports",
                        "View Logbooks",
                        "View Service Providers",
                      ];
                      API.graphql({
                        query: mutations.createAssetOwner,
                        variables: {
                          input: {
                            id: result.userSub,
                            firstName: props.values.FirstName,
                            lastName: props.values.LastName,
                            email: props.values.Aomail,
                            mobile: props.values.MObileAssetOwner,
                            companydetailsID: cdId,
                            companyName: props.values.CompanyName,
                            profilePicture: props.values.aoPic,
                            status: "Active",
                            permission: JSON.stringify(permissionsList),
                          },
                        },
                        authMode: "API_KEY",
                      })
                        .then((result1) => {
                          console.log(result1);
                          const inputArray = props.values.siteDetails;
                          console.log(inputArray);
                          inputArray.forEach(function (d, idx) {
                            console.log(cdId);
                            console.log(result.userSub);
                            API.graphql({
                              query: mutations.createSiteDetails,
                              variables: {
                                input: {
                                  unitNumber: d.SiteAddress,
                                  siteName: d.siteName,
                                  assetownerID: result.userSub,
                                  companydetailsID: cdId,
                                  status: "Active",
                                },
                              },
                              authMode: "API_KEY",
                            })
                              .then((result2) => {
                                const siteId = result2.data.createSiteDetails.id;
                                siteDetailsId.push(siteId);
                                console.log(siteDetailsId);
                                API.graphql({
                                  query: mutations.createLogBook,
                                  variables: {
                                    limit: 10000,
                                    input: {
                                      siteId: result2.data.createSiteDetails.id,
                                      sitedetailsID: result2.data.createSiteDetails.id,
                                      status: "Active",
                                      logbookName: d.siteName + " - Default Logbook",
                                    },
                                  },
                                  authMode: "API_KEY",
                                }).then((result1) => {
                                  console.log(result1);
                                });
                                //});
                                API.graphql({
                                  query: mutations.createLinkUsersAndSite,
                                  variables: {
                                    input: {
                                      sitedetailsID: result2.data.createSiteDetails.id,
                                      userdetailsID: result.userSub,
                                      status: "Active",
                                    },
                                  },
                                  authMode: "API_KEY",
                                });
                                console.log(result);
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          });
                          //---site saving ends ---
                          // ---userdetails
                          console.log(siteDetailsId);

                          //---sp saving    


                          API.graphql({
                            query: mutations.createSPUserDetails,
                            variables: {
                              input: {
                                id: result.userSub,
                                fname: props.values.firstName,
                                lname: props.values.lastName,
                                email: props.values.spemail,
                                mobile: props.values.spmobileNumber,
                                license: props.values.splicence,
                                profilepic: props.values.spProfilepic,
                                SPGroup: "admin",
                                status: "Active",
                                userGroup: "electrician",
                                permission: JSON.stringify(permissionsList),
                              },
                            },
                            authMode: "API_KEY",
                          })
                            .then((response2) => {
                              console.log(response2);
                              //---userdetails table
                              API.graphql({
                                query: mutations.createUserDetails,
                                variables: {
                                  input: {
                                    id: result.userSub,
                                    username: data.username.toLowerCase(),
                                    email: props.values.Aomail,
                                    companyDetailsId: props.values.CompanyIdSelected,
                                    assetOwnerId: result.userSub,
                                    siteDetailsId: siteDetailsId,
                                    paymentId: props.values.PaymentId,
                                    permissions: JSON.stringify(permissionsList),
                                  },
                                },
                                authMode: "API_KEY",
                              })
                                .then((userUpdate) => {
                                  console.log(userUpdate);

                                })
                                .catch((error) => {
                                  console.log(error);
                                  toast.error("Error while Creating ", {
                                    position: toast.POSITION.TOP_CENTER,
                                  });
                                });

                            })
                            .catch((error) => {
                              console.log("error in spuserdetails insertion - ", error);
                            });
                        })
                        .catch((error) => {
                          console.log(error);
                          toast.error(" Error while Creating ", {
                            position: toast.POSITION.TOP_CENTER,
                          });
                        });
                    });
                    console.log("---Ao saving ends ----");

                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          */
            }
            //else {
            const siteDetailsId = [];
            API.graphql({
              query: mutations.createCompanyDetails,
              variables: {
                input: {
                  companyName: props.values.CompanyName,
                  companyBN: props.values.BusinessNumber,
                  companyAddress: props.values.CompanyAddress,
                  managerName: props.values.ManagerName,
                  email: props.values.EmailManager,
                  mobile: props.values.MobileNumber,
                  logo: props.values.CompanyLogo,
                  status: "Active",
                },
              },
              authMode: "API_KEY",
            }).then(async (result4) => {
              const cdId = result4.data.createCompanyDetails.id;
              console.log(cdId);
              console.log(result4);
              const permissionsList = [
                "Create Assets",
                "Import Assets",
                "Edit Assets",
                "View Assets",
                "Create Tasks",
                "Assign Tasks",
                "View Tasks",
                "Export Tasks",
                "Create Sites",
                "Edit Sites",
                "View Sites",
                "Import Sites",
                "Create Certificates",
                "View Certificates",
                "Download Reports",
                "View Reports",
                "View Logbooks",
                "View Service Providers",
              ];
              API.graphql({
                query: mutations.createAssetOwner,
                variables: {
                  input: {
                    id: result.userSub,
                    firstName: props.values.FirstName,
                    lastName: props.values.LastName,
                    email: props.values.Aomail,
                    mobile: props.values.MObileAssetOwner,
                    companydetailsID: cdId,
                    companyName: props.values.CompanyName,
                    profilePicture: props.values.aoPic,
                    status: "Active",
                    permission: JSON.stringify(permissionsList),
                  },
                },
                authMode: "API_KEY",
              })
                .then((result1) => {
                  console.log(result1);
                  const inputArray = props.values.siteDetails;
                  console.log(inputArray);
                  inputArray.forEach(function (d, idx) {
                    console.log(cdId);
                    console.log(result.userSub);
                    API.graphql({
                      query: mutations.createSiteDetails,
                      variables: {
                        input: {
                          unitNumber: d.SiteAddress,
                          siteName: d.siteName,
                          assetownerID: result.userSub,
                          companydetailsID: cdId,
                          status: "Active",
                        },
                      },
                      authMode: "API_KEY",
                    })
                      .then((result2) => {
                        const siteId = result2.data.createSiteDetails.id;
                        siteDetailsId.push(siteId);
                        console.log(siteDetailsId);
                        API.graphql({
                          query: mutations.createLogBook,
                          variables: {
                            limit: 10000,
                            input: {
                              siteId: result2.data.createSiteDetails.id,
                              sitedetailsID: result2.data.createSiteDetails.id,
                              status: "Active",
                              logbookName: d.siteName + " - Default Logbook",
                            },
                          },
                          authMode: "API_KEY",
                        }).then((result1) => {
                          console.log(result1);
                        });
                        //});
                        API.graphql({
                          query: mutations.createLinkUsersAndSite,
                          variables: {
                            input: {
                              sitedetailsID: result2.data.createSiteDetails.id,
                              userdetailsID: result.userSub,
                              status: "Active",
                            },
                          },
                          authMode: "API_KEY",
                        });
                        console.log(result);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                  //---site saving ends ---
                  // ---userdetails
                  console.log(siteDetailsId);

                  //---sp saving

                  API.graphql({
                    query: mutations.createSPCompanyDetails,
                    variables: {
                      input: {
                        id: cdId,
                        companyName: props.values.CompanyName,
                        companyBN: props.values.BusinessNumber,
                        companyAddress: props.values.CompanyAddress,
                        companyLicense: props.values.licencenumb,
                        logo: props.values.CompanyLogo,
                        status: "Active",
                        email: props.values.EmailManager,
                        phone: props.values.MobileNumber,
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then(async (response1) => {
                      console.log(response1);
                      const addedsite = await API.graphql({
                        query: queries.getSPCompanyDetails,
                        variables: {
                          id: cdId,
                        },
                        authMode: "API_KEY",
                      });
                      if (addedsite.data.getSPCompanyDetails !== null) {
                        if (localStorage.getItem("spCompany") !== null) {
                          let activeDB = JSON.parse(
                            base64.decode(localStorage.getItem("spCompany"))
                          );
                          console.log(activeDB);
                          activeDB.push(addedsite.data.getSPCompanyDetails);
                          console.log(activeDB);
                          localStorage.setItem(
                            "spCompany",
                            base64.encode(JSON.stringify(activeDB))
                          );
                        }
                      }
                      API.graphql({
                        query: mutations.createLinkAOAPCompany,
                        variables: {
                          input: {
                            companyName: props.values.CompanyName,
                            companyBN: props.values.BusinessNumber,
                            companyAddress: props.values.CompanyAddress,
                            companyLicense: props.values.licencenumb,
                            logo: props.values.CompanyLogo,
                            status: "Active",
                            email: props.values.EmailManager,
                            phone: props.values.MobileNumber,
                            spaoId: result.userSub,
                            spcomapnyId: cdId,
                          },
                        },
                        authMode: "API_KEY",
                      });
                      API.graphql({
                        query: mutations.createSPUserDetails,
                        variables: {
                          input: {
                            id: result.userSub,
                            fname: props.values.FirstName,
                            lname: props.values.LastName,
                            email: props.values.Aomail,
                            mobile: props.values.MObileAssetOwner,
                            license: props.values.splicence,
                            profilepic: props.values.aoPic,
                            SPGroup: "admin",
                            status: "Active",
                            userGroup: "electrician",
                            permission: JSON.stringify(permissionsList),
                            spcompanydetailsID: cdId,
                          },
                        },
                        authMode: "API_KEY",
                      })
                        .then((response2) => {
                          console.log(response2);
                          //---userdetails table
                          API.graphql({
                            query: mutations.createUserDetails,
                            variables: {
                              input: {
                                id: result.userSub,
                                username: data.username.toLowerCase(),
                                email: props.values.Aomail,
                                companyDetailsId: cdId,
                                assetOwnerId: result.userSub,
                                siteDetailsId: siteDetailsId,
                                paymentId: props.values.PaymentId,
                                status: "Active",
                                permissions: JSON.stringify(permissionsList),
                              },
                            },
                            authMode: "API_KEY",
                          })
                            .then((userUpdate) => {
                              console.log(userUpdate);
                            })
                            .catch((error) => {
                              console.log(error);
                              toast.error("Error while Creating ", {
                                position: toast.POSITION.TOP_CENTER,
                              });
                            });
                        })
                        .catch((error) => {
                          console.log(
                            "error in spuserdetails insertion - ",
                            error
                          );
                        });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                  toast.error(" Error while Creating ", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                });
            });
            console.log("---Aosp saving ends ----");
            //}
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("error signing up: " + err.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        });
    } catch (err) {
      toast.error("error signing up: " + err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }

  async function confirmSignUp(data) {
    setLoading(true);
    try {
      await Auth.confirmSignUp(
        data.username,
        data.authenticationCode,
        "SOFTWARE_TOKEN_MFA"
      )
        .then(async (res) => {
          console.log("----sign up ----");
          const userId = uuid.v4();

          //const insertData = insertUserDetails(data, userId);
          //console.log(insertData);
          console.log(statenewval);
          console.log(state);
          param.userId = userSub;
          param.username = data.username;
          param.email =
            statenewval === "electrician"
              ? props.values.spemail
              : props.values.Aomail;
          toast.success("User successfully signed up", {
            position: toast.POSITION.TOP_CENTER,
          });
          if (statenewval === "site_owner" || statenewval === "spao") {
            if (props.values.PaymentId === "1") {
              window.location.href = LITE + userSub;
            } else if (props.values.PaymentId === "2") {
              window.location.href = TIER1 + userSub;
            } else if (props.values.PaymentId === "3") {
              window.location.href = TIER2 + userSub;
              // } else if (props.values.PaymentId === "4") {
              //   window.location.href = TIER3 + userSub;
            } else if (props.values.PaymentId === "5") {
              window.location.href = CORPORATE + userSub;
            }
            //local bill plan
            // else {
            //   window.location.href = DEVELOPERTESTING + userSub;
            // }
          } else {
            navigate(`${process.env.PUBLIC_URL}/thankyou`, { state: param });
          }
          //(statenewval === 'site_owner') ? navigate(`${process.env.PUBLIC_URL}/thankyou`, {state : param}) : navigate(`${process.env.PUBLIC_URL}/thankyou`, {state : param});
          setLoading(false);
        })
        .catch((err) => {
          toast.error("error confirming sign up: " + err.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        });
    } catch (err) {
      if (err.code === "UserLambdaValidationException") {
        console.error("PostConfirmation error:", err.message);
      } else {
        console.error("Error confirming sign up:", err);
      }
      toast.error("An error occurred while confirming sign up", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }
  async function resendConfirmationCode() {
    setLoading(true);
    try {
      await Auth.resendSignUp(username)
        .then((data) => {
          toast.success("Resent the Code..", {
            position: toast.POSITION.TOP_CENTER,
          });
          console.log("code resent successfully");
          setLoading(false);
        })
        .catch((err) => {
          toast.error("error resending code: " + err.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          console.log("error resending code: " + err.message);
          setLoading(false);
        });
    } catch (err) {
      toast.error("error resending code: " + err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log("error resending code: " + err.message);
      setLoading(false);
    }
  }
  return (
    // <Row className="m-0">className="login-card1"
    //   <Col xs="12" className="p-0">

    <div className="removecss login-card1">
      {step === 0 && (
        <Form
          className="theme-form login-form needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormGroup>
            <Label htmlFor="validationCustom01">Create Username</Label>
            <div className="input-group">
              <span className="input-group-text">
                <User />
              </span>
              <input
                className="form-control"
                name="username"
                type="text"
                placeholder="Username"
                {...register("username", {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: "Username must be alphanumeric",
                  },
                })}
              />
            </div>
            <span className="invalid-span">
              {errors.username && "Username must be alphanumeric"}
            </span>
            <div className="invalid-feedback">
              {"Username must be alphanumeric"}
            </div>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="validationCustom02">Email</Label>
            <div className="input-group">
              <span className="input-group-text">
                <Mail />
              </span>
              {state === "electrician" ? (
                <input
                  className="form-control"
                  type="email"
                  disabled={true}
                  name="email"
                  value={props.values.spemail}
                  placeholder="Email"
                  {...register("email", { required: false })}
                />
              ) : (
                <input
                  className="form-control"
                  type="email"
                  disabled={true}
                  name="email"
                  value={props.values.Aomail}
                  placeholder="Email"
                  {...register("email", { required: false })}
                />
              )}
            </div>
            <span className="invalid-span">
              {errors.email && "Please enter Email"}
            </span>
            <div className="invalid-feedback">{"Please enter Email"}</div>
          </FormGroup>
          <FormGroup>
            <Label>Create Password</Label>
            <InputGroup>
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
              <input
                className="form-control"
                type={togglePassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                {...register("password", { required: true })}
              />
              <div
                className="show-hide"
                onClick={() => setTogglePassword(!togglePassword)}
              >
                <span className={togglePassword ? "" : "show"}></span>
              </div>
            </InputGroup>
            <p>
              Password should have at least 1 Capital Letter, 1 Number, 1
              Special Character and be a minimum of 8 characters in length
            </p>
            <span className="invalid-span">
              {errors.password && "Please enter Password"}
            </span>
            <div className="invalid-feedback">{"Please enter Password"}</div>
          </FormGroup>
          <FormGroup>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
              }}
            >
              {loading ? "LOADING..." : SignUp}
            </Btn>
          </FormGroup>
          <div className="login-social-title"></div>
          <br></br>
          <P>
            Already have an password?
            <Link to={`${process.env.PUBLIC_URL}/login`}>{SignIn}</Link>
          </P>
        </Form>
      )}
      {step === 1 && (
        <Form
          className="theme-form login-form needs-validation"
          noValidate=""
          onSubmit={handleSubmit(confirmSignUp)}
        >
          {" "}
          <div className="login-header text-center">
            <H3>Confirmation Code</H3>
          </div>
          {/* {statenewval === "site_owner" && (
            <div className="login-header text-center">
              <div className="logo-wrapper">
                <a>
                  <Image
                    attrImage={{
                      className: "img-fluid",
                      src: `${imggao}`,
                      alt: "",
                    }}
                  />
                </a>
              </div>
              <H3>Confirmation Code</H3>
            </div>
          )}
          {statenewval === "electrician" && (
            <div className="login-header text-center">
              <div className="logo-wrapper">
                <a>
                  <Image
                    attrImage={{
                      className: "img-fluid",
                      src: `${imgg}`,
                      alt: "",
                    }}
                  />
                </a>
              </div>
              <H3>Confirmation Code</H3>
            </div>
          )} */}
          <FormGroup>
            <Label htmlFor="validationCustom01">Username</Label>
            <div className="input-group">
              <span className="input-group-text">
                <User />
              </span>
              <input
                className="form-control"
                name="username"
                type="text"
                placeholder="Username"
                {...register("username", { required: true })}
              />
            </div>
            <span className="invalid-span">
              {errors.username && "Please enter Username"}
            </span>
            <div className="invalid-feedback">{"Please enter Username"}</div>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="validationCustom02">Enter Code</Label>
            <div className="input-group">
              <span className="input-group-text">
                <Key />
              </span>
              <input
                className="form-control"
                type="text"
                name="authenticationCode"
                placeholder="Authentication Code"
                {...register("authenticationCode", { required: true })}
              />
            </div>
            <span className="invalid-span">
              {errors.authenticationCode && "Please enter Code"}
            </span>
            <div className="invalid-feedback">{"Please enter Code"}</div>
          </FormGroup>
          <FormGroup className="login-btn">
            <Link onClick={resendConfirmationCode} className="link">
              Resend Code.
            </Link>
          </FormGroup>
          <FormGroup>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
              }}
            >
              {loading ? "LOADING..." : "Confirm Sign Up"}
            </Btn>
          </FormGroup>
        </Form>
      )}
    </div>
    //  </Col>
    // </Row>
  );
};

export default RegisterFrom;
