var base64 = require("base-64");
var SiteDBData = (function () {
  var siteDBDataDetails = "";

  var getSiteDBData = function () {
    try {
      if (siteDBDataDetails === "") {
        siteDBDataDetails =
          localStorage.getItem("siteDB") !== null
            ? JSON.parse(base64.decode(localStorage.getItem("siteDB")))
            : [];
        console.log("siteDBDataDetails", siteDBDataDetails);
      }
    } catch (error) {
      console.log(error);
    }
    return siteDBDataDetails; // Or pull this from cookie/localStorage
  };

  var setSiteDBData = function (siteDBData) {
    try {
      if (siteDBData === "") {
        siteDBDataDetails = JSON.parse(
          base64.decode(localStorage.getItem("siteDB"))
        );
      } else {
        siteDBDataDetails = siteDBData;
      }
    } catch (error) {
      console.log(error);
    }
    // Also set this in cookie/localStorage
  };

  return {
    getSiteDBData: getSiteDBData,
    setSiteDBData: setSiteDBData,
  };
})();

export default SiteDBData;
