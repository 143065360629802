import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import UserPanelContain from '../../Component/UserPanel/index';

const UserPanel = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="UserPanel" title="Sites" />
      <UserPanelContain />
    </Fragment>
  );
};
export default UserPanel;