import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import CategoryAssetsContain from '../../Component/UserPanel/categoryAssets';

const CategoryAssets = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="UserPanel" title="Assets" />
      <CategoryAssetsContain />
    </Fragment>
  );
};
export default CategoryAssets;