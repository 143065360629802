import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AssetsCount from '../../Component/AssetsCount/index';

const AssetsCountt = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Assets" title="Assets List" />
      <AssetsCount />
    </Fragment>
  );
};
export default AssetsCountt;