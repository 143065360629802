import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../AbstractElements';
import AddAssetOwnerContain from '../../Component/AssetOwner/AddAssetOwners';

const AddAssetOwner = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="CompanyAssetOwner" title="Manage Asset Owner" />
      <AddAssetOwnerContain />
    </Fragment>
  );
};

export default AddAssetOwner;