import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import TestingContain from '../../Component/UserPanel/testing';

const Testings = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="UserPanel" title="Testing" />
      <TestingContain />
    </Fragment>
  );
};
export default Testings;