import React, { Fragment } from "react";
import PaymentFailContain from "../../Component/SiteOwner/PaymentFail";

const PaymentFail = () => {
  return (
    <Fragment>
      <PaymentFailContain />
    </Fragment>
  );
};
export default PaymentFail;
