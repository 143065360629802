import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import ServiceGroupDetailsContain from '../../Component/AssetCategory/ServiceGroups/index';

const ServiceGroupDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="AssetCategory" title="Service Groups" />
      <ServiceGroupDetailsContain />
    </Fragment>
  );
};
export default ServiceGroupDetails;