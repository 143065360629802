import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../AbstractElements';
import AssetOwnerContain from '../../Component/AssetOwner/index';

const AssetOwnerList = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Subscribers" title="Asset Owner" />
      <AssetOwnerContain />
    </Fragment>
  );
};

export default AssetOwnerList;