import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import GenerateReports from "../../Component/Reports/index";

const GenerateReportsPage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Reports" title="" />
      <GenerateReports />
    </Fragment>
  );
};
export default GenerateReportsPage;
