var base64 = require("base-64");
var CategoryData = (function () {
  var CategoryDataDetails = "";
  var CategoryAssetCounts = "";

  var getCategoryData = function () {
    try {
      if (CategoryDataDetails === "") {
        CategoryDataDetails = JSON.parse(
          base64.decode(localStorage.getItem("category"))
        );
      }
    } catch (error) {
      console.log(error);
    }
    return CategoryDataDetails; // Or pull this from cookie/localStorage
  };

  var setCategoryData = function (CategoryData) {
    try {
      if (CategoryData === "") {
        CategoryDataDetails = JSON.parse(
          base64.decode(localStorage.getItem("category"))
        );
      } else {
        CategoryDataDetails = CategoryData;
      }
    } catch (error) {
      console.log(error);
    }
    // Also set this in cookie/localStorage
  };

  var getCategoryAssetCounts = function () {
    try {
      if (CategoryDataDetails === "") {
        CategoryAssetCounts = JSON.parse(
          base64.decode(localStorage.getItem("categoryAssetCount"))
        );
      }
    } catch (error) {
      console.log(error);
    }
    return CategoryAssetCounts; // Or pull this from cookie/localStorage
  };

  var setCategoryAssetCounts = function (CategoryData) {
    try {
      if (CategoryData === "") {
        CategoryAssetCounts = JSON.parse(
          base64.decode(localStorage.getItem("categoryAssetCount"))
        );
      } else {
        CategoryAssetCounts = CategoryData;
      }
    } catch (error) {
      console.log(error);
    }
    // Also set this in cookie/localStorage
  };

  return {
    getCategoryData: getCategoryData,
    setCategoryData: setCategoryData,
    getCategoryAssetCounts: getCategoryAssetCounts,
    setCategoryAssetCounts: setCategoryAssetCounts,
  };
})();

export default CategoryData;
