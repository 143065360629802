import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import PendingSiteUserDetails from '../../Component/SiteOwnerPanel/pendingSiteUser';

const PendingSiteUser = () => {
  
  return (
    <Fragment>
      <Breadcrumbs parent="Sites" title="Pending Site User" />
      <PendingSiteUserDetails />
    </Fragment>
  );
};
export default PendingSiteUser;