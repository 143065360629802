import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../AbstractElements';
import CompanyDetailsContain from '../../Component/CompanyDetails/index';

const CompanyAssetOwner = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Subscribers" title="Company" />
      <CompanyDetailsContain />
    </Fragment>
  );
};

export default CompanyAssetOwner;