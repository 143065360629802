import {
  BlogSvg,
  BonusUISvg,
  ButtonsSvg,
  CalanderSvg,
  ChartsSvg,
  ChatSvg,
  ContactSvg,
  EcommerceSvg,
  EditorsSvg,
  EmailSvg,
  FAQSvg,
  FilemanagerSvg,
  FormsSvg,
  GallarySvg,
  HeaderBookmarkSvg,
  HomeSvg,
  IconsSvg,
  JobsearchSvg,
  KanbanSvg,
  KnowledgebaseSvg,
  LearningSvg,
  MapsSvg,
  OthersSvg,
  ProjectSvg,
  SamplePageSvg,
  SearchResultSvg,
  SocialappSvg,
  SupportTicketSvg,
  TablesSvg,
  TaskSvg,
  TodoSvg,
  UiKitsSvg,
  UsersComponentSvg,
  WidgetsSvg,
  SidebarHomeIcon,
  SidebarSiteIcon,
  SidebarServiceIcon,
  SidebarAssetsIcon,
  SidebarSubscriptionsIcon,
  SidebarLogbooksIcon,
  SidebarReportsIcon,
  SidebarCertificatesIcon,
} from "../../Data/svgIcons";

export const MENUITEMS = [
  {
    menutitle: "DashBoard",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/default`,
        bookmark: true,
        icon: SidebarHomeIcon,
        title: "Dashboard",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Logbooks",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/generatereportfasset`,
        bookmark: true,
        icon: SidebarLogbooksIcon,
        title: "Logbooks",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Reports",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/reports/catList`,
        bookmark: true,
        icon: SidebarReportsIcon,
        title: "Reports",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Tasks",
    Items: [
      {
        title: "Tasks",
        type: "link",
        icon: SidebarHomeIcon,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/tasksao`,
            title: "Manage Tasks",
            type: "link",
          },
        ],
      },
    ],
  },
];
