import { Btn, H6, H4, Image } from "../../../AbstractElements";
import React, { Fragment } from "react";
import { useState, useEffect, useCallback } from "react";
import {
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
  Card,
  CardHeader,
  CardBody,
  Container,
} from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import {
  Controller,
  useFormContext,
  useFieldArray,
  useForm,
} from "react-hook-form";
import imggao from "../../../assets/images/logo/KYAAO logo.png";

import AssetOwner from "./AssetOwner";
import SiteDetails from "./SiteDetails";
import CompanyDetails from "./CompanyDetails";
import PaymentOption from "./PaymentOption";
import BillingPlan from "./BillingPlan";
import RegisterFrom from "../../Pages/Auth/RegisterFrom";

const AssetOwnerRegistration = () => {
  const { state } = useLocation();
  const [pageData, setPageData] = useState({
    step: 1,
    aoPic: "",
    CompanyId: "",
    CompanyName: "",
    CompanyLogo: "",
    ManagerName: "",
    BusinessNumber: "",
    CompanyAddress: "",
    MobileNumber: "",
    EmailManager: "",
    FirstName: "",
    LastName: "",
    MObileAssetOwner: "",
    SiteIds: "",
    siteName: "",
    UnitNumber: "",
    distributionBoxName: "",
    distributionBoxLocation: "",
    AssetOwnerId: "",
    PaymentId: "",
    Aomail: "",
    UserId: "",
    siteDetails: [],
    Email: "",
  });
  const nextStep = () => {
    const {
      step,
      CompanyId,
      aoPic,
      CompanyName,
      CompanyLogo,
      ManagerName,
      BusinessNumber,
      MobileNumber,
      EmailManager,
      CompanyAddress,
      FirstName,
      LastName,
      MObileAssetOwner,
      SiteIds,
      siteName,
      UnitNumber,
      distributionBoxName,
      distributionBoxLocation,
      AssetOwnerId,
      Aomail,
      PaymentId,
      UserId,
      siteDetails,
      Email,
    } = pageData;
    setPageData({
      step: step + 1,
      CompanyId: CompanyId,
      aoPic: aoPic,
      CompanyName: CompanyName,
      CompanyLogo: CompanyLogo,
      ManagerName: ManagerName,
      BusinessNumber: BusinessNumber,
      MobileNumber: MobileNumber,
      EmailManager: EmailManager,
      CompanyAddress: CompanyAddress,
      FirstName: FirstName,
      LastName: LastName,
      MObileAssetOwner: MObileAssetOwner,
      SiteIds: SiteIds,
      siteName: siteName,
      UnitNumber: UnitNumber,
      distributionBoxName: distributionBoxName,
      distributionBoxLocation: distributionBoxLocation,
      AssetOwnerId: AssetOwnerId,
      Aomail: Aomail,
      PaymentId: PaymentId,
      UserId: UserId,
      siteDetails: siteDetails,
      Email: Email,
    });
  };
  // Go to prev step
  const prevStep = () => {
    const {
      step,
      CompanyId,
      aoPic,
      CompanyName,
      CompanyLogo,
      ManagerName,
      BusinessNumber,
      MobileNumber,
      EmailManager,
      CompanyAddress,
      FirstName,
      LastName,
      MObileAssetOwner,
      SiteIds,
      siteName,
      UnitNumber,
      distributionBoxName,
      distributionBoxLocation,
      AssetOwnerId,
      PaymentId,
      Aomail,
      UserId,
      siteDetails,
      Email,
    } = pageData;
    setPageData({
      step: step - 1,
      CompanyId: CompanyId,
      aoPic: aoPic,
      CompanyName: CompanyName,
      CompanyLogo: CompanyLogo,
      ManagerName: ManagerName,
      BusinessNumber: BusinessNumber,
      MobileNumber: MobileNumber,
      EmailManager: EmailManager,
      CompanyAddress: CompanyAddress,
      FirstName: FirstName,
      LastName: LastName,
      MObileAssetOwner: MObileAssetOwner,
      SiteIds: SiteIds,
      siteName: siteName,
      UnitNumber: UnitNumber,
      distributionBoxName: distributionBoxName,
      distributionBoxLocation: distributionBoxLocation,
      AssetOwnerId: AssetOwnerId,
      Aomail: Aomail,
      PaymentId: PaymentId,
      UserId: UserId,
      siteDetails: siteDetails,
      Email: Email,
    });
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xl="12">
          <div className="login-card">
            <Card>
              <CardHeader>
                <div className="login-header text-center">
                  <div className="logo-wrapper">
                    <a href="#javascript">
                      <Image
                        attrImage={{
                          className: "img-fluid",
                          src: `${imggao}`,
                          alt: "",
                        }}
                      />
                    </a>
                  </div>
                  <H4>Create your account</H4>
                </div>
              </CardHeader>
              <CardBody>
                {pageData.step === 1 && (
                  <CompanyDetails nextStep={nextStep} values={pageData} />
                )}
                {pageData.step === 2 && (
                  <AssetOwner
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                {pageData.step === 3 && (
                  <SiteDetails
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                {pageData.step === 4 && (
                  <BillingPlan
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                 {pageData.step === 5 && (
                  <RegisterFrom
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )}
                {/* {pageData.step === 5 && (
                  <PaymentOption
                    prevStep={prevStep}
                    nextStep={nextStep}
                    values={pageData}
                  />
                )} */}
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AssetOwnerRegistration;
