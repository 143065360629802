import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AddEditLogBookDetails from '../../../Component/UserPanel/LogBook/AddEditLogBook';

const AddEditLogBook = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="User panel"/>
      <AddEditLogBookDetails />
    </Fragment>
  );
};
export default AddEditLogBook;

