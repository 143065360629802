import { Btn, H4 } from "../../../AbstractElements";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, Label, InputGroup, InputGroupText, Row } from "reactstrap";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import uuid from "react-native-uuid";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  COMPANY_FOLDER,
  S3_BUCKET,
  REGION,
  ACCESSKEYID,
  SECRETACCESSKEY,
} from "../../../Config/Config";

Amplify.configure(awsExports);
const notValidClasses = {};
const CompanyDetails = (props) => {
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [companyAdress, setCompanyAdress] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [siteAdressArray, setsiteAdressArray] = useState([]);
  const [siteAdress, setsiteAdress] = useState();
  const [companyId, setCompanyId] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [logoUrl, setLogoUrl] = useState(null);
  const [addressError, setAddressError] = useState(false);
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${COMPANY_FOLDER}/${file.name}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        const filedetails = [];
        filedetails.push({ fileName: file.name, type: file.type });
        console.log(filedetails[0].fileName);
        setSelectedFile(filedetails[0].fileName);
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };
  //address...............................
  const onLoad = (autocomplete) => {
    setsiteAdress(autocomplete);
  };
  const onPlaceChanged = (detailAddress) => {
    console.log(detailAddress);
    let fullAddress1 = [];
    for (let i = 0; i < detailAddress.length; i++) {
      if (detailAddress[i].types.includes("postal_code")) {
        fullAddress1["postcode"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("locality")) {
        fullAddress1["suburb"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("administrative_area_level_1")) {
        fullAddress1["state"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("administrative_area_level_2")) {
        fullAddress1["street"] = detailAddress[i].long_name;
      }
      if (detailAddress[i].types.includes("country")) {
        fullAddress1["country"] = detailAddress[i].long_name;
      }
    }
    console.log(fullAddress1);
    setsiteAdressArray(fullAddress1);
  };

  //---------------------------------------

  useEffect(() => {
    console.log(props);
    // fetchlistUserDetails();
  }, []);

  {
    /*
  async function fetchlistUserDetails() {
    const dataDetails = await API.graphql({
      query: queries.listUserDetails,
      authMode: "API_KEY",
    });
    setUserDetails(dataDetails);
  }
*/
  }

  //-----------------------------

  const onSubmit = async (data) => {
    try {
      //API.graphql({
      //query: mutations.createCompanyDetails,
      //variables: {
      //input: {
      //companyName: data.companyname,
      //  companyBN: data.businessnumber,
      //companyAddress: companyAdress,
      //managerName: data.managername,
      //email: data.manageremail,
      //mobile: data.mobilenumber,
      //logo: selectedFile,
      //street: siteAdressArray.hasOwnProperty("street")
      // ? siteAdressArray.street
      //: "",
      //suburb: siteAdressArray.hasOwnProperty("suburb")
      //? siteAdressArray.suburb
      //: "",
      //state: siteAdressArray.hasOwnProperty("state")
      // ? siteAdressArray.state
      //: "",
      //postcode: siteAdressArray.hasOwnProperty("postcode")
      // ? siteAdressArray.postcode
      //  : "",
      //country: siteAdressArray.hasOwnProperty("country")
      //  ? siteAdressArray.country
      // : "",
      // status: "Active",
      // },
      // },
      // authMode: "API_KEY",
      // }).then(async (result) => {
      // const cdId = result.data.createCompanyDetails.id;
      // console.log(cdId);
      if (companyAdress || props.values.CompanyAddress) {
        console.log(addressError);
        setAddressError(false);
        const address =
          companyAdress !== "" ? companyAdress : props.values.CompanyAddress;
        console.log(address);
        const pic =
          selectedFile !== null ? selectedFile : props.values.CompanyLogo;
        console.log(pic);

        props.values.CompanyName = data.companyname;
        props.values.ManagerName = data.managername;
        props.values.BusinessNumber = data.businessnumber;
        props.values.MobileNumber = data.mobilenumber;
        props.values.EmailManager = data.manageremail;
        props.values.CompanyLogo = pic;
        props.values.CompanyAddress = address;
        console.log(companyAdress);
        // console.log(result);
        // API.graphql({
        //   query: mutations.updateUserDetails,
        //  variables: {
        //    input: {
        //    id: props.values.UserId,
        //    companyDetailsId: cdId,
        //  },
        // },
        // authMode: "API_KEY",
        // })
        //   .then((userUpdate) => {
        //    console.log(userUpdate);
        //    toast.success("Created successfully.", {
        //     position: toast.POSITION.TOP_CENTER,
        //   });
        //  setCompanyId(cdId);
        //props.values.CompanyId = cdId;
        props.nextStep();
        // })
        // .catch((error) => {
        //   console.log(error);
        //   toast.error("Error while Creating ", {
        //     position: toast.POSITION.TOP_CENTER,
        //   });
        // });
        //  });
      } else {
        console.log(addressError);
        setAddressError(true);
      }
    } catch (companyError) {
      console.log(companyError);
    }
  };

  useEffect(() => {
    const fetchLogoUrl = async () => {
      try {
        const url = await getfilename1(props.values.CompanyLogo);
        setLogoUrl(url);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLogoUrl();
  }, [props.values.CompanyLogo]);

  const getImage1 = async (filename) => {
    try {
      if (!filename) {
        console.log("No logo available.");
        return null;
      }

      var params = {
        Bucket: S3_BUCKET,
        Key: `public/${COMPANY_FOLDER}/${filename}`,
      };

      try {
        var url = await myBucket.getSignedUrlPromise("getObject", params);
        console.log(url);
        return url;
      } catch (error) {
        if (error.code === "NotFound") {
          console.log("File not found.");
          return null;
        }
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getfilename1 = async (path) => {
    try {
      const url = await getImage1(path);
      console.log(url);
      return url;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  //------------------------------------------------------------

  return (
    <Fragment>
      <H4>Enter Company Details</H4>
      <br></br>
      <Form
        className="needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col md="6 mb-3">
            <Label>Company Name</Label>
            <input
              className="form-control"
              name="companyname"
              type="text"
              placeholder="Company Name"
              {...register("companyname", { required: true })}
              defaultValue={props.values.CompanyName}
            />
            <span>{errors.companyname && "Company Name is required"}</span>
            {/* <div className="invalid-feedback">{'Company Name is required'}</div> */}
          </Col>
          <Col md="6 mb-3">
            <Label>Business Number</Label>
            <input
              className="form-control"
              name="businessnumber"
              type="text"
              placeholder="Business Number"
              {...register("businessnumber", { required: true })}
              defaultValue={props.values.BusinessNumber}
            />
            <span>
              {errors.businessnumber && "Business Number is required"}
            </span>
            {/* <div className="invalid-feedback">{'Business Number is required'}</div> */}
          </Col>
        </Row>
        <Row>
          <Col md="6 mb-3">
            <Label>Company Address</Label>
            <Autocomplete
              apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
              className="form-control"
              onPlaceSelected={(place) => {
                console.log(place);
                setCompanyAdress(place.formatted_address);
                const address = place.address_components;
                onPlaceChanged(address);
                if (place) {
                  setAddressError(false);
                } else {
                  setAddressError(true);
                }
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "AU" },
              }}
              name="companyaddress"
              placeholder="Company Address"
              defaultValue={props.values.CompanyAddress}
            />
            {addressError ? <span>{"Selecting Address is required"}</span> : ""}
          </Col>
          <Col md="6 mb-3">
            <Label>Manager Name</Label>
            <InputGroup>
              <input
                className="form-control"
                name="managername"
                type="text"
                placeholder="Manager Name"
                {...register("managername", { required: true })}
                defaultValue={props.values.ManagerName}
              />
              <span>{errors.managername && "Manager name is required"}</span>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6 mb-3">
            <Label>E-Mail</Label>
            <InputGroup>
              <InputGroupText>{"@"}</InputGroupText>
              <input
                className="form-control"
                name="manageremail"
                type="email"
                placeholder=" E-mail"
                {...register("manageremail", { required: true })}
                defaultValue={props.values.EmailManager}
              />
              <span>{errors.manageremail && "E-mail Address is required"}</span>
            </InputGroup>
          </Col>
          <Col md="6 mb-3">
            <Label>Mobile Number</Label>
            <input
              className="form-control"
              name="mobilenumber"
              type="Number"
              placeholder="Mobile number"
              {...register("mobilenumber", { required: true })}
              defaultValue={props.values.MobileNumber}
            />
            <span>
              {errors.mobilenumber && "Please provide a valid Mobile Number"}
            </span>
            {/* <div className="ininvalid-feedback">{'Please provide a valid Mobile Number.'}</div> */}
          </Col>
        </Row>
        <Row>
          <Col md="12 mb-3">
            <Label>Company Logo</Label>
            <input
              className="form-control"
              name="companylogo"
              type="file"
              placeholder="Logo"
              onChange={handleFileInput}
            />
            {props.values && props.values.CompanyLogo && (
              <div>
                <img
                  src={logoUrl}
                  width="150px"
                  height="150px"
                  alt="Company Logo"
                />
              </div>
            )}
          </Col>
        </Row>
        <Btn attrBtn={{ color: "primary" }}>Continue</Btn>
      </Form>
    </Fragment>
  );
};

export default CompanyDetails;
