import { H5, P } from '../../AbstractElements';
import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Management from './management';
import Activity from './activity';

const SiteUserPanelDetails = () => {
    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
          <Tabs
              defaultActiveKey="Management"
              transition={false}
              id="Management"
              className="mb-3"
              >
              <Tab eventKey="Management" title="Management" id="Management">
                <Management />
              </Tab>
              <Tab eventKey="activity" title="Activities">
                <Activity />
              </Tab>
             
          </Tabs>
          </Row>
        </Container>
      </Fragment>
    );
};
export default SiteUserPanelDetails;





