import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import RegistrationElectricianContain from '../../Component/SiteOwner/RegistrationElectrician/Register';

const RegistrationElectrician = () => {
  return (
    <Fragment>
      <RegistrationElectricianContain />
    </Fragment>
  );
};
export default RegistrationElectrician;