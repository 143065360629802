import React, { Fragment } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import CategoriesSubListContain from "../../../Component/Site/SiteList/listSubCat";

const CategoriesSubList = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Site" title="" />
      <CategoriesSubListContain />
    </Fragment>
  );
};
export default CategoriesSubList;
