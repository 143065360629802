import React, { Fragment } from 'react';
import { Login } from '../../../Constant';
import { H4, H6 } from '../../../AbstractElements';

const FormHeader = () => {
    return (
        <Fragment>
            <div className="login-header text-center">
                <H6>Welcome back! Log in to your account.</H6>
            </div>
        </Fragment>
    );
};
export default FormHeader;