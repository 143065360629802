import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, Row,NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import CateGoryCertificates from './categoryCertificates';


const CatCertContain = () => {
  const [primarycolorTab, setprimarycolorTab] = useState('1');

  

  return (
    <Col>
      <CateGoryCertificates />           
    </Col>
  );
};

export default CatCertContain;