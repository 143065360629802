import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Btn } from '../../../AbstractElements';
import { Col, Form, Label, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as mutations from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import uuid from 'react-native-uuid';
import { toast } from 'react-toastify';

const AddServiceGroups = (props) => {
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  
  const [isEnable, setisEnable] = useState(true);
  const enableButton = (id) => {
    console.log(id);
    (id === 0 ? setisEnable(true) : setisEnable(false))
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  async function onSubmit(formData) {
    await API.graphql({
      query: mutations.createServiceGroup,
      variables: {input: {
          id: uuid.v4(),
          serviceName: formData.serviceGroupName,
          serviceProvider: formData.serviceProvider,
          status: 'Active'
      }},
      authMode: 'API_KEY'
    }).then(result => {
      console.log(result);
      toast.success('Service Group Saved Successfully', {
        position: toast.POSITION.TOP_CENTER
      });
    });
  }

  return (
    <Fragment>
      <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Add Service Group
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
          <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="6 mb-3">
                <Label>Service Group Name</Label>
                <input type="text" className="form-control" placeholder="Service Group Name" name="serviceGroupName" {...register('serviceGroupName', { required: true })} />
                <span>{errors.serviceGroupName && 'Please Enter Service Group Name.'}</span>
              </Col>
              <Col md="6 mb-3">
                <Label>Service Provider</Label>
                <input type="text" className="form-control" placeholder="Service Provider" name="serviceProvider" {...register('serviceProvider', { required: true })} />                  
                <span>{errors.serviceProvider && 'Please Select Service Provider.'}</span>
              </Col>
            </Row>
            <Btn attrBtn={{ color: 'primary' }}>Save</Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
export default AddServiceGroups;