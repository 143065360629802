import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import CatList from "../../Component/Reports/catList";

const CatListContain = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Reports" title="Choose Category" />
      <CatList />
    </Fragment>
  );
};
export default CatListContain;
