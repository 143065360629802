export const LITE =
  "https://buy.stripe.com/aEU8AwelYg2adLWfYY?client_reference_id=";
export const TIER1 =
  "https://buy.stripe.com/dR6040cdQ7vEbDOaEF?client_reference_id=";
export const TIER2 =
  "https://buy.stripe.com/00g9EA0v89DM6ju146?client_reference_id=";
export const TIER3 =
  "https://buy.stripe.com/cN29EAcdQ17g4bm4gj?client_reference_id=";
export const CORPORATE =
  "https://buy.stripe.com/aEU184a5IeY60Za5ko?client_reference_id=";
export const USERADDON =
  "https://buy.stripe.com/00g4kgfq29DM5fqbIP?client_reference_id=";
export const ASSETADDON =
  "https://buy.stripe.com/00g3gcdhUaHQ5fq28e?client_reference_id=";
export const DEVELOPERTESTING = "https://buy.stripe.com/cN29EA0v8eY64bm6ow?client_reference_id=";

// export const LITE =
//   "https://buy.stripe.com/test_9AQdUvfiO1r9dyw8wB?client_reference_id=";
// export const TIER1 =
//   "https://buy.stripe.com/test_6oEbMn1rY1r9cusdQU?client_reference_id=";
// export const TIER2 =
//   "https://buy.stripe.com/test_9AQ3fR5Iec5N8ec003?client_reference_id=";
// export const TIER3 =
//   "https://buy.stripe.com/test_4gwbMn9Yu5Hp8ec3ce?client_reference_id=";
// export const CORPORATE =
//   "https://buy.stripe.com/test_aEUg2D4Eagm3amk6op?client_reference_id=";
// export const USERADDON =
//   "https://buy.stripe.com/00g4kgfq29DM5fqbIP?client_reference_id=";
// export const ASSETADDON =
//   "https://buy.stripe.com/00g3gcdhUaHQ5fq28e?client_reference_id=";
