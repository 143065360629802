import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import SiteUserDetails from '../../Component/SiteOwnerPanel/siteUser';

const SiteUser = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Site" title="Manage Site Users" />
      <SiteUserDetails />
    </Fragment>
  );
};
export default SiteUser;