export const StandardData = [
  {
    id: 1,
    prize: '$10',
    title: 'Standard',
    month: '/month',
    storage1: '50GB',
    props1: 'Disk Space',
    storage2: '50',
    props2: 'Email Accounts',
    storage3: '50GB',
    props3: 'Bandwidth',
    storage4: '10',
    props4: 'Subdomains',
    storage5: '15',
    props5: 'Domains',
  },
  {
    id: 2,
    prize: '$20',
    title: 'PREMIUM',
    month: '/month',
    storage1: '20GB',
    props1: 'Disk Space',
    storage2: '20',
    props2: 'Email Accounts',
    storage3: '20GB',
    props3: 'Bandwidth',
    storage4: '20',
    props4: 'Subdomains',
    storage5: '20',
    props5: 'Domains',
  },
  {
    id: 3,
    prize: '$30',
    title: 'AUTHER PACK',
    month: '/month',
    storage1: '30GB',
    props1: 'Disk Space',
    storage2: '30',
    props2: 'Email Accounts',
    storage3: '30GB',
    props3: 'Bandwidth',
    storage4: '25',
    props4: 'Subdomains',
    storage5: '25',
    props5: 'Domains',
  },
  {
    id: 4,
    prize: '$40',
    title: 'BUSINESS',
    month: '/month',
    storage1: '40GB',
    props1: 'Disk Space',
    storage2: '40',
    props2: 'Email Accounts',
    storage3: '40GB',
    props3: 'Bandwidth',
    storage4: '30',
    props4: 'Subdomains',
    storage5: '30',
    props5: 'Domains',
  },
];

export const SmallCard = [
  {
    id: 1,
    title: 'Standard',
    prize: '$15',
  },
  {
    id: 2,
    title: 'Business',
    prize: '$25',
  },
  {
    id: 3,
    title: 'Premium',
    prize: '$35',
  },
  {
    id: 4,
    title: 'Extra',
    prize: '$45',
  },
];
