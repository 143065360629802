import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AddEditSPDetails from '../../Component/SiteOwnerPanel/AddEditSP/addEditSP';

const AddEditSP = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Service Providers" title="Manage Service Providers" />
      <AddEditSPDetails />
    </Fragment>
  );
};
export default AddEditSP;