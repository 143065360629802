import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import SiteListDetails from '../../Component/SiteUserPanel/FormDataTable';

const AssetTable = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="User panel" title="KYA User Panel" />
      <SiteListDetails />
    </Fragment>
  );
};
export default AssetTable;

