import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  FormGroup,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { PlusCircle } from "react-feather";
import { Btn, H5, H3, LI, UL } from "../../../AbstractElements";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import * as mergedQueries from "../../../graphql/mergedQueries";
import * as mutations from "../../../graphql/mutations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import LinkUserWithSite from "../AddSite/linkUserwithSite";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { useForm, setError, useFieldArray } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { useFormState } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { GOOGLEAPIKEY } from "../../../Config/Config";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { identity } from "rxjs";

const SiteViewDetails = () => {
  const navigate = useNavigate();
  const state = useLocation();

  const [siteDetails, setSiteDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [distributionSite, setDistributionSite] = useState([]);
  const [siteUsers, setSiteUsers] = useState([]);
  const [sudemo, setsudemo] = useState([]);
  const [companyName, setComanyName] = useState([]);
  const [collection, setCollection] = useState([]);
  const [userDetailsId, setUserDetailsId] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [spdemo, setspdemo] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [assets, setAssets] = useState([]);
  const [assetsdemo, setAssetsdemo] = useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);

  async function fetchSiteDetails(id) {
    setIsLoading(true);
    try {
      const result = await API.graphql({
        query: queries.listSiteDetails,
        variables: {
          filter: {
            id: {
              eq: id,
            },
          },
          limit: 10000,
        },
        authMode: "API_KEY",
      });
      const siteDetailsData = result.data.listSiteDetails.items;
      console.log(siteDetailsData)
      setSiteDetails(result.data.listSiteDetails.items);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setErrorMessage("Unable to fetch the site details");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchSiteDetails(state.state.id);
  }, []);

  async function fetchDistributionDetails(siteId) {
    setIsLoading(true);
    await API.graphql({
      query: queries.distributionBoxesBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        limit: 10000,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setDistributionSite(result.data.distributionBoxesBySitedetailsID.items);
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Unable to fetch the list");
        setIsLoading(false);
      });
  }

  async function fetchAssets(siteId) {
    setIsLoading(true);
    await API.graphql({
      query: queries.listAssets,
      variables: {
        filter:{
          siteId:{
            eq: siteId
          }
        },
        limit: 10000,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        setAssetsdemo(result.data.listAssets.items);
        setAssets(cloneDeep(result.data.listAssets.items.slice(0, countPerPage)));
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Unable to fetch the list");
        setIsLoading(false);
      });
  }

  function getDB(dbid) {
    const distri = distributionSite.find((item) => item.id === dbid);
    return distri ? distri.distributionBoxName : "";
  }

  useEffect(() => {
    fetchDistributionDetails(state.state.id);
    fetchAssets(state.state.id);
  }, []);

  //siteusers and service providers
  async function fetchUsersServiceProvidersDetails(siteId) {
    setIsLoading(true);
    await API.graphql({
      query: queries.linkUsersAndSitesBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        limit: 10000,
      },
      authMode: "API_KEY",
    })
      .then((resultnew) => {
        console.log(resultnew);
        const siteDetails =
          resultnew.data.linkUsersAndSitesBySitedetailsID.items;
        if (siteDetails.length > 0) {
          API.graphql({
            query: queries.listSPUserDetails,
            variables: {
              limit: 10000,
            },
          })
            .then((response) => {
              console.log(response);
              const linkedUsers = response.data.listSPUserDetails.items.filter(
                (item) => {
                  const _matchSites = siteDetails?.find(
                    (site) => site.userdetailsID === item?.id
                  );
                  if (_matchSites) {
                    return item;
                  }
                }
              );
              if (linkedUsers.length > 0) {
                const users = linkedUsers.filter(
                  (user) => user.userGroup === "site_user"
                );
                const providers = linkedUsers.filter(
                  (user) => user.userGroup === "electrician"
                );
                setsudemo(users);
                //setSiteUsers(users);
                setSiteUsers(cloneDeep(users.slice(0, countPerPage)));
                setspdemo(providers);
                //setServiceProviders(providers);
                setServiceProviders(cloneDeep(providers.slice(0, countPerPage)));
              } else {
                setSiteUsers([]);
                setspdemo([]);                
                setServiceProviders([]);
              }
            })
            .catch((error) => {
              console.log(error);
              setErrorMessage("Unable to fetch the list");
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Unable to fetch the list");
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchUsersServiceProvidersDetails(state.state.id);
  }, []);

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/sites`);
  };

  async function fetchCompanyDetails() {
    await API.graphql({
      query: queries.listCompanyDetails,
      authMode: "API_KEY",
      variables: {
        limit: 10000,
      },
    })
      .then((dataDetails) => {
        const companyNames = dataDetails.data.listCompanyDetails.items;
        API.graphql({
          query: queries.listSPCompanyDetails,
          authMode: "API_KEY",
          variables: {
            limit: 10000,
          },
        })
          .then((dataDetailsSP) => {
            const companyNamesSP =
              dataDetailsSP.data.listSPCompanyDetails.items;
            console.log(companyNamesSP);
            let CompanyNamesList = [];
            CompanyNamesList.push(...companyNamesSP);
            CompanyNamesList.push(...companyNames);
            setCompanyDetails(CompanyNamesList);
          })
          .catch((errors) => {
            console.log(errors);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  function getCompanyName(companyId) {
    const company = companyDetails.find((item) => item.id === companyId);
    return company ? company.companyName : "";
  }

  //remove
  async function removeLink(row) {
    setIsLoading(true);
    console.log(row);
    await API.graphql({
      query: queries.listLinkUsersAndSites,
      variables: {
        filter: {
          userdetailsID: {
            eq: row.id,
          },
          sitedetailsID: {
            eq: state.state.id,
          },
        },
        limit: 10000,
      },
    })
      .then((response) => {
        const linkUsersAndSite = response.data.listLinkUsersAndSites.items;
        if (linkUsersAndSite.length === 0) {
          setIsLoading(false);
          toast.error("Link user and site not found", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const id = linkUsersAndSite[0].id;
          console.log("id");
          console.log(id);
          API.graphql({
            query: mutations.deleteLinkUsersAndSite,
            variables: { input: { id: id } },
            authMode: "API_KEY",
          })
            .then((result) => {
              setIsLoading(false);
              console.log(result);
              fetchUsersServiceProvidersDetails(state.state.id);
              toast.success(
                "User was unlinked from the site " + siteDetails[0].unitNumber,
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );
            })
            .catch((error) => {
              setIsLoading(false);
              console.log(error);
              toast.error("Unable to remove link", {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error("Unable to remove link", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  const updatePage1 = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setAssets(cloneDeep(assetsdemo.slice(from, to)));
  };

  const updatePage2 = (p) => {
    setCurrentPage1(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setServiceProviders(cloneDeep(spdemo.slice(from, to)));
  };

  const updatePage3 = (p) => {
    setCurrentPage2(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setSiteUsers(cloneDeep(sudemo.slice(from, to)));
  };

  return (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <Col md="12" className="project-list">
          <Card className="custom-card">
            {siteDetails.map((item) => (
              <div key={item.id}>
                <H3>{item.siteName} Site Details</H3>
                <Row>
                  <Col md="6 mb-3">
                    <Label>Site Name: </Label>
                    <Label>{item.siteName}</Label>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Company Name: </Label>
                    <Label>{getCompanyName(item.companydetailsID)}</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="6 mb-3">
                    <Label>Address: </Label>
                    <Label>
                      {item.unitNumber}
                    </Label>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Distribution Boards:</Label>
                    <br />
                    <ul>
                      {distributionSite.map((item, i) => (
                        <li key={i}>{item.distributionBoxName}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </div>
            ))}
          </Card>
          <Row>
            <Col md="12">
              <H3>Site Users & Service Providers</H3>
              <Tabs defaultActiveKey="site-users">
                <Tab eventKey="site-users" title="Site Users">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Added On</th>
                          <th>User Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {siteUsers.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {item.fname} {item.lname}
                            </td>
                            <td>{item.email}</td>
                            <td>
                              {moment(item.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>Site Users</td>
                            <td>
                              <Btn
                                attrBtn={{
                                  className: "btn-outline-* btn-xs m-r-5",
                                  color: "primary",
                                  onClick: () => removeLink(item),
                                }}
                              >
                                Remove
                              </Btn>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <CardFooter className="row text-center">
                      <Pagination
                        className="p-t-10"
                        pageSize={countPerPage}
                        onChange={updatePage3}
                        current={currentPage2}
                        total={
                          sudemo.length
                        }
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total} items`
                        }
                      />
                    </CardFooter>
                </Tab>
                <Tab eventKey="service-providers" title="Service Providers">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Added On</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {serviceProviders.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {item.fname} {item.lname}
                            </td>
                            <td>{item.email}</td>
                            <td>
                              {moment(item.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              <Btn
                                attrBtn={{
                                  className: "btn-outline-* btn-xs m-r-5",
                                  color: "primary",
                                  onClick: () => removeLink(item),
                                }}
                              >
                                Remove
                              </Btn>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <CardFooter className="row text-center">
                      <Pagination
                        className="p-t-10"
                        pageSize={countPerPage}
                        onChange={updatePage2}
                        current={currentPage1}
                        total={
                          spdemo.length
                        }
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total} items`
                        }
                      />
                    </CardFooter>
                </Tab>
                <Tab eventKey="assets" title="Assets">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>Added On</th>
                          <th>Fitting Number</th>
                          <th>Location</th>
                          <th>Distribution Board</th>
                          <th>Asset Type</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {assets.map((item, i) => (
                          <tr key={i}>                           
                            <td>
                              {moment(item.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>{item.fittingNumber}</td>
                            <td>{item.assetLocation}</td>
                            <td>{getDB(item.distributionboxID)}</td>    
                            <td>{item.assetType}</td>                        
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <CardFooter className="row text-center">
                      <Pagination
                        className="p-t-10"
                        pageSize={countPerPage}
                        onChange={updatePage1}
                        current={currentPage}
                        total={
                          assetsdemo.length
                        }
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total} items`
                        }
                      />
                    </CardFooter>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="text-end">
          <br></br>
          <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>Back</Btn>
        </Col>
      </Container>
    </Fragment>
  );
};

export default SiteViewDetails;
