import {
  BlogSvg,
  BonusUISvg,
  ButtonsSvg,
  CalanderSvg,
  ChartsSvg,
  ChatSvg,
  ContactSvg,
  EcommerceSvg,
  EditorsSvg,
  EmailSvg,
  FAQSvg,
  FilemanagerSvg,
  FormsSvg,
  GallarySvg,
  HeaderBookmarkSvg,
  HomeSvg,
  IconsSvg,
  JobsearchSvg,
  KanbanSvg,
  KnowledgebaseSvg,
  LearningSvg,
  MapsSvg,
  OthersSvg,
  ProjectSvg,
  SamplePageSvg,
  SearchResultSvg,
  SocialappSvg,
  SupportTicketSvg,
  TablesSvg,
  TaskSvg,
  TodoSvg,
  UiKitsSvg,
  UsersComponentSvg,
  WidgetsSvg,
  SidebarHomeIcon,
  SidebarSiteIcon,
  SidebarServiceIcon,
  SidebarAssetsIcon,
  SidebarSubscriptionsIcon,
  SidebarLogbooksIcon,
  SidebarReportsIcon,
  SidebarCertificatesIcon,
  SidebarBulkImportIcon,
} from "../../Data/svgIcons";

const group = localStorage.getItem("groups");
console.log(group);

export const MENUITEMS = [
  {
    menutitle: "DashBoard",
    Items: [
      {
        path:
          group === "site_owner"
            ? `${process.env.PUBLIC_URL}/dashboardsao`
            : group === "spao"
            ? `${process.env.PUBLIC_URL}/dashboardsao`
            : `${process.env.PUBLIC_URL}/dashboard/default`,
        bookmark: true,
        title: "Dashboard",
        icon: SidebarHomeIcon,
        type: "link",
      },
    ],
  },
  {
    menutitle: "Sites",
    Items: [
      {
        title: "Sites",
        icon: SidebarSiteIcon,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/sites`,
            title: "Manage Sites",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/siteownerpanel/siteUser`,
            title: "Manage Site Users",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/sites/pendingsiteuser`,
            title: "Pending Site Users",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Service Providers",
    Items: [
      {
        title: "Service Providers",
        icon: SidebarServiceIcon,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/siteownerpanel/serviceprovider`,
            title: "Service Provider",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/siteownerpanel/pendingserviceprovider`,
            title: "Pending Service Provider",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Assets",
    Items: [
      {
        title: "Assets",
        icon: SidebarAssetsIcon,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/catassetlist`,
            title: "Assets",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/assettemplates`,
            title: "Asset Templates",
            type: "link",
          },
        ],
      },
    ],
  },
  group === "site_owner" && {
    menutitle: "Subscriptions",
    Items: [
      {
        title: "Subscriptions",
        icon: SidebarSubscriptionsIcon,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/subscriptions`,
            title: "Subscriptions",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/transactions`,
            title: "Transactions",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Logbooks",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/generatereportfasset`,
        bookmark: true,
        title: "Logbooks",
        icon: SidebarLogbooksIcon,
        type: "link",
      },
    ],
  },
  {
    menutitle: "Reports",
    Items: [
      {
        title: "Reports",
        icon: SidebarReportsIcon,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/reports/catList`,
            title: "Generate Reports",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Certificates",
    Items: [
      {
        title: "Certificates",
        icon: SidebarCertificatesIcon,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/certificates`,
            title: "Certificates",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Tasks",
    Items: [
      {
        title: "Tasks",
        icon: SidebarHomeIcon,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/tasksao`,
            title: "Manage Tasks",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/tasksao/addtasks`,
            title: "Add Tasks",
            type: "link",
          },
        ],
      },
    ],
  },
].filter(Boolean);
