import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { Amplify, API } from "aws-amplify";
import { Col, Media, Row } from 'reactstrap';
import * as queries from "../../../graphql/queries";
import { H3, H4, P, Image } from '../../../AbstractElements';
import { InvoiceHash, IssuedMay, PaymentDue } from '../../../Constant';
import login from '../../../assets/images/logo/logo-icon.png';
import awsExports from "../../../aws-exports";
import AWS from "aws-sdk";
import {
  S3_BUCKET,
  REGION,
  COMPANY_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../../Config/Config";

Amplify.configure(awsExports);

const InvoiceHeader = () => {
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  console.log(LoginUserDetails);

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const LoginUserGroup = localStorage.getItem("groups");
  console.log(LoginUserGroup);
  const state = useLocation();
  console.log(state);
  const [company, setCompany] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [downloadedFile, setdownloadedFile] = useState(null);

  const stripe = require('stripe')('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');

  async function inputinvoice() {
    try {
      const invoiceId = state.state?.row?.invoiceId;

      if (!invoiceId) {
        throw new Error("Invoice ID is missing.");
      }

      const invoice = await stripe.invoices.retrieve(invoiceId);
      console.log(invoice);
      setInvoice(invoice);
    }
    catch (error) {
      console.log("Error retrieving invoice:", error.message);
    }
  }

  async function fetchcompany() {
    const company = await API.graphql({
      query: queries.getCompanyDetails,
      authMode: "API_KEY",
      variables: {
        id: LoginUserDetails.companyDetailsId,
      },
    });
    console.log(company);
    getfilename(company.data.getCompanyDetails.logo);
    setCompany(company.data.getCompanyDetails);
  }

  useEffect(() => {
    fetchcompany();
    inputinvoice();
  }, [])

  const getImage = (filename) => {
    var params = {
      Bucket: S3_BUCKET,
      Key: `public/${COMPANY_FOLDER}/${filename}`,
    };
    var promise = myBucket.getSignedUrlPromise("getObject", params);
    promise.then(
      function (url) {
        setdownloadedFile(url);
      },
      function (err) {
        console.log(err);
      }
    );
  };
  //-------------------------------------------------------------------------
  function getfilename(path) {
    try {
      console.log(path)
      if (path.length > 0) {
        var newfilename = path[0].fileName;
        getImage(path);
      }
    } catch (error) {
      console.log("Error parsing JSON input:", error);
    }
  }


  return (
    <Fragment>
      <Row>
        <Col sm="6">
          <Media>
            <div className="media-left">
              <Link to={`${process.env.PUBLIC_URL}/dashboardsao`}>
                <Image attrImage={{ className: 'media-object img-60', src: `${downloadedFile}`, alt: '' }}
                />
              </Link>
            </div>
            <Media body className="m-l-20">
              <H4 attrH4={{ className: 'media-heading' }}>{company.companyName}</H4>
              <P> {company.email}
                <br />
                <span>{company.mobile}</span>
              </P>
            </Media>
          </Media>
        </Col>
        <Col sm="6">
          <div className="text-md-end text-xs-center">
            <H3>
              {/* {InvoiceHash} */}
              <span className="counter">{invoice.number}</span>
            </H3>
            <P>
              Issued:
              <span> {state.state.row.startDate}</span>
              <br />
              Payment Due:{' '}
              <span> {state.state.row.dueDate}</span>
            </P>
          </div>
        </Col>
      </Row>
      <hr />
    </Fragment >
  );
};
export default InvoiceHeader;