import React, { Fragment } from "react";
import PaymentStatusContain from "../../Component/SiteOwner/PaymentStatus";

const PaymentStatus = () => {
  return (
    <Fragment>
      <PaymentStatusContain />
    </Fragment>
  );
};
export default PaymentStatus;
