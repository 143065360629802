import {
  BlogSvg,
  BonusUISvg,
  ButtonsSvg,
  CalanderSvg,
  ChartsSvg,
  ChatSvg,
  ContactSvg,
  EcommerceSvg,
  EditorsSvg,
  EmailSvg,
  FAQSvg,
  FilemanagerSvg,
  FormsSvg,
  GallarySvg,
  HeaderBookmarkSvg,
  HomeSvg,
  IconsSvg,
  JobsearchSvg,
  KanbanSvg,
  KnowledgebaseSvg,
  LearningSvg,
  MapsSvg,
  OthersSvg,
  ProjectSvg,
  SamplePageSvg,
  SearchResultSvg,
  SocialappSvg,
  SupportTicketSvg,
  TablesSvg,
  TaskSvg,
  TodoSvg,
  UiKitsSvg,
  UsersComponentSvg,
  WidgetsSvg,
  SidebarHomeIcon,
  SidebarSiteIcon,
  SidebarServiceIcon,
  SidebarAssetsIcon,
  SidebarSubscriptionsIcon,
  SidebarLogbooksIcon,
  SidebarReportsIcon,
  SidebarCertificatesIcon,
  SidebarBulkImportIcon,
} from "../../Data/svgIcons";

const group = localStorage.getItem("groups");
console.log(group);

export const MENUITEMS = [
  {
    menutitle: "Dashboard",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboardsao`,
        bookmark: true,
        title: "Dashboard",
        icon: SidebarLogbooksIcon,
        type: "link",
      },
    ],
  },
  {
    menutitle: "Service Providers",
    Items: [
      {
        title: "Service Providers",
        icon: SidebarServiceIcon,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/siteownerpanel/serviceprovider`,
            title: "Service Provider",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/siteownerpanel/pendingserviceprovider`,
            title: "Pending Service Provider",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Logbooks",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/generatereportfasset`,
        bookmark: true,
        title: "Logbooks",
        icon: SidebarLogbooksIcon,
        type: "link",
      },
    ],
  },
  {
    menutitle: "Reports",
    Items: [
      {
        title: "Reports",
        icon: SidebarReportsIcon,
        type: "link",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/reports/catList`, //`${process.env.PUBLIC_URL}/reports`,
            title: "Generate Reports",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Certificates",
    Items: [
      {
        title: "Certificates",
        icon: SidebarCertificatesIcon,
        type: "link",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/certificates`,
            title: "Certificates",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Asset Owner Menu",
    Items: [
      {
        title: "Asset Owner Menu",
        icon: SidebarSiteIcon,
        type: "sub",
        children: [
          //{
          //  path: `${process.env.PUBLIC_URL}/dashboardsao`,
          //  bookmark: true,
          //  title: "Dashboard",
          //  icon: SidebarHomeIcon,
          //  type: "link",
          //},
          {
            title: "Sites",
            type: "link",
            type: "sub",
            children: [
              {
                path: `${process.env.PUBLIC_URL}/sites`,
                title: "Manage Sites",
                type: "link",
              },
              {
                path: `${process.env.PUBLIC_URL}/siteownerpanel/siteUser`,
                title: "Manage Site Users",
                type: "link",
              },

              {
                path: `${process.env.PUBLIC_URL}/sites/pendingsiteuser`,
                title: "Pending Site Users",
                type: "link",
              },
            ],
          },
          //{
          //  title: "Service Providers",
          //  icon: SidebarServiceIcon,
          //  type: "sub",
          //  children: [
          //    {
          //      path: `${process.env.PUBLIC_URL}/siteownerpanel/serviceprovider`,
          //      title: "Service Provider",
          //      type: "link",
          //    },

          //    {
          //      path: `${process.env.PUBLIC_URL}/siteownerpanel/pendingserviceprovider`,
          //      title: "Pending Service Provider",
          //      type: "link",
          //    },
          //  ],
          //},
          {
            title: "Assets",
            icon: SidebarAssetsIcon,
            type: "link",
            type: "sub",
            children: [
              {
                path: `${process.env.PUBLIC_URL}/catassetlist`,
                title: "Assets",
                type: "link",
              },
              {
                path: `${process.env.PUBLIC_URL}/assettemplates`,
                title: "Asset Templates",
                type: "link",
              },
            ],
          },
          {
            title: "Subscriptions",
            icon: SidebarSubscriptionsIcon,
            type: "link",
            type: "sub",
            children: [
              {
                path: `${process.env.PUBLIC_URL}/subscriptions`,
                title: "Subscriptions",
                type: "link",
              },
            ],
          },
          //{
          //  path: `${process.env.PUBLIC_URL}/generatereportfasset`,
          //  bookmark: true,
          //  title: "Logbooks",
          //  icon: SidebarLogbooksIcon,
          //  type: "link",
          //},
          //{
          //  title: "Reports",
          //  icon: SidebarReportsIcon,
          //  type: "link",
          //  type: "sub",
          //  children: [
          //    {
          //      path: `${process.env.PUBLIC_URL}/reports/catList`, //`${process.env.PUBLIC_URL}/reports`,
          //      title: "Generate Reports",
          //      type: "link",
          //    },
          //  ],
          //},
          //{
          //  title: "Certificates",
          //  icon: SidebarCertificatesIcon,
          //  type: "link",
          //  type: "sub",
          //  children: [
          //    {
          //      path: `${process.env.PUBLIC_URL}/certificates`,
          //      title: "Certificates",
          //      type: "link",
          //    },
          //  ],
          //},
          {
            title: "Tasks",
            icon: SidebarHomeIcon,
            type: "link",
            type: "sub",
            children: [
              {
                path: `${process.env.PUBLIC_URL}/tasksaosp`,
                title: "Manage Tasks",
                type: "link",
              },
              {
                path: `${process.env.PUBLIC_URL}/tasksao/addtasks`,
                title: "Add Tasks",
                type: "link",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menutitle: "Service Provider Menu",
    Items: [
      {
        title: "Service Provider Menu",
        icon: SidebarSiteIcon,
        type: "sub",
        children: [
          //{
          //  path: `${process.env.PUBLIC_URL}/dashboard/default`,
          //  bookmark: true,
          //  title: "Dashboard",
          //  icon: SidebarHomeIcon,
          //  type: "link",
          //},
          //{
          //  title: "Sites",
          //  type: "link",
          //  type: "sub",
          // children: [
          //   {
          //     path: `${process.env.PUBLIC_URL}/sites`,
          //     title: "Manage Sites",
          //     type: "link",
          //   },
          //   {
          //     path: `${process.env.PUBLIC_URL}/siteownerpanel/siteUser`,
          //     title: "Manage Site Users",
          //     type: "link",
          //   },
          //   {
          //     path: `${process.env.PUBLIC_URL}/sites/pendingsiteuser`,
          //     title: "Pending Site Users",
          //     type: "link",
          //   },
          // ],
          //},
          //{
          //  title: "Service Providers",
          //  icon: SidebarServiceIcon,
          //  type: "sub",
          // children: [
          //    {
          //      path: `${process.env.PUBLIC_URL}/siteownerpanel/serviceprovider`,
          //      title: "Service Provider",
          //      type: "link",
          //    },

          //    {
          //      path: `${process.env.PUBLIC_URL}/siteownerpanel/pendingserviceprovider`,
          //      title: "Pending Service Provider",
          //      type: "link",
          //    },
          //  ],
          //},
          //{
          //  title: "Assets",
          //  icon: SidebarAssetsIcon,
          //  type: "link",
          //  type: "sub",
          //  children: [
          //    {
          //      path: `${process.env.PUBLIC_URL}/catassetlist`,
          //      title: "Assets",
          //      type: "link",
          //    },
          //    {
          //      path: `${process.env.PUBLIC_URL}/assettemplates`,
          //      title: "Asset Templates",
          //      type: "link",
          //    },
          //  ],
          //},
          //{
          //  title: "Subscriptions",
          //  icon: SidebarSubscriptionsIcon,
          //  type: "link",
          //  type: "sub",
          //  children: [
          //   {
          //      path: `${process.env.PUBLIC_URL}/subscriptions`,
          //      title: "Subscriptions",
          //      type: "link",
          //    },
          //  ],
          //},
          //{
          //  path: `${process.env.PUBLIC_URL}/generatereportfasset`,
          //  bookmark: true,
          //  title: "Logbooks",
          //  icon: SidebarLogbooksIcon,
          //  type: "link",
          //},
          //{
          //  title: "Reports",
          //  icon: SidebarReportsIcon,
          //  type: "link",
          //  type: "sub",
          //  children: [
          //    {
          //      path: `${process.env.PUBLIC_URL}/reports/catList`, //`${process.env.PUBLIC_URL}/reports`,
          //      title: "Generate Reports",
          //      type: "link",
          //    },
          //  ],
          //},
          //{
          //  title: "Certificates",
          //  icon: SidebarCertificatesIcon,
          //  type: "link",
          //  type: "sub",
          //  children: [
          //    {
          //      path: `${process.env.PUBLIC_URL}/certificates`,
          //      title: "Certificates",
          //      type: "link",
          //    },
          //  ],
          //},
          {
            title: "Tasks",
            icon: SidebarHomeIcon,
            type: "link",
            type: "sub",
            children: [
              {
                path: `${process.env.PUBLIC_URL}/taskssp`,
                title: "Manage Tasks",
                type: "link",
              },
              {
                path: `${process.env.PUBLIC_URL}/tasksao/addtasks`,
                title: "Add Tasks",
                type: "link",
              },
            ],
          },
        ],
      },
    ],
  },
];
