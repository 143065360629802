var base64 = require("base-64");
var AssetTemplate = (function () {
  var assetTemplateDetails = "";

  var getAssetTemplate = function () {
    try {
      if (assetTemplateDetails === "") {
        assetTemplateDetails = JSON.parse(
          base64.decode(localStorage.getItem("assetTemp"))
        );
      }
    } catch (error) {
      console.log(error);
    }
    return assetTemplateDetails; // Or pull this from cookie/localStorage
  };

  var setAssetTemplate = function (assetTemplate) {
    try {
      if (assetTemplate === "") {
        assetTemplateDetails = JSON.parse(
          base64.decode(localStorage.getItem("assetTemp"))
        );
      } else {
        assetTemplateDetails = assetTemplate;
      }
    } catch (error) {
      console.log(error);
    }
    // Also set this in cookie/localStorage
  };

  return {
    getAssetTemplate: getAssetTemplate,
    setAssetTemplate: setAssetTemplate,
  };
})();

export default AssetTemplate;
