import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import SiteViewDetails from '../../../Component/Site/SiteList/siteView';

const SiteView = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Site" title="Site View" />
      <SiteViewDetails />
    </Fragment>
  );
};
export default SiteView;