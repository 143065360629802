import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { P } from '../../../AbstractElements';
import { CreateAccount } from '../../../Constant';

const SignInWith = () => {
    return (
        <Fragment>
            <div className='login-social-title'></div>
            <br></br>
            <P>Don't have account?
                <Link to={`${process.env.PUBLIC_URL}/registeroption`} className="ms-2">
                    {CreateAccount}
                </Link>
            </P>
        </Fragment>
    );
};
export default SignInWith;