import { Btn, H3, H6 } from "../../../AbstractElements";
import { useForm, useFieldArray } from "react-hook-form";
import React, { Fragment } from "react";
import { useState, useEffect, useCallback } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import {
    User_FOLDER,
    S3_BUCKET,
    REGION,
    ACCESSKEYID,
    SECRETACCESSKEY,
    USERPOOLID,
} from "../../../Config/Config";

const SpContain = (props) => {
    const [uploadedPP, setUploadedPP] = useState([]);
    const [compId, setcompId] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);    
    const [downloadedFile, setdownloadedFile] = useState(null);
    const history = useNavigate();
    AWS.config.update({
        accessKeyId: ACCESSKEYID,
        secretAccessKey: SECRETACCESSKEY,
    });
    const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
        apiVersion: "2022-10-03",
        region: REGION,
    });
    const myBucket = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });
    const { state } = useLocation();
    const { register, control, handleSubmit,        
    formState: { errors },
        reset, watch } = useForm({
        defaultValues: {
            userDetails: [
                {
                    firstName: "",
                    lastName: "",
                    email: "",
                    mobile: "",
                    license: "",
                    profilePic: "",
                },
            ],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "userDetails",
    });
    useEffect(() => {
        console.log(props);
    }, []);
    //---------------------------------------------------------------------

    let cdId;
    const onSubmit = useCallback(async (data) => {
        console.log(data)
        console.log(props.values.CompanyIdSelected)
        console.log(props.values)
      
        props.values.firstName = data.firstName;
        props.values.lastName = data.lastName;
        props.values.spemail = data.email;
        props.values.spmobileNumber = data.mobileNumber;
        props.values.splicence = data.license;
        props.values.spProfilepic = selectedFile;

        props.nextStep();
        
    });

    //----------------------------------------------------------------------------------
    const onSelectFile = (e) => {
        const file = e.target.files[0];
        const params = {
          ACL: "public-read",
          Body: file,
          Bucket: S3_BUCKET,
          Key: `public/${User_FOLDER}/${file.name}`,
        };
        myBucket
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            const filedetails = [];
            filedetails.push({ fileName: file.name, type: file.type });
            console.log(filedetails[0].fileName);
            setSelectedFile(filedetails[0].fileName);
          })
          .send((err) => {
            if (err) console.log(err);
          });
    };

    const getImage = (filename) => {
        var params = {
          Bucket: S3_BUCKET,
          Key: `public/${User_FOLDER}/${filename}`,
        };
        var promise = myBucket.getSignedUrlPromise("getObject", params);
        promise.then(
          function (url) {
            setdownloadedFile(url);
          },
          function (err) {
            console.log(err);
          }
        );
      };
      //-----------------------------------------------------------------------------
      function getfilename(path) {
        getImage(path);
      }

    return (
        <Fragment>
            <H6>Enter Your Personal Details</H6>
            <Form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
            >

                <Row>
                    <Col md="5 mb-3">
                        <Label>First Name</Label>
                        <input
                            className="form-control"
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            {...register(`firstName`, {
                                required: true,
                            })}
                        />
                        <span>{errors.firstName && "First Name is required"}</span>
                    </Col>
                    <Col md="5 mb-3">
                        <Label>Last Name</Label>
                        <input
                            className="form-control"
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            {...register(`lastName`, {
                                required: true,
                            })}
                        />
                        <span>{errors.lastName && "Lat Name is required"}</span>
                    </Col>
                </Row>
                <Row>
                    <Col md="5 mb-3">
                        <Label htmlFor="validationCustomUsername">E-Mail</Label>
                        <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="E-Mail"
                            {...register(`email`, {
                                required: true,
                            })}
                        />
                        <span>{errors.email && "Email is required"}</span>
                    </Col>
                    <Col md="5 mb-3">
                        <Label>Mobile Number</Label>
                        <input
                            className="form-control"
                            type="Number"
                            name="mobileNumber"
                            placeholder="Mobile Number"
                            {...register(`mobileNumber`, {
                                required: true,
                            })}
                        />
                        <span>{errors.mobileNumber && "Mobile Number is required"}</span>
                    </Col>
                </Row>
                <Row>
                    <Col md="5 mb-3">
                        <Label>License #</Label>
                        <input
                            className="form-control"
                            type="text"
                            name="license"
                            placeholder="License #"
                            {...register(`license`, {
                                required: true,
                            })}
                        />
                        <span>{errors.license && "Licence is required"}</span>
                    </Col>
                    <Col md="5 mb-3">
                        <Label>Profile Picture</Label>
                        <input
                            className="form-control"
                            type="file"
                            name="profilePic"
                            placeholder="Profile Picture"
                            onChange={onSelectFile}                            
                        />
                    </Col>
                </Row>
                <br></br>
                <Btn attrBtn={{ color: "primary", onClick: props.prevStep }}>Back</Btn>
                <Btn attrBtn={{ color: "primary" }}>Continue</Btn>
                <br></br>
            </Form>
            <br></br>
        </Fragment>
    );
};
export default SpContain;
