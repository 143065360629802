export const userPanelDetails = /* GraphQL */ `
  query userPanelDetails {
    listServiceGroups {
      items {
        serviceName
        id
        serviceProvider
        Categories {
          items {
            categoryName
            id
            servicegroupID
            formIds
            AssetTemplates {
              items {
                id
                servicegroupID
                templatename
                categoriesID
                Assets {
                  items {
                    assetDescription
                    assetLocation
                    assetMake
                    assetModel
                    assetSerialNumber
                    assetType
                    assetWarrantyEnd
                    assetWarrantyStart
                    assettemplateID
                    createdAt
                    updatedAt
                    status
                    siteId
                    installedOn
                    id
                    formFieldValues
                    distributionboxID
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const loadCategories = /* GraphQL */ `
  query loadCategories {
    listServiceGroups {
      items {
        serviceName
        serviceProvider
        id
        Categories {
          items {
            _deleted
            _lastChangedAt
            _version
            categoryName
            categoryNumber
            createdAt
            formDescription
            formIds
            id
            servicegroupID
            status
            updatedAt
          }
        }
      }
    }
  }
`;
export const loadSiteDetails = /* GraphQL */ `
  query loadSiteDetails {
    listCompanyDetails {
      items {
        companyName
        companyAddress
        id
        status
        SiteDetails {
          items {
            companydetailsID
            unitNumber
            suburb
            street
            status
            state
            postCode
            id
            country
            createdAt
          }
        }
        AssetOwners {
          items {
            companyName
            firstName
            lastName
            status
            id
          }
        }
      }
    }
  }
`;
export const getSiteById = /* GraphQL */ `
  query getSiteById($id: ID!) {
    getSiteDetails(id: $id) {
      assetownerID
      companydetailsID
      country
      createdAt
      id
      postCode
      status
      state
      suburb
      street
      unitNumber
      updatedAt
      DistributionBoxes {
        items {
          distributionBoxNumber
          distributionBoxName
          id
          location
        }
      }
    }
  }
`;
export const getUserLoginDetails = /* GraphQL */ `
  query getUserLoginDetails($userdetailsID: ID) {
    listSiteDetails {
      items {
        updatedAt
        unitNumber
        suburb
        street
        status
        postCode
        state
        id
        createdAt
        country
        companydetailsID
        assetownerID
        _version
        _lastChangedAt
        _deleted
        LinkUsersAndSites(filter: { userdetailsID: { eq: $userdetailsID } }) {
          items {
            userdetailsID
            updatedAt
            status
            sitedetailsID
            id
            createdAt
            _lastChangedAt
            _deleted
            _version
          }
        }
      }
    }
  }
`;
export const getAssetsFromCategory = /* GraphQL */ `
  query getAssetsFromCategory(
    $filter: ModelAssetTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Assets {
          items {
            assetModel
            assetMake
            assetLocation
            assetDescription
            assetNumber
            assetSerialNumber
            assetType
            assetWarrantyEnd
            assetWarrantyStart
            assettemplateID
            batteryTypeId
            createdAt
            distributionboxID
            fittingTypeId
            formFieldValues
            installedOn
            lampTypeId
            siteId
            status
            updatedAt
            id
          }
        }
        updatedAt
        templatename
        status
        servicegroupID
        id
        formId
        categoriesID
        createdAt
        description
      }
    }
  }
`;
export const getTestingDataForAsset = /* GraphQL */ `
  query getTestingDataForAsset {
    listAssets {
      items {
        Testings {
          items {
            additionalInformation
            assetsID
            calendar
            createdAt
            customFields
            dataModelName
            electrician
            emailNofication
            fittingId
            id
            nextTestSchedule
            reminder
            status
            testOn
            testReport
            testResult
            updatedAt
            warranty
          }
        }
        updatedAt
        status
        siteId
        installedOn
        id
        formFieldValues
        distributionboxID
        createdAt
        assettemplateID
        assetWarrantyStart
        assetWarrantyEnd
        assetType
        assetSerialNumber
        assetModel
        assetMake
        assetLocation
        assetDescription
      }
    }
  }
`;
export const checkCategoryUsed = /* GraphQL */ `
  query checkCategoryUsed {
    listCategories {
      items {
        AssetTemplates {
          items {
            Assets {
              items {
                Testings {
                  items {
                    id
                  }
                }
                Repairs {
                  items {
                    id
                  }
                }
                LogBooks {
                  items {
                    id
                  }
                }
                id
              }
            }
            id
          }
        }
        id
        categoryName
        categoryNumber
        formIds
        formDescription
        servicegroupID
        status
        updatedAt
        createdAt
      }
    }
  }
`;
export const getLoggedInUserCategories = /* GraphQL */ `
  query getLoggedInUserCategories {
    listServiceGroups {
      items {
        Categories {
          items {
            categoryName
            id
            formIds
            servicegroupID
            status
          }
        }
        serviceProvider
        serviceName
        id
        status
      }
    }
  }
`;
export const getTestingByCategory = /*GraphQL */ `
query getTestingByCategory($categoriesID: ID!, $sitedetailsID: ID) {
  assetTemplatesByCategoriesID(categoriesID: $categoriesID, limit: 200000) {
    items {
      templatename
      updatedAt
      id
      servicegroupID
      status
      formId
      description
      createdAt
      categoriesID
      Assets(limit: 200000, filter: {status: {eq: "Active"}, sitedetailsID: {eq: $sitedetailsID}}) {
        items {
          updatedAt
          status
          sitedetailsID
          siteId
          logbookId
          lampTypeId
          installedOn
          id
          formFieldValues
          fittingTypeId
          fittingNumber
          distributionboxID
          createdAt
          batteryTypeId
          assettemplateID
          assetWarrantyStart
          assetWarrantyEnd
          assetType
          assetNumber
          assetSerialNumber
          assetModel
          assetMake
          assetLocation
          assetImage
          assetDescription
          Testings(limit: 200000, filter: {testResult: {eq: "Fail"}}) {
            items {
              additionalInformation
              assetsID
              bulkUpdate
              calendar
              createdAt
              customFields
              emailNofication
              groupId
              id
              logbookID
              nextTestSchedule
              reminder
              siteId
              spUserId
              status
              technician
              testOn
              testReport
              warranty
              updatedAt
              tripTime
              testType
              testTime
              testResult
            }
          }
        }
      }
    }
  }
}`;
export const getRepairReports = /*GraphQL */ `
query getRepairReports($sitedetailsID: ID!, $nextToken: String, $filter: ModelRepairFilterInput) {
  assetsBySitedetailsID(sitedetailsID: $sitedetailsID, limit: 200000, nextToken: $nextToken) {
    items {
      Repairs(limit: 200000, filter: $filter) {
        items {
          additionalInformation
          assetsID
          createdAt
          customFields
          details
          id
          logbookID
          warrantyUpto
          updatedAt
          technician
          status
          siteId
          reportorPics
          replacedAssetID
          repairTime
          repairDate
        }
      }
      assetNumber
      assetModel
      assetMake
      assetLocation
      assetDescription
      assetImage
      assetSerialNumber
      assetType
      assetWarrantyEnd
      assetWarrantyStart
      assettemplateID
      batteryTypeId
      createdAt
      distributionboxID
      fittingTypeId
      formFieldValues
      id
      installedOn
      updatedAt
      status
      sitedetailsID
      siteId
      lampTypeId
      logbookId
      fittingNumber
      type
    }
    nextToken
  }
}
`;
export const getRepairTesting = /* GraphQL */ `
  query getRepairTesting($assetsID: ID!) {
    getAssets(id: $assetsID) {
      id
      siteId
      formFieldValues
      status
      assettemplateID
      fittingNumber
      Repairs(limit: 200000) {
        items {
          additionalInformation
          assetsID
          createdAt
          customFields
          details
          id
          logbookID
          warrantyUpto
          updatedAt
          technician
          status
          siteId
          reportorPics
          replacedAssetID
          repairTime
          repairDate
        }
      }
      Testings(limit: 200000) {
        items {
          additionalInformation
          assetsID
          createdAt
          customFields
          groupId
          id
          logbookID
          nextTestSchedule
          updatedAt
          technician
          status
          spUserId
          testOn
          testReport
          testResult
          testTime
          warranty
        }
      }
      assetLocation
      assetType
      assetSerialNumber
      assetDescription
      assetModel
      assetMake
      assetWarrantyStart
      assetWarrantyEnd
      installedOn
      assetNumber
      batteryTypeId
      lampTypeId
      fittingTypeId
      assetImage
      distributionboxID
      logbookId
      sitedetailsID
      createdAt
      updatedAt
    }
  }
`;
export const getAOLinkedSPCompanies = /*GraphQL*/ `
query getAOLinkedSPCompanies($assetownerID: ID!){
  siteDetailsByAssetownerID(assetownerID: $assetownerID, limit: 200000) {
    items {
      SPUserDetails {
        items {
          UserDetails {
            LinkUsersAndSites{
              items {
                sitedetailsID
                userdetailsID
                id
                status
              }
              nextToken
            }
          assetOwnerId
          companyDetailsId
          siteDetailsId
          permissions
          username
          id
          email
        }
        fname
        email
        id
        invitedBy
        lname
        mobile
        permission
        profilepic
        sitedetailsID
        status
        userGroup
      }
      nextToken
    }
    siteName
    id
    assetownerID
    companydetailsID
    unitNumber
  }
  nextToken
  }
}`;
export const getSPUserDetailsLinkedSites = /*GraphQL*/ `
query getSPUserDetailsLinkedSites($ID: ID!){
  getSPUserDetails(id: $ID) {
    UserDetails {
      LinkUsersAndSites {
        items {
          userdetailsID
          updatedAt
          status
          sitedetailsID
          id
          createdAt
        }
        nextToken
      }
      updatedAt
      siteDetailsId
      pushNotificationToken
      permissions
      paymentId
      id
      email
      createdAt
      companyDetailsId
      assetOwnerId
      userDetailsId
      status
      username
    }
    SPGroup
    userGroup
    status
    updatedAt
    sitedetailsID
    profilepic
    permission
    password
    mobile
    lname
    license
    invitedBy
    id
    fname
    email
    createdAt
  }
}`;
//, $nextToken: String, $nextTokenTesting: String, $nextTokenRepair: String
export const getAssetsFullCount = /*GraphQL*/ `
query getAssetsFullCount($sitedetailsID: ID!) {
  assetsBySitedetailsID(sitedetailsID: $sitedetailsID) {
    items {
      Testings {
        nextToken
        items {
          additionalInformation
          assetsID
          bulkUpdate
          calendar
          createdAt
          customFields
          emailNofication
          groupId
          id
          logbookID
          nextTestSchedule
          reminder
          siteId
          spUserId
          status
          testTime
          testOn
          technician
          testReport
          testResult
          testType
          warranty
          updatedAt
          tripTime
        }
      }
      Repairs {
        items {
          warrantyUpto
          updatedAt
          technician
          status
          siteId
          reportorPics
          replacedAssetID
          repairTime
          repairDate
          logbookID
          id
          details
          customFields
          createdAt
          assetsID
          additionalInformation
        }
        nextToken
      }
      updatedAt
      type
      status
      source
      sitedetailsID
      siteId
      logbookId
      lampTypeId
      installedOn
      id
      formFieldValues
      fittingTypeId
      fittingNumber
      duplicate
      distributionboxID
      createdBy
      createdAt
      batteryTypeId
      assettemplateSiteID
      assetWarrantyStart
      assettemplateID
      assetWarrantyEnd
      assetType
      assetSerialNumber
      assetNumber
      assetNum
      assetModel
      assetMake
      assetLocation
      assetImage
      assetDescription
    }
    nextToken
  }
}
`;
export const getAssetOwnerDetails = /*GraphQL*/ `
query getAssetOwnerDetails($assetownerID: ID!) {
  siteDetailsByAssetownerID(assetownerID: $assetownerID, limit: 20000) {
    items {
      SPUserDetails {
        items {
          userGroup
          updatedAt
          status
          sitedetailsID
          profilepic
          permission
          password
          mobile
          lname
          license
          invitedBy
          id
          fname
          email
          createdAt
          SPGroup
        }
        nextToken
      }
    }
  }
}
`;
export const listSUSPUserDetails = /*GraphQL*/ `
query listSUSPUserDetails($userGroup: String, $nextToken: String) {
  listSPUserDetails(filter:{userGroup: {eq:$userGroup}}, limit: 100000, nextToken: $nextToken) {
    items {
      createdAt
      email
      fname
      userGroup
      updatedAt
      status
      spcompanydetailsID
      sitedetailsID
      profilepic
      permission
      password
      mobile
      lname
      license
      invitedBy
      id
      SPGroup
      UserDetails {
        username
        id
        assetOwnerId
        companyDetailsId
        createdAt
        paymentId
        email
        paymentObject
        permissions
        pushNotificationToken
        siteDetailsId
        status
        updatedAt
        userDetailsId
      }
    }
    nextToken
  }
}`;
export const listSUAOUserDetails = /*GraphQL*/ `
query listSUAOUserDetails($userGroup: String, $nextToken: String, $invitedBy: String) {
  listSPUserDetails(filter:{userGroup: {eq:$userGroup}, invitedBy:{eq:$invitedBy}}, limit: 100000, nextToken: $nextToken) {
    items {
      createdAt
      email
      fname
      userGroup
      updatedAt
      status
      spcompanydetailsID
      sitedetailsID
      profilepic
      permission
      password
      mobile
      lname
      license
      invitedBy
      id
      SPGroup
      UserDetails {
        username
        id
        assetOwnerId
        companyDetailsId
        createdAt
        paymentId
        email
        paymentObject
        permissions
        pushNotificationToken
        siteDetailsId
        status
        updatedAt
        userDetailsId
      }
    }
    nextToken
  }
}`;
export const listSPAOUserDetails = /*GraphQL*/ `
query listSPAOUserDetails($userGroup: String, $id: ID!) {
  listSPUserDetails(filter:{userGroup: {eq:$userGroup}, id:{eq:$id}}, limit: 100000) {
    items {
      createdAt
      email
      fname
      userGroup
      updatedAt
      status
      spcompanydetailsID
      sitedetailsID
      profilepic
      permission
      password
      mobile
      lname
      license
      invitedBy
      id
      SPGroup
      UserDetails {
        username
        id
        assetOwnerId
        companyDetailsId
        createdAt
        paymentId
        email
        paymentObject
        permissions
        pushNotificationToken
        siteDetailsId
        status
        updatedAt
        userDetailsId
      }
    }
    nextToken
  }
}`;
export const listSPSUUserDetails = /*GraphQL*/ `
query listSPSUUserDetails($userGroup: String) {
  listSPUserDetails(filter:{userGroup: {eq:$userGroup}}, limit: 100000) {
    items {
      createdAt
      email
      fname
      userGroup
      updatedAt
      status
      spcompanydetailsID
      sitedetailsID
      profilepic
      permission
      password
      mobile
      lname
      license
      invitedBy
      id
      SPGroup
      UserDetails {
        username
        id
        assetOwnerId
        companyDetailsId
        createdAt
        paymentId
        email
        paymentObject
        permissions
        pushNotificationToken
        siteDetailsId
        status
        updatedAt
        userDetailsId
      }
    }
    nextToken
  }
}`;
export const getSiteUsersFromLink = /*GraphQL*/ `
query getSiteUsersFromLink($assetownerID: ID!, $userdetailsID: ID!, $nextToken: String) {
  siteDetailsByAssetownerID(assetownerID:$assetownerID, nextToken: $nextToken) {
    items {
      assetownerID
      companydetailsID
      country
      createdAt
      siteName
      id
      postCode
      status
      state
      street
      suburb
      unitNumber
      updatedAt
      LinkUsersAndSites(filter: { userdetailsID: { eq: $userdetailsID } }) {
      nextToken
        items {
          createdAt
          id
          sitedetailsID
          status
          updatedAt
          userdetailsID
        }
      }
    }
    nextToken
  }
}`;
