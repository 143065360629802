import { H5 } from "../../AbstractElements";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Label,
  Media,
  CardBody,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { PlusCircle } from "react-feather";
import { LargeModal } from "../../Constant/index";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { toast } from "react-toastify";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import ScrollBar from "react-perfect-scrollbar";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import DateRangePicker from "../DateRangePicker/DateRangePicker.jsx";

const SubscriptionsListAdminDetails = () => {
  const [subscriptionlist, setsubscriptionlist] = useState([]);
  const [assetOnerList, setassetOnerList] = useState([]);
  const [userDetailsList, setuserDetailsList] = useState([]);
  const [collection, setCollection] = React.useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredDataasset, setFilteredData] = React.useState([]);
  const [limit, setLimit] = useState(100000);

  //-----------------------------------------------------------------------------------------------

  useEffect(async () => {
    let AOarry = [];
    let subscriptionList = [];
    let UDarry = [];
    let UserDataList = [];

    async function fetchAssetOwnerDetailsData() {
      const response = await API.graphql({
        query: queries.listAssetOwners,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (response.data.listAssetOwners.items.length > 0) {
        AOarry = response.data.listAssetOwners.items;
      }
    }
    //----------------------------------------------------------
    async function fetchSubscriptionList() {
      const response = await API.graphql({
        query: queries.listSubscriptionLimits,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (response.data.listSubscriptionLimits.items.length > 0) {
        subscriptionList = response.data.listSubscriptionLimits.items;
      }
    }
    //----------------------------------------------------------------
    async function fetchUserDetailsData() {
      setIsLoading(true);
      const result = await API.graphql({
        query: queries.listUserDetails,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (result.data.listUserDetails.items.length > 0) {
        console.log(result);
        setuserDetailsList(result.data.listUserDetails.items);
        let newrarray = [];
        newrarray = result.data.listUserDetails.items;
        //AOarry.map((itesAO) => {
        for (let itesAO of AOarry) {
          //newrarray.map((udItem) => {
          for (let udItem of newrarray) {
            if (udItem.assetOwnerId === itesAO.id) {
              if (udItem.paymentId != null && udItem.paymentId != "") {
                //subscriptionList.map((sitem) => {
                for (let sitem of subscriptionList) {
                  if (udItem.paymentId === sitem.subscriptionId) {
                    UDarry.push({
                      id: udItem.assetOwnerId,
                      name: itesAO.firstName + " " + itesAO.lastName,
                      companyName: itesAO.companyName,
                      // paymentId:udItem.paymentId,
                      paymentId: sitem.SubscriptionName,
                      paymentStatus:
                        udItem.paymentObject !== null
                          ? "Payment Done"
                          : "Payment not done",
                      assetLimit: sitem.AssetLimit,
                      price: sitem.Price,
                      siteLimit: sitem.SiteLimit,
                      siteUserLimit: sitem.SiteUserLimit,
                    });
                  }
                }
              }
            }
          }
        }

        let uniqueIds = new Set();
        console.log(UDarry);
        let uniqueUDarry = UDarry.filter((item) => {
          if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            return true;
          }
          return false;
        });
        console.log(uniqueUDarry);
        setassetOnerList(uniqueUDarry);
        setCollection(cloneDeep(uniqueUDarry.slice(0, countPerPage)));
        setIsLoading(false);
      }
    }

    fetchAssetOwnerDetailsData();
    fetchSubscriptionList();
    fetchUserDetailsData();
  }, []);

  //---------------------------------------------------------------------------

  const globalSearch = (searchValue) => {
    console.log(assetOnerList);
    let filteredData = assetOnerList.filter((row) => {
      return (
        row.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.companyName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.paymentId?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    console.log(filteredData);
    return filteredData;
  };

  React.useEffect(() => {
    if (!value) {
      console.log(assetOnerList);
      setCollection(cloneDeep(assetOnerList.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      console.log(filteredData);
      setFilteredData(filteredData);
      console.log(collection);

      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
      console.log(collection);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;

    if (!value) {
      setCollection(cloneDeep(assetOnerList.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      console.log(filteredData);
      const pageData = filteredData.slice(from, to);
      setCollection(cloneDeep(pageData));
    }
  };

  //------------------------------------------------------------

  const renderList = (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <Col md="4">
                    <InputGroup>
                      <span className="input-group-text">
                        <i className="icon-search"></i>
                      </span>
                      <Input
                        type="search"
                        placeholder="Search .."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="card-block row">
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      {isLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <>
                          <Table className="table-striped  table-de">
                            <thead className="custom-table-head">
                              <tr>
                                <th>Asset Owner</th>
                                <th>Company</th>
                                <th>Subscription Id</th>
                                <th>Monthly Charge</th>
                                <th>Latest Payment Status</th>
                                <th>Asset Limit</th>
                                <th>Site Limit</th>
                                <th>Site Users Limit</th>
                              </tr>
                            </thead>
                            <tbody className="trhover">
                              {collection.length > 0 ? (
                                collection.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.companyName}</td>
                                    <td>{item.paymentId}</td>
                                    <td>${item.price}</td>
                                    <td>{item.paymentStatus}</td>
                                    <td>{item.assetLimit}</td>
                                    <td>{item.siteLimit}</td>
                                    <td>{item.siteUserLimit}</td>
                                  </tr>
                                ))
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan="5" className="text-center">
                                      No Data Available.
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </tbody>
                          </Table>
                        </>
                      )}
                    </div>
                  </Col>
                </div>
              </CardBody>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={
                    value ? filteredDataasset.length : assetOnerList.length
                  }
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};
export default SubscriptionsListAdminDetails;
