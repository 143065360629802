import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import FormDetailsContain from '../../Component/AssetCategory/Form/FormDetails/index';

const FormDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="AssetCategory" title="Form Details" />
      <FormDetailsContain />
    </Fragment>
  );
};
export default FormDetails;