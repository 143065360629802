import React, { Fragment, useState } from "react";
import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Col,
} from "reactstrap";
import { Btn, Image, H2 } from "../../../AbstractElements";
import { Password, SignIn } from "../../../Constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormPassword from "./FormPassword";
import { useForm } from "react-hook-form";
import { Auth, API } from "aws-amplify";
import SignInWith from "./SignInWith";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import AWS from "aws-sdk";
//var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
//var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
const cognito = new AWS.CognitoIdentityServiceProvider({
  region: "ap-southeast-2",
});

const LoginTab = ({ selected }) => {
  const [aoactive, setAoactive] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const menueRedirect = {
    site_owner: "dashboardsao",
    electrician: "dashboard/default",
    site_user: "dashboard/default",
    admin: "dashboards",
    spao: "dashboardsao",
  };
  const [step, setStep] = useState(1);
  const [loginUserDetail, setLoginUserDetail] = useState([]);
  async function onSubmit(data) {
    setLoading(true);
    try {
      await Auth.signIn(data.username, data.password)
        .then(async (result) => {
          console.log("user ", result);
          localStorage.setItem("spaoUserCheckForSUInvite", "");
          setLoginUserDetail(result);
          if (result.challengeName !== "NEW_PASSWORD_REQUIRED") {

            //}
            if (
              result.signInUserSession &&
              result.signInUserSession.accessToken &&
              result.signInUserSession.accessToken.payload[
              "cognito:groups"
              ][0] === "electrician"
            ) {
              const id = result.signInUserSession.accessToken.payload["sub"];
              const userDetail = await API.graphql({
                query: queries.getUserDetails,
                variables: {
                  id: id,
                },
                authMode: "API_KEY",
              });
              localStorage.setItem(
                "userDetails",
                JSON.stringify(userDetail.data.getUserDetails)
              );
              API.graphql({
                query: queries.listSPUserDetails,
                variables: {
                  filter: {
                    id: {
                      eq: id,
                    },
                  },
                  limit: 10000,
                },
                authMode: "API_KEY",
              }).then((result1) => {
                console.log(result1);
                const sp = result1.data.listSPUserDetails.items;
                if (sp[0].SPGroup === "admin") {
                  if (result.signInUserSession !== null) {
                    setStep(1);
                    const userdatakey = result.userDataKey;
                    const newdata = JSON.parse(result.storage[userdatakey]);
                    localStorage.setItem(
                      "groups",
                      result.signInUserSession.accessToken.payload[
                      "cognito:groups"
                      ][0]
                    );
                    localStorage.setItem(
                      "auth0_profile",
                      JSON.stringify(newdata.UserAttributes)
                    );
                    localStorage.setItem("authenticated", true);
                    toast.success("Successfully Logged In...", {
                      position: toast.POSITION.TOP_CENTER,
                    });
                    history(
                      `${process.env.PUBLIC_URL}/${menueRedirect[
                      result.signInUserSession.accessToken.payload[
                      "cognito:groups"
                      ][0]
                      ]
                      }`
                    );
                  } else {
                    if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
                      setLoading(false);
                      setStep(2);
                    }
                  }
                } else {
                  console.log("sp group value is not admin");
                  toast.error("User don't have the access to login", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  setLoading(false);
                }
              });
            } else if (
              result.signInUserSession &&
              result.signInUserSession.accessToken &&
              result.signInUserSession.accessToken.payload[
              "cognito:groups"
              ][0] === "spao"
            ) {
              if (result.signInUserSession.accessToken !== null) {
                const id = result.signInUserSession.accessToken.payload["sub"];
                const userDetail = await API.graphql({
                  query: queries.getUserDetails,
                  variables: {
                    id: id,
                  },
                  authMode: "API_KEY",
                });
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(userDetail.data.getUserDetails)
                );
                const aodata = userDetail.data.getUserDetails;
                console.log(aodata);
                if (
                  aodata &&
                  aodata.paymentObject &&
                  aodata.paymentObject !== null
                ) {
                  const paymentStatus = JSON.parse(aodata.paymentObject);
                  if (paymentStatus.payment_status !== "unpaid") {
                    setStep(1);
                    localStorage.setItem(
                      "groups",
                      result.signInUserSession.accessToken.payload[
                      "cognito:groups"
                      ][0]
                    );
                    localStorage.setItem("authenticated", true);
                    toast.success("Successfully Logged In...", {
                      position: toast.POSITION.TOP_CENTER,
                    });
                    localStorage.setItem(
                      "userDetails",
                      JSON.stringify(userDetail.data.getUserDetails)
                    );
                    history(
                      `${process.env.PUBLIC_URL}/${menueRedirect[
                      result.signInUserSession.accessToken.payload[
                      "cognito:groups"
                      ][0]
                      ]
                      }`
                    );
                  } else {
                    navigate(`${process.env.PUBLIC_URL}/paymentstatus`, {
                      state: {
                        site: aodata,
                      },
                    });
                  }
                } else {
                  navigate(`${process.env.PUBLIC_URL}/paymentstatus`, {
                    state: {
                      site: aodata,
                    },
                  });
                }
              }
            } else if (
              result.signInUserSession &&
              result.signInUserSession.accessToken &&
              result.signInUserSession.accessToken.payload[
              "cognito:groups"
              ][0] === "site_owner"
            ) {
              if (result.signInUserSession !== null) {
                const userDetail = await API.graphql({
                  query: queries.getUserDetails,
                  variables: {
                    id: result.attributes.sub,
                  },
                  authMode: "API_KEY",
                });
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(userDetail.data.getUserDetails)
                );
                const aodata = userDetail.data.getUserDetails;
                console.log(aodata);
                if (aodata.status === "Active") {
                  if (
                    result.signInUserSession.accessToken.payload[
                    "cognito:groups"
                    ][0] === "site_owner"
                  ) {
                    if (
                      aodata &&
                      aodata.paymentObject &&
                      aodata.paymentObject !== null
                    ) {
                      const paymentStatus = JSON.parse(aodata.paymentObject);
                      if (paymentStatus.payment_status !== "unpaid") {
                        setStep(1);
                        const userdatakey = result.userDataKey;
                        const newdata = JSON.parse(result.storage[userdatakey]);
                        localStorage.setItem(
                          "groups",
                          result.signInUserSession.accessToken.payload[
                          "cognito:groups"
                          ][0]
                        );
                        localStorage.setItem(
                          "auth0_profile",
                          JSON.stringify(newdata.UserAttributes)
                        );
                        localStorage.setItem("authenticated", true);
                        toast.success("Successfully Logged In...", {
                          position: toast.POSITION.TOP_CENTER,
                        });
                        localStorage.setItem(
                          "userDetails",
                          JSON.stringify(userDetail.data.getUserDetails)
                        );
                        history(
                          `${process.env.PUBLIC_URL}/${menueRedirect[
                          result.signInUserSession.accessToken.payload[
                          "cognito:groups"
                          ][0]
                          ]
                          }`
                        );
                      } else {
                        navigate(`${process.env.PUBLIC_URL}/paymentstatus`, {
                          state: {
                            site: aodata,
                          },
                        });
                      }
                    } else {
                      navigate(`${process.env.PUBLIC_URL}/paymentstatus`, {
                        state: {
                          site: aodata,
                        },
                      });
                    }
                  } else {
                    setStep(1);
                    const getuserinviteData = await API.graphql({
                      query: queries.getSPUserDetails,
                      variables: {
                        id: result.attributes.sub,
                        limit: 10000,
                      },
                      authMode: "API_KEY",
                    });
                    const invitedBy =
                      getuserinviteData.data.getSPUserDetails.invitedBy;
                    const invitedByUserData = await API.graphql({
                      query: queries.getUserDetails,
                      variables: {
                        id: invitedBy,
                        limit: 10000,
                      },
                      authMode: "API_KEY",
                    });
                    var params = {
                      UserPoolId: "ap-southeast-2_Y8sY9qqTz",
                      Username: invitedByUserData.data.getUserDetails.username,
                    };
                    cognito.adminGetUser(params, function (err, data) {
                      if (err) console.log(err, err.stack); // an error occurred
                      else {
                        console.log(data.UserAttributes[0].Value);
                        localStorage.setItem(
                          "spaoUserCheckForSUInvite",
                          data.UserAttributes[0].Value
                        );
                      } // successful response
                    });
                    const userdatakey = result.userDataKey;
                    const newdata = JSON.parse(result.storage[userdatakey]);
                    localStorage.setItem(
                      "groups",
                      result.signInUserSession.accessToken.payload[
                      "cognito:groups"
                      ][0]
                    );
                    localStorage.setItem(
                      "auth0_profile",
                      JSON.stringify(newdata.UserAttributes)
                    );
                    localStorage.setItem("authenticated", true);
                    toast.success("Successfully Logged In...", {
                      position: toast.POSITION.TOP_CENTER,
                    });
                    localStorage.setItem(
                      "userDetails",
                      JSON.stringify(userDetail.data.getUserDetails)
                    );
                    history(
                      `${process.env.PUBLIC_URL}/${menueRedirect[
                      result.signInUserSession.accessToken.payload[
                      "cognito:groups"
                      ][0]
                      ]
                      }`
                    );
                  }
                } else {
                  console.log("Inactive AssetOwner ...");
                  setLoading(false);
                  setAoactive(true);
                }
              } else {
                if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
                  setLoading(false);
                  setStep(2);
                }
              }
            } else if (
              result.signInUserSession &&
              result.signInUserSession.accessToken &&
              result.signInUserSession.accessToken.payload[
              "cognito:groups"
              ][0] === "site_user"
            ) {
              if (result.signInUserSession !== null) {
                const userDetail = await API.graphql({
                  query: queries.getUserDetails,
                  variables: {
                    id: result.attributes.sub,
                  },
                  authMode: "API_KEY",
                });
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(userDetail.data.getUserDetails)
                );
                const aodata = userDetail.data.getUserDetails;
                console.log(aodata);
                setStep(1);
                const getuserinviteData = await API.graphql({
                  query: queries.getSPUserDetails,
                  variables: {
                    id: result.attributes.sub,
                    limit: 10000,
                  },
                  authMode: "API_KEY",
                });
                const invitedBy =
                  getuserinviteData.data.getSPUserDetails.invitedBy;
                const invitedByUserData = await API.graphql({
                  query: queries.getUserDetails,
                  variables: {
                    id: invitedBy,
                    limit: 10000,
                  },
                  authMode: "API_KEY",
                });
                var params = {
                  UserPoolId: "ap-southeast-2_Y8sY9qqTz",
                  Username: invitedByUserData.data.getUserDetails.username,
                };
                cognito.adminGetUser(params, function (err, data) {
                  if (err) console.log(err, err.stack); // an error occurred
                  else {
                    console.log(data.UserAttributes[0].Value);
                    localStorage.setItem(
                      "spaoUserCheckForSUInvite",
                      data.UserAttributes[0].Value
                    );
                  } // successful response
                });
                const userdatakey = result.userDataKey;
                const newdata = JSON.parse(result.storage[userdatakey]);
                localStorage.setItem(
                  "groups",
                  result.signInUserSession.accessToken.payload[
                  "cognito:groups"
                  ][0]
                );
                localStorage.setItem(
                  "auth0_profile",
                  JSON.stringify(newdata.UserAttributes)
                );
                localStorage.setItem("authenticated", true);
                toast.success("Successfully Logged In...", {
                  position: toast.POSITION.TOP_CENTER,
                });
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(userDetail.data.getUserDetails)
                );
                history(
                  `${process.env.PUBLIC_URL}/${menueRedirect[
                  result.signInUserSession.accessToken.payload[
                  "cognito:groups"
                  ][0]
                  ]
                  }`
                );
              } else {
                if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
                  setLoading(false);
                  setStep(2);
                }
              }
            } else {
              console.log("admin login");
              if (result.signInUserSession !== null) {
                const id = result.signInUserSession.accessToken.payload["sub"];
                setStep(1);
                localStorage.setItem(
                  "groups",
                  result.signInUserSession.idToken.payload["cognito:groups"][0]
                );
                localStorage.setItem("authenticated", true);
                toast.success("Successfully Logged In...", {
                  position: toast.POSITION.TOP_CENTER,
                });
                const userDetail = await API.graphql({
                  query: queries.getUserDetails,
                  variables: {
                    id: id,
                  },
                  authMode: "API_KEY",
                });
                console.log(userDetail);
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(userDetail.data.getUserDetails)
                );
                history(
                  `${process.env.PUBLIC_URL}/${menueRedirect[
                  result.signInUserSession.idToken.payload[
                  "cognito:groups"
                  ][0]
                  ]
                  }`
                );
              }
            }
          }
          else {
            setLoading(false);
            setStep(2);
          }
        })
        .catch((errors) => {
          console.log(errors);
          setLoading(false);
          alert(errors);
          toast.error(errors, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }

  async function changePassword(data) {
    try {
      setLoading(true);
      console.log(loginUserDetail);
      const { requiredAttributes } = loginUserDetail.challengeParam;
      Auth.completeNewPassword(
        loginUserDetail, // the Cognito User Object
        data.newPassword,
        requiredAttributes
      )
        .then(async (user) => {
          console.log(user);
          await API.graphql({
            query: queries.listUserDetails,
            variables: {
              filter: {
                username: {
                  eq: user.username,
                },
              },
              limit: 200000,
            },
            authMode: "API_KEY",
          })
            .then(async (userDetail) => {
              var userId = userDetail.data.listUserDetails.items;
              console.log(userId);
              await API.graphql({
                query: mutations.updateUserDetails,
                variables: {
                  input: {
                    id: userId[0].id,
                    status: "Active",
                  },
                },
                authMode: "API_KEY",
              });
              if (userId[0].companyDetailsId !== null) {
                await API.graphql({
                  query: mutations.updateSPUserDetails,
                  variables: {
                    input: {
                      id: userId[0].id,
                      status: "Active",
                    },
                  },
                  authMode: "API_KEY",
                });
              }
              setLoading(false);
              toast.success("Password Changed Successfully..", {
                position: toast.POSITION.TOP_CENTER,
              });
              window.location.href = `${process.env.PUBLIC_URL}/login`;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        });
    } catch (err) {
      toast.error("Error Changing The Password: " + err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }

  return (
    <Fragment>
      {step === 1 && (
        <Form
          className="theme-form login-form needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormHeader selected={selected} />
          <FormGroup>
            <Label>Username</Label>
            <InputGroup>
              <InputGroupText>
                <i className="icon-user"></i>
              </InputGroupText>
              <input
                className="form-control"
                type="text"
                id="username"
                name="username"
                {...register("username", { required: true })}
                placeholder="Username"
              />
            </InputGroup>
            <span className="invalid-span">
              {errors.username && "Please enter Username"}
            </span>
            <div className="invalid-feedback">{"Please enter Username"}</div>
          </FormGroup>
          <FormGroup>
            <Label>{Password}</Label>
            <InputGroup>
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
              <input
                className="form-control"
                type={togglePassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                {...register("password", { required: true })}
              />
              <div
                className="show-hide"
                onClick={() => setTogglePassword(!togglePassword)}
              >
                <span className={togglePassword ? "" : "show"}></span>
              </div>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
              }}
            >
              {loading ? "LOADING..." : SignIn}
            </Btn>
          </FormGroup>
          <FormPassword />
          <SignInWith />
        </Form>
      )}
      {step === 2 && (
        <Form
          className="theme-form login-form needs-validation"
          noValidate=""
          onSubmit={handleSubmit(changePassword)}
        >
          <H2 attrH2={{ className: "mb-3 text-center" }}>Change Password</H2>
          <FormGroup>
            <Label>Username</Label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="icon-lock"></i>
              </span>
              <input
                className="form-control"
                type="text"
                name="username"
                {...register("username", { required: true })}
                placeholder="Confirmation username"
              />
            </div>
            <span className="invalid-span">
              {errors.username && "Please enter username"}
            </span>
            <div className="invalid-feedback">{"Please enter username"}</div>
          </FormGroup>
          <FormGroup>
            <Label>New Password</Label>
            <InputGroup>
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
              <input
                className="form-control"
                type={togglePassword ? "text" : "password"}
                placeholder="New Password"
                name="newPassword"
                {...register("newPassword", { required: true })}
              />
              <div
                className="show-hide"
                onClick={() => setTogglePassword(!togglePassword)}
              >
                <span className={togglePassword ? "" : "show"}></span>
              </div>
            </InputGroup>
            <span className="invalid-span">
              {errors.newPassword && "Please enter New Password"}
            </span>
            <div className="invalid-feedback">
              {"Please enter New Password"}
            </div>
            <p>
              Password should atleast have 1 Capital Letter, 1 Number, 1 Special
              character and minimum 8 characters length
            </p>
          </FormGroup>
          <FormGroup>
            <Btn
              attrBtn={{
                color: "primary",
                disabled: loading ? loading : loading,
              }}
            >
              {loading ? "LOADING..." : "Change Password"}
            </Btn>
          </FormGroup>
        </Form>
      )}
      {aoactive && (
        <Form
          className="theme-form login-form needs-validation"
          noValidate=""
        //onSubmit={handleSubmit(submitMFA)}
        >
          <Row>
            <Col sm="12">
              <p className="errorSubscription">
                Sorry!.. Your Account has been suspended please contact admin
                "admin@knowyourasset.com.au".
              </p>
            </Col>
          </Row>
        </Form>
      )}
    </Fragment>
  );
};

export default LoginTab;
