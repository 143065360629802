import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import UserContain from '../../../Component/DashBoard/User/index';


const User = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Dashboards" title="Dashboard" />
      <UserContain />
    </Fragment>
  );
};
export default User;