import { Btn, H4, H5, H1, H2 } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Form,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Input,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import awsExports from "../../../aws-exports";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import * as mergedQueries from "../../../graphql/mergedQueries";
import {
  S3_BUCKET,
  REGION,
  ASSETS,
  LOGBOOK_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
} from "../../../Config/Config";

Amplify.configure(awsExports);

const ViewAsset = (props) => {
  const [isOpen, setIsOpen] = useState(1);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  const navigate = useNavigate();
  const state = useLocation();
  console.log(state);

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [ViewLarge, setViewLarge] = useState(false);
  const ViewLargeModaltoggle = () => setViewLarge(!ViewLarge);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [reportUrl, setReportUrl] = useState("");
  const openReportInPopup = async (reportName) => {
    try {
      const reportObj = JSON.parse(reportName);
      const fileName = reportObj[0].fileName;
      var params = {
        Bucket: S3_BUCKET,
        Key: `public/${LOGBOOK_FOLDER}/${fileName}`,
      };
      var promise = myBucket.getSignedUrlPromise("getObject", params);
      promise.then(
        function (url) {
          console.log(url);
          setReportUrl(url);
          ViewLargeModaltoggle();
        },
        function (err) {
          console.log(err);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [formFieldData, setFormData] = useState([]);
  const [viewFormFieldData, setViewFormFieldData] = useState([]);
  const [ProfilePic, setProfilePic] = useState([]);
  const [downloadedFile, setdownloadedFile] = useState(null);
  const [selectedImg, setselectedImg] = useState(null);
  const [fullFormData, setFullFormData] = useState({});
  const [repairAsset, setRepairAsset] = useState([]);
  const [testAsset, setTestAsset] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  useEffect(() => {
    if (state.state) {
      fetchFormDetails();
      //fetchTesting();
      //fetchRepair();
      fetchRepairDemo();
    }
    async function fetchTesting() {
      try {
        await API.graphql({
          query: queries.testingsByAssetsID,
          variables: {
            limit: 10000,
            assetsID: state.state.item.id,
            //filter: {
            //  assetsID: {
            //    eq: state.state.item.id,
            // },
            //},
          },
          authMode: "API_KEY",
        })
          .then((response1) => {
            console.log(response1);
            const collectArray = [];
            const testArray = [];
            //setTestAsset(response1.data.listTestings.items);
            const Testing = response1.data.testingsByAssetsID.items;
            Testing.map((test) => {
              testArray.push({
                id: test.id,
                createdAt: test.createdAt,
                testOn: test.testOn,
                testResult: test.testResult,
                nextTestSchedule: test.nextTestSchedule,
                additionalInformationTest: test.additionalInformation,
              });
            });
            console.log(testArray);

            if (Testing.length > 0) {
              API.graphql({
                query: queries.listRepairs,
                variables: {
                  limit: 10000,
                  filter: {
                    assetsID: {
                      eq: state.state.item.id,
                    },
                  },
                },
                authMode: "API_KEY",
              })
                .then((response2) => {
                  console.log(response2);
                  //setRepairAsset(response2.data.listRepairs.items);
                  const repair = response2.data.listRepairs.items;

                  API.graphql({
                    query: queries.listRepairs,
                    variables: {
                      limit: 10000,
                      filter: {
                        replacedAssetID: {
                          eq: state.state.item.id,
                        },
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then((response3) => {
                      console.log(response3);
                      const repair2 = response3.data.listRepairs.items;
                      const combinedRepairs = [...repair, ...repair2];
                      console.log(combinedRepairs);
                      if (combinedRepairs.length > 0) {
                        const combinedArray = testArray.concat(combinedRepairs);

                        const uniqueCombinedArray = Array.from(
                          new Set(combinedArray.map(JSON.stringify))
                        ).map(JSON.parse);

                        console.log(uniqueCombinedArray);
                        const sort = uniqueCombinedArray.sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        );
                        setCombinedData(sort);
                      } else {
                        Testing.map((test) => {
                          collectArray.push({
                            id: test.id,
                            createdAt: test.createdAt,
                            testOn: test.testOn,
                            testResult: test.testResult,
                            nextTestSchedule: test.nextTestSchedule,
                            additionalInformationTest:
                              test.additionalInformation,
                            repairDate: "",
                            warrantyUpto: "",
                            additionalInformationRepair: "",
                            details: "",
                          });
                        });
                        console.log(collectArray);
                        const sort = collectArray.sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        );
                        setCombinedData(sort);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  //----
                  {
                    /*
                  if (repair.length > 0) {
                    const combinedArray = Testing.concat(repair);

                    const uniqueCombinedArray = Array.from(
                      new Set(combinedArray.map(JSON.stringify))
                    ).map(JSON.parse);

                    console.log(uniqueCombinedArray);
                    setCombinedData(uniqueCombinedArray);
                  } else {
                    Testing.map((test) => {
                      collectArray.push({
                        id: test.id,
                        createdAt: test.createdAt,
                        testOn: test.testOn,
                        testResult: test.testResult,
                        nextTestSchedule: test.nextTestSchedule,
                        additionalInformationTest: test.additionalInformation,
                        repairDate: "",
                        warrantyUpto: "",
                        additionalInformationRepair: "",
                      });
                    });
                    console.log(collectArray);
                    setCombinedData(collectArray);
                  }
                */
                  }
                  //-----
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              API.graphql({
                query: queries.listRepairs,
                variables: {
                  limit: 10000,
                  filter: {
                    assetsID: {
                      eq: state.state.item.id,
                    },
                  },
                },
                authMode: "API_KEY",
              })
                .then((response2) => {
                  console.log(response2);
                  const repair = response2.data.listRepairs.items;
                  API.graphql({
                    query: queries.listRepairs,
                    variables: {
                      limit: 10000,
                      filter: {
                        replacedAssetID: {
                          eq: state.state.item.id,
                        },
                      },
                    },
                    authMode: "API_KEY",
                  })
                    .then((response3) => {
                      console.log(response3);
                      console.log(repair);
                      const repair2 = response3.data.listRepairs.items;
                      const combinedRepairs = [...repair, ...repair2];
                      console.log(combinedRepairs);
                      if (combinedRepairs.length > 0) {
                        combinedRepairs.map((item) => {
                          collectArray.push({
                            id: "",
                            createdAt: "",
                            testOn: "",
                            testResult: "",
                            nextTestSchedule: "",
                            additionalInformationTest: "",
                            repairDate: item.repairDate,
                            warrantyUpto: item.warrantyUpto,
                            replacedAssetID: item.replacedAssetID,
                            details: item.details,
                          });
                        });
                        console.log(collectArray);
                        const sort = collectArray.sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        );
                        setCombinedData(sort);
                      } else {
                        setCombinedData([]);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  //----
                  {
                    /*
                  if (repair.length > 0) {
                    repair.map((item) => {
                      collectArray.push({
                        id: "",
                        createdAt: "",
                        testOn: "",
                        testResult: "",
                        nextTestSchedule: "",
                        additionalInformationTest: "",
                        repairDate: item.repairDate,
                        warrantyUpto: item.warrantyUpto,
                        additionalInformationRepair: item.additionalInformation,
                      });
                    });
                    console.log(collectArray);
                    setCombinedData(collectArray);
                  } else {
                    setCombinedData([]);
                  }
                */
                  }
                  //---
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchRepairDemo() {
      try {
        const inactiveAsset = [];
        let repair = [];
        await API.graphql({
          query: mergedQueries.getRepairTesting,
          variables: {
            assetsID: state.state.item.id,
          },
          authMode: "API_KEY",
        })
          .then(async (response2) => {
            console.log(response2);
            const repairactive = response2.data.getAssets.Repairs.items;
            repair.push(...repairactive);
            console.log(repair);
            const testing = response2.data.getAssets.Testings.items;
            console.log(testing);
            //--
            let testArray = [];
            testing.map((test) => {
              testArray.push({
                id: test.id,
                createdAt: test.createdAt,
                testOn: test.testOn,
                testResult: test.testResult,
                nextTestSchedule: test.nextTestSchedule,
                additionalInformationTest: test.additionalInformation,
              });
            });
            console.log(testArray);
            //--
            await API.graphql({
              query: queries.listRepairs,
              variables: {
                limit: 10000,
                filter: {
                  replacedAssetID: {
                    eq: state.state.item.id,
                  },
                },
              },
              authMode: "API_KEY",
            })
              .then(async (response3) => {
                console.log(response3);
                console.log(repair);
                const repair2 = response3.data.listRepairs.items;
                //---
                let i = 0;
                while (i < repair.length) {
                  //&& repair[i].replacedAssetID !== null){
                  const currentRepair = repair[i];
                  console.log(currentRepair);

                  if (currentRepair.replacedAssetID) {
                    inactiveAsset.push(currentRepair.replacedAssetID);
                    console.log(inactiveAsset);

                    await API.graphql({
                      query: mergedQueries.getRepairTesting,
                      variables: {
                        assetsID: currentRepair.replacedAssetID,
                      },
                      authMode: "API_KEY",
                    })
                      .then((response2) => {
                        console.log(response2);
                        const replacedAssetRepairs =
                          response2.data.getAssets.Repairs.items;
                        console.log(replacedAssetRepairs);

                        repair.push(...replacedAssetRepairs);
                        console.log(repair);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    console.log("no replace asset id in this repair record");
                  }

                  console.log(repair);
                  console.log(i);
                  i++;
                }

                console.log(inactiveAsset);
                //const filteredRepairs = repair.filter(siteee => siteee.replacedAssetID);
                //--
                if (inactiveAsset.length > 0) {
                  const promises = inactiveAsset.map(async (siteee) => {
                    return API.graphql({
                      query: mergedQueries.getRepairTesting,
                      variables: {
                        assetsID: siteee,
                      },
                      authMode: "API_KEY",
                    });
                  });
                  Promise.all(promises)
                    .then(async (results) => {
                      const testingsss = [];
                      const repairssss = [];
                      results.forEach((result1) => {
                        const repair3 = result1.data.getAssets.Repairs.items;
                        console.log(repair3);
                        if (repair3.length > 0) {
                          repairssss.push(...repair3);
                        }
                        const testing2 = result1.data.getAssets.Testings.items;
                        console.log(testing2);
                        if (testing2.length > 0) {
                          testingsss.push(...testing2);
                        }
                      });
                      console.log("final data");
                      console.log(testingsss);
                      console.log(repairssss);
                      //--
                      let testArray2 = [];
                      testingsss.map((test) => {
                        testArray2.push({
                          id: test.id,
                          createdAt: test.createdAt,
                          testOn: test.testOn,
                          testResult: test.testResult,
                          nextTestSchedule: test.nextTestSchedule,
                          additionalInformationTest: test.additionalInformation,
                        });
                      });
                      console.log(testArray2);
                      //--

                      const promises = inactiveAsset.map(async (siteee) => {
                        return API.graphql({
                          query: queries.listRepairs,
                          variables: {
                            limit: 10000,
                            filter: {
                              replacedAssetID: {
                                eq: siteee,
                              },
                            },
                          },
                          authMode: "API_KEY",
                        });
                      });
                      Promise.all(promises)
                        .then((results) => {
                          const repair4 = [];
                          results.forEach((result2) => {
                            const assetList = result2.data.listRepairs.items;
                            if (assetList.length > 0) {
                              console.log(assetList);
                              repair4.push(...assetList);
                            }
                          });
                          console.log("final data");
                          console.log(repair4);
                          //--
                          const combinedRepairs = [
                            ...repairssss,
                            ...repair2,
                            ...repair,
                            ...repair4,
                          ];
                          console.log(combinedRepairs);
                          let repairArray = [];
                          combinedRepairs.map((test) => {
                            repairArray.push({
                              id: test.id,
                              createdAt: test.createdAt,
                              repairDate: test.repairDate,
                              replacedAssetID: test.replacedAssetID,
                              warrantyUpto: test.warrantyUpto,
                              additionalInformationRepair:
                                test.additionalInformation,
                            });
                          });
                          console.log(repairArray);
                          const combinedTesting = [...testArray, ...testArray2];
                          console.log(combinedTesting);
                          const combinedArray =
                            combinedTesting.concat(repairArray);
                          console.log(combinedArray);

                          const uniqueCombinedArray = Array.from(
                            new Set(combinedArray.map(JSON.stringify))
                          ).map(JSON.parse);

                          console.log(uniqueCombinedArray);
                          const sort = uniqueCombinedArray.sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          );
                          setCombinedData(sort);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  const combinedRepairs = [...repairactive, ...repair2];
                  console.log(combinedRepairs);
                  let repairArray = [];
                  combinedRepairs.map((test) => {
                    repairArray.push({
                      id: test.id,
                      createdAt: test.createdAt,
                      repairDate: test.repairDate,
                      replacedAssetID: test.replacedAssetID,
                      warrantyUpto: test.warrantyUpto,
                      additionalInformationRepair: test.additionalInformation,
                    });
                  });
                  console.log(repairArray);
                  const combinedArray = testArray.concat(repairArray);

                  const uniqueCombinedArray = Array.from(
                    new Set(combinedArray.map(JSON.stringify))
                  ).map(JSON.parse);

                  console.log(uniqueCombinedArray);
                  const sort = uniqueCombinedArray.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  );
                  setCombinedData(sort);
                }
              })
              .catch((error) => {
                console.log(error);
              });
            //--
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchRepair() {
      try {
        await API.graphql({
          query: mergedQueries.getRepairTesting,
          variables: {
            assetsID: state.state.item.id,
          },
          authMode: "API_KEY",
        })
          .then(async (response2) => {
            console.log(response2);
            const repair = response2.data.getAssets.Repairs.items;
            console.log(repair);
            const testing = response2.data.getAssets.Testings.items;
            console.log(testing);
            //--
            let testArray = [];
            testing.map((test) => {
              testArray.push({
                id: test.id,
                createdAt: test.createdAt,
                testOn: test.testOn,
                testResult: test.testResult,
                nextTestSchedule: test.nextTestSchedule,
                additionalInformationTest: test.additionalInformation,
              });
            });
            console.log(testArray);
            //--
            await API.graphql({
              query: queries.listRepairs,
              variables: {
                limit: 10000,
                filter: {
                  replacedAssetID: {
                    eq: state.state.item.id,
                  },
                },
              },
              authMode: "API_KEY",
            })
              .then(async (response3) => {
                console.log(response3);
                console.log(repair);
                const repair2 = response3.data.listRepairs.items;
                //--
                if (repair2.length > 0) {
                  await API.graphql({
                    query: mergedQueries.getRepairTesting,
                    variables: {
                      assetsID: repair2[0].assetsID,
                    },
                    authMode: "API_KEY",
                  })
                    .then(async (response4) => {
                      console.log(response4);
                      const repair3 = response4.data.getAssets.Repairs.items;
                      console.log(repair3);
                      const testing2 = response4.data.getAssets.Testings.items;
                      console.log(testing2);
                      //--
                      let testArray2 = [];
                      testing2.map((test) => {
                        testArray2.push({
                          id: test.id,
                          createdAt: test.createdAt,
                          testOn: test.testOn,
                          testResult: test.testResult,
                          nextTestSchedule: test.nextTestSchedule,
                          additionalInformationTest: test.additionalInformation,
                        });
                      });
                      console.log(testArray2);
                      await API.graphql({
                        query: queries.listRepairs,
                        variables: {
                          limit: 10000,
                          filter: {
                            replacedAssetID: {
                              eq: repair2[0].assetsID,
                            },
                          },
                        },
                        authMode: "API_KEY",
                      })
                        .then(async (response5) => {
                          console.log(response5);
                          const repair4 = response5.data.listRepairs.items;
                          const combinedRepairs = [
                            ...repair3,
                            ...repair2,
                            ...repair,
                            ...repair4,
                          ];
                          console.log(combinedRepairs);
                          let repairArray = [];
                          combinedRepairs.map((test) => {
                            repairArray.push({
                              id: test.id,
                              createdAt: test.createdAt,
                              repairDate: test.repairDate,
                              replacedAssetID: test.replacedAssetID,
                              warrantyUpto: test.warrantyUpto,
                              additionalInformationRepair:
                                test.additionalInformation,
                            });
                          });
                          console.log(repairArray);
                          const combinedTesting = [...testArray, ...testArray2];
                          console.log(combinedTesting);
                          const combinedArray =
                            combinedTesting.concat(repairArray);

                          const uniqueCombinedArray = Array.from(
                            new Set(combinedArray.map(JSON.stringify))
                          ).map(JSON.parse);

                          console.log(uniqueCombinedArray);
                          const sort = uniqueCombinedArray.sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          );
                          setCombinedData(sort);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  const combinedRepairs = [...repair, ...repair2];
                  console.log(combinedRepairs);
                  let repairArray = [];
                  combinedRepairs.map((test) => {
                    repairArray.push({
                      id: test.id,
                      createdAt: test.createdAt,
                      repairDate: test.repairDate,
                      replacedAssetID: test.replacedAssetID,
                      warrantyUpto: test.warrantyUpto,
                      additionalInformationRepair: test.additionalInformation,
                    });
                  });
                  console.log(repairArray);
                  const combinedArray = testArray.concat(repairArray);

                  const uniqueCombinedArray = Array.from(
                    new Set(combinedArray.map(JSON.stringify))
                  ).map(JSON.parse);

                  console.log(uniqueCombinedArray);
                  const sort = uniqueCombinedArray.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  );
                  setCombinedData(sort);
                }

                {
                  /*
                const combinedRepairs = [...repair, ...repair2];
                console.log(combinedRepairs);
                let repairArray = [];
                combinedRepairs.map((test) => {
                  repairArray.push({
                    id: test.id,
                    createdAt: test.createdAt,
                    repairDate: test.repairDate,
                    replacedAssetID: test.replacedAssetID,
                    warrantyUpto: test.warrantyUpto,
                    additionalInformationRepair: test.additionalInformation,
                  });
                });
                console.log(repairArray);
                const combinedArray = testArray.concat(repairArray);

                const uniqueCombinedArray = Array.from(
                  new Set(combinedArray.map(JSON.stringify))
                ).map(JSON.parse);

                console.log(uniqueCombinedArray);
                const sort = uniqueCombinedArray.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                setCombinedData(sort);
                */
                }
              })
              .catch((error) => {
                console.log(error);
              });
            //--
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchFormDetails() {
      await API.graphql({
        query: queries.getAssetTemplate,
        variables: {
          id: state.state.item.assettemplateID,
        },
        authMode: "API_KEY",
      })
        .then(async (results) => {
          const formId = results.data.getAssetTemplate.formId;
          await API.graphql({
            query: queries.getFormDetails,
            variables: {
              id: formId,
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              const extraFormInputs =
                result.data.getFormDetails !== null
                  ? JSON.parse(result.data.getFormDetails.formFields)
                  : [];
              console.log(extraFormInputs);
              setFormData(extraFormInputs);
              await API.graphql({
                query: queries.getLogBook,
                variables: {
                  id: state.state.item.logbookId,
                  limit: 10000,
                },
                authMode: "API_KEY",
              })
                .then(async (logres) => {
                  console.log(logres.data.getLogBook);
                  const formData = {
                    status: state.state.item.status,
                    assetModel: state.state.item.assetModel,
                    distributionboxID: state.state.item.distributionboxID,
                    site: state.state.site,
                    siteId: state.state.item.siteId,
                    createdAt: state.state.item.createdAt,
                    id: state.state.item.id,
                    assetName: state.state.item.templatename,
                    formId: formId,
                    lampTypeId: state.state.item.lampTypeId,
                    installedOn: state.state.item.installedOn,
                    fittingTypeId: state.state.item.fittingTypeId,
                    formFieldValues: state.state.item.formFieldValues,
                    batteryTypeId: state.state.item.batteryTypeId,
                    assettemplateID: state.state.item.assettemplateID,
                    assetWarrantyStart: state.state.item.assetWarrantyStart,
                    assetWarrantyEnd: state.state.item.assetWarrantyEnd,
                    assetType: results.data.getAssetTemplate.templatename,
                    assetSerialNumber: state.state.item.assetSerialNumber,
                    assetNumber: state.state.item.assetNumber,
                    fittingNumber: state.state.item.fittingNumber,
                    assetMake: state.state.item.assetMake,
                    assetLocation: state.state.item.assetLocation,
                    assetDescription: state.state.item.assetDescription,
                    distributionBox: state.state.db,
                    serviceGroup: state.state.item.serviceGroupValue,
                    logbookId: state.state.item.logbookId,
                    logbook:
                      logres.data.getLogBook !== null
                        ? logres.data.getLogBook.logbookName !== null
                          ? logres.data.getLogBook.logbookName
                          : ""
                        : "",
                    assetImage: state.state.item.assetImage,
                  };
                  getfilename(formData.assetImage);
                  await Object.keys(extraFormInputs).map((e) => {
                    console.log("e", e);
                    try {
                      const values = JSON.parse(
                        state.state.item.formFieldValues || "[]"
                      );
                      for (let i = 0; i < values.length; i++) {
                        if (values[i].hasOwnProperty(e)) {
                          formData[e] = values[i][e];
                          break;
                        }
                      }
                    } catch (error) {
                      console.log(
                        `Error parsing JSON data for field ${e}: ${error}`
                      );
                    }
                  });
                  reset({ ...formData });
                  console.log(formData);
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [state.state]);

  async function onSubmit(formData) {
    console.log(formData);
    try {
      const formValues = [];
      Object.keys(formFieldData).map((e) => {
        formValues.push({ [e]: formData[e] });
      });
      await API.graphql({
        query: mutations.updateAssets,
        variables: {
          input: {
            id: state.state.item.id,
            assettemplateID: state.state.item.assettemplateID,
            distributionboxID: state.state.item.distributionboxID,
            siteId: state.state.item.siteId,
            formFieldValues: JSON.stringify(formValues),
            assetModel: formData.assetModel,
            assetDescription: formData.assetDescription,
            assetWarrantyStart: formData.assetWarrantyStart,
            assetWarrantyEnd: formData.assetWarrantyEnd,
            assetMake: formData.assetMake,
            installedOn: formData.installedOn,
            assetSerialNumber: formData.assetSerialNumber,
            assetLocation: formData.assetLocation,
            assetImage:
              ProfilePic !== null && ProfilePic.length > 0
                ? JSON.stringify(ProfilePic)
                : formData.assetImage ? JSON.stringify(formData.assetImage[0]) : "",
            logbookId: state.state.item.logbookId,
          },
        },
        authMode: "API_KEY",
      })
        .then((result) => {
          toast.success("Successfully Updated Assets.", {
            position: toast.POSITION.TOP_CENTER,
          });
          console.log(result);
          navigate(`${process.env.PUBLIC_URL}/assets`, {
            state: { category: state.state.category },
          });
        })
        .catch((error) => {
          toast.error("Error While Updated Assets : " + error, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (e) {
      toast.error("Error While Adding Assets : " + e.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  const formInputs = Object.keys(formFieldData).map((e) => {
    const { label, type, mandatory, errorMessage, options } = formFieldData[e];
    console.log(e);
    return (
      <Col md="6 mb-3" key={e}>
        <label>{label}</label>
        {type !== "dropdown" && (
          <input
            placeholder={label}
            className="form-control"
            type={type}
            name={e}
            {...register(e, { required: mandatory })}
          />
        )}
        {type === "dropdown" && (
          <select
            className="form-control"
            name={e}
            {...register(e, { required: mandatory })}
          >
            <option value="">Choose {label}...</option>
            {options.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        )}
        <span>{errors.e && { errorMessage }}</span>
      </Col>
    );
  });

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/assets`, {
      state: { category: state.state.category },
    });
  };

  // image retrive

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const getImage = (filename) => {
    console.log(filename);
    var params = { Bucket: S3_BUCKET, Key: `public/${ASSETS}/${filename}` };
    var promise = myBucket.getSignedUrlPromise("getObject", params);
    promise.then(
      function (url) {
        setdownloadedFile(url);
        setselectedImg(url);
      },
      function (err) {
        console.log(err);
      }
    );
  };

  function getfilename(path) {
    console.log(path);
    if (!path) {
      return;
    }
    let files;
    try {
      files = JSON.parse(path);
    } catch (error) {
      console.log("Invalid JSON data:", error);
      return;
    }
    console.log(files);
    if (typeof files === "string") {
      try {
        const newDoc = JSON.parse(files);
        console.log(newDoc);
        console.log(newDoc.length);
        if (newDoc.length > 0) {
          var newfilename = newDoc[0].fileName;
          console.log(newfilename);
          getImage(newfilename);
        }
      } catch (error) {
        console.log("Invalid JSON data in string:", error);
      }
    } else {
      console.log(files.length);
      if (files.length > 0) {
        var newfilename = files[0].fileName;
        console.log(newfilename);
        getImage(newfilename);
      }
    }
  }

  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${ASSETS}/${file.name}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(evt);
        const filedetails = [];
        filedetails.push({ fileName: file.name, type: file.type });
        setProfilePic(filedetails);
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  return (
    <Fragment>
      <br></br>
      <Container fluid={true} style={{ paddingLeft: "0px !important" }}>
        <H1>{state.state && state.state.item.assetModel}</H1>
        <Card>
          <CardBody>
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col md="4 mb-3">
                  <Label className="mr-2">Asset Type:</Label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    name="assetType"
                    {...register("assetType")}
                  />
                </Col>

                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Fitting Number:</Label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly={true}
                      name="fittingNumber"
                      {...register("fittingNumber")}
                    />
                  </div>
                </Col>

                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Asset Model:</Label>
                    <input
                      className="form-control"
                      name="assetModel"
                      type="text"
                      {...register("assetModel")}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Sites:</Label>
                    <input
                      className="form-control"
                      name="site"
                      type="text"
                      {...register("site")}
                      readOnly={true}
                    />
                  </div>
                </Col>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Distribution Board:</Label>
                    <input
                      className="form-control"
                      name="distributionBox"
                      type="text"
                      {...register("distributionBox")}
                      readOnly={true}
                    />
                  </div>
                </Col>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Asset Description:</Label>
                    <input
                      className="form-control"
                      name="assetDescription"
                      type="text"
                      {...register("assetDescription")}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Warranty Start Date:</Label>
                    <input
                      className="form-control"
                      name="assetWarrantyStart"
                      type="date"
                      {...register("assetWarrantyStart", {
                        required: true,
                      })}
                    />
                  </div>
                </Col>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Warranty End Date:</Label>
                    <input
                      className="form-control"
                      name="assetWarrantyEnd"
                      type="date"
                      {...register("assetWarrantyEnd")}
                    />
                  </div>
                </Col>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Installed On:</Label>
                    <input
                      className="form-control"
                      name="installedOn"
                      type="date"
                      {...register("installedOn")}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Asset Make:</Label>
                    <input
                      className="form-control"
                      name="assetMake"
                      placeholder="Asset Make"
                      type="text"
                      {...register("assetMake")}
                    />
                  </div>
                </Col>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Asset Serial Number:</Label>
                    <input
                      className="form-control"
                      name="assetSerialNumber"
                      placeholder="Asset Serial Number"
                      type="text"
                      {...register("assetSerialNumber")}
                    />
                  </div>
                </Col>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Asset Location:</Label>
                    <input
                      className="form-control"
                      name="assetLocation"
                      type="text"
                      {...register("assetLocation")}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Logbook:</Label>
                    <input
                      className="form-control"
                      name="logbook"
                      type="text"
                      {...register("logbook")}
                      readOnly={true}
                    />
                  </div>
                </Col>
                <Col md="4" className="mb-3">
                  <div className="d-flex flex-column align-items-left">
                    <Label className="mr-2">Asset Image:</Label>
                    <Input
                      type="file"
                      className="form-control"
                      name="profilePic"
                      onChange={onSelectFile}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-left">
                    {downloadedFile ? (
                      <img src={downloadedFile} width="150px" height="150px" />
                    ) : (
                      "No Image"
                    )}
                  </div>
                </Col>
                <Col md="4" className="mb-3"></Col>
              </Row>
              <Row>
                <Col md="12 mb-3">
                  <hr className="m-t5 m-b5"></hr>
                </Col>
                <Col md="12">
                  <Row>{formInputs}</Row>
                </Col>
              </Row>
              <Row>
                <H5>Test, Repair/Replace Data</H5>
                <div className="card-block row">
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <Table className="table-striped  table-de" id="report">
                        <thead className="custom-table-head">
                          <tr>
                            <th>Test Date</th>
                            <th>Repair Date</th>
                            <th>Repair Additional Info</th>
                            <th>Test Result</th>
                            <th>Repair Warranty Upto</th>
                            <th>Next Testing Date</th>
                            <th>Test Additional Info</th>
                            <th>Replaced Asset</th>
                            <th>Replaced Date</th>
                          </tr>
                        </thead>
                        <tbody className="trhover">
                          {combinedData.map((item) => (
                            <tr key={item.id}>
                              <td>
                                {item.testOn
                                  ? moment(item.testOn).format("DD/MM/YYYY")
                                  : "-"}
                              </td>
                              <td>
                                {item.repairDate
                                  ? moment(item.repairDate).format("DD/MM/YYYY")
                                  : "-"}
                              </td>
                              <td>{item.additionalInformationRepair}</td>
                              <td>{item.testResult}</td>
                              <td>
                                {item.warrantyUpto
                                  ? moment(item.warrantyUpto).format(
                                    "DD/MM/YYYY"
                                  )
                                  : "-"}
                              </td>
                              <td>
                                {item.nextTestSchedule
                                  ? moment(item.nextTestSchedule).format(
                                    "DD/MM/YYYY"
                                  )
                                  : "-"}
                              </td>
                              <td>
                                {item.testResult
                                  ? item.additionalInformationTest
                                  : ""}
                              </td>
                              <td>{item.replacedAssetID ? "replaced" : "-"}</td>
                              <td>
                                {item.replacedAssetID
                                  ? moment(item.repairDate).format("DD/MM/YYYY")
                                  : "-"}
                              </td>
                            </tr>
                          ))}
                          {combinedData.length === 0 && (
                            <tr>
                              <td className="text-center" colSpan="12">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </div>
              </Row>
              <Row>
                <Col md="6" className="text-end"></Col>
                <Col md="6" className="text-end">
                  <Btn attrBtn={{ color: "primary" }}>Update</Btn>
                  <Btn attrBtn={{ color: "danger", onClick: cancelForm }}>
                    Cancel
                  </Btn>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};
export default ViewAsset;
