import { H5,H4,H6, P } from '../../AbstractElements';
import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row ,Media} from 'reactstrap';

import CountUp from 'react-countup';


const Activity = () => {

  const EarningsData = [
    {
        id: 1,
        product: 'Emergency Lights',
        count: 6659,
        // icon: <DollerSvg />,
        class: 'progress-gradient-secondary',
    },
    {
        id: 2,
        product: 'RCD',
        count: 9856,
        // icon: <ProductSvg />,
        class: 'progress-gradient-success',
    },
    {
        id: 3,
        product: 'Test And Tags',
        count: 893,
        // icon: <MessageSvg />,
        class: 'progress-gradient-primary',
    },
    {
        id: 4,
        product: 'Termal Imaginf',
        count: 45631,
        // icon: <NewUsersSvg />,
        class: 'progress-gradient-danger',
    },
    {
      id: 5,
      product: 'Windows',
      count: 45631,
      // icon: <NewUsersSvg />,
      class: 'progress-gradient-danger',
  },
  {
    id: 6,
    product: 'Parks',
    count: 45631,
    // icon: <NewUsersSvg />,
    class: 'progress-gradient-danger',
},
  ];

  return (
    <Fragment>
      <Row>
      {
        EarningsData.map((item) => {
          return (
            <Col  key={item.id}>
              <Card className="o-hidden">
                <CardBody>
                  <Media className="static-widget">
                    <Media body><H6 attrH6={{ className: 'font-roboto' }}>{item.product}</H6>
                      <H4 attrH4={{ className: 'mb-0 counter' }}><CountUp end={item.count} /></H4>
                    </Media>
                    {item.icon}
                  </Media>
                </CardBody>
              </Card>
            </Col>
          );
        })
      }
      </Row>
    </Fragment>
  );
};
export default Activity;






