import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import Import from "../../Component/BulkImport/index";

const BulkDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="BulkImport" title="Bulk Import" />
      <Import />
    </Fragment>
  );
};
export default BulkDetails;
