import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import ReportImaging from '../../../Component/Assets/AddAsset/ReportThermal';

const ReportImagingContain = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Assets" title="Thermal Imaging Report" />
      <ReportImaging />
    </Fragment>
  );
};
export default ReportImagingContain;