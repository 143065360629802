import React from "react";
import { Col, Row, CardBody, Card, Table } from "reactstrap";
import { Btn } from "../../AbstractElements";

const XLSX = require("xlsx");

const SampleTemplate = () => {
  const handleExport = (tblName) => {
    console.log(tblName);
    var elt = document.getElementById(tblName);
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    return XLSX.writeFile(wb, tblName + ".xlsx");
  };
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Table
              className="table-striped  table-de"
              style={{ display: "none" }}
              id="siteTable"
            >
              <thead className="custom-table-head">
                <tr>
                  <th>siteName</th>
                  <th>assetowner</th>
                  <th>companydetails</th>
                  <th>country</th>
                  <th>state</th>
                  <th>street</th>
                  <th>suburb</th>
                  <th>postCode</th>
                  <th>unitNumber</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Balga Senior High School</td>
                  <td>James Daniel</td>
                  <td>Compnany Name</td>
                  <td>Australia</td>
                  <td>WA</td>
                  <td>23</td>
                  <td>Perth</td>
                  <td>6000</td>
                  <td>23 st, Perth 6000, WA, Australia</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
      {/* <Col md="4">
        <Card>
          <CardBody>
            <Btn
              attrBtn={{
                color: "primary",
                onClick: () => handleExport("dbTable"),
              }}
            >
              Download Distribution Board Import File Template
            </Btn>
            <Table
              className="table-striped  table-de"
              style={{ display: "none" }}
              id="dbTable"
            >
              <thead className="custom-table-head">
                <tr>
                  <th>siteName</th>
                  <th>distributionBoxName</th>
                  <th>location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Balga Senior High School</td>
                  <td>Markham Block South DB11A</td>
                  <td>Perth</td>
                </tr>
                <tr>
                  <td>Balga Senior High School</td>
                  <td>GYM DB17</td>
                  <td>Perth</td>
                </tr>
                <tr>
                  <td>Balga Senior High School</td>
                  <td>Library DB5</td>
                  <td>Perth</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
      <Col md="4">
        <Card>
          <CardBody>
            <Btn
              attrBtn={{
                color: "primary",
                onClick: () => handleExport("assetTable"),
              }}
            >
              Download Logbook Import File Template
            </Btn>
            <Table
              className="table-striped  table-de"
              style={{ display: "none" }}
              id="assetTable"
            >
              <thead className="custom-table-head">
                <tr>
                  <th>siteName</th>
                  <th>distributionbox</th>
                  <th>assetDescription</th>
                  <th>assetLocation</th>
                  <th>assetMake</th>
                  <th>assetModel</th>
                  <th>assetNumber</th>
                  <th>assettemplate</th>
                  <th>assetWarrantyStart</th>
                  <th>assetWarrantyEnd</th>
                  <th>installedOn</th>
                  <th>Any custom label for the Emergency Lights Template</th>
                  <th>logbook</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Balga Senior High School</td>
                  <td>Library DB5</td>
                  <td>test</td>
                  <td>perth</td>
                  <td>Make</td>
                  <td>Model</td>
                  <td>emrgLht001</td>
                  <td>Emergency Lights Template</td>
                  <td>2023/07/01</td>
                  <td>2023/12/01</td>
                  <td>2023/07/01</td>
                  <td>Any custom values for the Emergency Lights Template</td>
                  <td>Balga Senior High School Default template</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col> */}
    </Row>
  );
};
export default SampleTemplate;
