import React, { useState } from 'react';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import { Image } from '../AbstractElements';
import NavAuth from './Nav';
import LoginTab from './Tabs/LoginTab';
import AuthTab from './Tabs/AuthTab';
import imgg from '../assets/images/logo/logo.png';

const Logins = () => {
  const [selected, setSelected] = useState('firebase');

  const callbackNav = ((select) => {
    setSelected(select);
  });

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main1 login-tab1">
                <div className="login-header text-center">
                  <div className="logo-wrapper" >
                    <a href="#javascript">
                      <Image attrImage={{ className: 'img-fluid', src: `${imgg}`, alt: '' }} />
                    </a>
                  </div>
                </div>
                <LoginTab />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;