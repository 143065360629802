import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import FormsListingContain from '../../Component/UserPanel/formsList';

const FormsList = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="UserPanel" />
      <FormsListingContain />
    </Fragment>
  );
};
export default FormsList;