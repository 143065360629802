import React, { Fragment, useState, useEffect } from "react";
import { API, DataStore, Amplify } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mergedQueries from '../../graphql/mergedQueries';
import { toast } from 'react-toastify';
import { CardBody, CardHeader, Card, Row, Col, Media, Container, Label } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { H4, UL, LI } from '../../AbstractElements';
import { useNavigate, useLocation, useParams , Link} from 'react-router-dom';
import { AssetTemplate } from "../../models/index";
import awsExports from "../../aws-exports";
import AWS from 'aws-sdk';
import { FUNCTIONNAME, REGION, SECRETACCESSKEY, ACCESSKEYID } from '../../Config/Config';
import * as mutations from '../../graphql/mutations';

Amplify.configure(awsExports);

const TestingContain = () => {
    const state = useLocation();
    AWS.config.update({
        region: REGION,
        accessKeyId: ACCESSKEYID,
        secretAccessKey: SECRETACCESSKEY,
    });

    useEffect(async () => {
        console.log(state.state.assets);
        API.graphql({
            query: mutations.createLogBook,
            variables: {input: {
                siteId: state.state.sites,
                distributionBox: state.state.db,
                assetsID: state.state.assets,
                status:'Active'
            }},
            authMode: 'API_KEY'
        }).then((result) => {
        toast.success('The Testing is Creating Succesfully!..', {
            position: toast.POSITION.TOP_CENTER 
        });
        
        console.log(result);
        }).catch((error) => {
        console.log(error);
        });
        // const payload = {
        //     "id": "365a34ef-763d-4322-8420-abe3fc6222f2",
        //     "assetDescription": "Emergency Light",
        //     "assetLocation": "Geelong VIC, Australia",
        //     "assetMake": "Model A",
        //     "assetModel": "HD32#D",
        //     "assetNumber": "ASSET001",
        //     "assetSerialNumber": "3432432423",
        //     "assettemplateID": "Emergency Light",
        //     "assetWarrantyEnd": "2023-03-12",
        //     "assetWarrantyStart": "2023-02-01",
        //     "batteryTypeId": "",
        //     "createdAt": "2023-02-02T07:22:04.434Z",
        //     "distributionboxID": "D0014",
        //     "fittingTypeId": "",
        //     "installedOn": "2023-02-02",
        //     "siteId": "SatUN123",
        //     "status": "Active",
        // }
        // const lambda = new AWS.Lambda();
        // const params = {
        //   FunctionName: FUNCTIONNAME,
        //   Payload: JSON.stringify(payload),
        // };
        // lambda.invoke(params).promise();


    }, []);

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col md="12" className="project-list">
                        <Card>
                            <CardBody>                                
                                <Row>
                                    <Col md="12">
                                        <Label>Asset Lists</Label>
                                        Asset001 - Fail
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
export default TestingContain;