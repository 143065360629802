import {
  BlogSvg,
  BonusUISvg,
  ButtonsSvg,
  CalanderSvg,
  ChartsSvg,
  ChatSvg,
  ContactSvg,
  EcommerceSvg,
  EditorsSvg,
  EmailSvg,
  FAQSvg,
  FilemanagerSvg,
  FormsSvg,
  GallarySvg,
  HeaderBookmarkSvg,
  HomeSvg,
  IconsSvg,
  JobsearchSvg,
  KanbanSvg,
  KnowledgebaseSvg,
  LearningSvg,
  MapsSvg,
  OthersSvg,
  ProjectSvg,
  SamplePageSvg,
  SearchResultSvg,
  SocialappSvg,
  SupportTicketSvg,
  TablesSvg,
  TaskSvg,
  TodoSvg,
  UiKitsSvg,
  UsersComponentSvg,
  WidgetsSvg,
  SidebarHomeIcon,
  SidebarSiteIcon,
  SidebarServiceIcon,
  SidebarAssetsIcon,
  SidebarSubscriptionsIcon,
  SidebarLogbooksIcon,
  SidebarReportsIcon,
  SidebarCertificatesIcon,
} from "../../Data/svgIcons";
export const MENUITEMS = [
  {
    menutitle: "DashBoard",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/default`,
        bookmark: true,
        icon: SidebarHomeIcon,
        title: "Dashboard",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Sites",
    Items: [
      {
        title: "Sites",
        type: "link",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/sites`,
            title: "Manage Sites",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/siteownerpanel/siteUser`,
            title: "Manage Site Users",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Service Providers",
    Items: [
      {
        title: "Service Providers",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/siteownerpanel/serviceprovider`,
            title: "Service Provider",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Assets",
    Items: [
      {
        title: "Assets",
        type: "link",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/catassetlist`,
            title: "Assets",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/assettemplates`,
            title: "Asset Templates",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Logbooks",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/generatereportfasset`,
        bookmark: true,
        title: "Logbooks",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Reports",
    Items: [
      {
        title: "Reports",
        type: "link",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/reports/catList`,
            title: "Generate Reports",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Certificates",
    Items: [
      {
        title: "Certificates",
        type: "link",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/certificates`,
            title: "Certificates",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Tasks",
    Items: [
      {
        title: "Tasks",
        type: "link",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/tasksao`,
            title: "Manage Tasks",
            type: "link",
          },
        ],
      },
    ],
  },
];
