import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import BulkImport from "../../Component/BulkImport/CategoryBulkUpload";

const BulkAdminDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="BulkImport" title="Bulk Import" />
      <BulkImport />
    </Fragment>
  );
};
export default BulkAdminDetails;
