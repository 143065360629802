import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import ViewAssetContain from '../../../Component/Assets/AddAsset/ViewAsset';

const ViewAsset = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Assets" title="Edit Asset" />
      <ViewAssetContain />
    </Fragment>
  );
};
export default ViewAsset;