import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import TestResultContains from '../../../Component/Site/SiteList/testResult';

const SiteTestResult = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Site" title="Test Result" />
      <TestResultContains />
    </Fragment>
  );
};
export default SiteTestResult;