import * as mutations from '../../graphql/mutations';

export const CREATEBATTERYTYPES = mutations.createBatteryType;
export const CREATEFITTINGYTYPES = mutations.createFittingType;
export const CREATELAMPTYPES = mutations.createLampType;
export const CREATEASSETOWNERS = mutations.createAssetOwner;
export const CREATEASSETTEMPLATES = mutations.createAssetTemplate;
export const CREATEASSETSS = mutations.createAssets;
export const CREATECATEGORIESS = mutations.createCategories;
export const CREATECOMPANYDETAILSS = mutations.createCompanyDetails;
export const CREATEDISTRIBUTIONBOXS = mutations.createDistributionBox;
export const CREATESERIVCEGROUPS = mutations.createServiceGroup;
export const CREATESITES = mutations.createSiteDetails;


export const tableMapping = ({
    'AssetOwner': mutations.createAssetOwner,
    'AssetTemplate': mutations.createAssetTemplate,
    'Assets': mutations.createAssets,
    'BatteryType': mutations.createBatteryType,
    // 'BillingPlan': mutations.createBillingPlan,
    'Categories': mutations.createCategories,
    'CognitoGroup': mutations.createCognitoGroup,
    'CompanyDetails': mutations.createCompanyDetails,
    'DistributionBox': mutations.createDistributionBox,
    'FittingType': mutations.createFittingType,
    'FormDetails': mutations.createFormDetails,
    'LampType': mutations.createLampType,
    'LinkUsersAndSite': mutations.createLinkUsersAndSite,
    'LogBook': mutations.createLogBook,
    'LogbookAssetLink': mutations.createLogbookAssetLink,
    'Repair': mutations.createRepair,
    'SPCompanyDetails': mutations.createSPCompanyDetails,
    'SPUserDetails': mutations.createSPUserDetails,
    'ServiceGroup': mutations.createServiceGroup,
    'ServiceGroupUserMap': mutations.createServiceGroupUserMap,
    'SiteDetails': mutations.createSiteDetails,
    'SubscriptionLimit': mutations.createSubscriptionLimit,
    'Testing': mutations.createTesting,
    'UserDetails': mutations.createUserDetails
})