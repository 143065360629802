import Default from "../Pages/DashBoard/Default/Default";
// import Ecommerce from '../Pages/DashBoard/Ecommerce/index';
import Crypto from "../Pages/DashBoard/Crypto";
import OrderHistory from "../Pages/Ecommerce/OrderHistory";
import PaymentDetails from "../Pages/Ecommerce/PaymentDetails";
import ProductList from "../Pages/Ecommerce/ProductList";
import ProductPage from "../Pages/Ecommerce/ProductPage";
import Products from "../Pages/Ecommerce/Products";
import FileManager from "../Pages/FileManager";
import KanbanBoard from "../Pages/KanbanBoard";
import CreateList from "../Pages/Project/Create List";
import ProjectList from "../Pages/Project/Project List";
import Charts from "../Pages/Widgets/Charts";
import General from "../Pages/Widgets/General";
import Invoice from "../Pages/Ecommerce/Invoice/index";
import ProductCart from "../Pages/Ecommerce/ProductCart";
import WishList from "../Pages/Ecommerce/Wishlist";
import CheckOut from "../Pages/Ecommerce/CheckOut";
import PricingMembership from "../Pages/Ecommerce/PricingMemberShip";
import MailInbox from "../Pages/Email/Mail Inbox";
import ReadMail from "../Pages/Email/Read Mail";
import Compose from "../Pages/Email/Compose";
import ChatApp from "../Pages/Chat/ChatApp";
import VideoChat from "../Pages/Chat/VideoChat";
import UsersProfile from "../Pages/Users/UsersProfile";
import UsersEdit from "../Pages/Users/UsersEdit";
import UsersCardss from "../Pages/Users/UsersCards";
import SocialApp from "../Pages/SocialApp";
import Typography from "../Pages/Ui-kit/Typography";
import Avatars from "../Pages/Ui-kit/Avtars";
import HelperClasses from "../Pages/Ui-kit/HelperClass";
import Grid from "../Pages/Ui-kit/Grid";
import TagAndPills from "../Pages/Ui-kit/TagAndPills";
import Progressbars from "../Pages/Ui-kit/Progress";
import Modals from "../Pages/Ui-kit/Modals";
import Alerts from "../Pages/Ui-kit/Alert";
import PopOvers from "../Pages/Ui-kit/Popover";
import Tooltips from "../Pages/Ui-kit/Tooltip";
import Spinners from "../Pages/Ui-kit/Spinners";
import Dropdowns from "../Pages/Ui-kit/Dropdown";
import TabBootstrap from "../Pages/Ui-kit/Tabs/BootstrapTab";
import TabLine from "../Pages/Ui-kit/Tabs/LineTab";
import Accordian from "../Pages/Ui-kit/Accordian";
import Shadow from "../Pages/Ui-kit/Shadows";
import List from "../Pages/Ui-kit/Lists";
import Calender from "../Pages/Calender/Index";
import SearchResult from "../Pages/Search Result";
import Draggable from "../Pages/Calender/Draggable Calender";
import FormValidation from "../Pages/Forms/Form Control/FormValidation";
import BaseInput from "../Pages/Forms/Form Control/BaseInput";
import InputGroups from "../Pages/Forms/Form Control/InputGroup";
import MegaOptions from "../Pages/Forms/Form Control/MegaOptions";
import RadioCheckbox from "../Pages/Forms/Form Control/RadioCheckbox";
import Todo from "../Pages/Todo";
import TodoFirebase from "../Pages/Todo Firebase";
import Bookmarks from "../Pages/BookMarks";
import Tasks from "../Pages/Tasks";
import Contact from "../Pages/Contact";
import ContactFirebase from "../Pages/Contact/contactFirebase";
import FormDatapicker from "../Pages/Forms/Form Widgets/FormDatepicker";
import FormTypeahead from "../Pages/Forms/Form Widgets/FormTypeahead";
import FormDefault from "../Pages/Forms/Form Layout/FormDefault";
import FormWizard from "../Pages/Forms/Form Layout/FormWizard";
import FormDateTime from "../Pages/Forms/Form Widgets/FormDateTime";
import FormSelect2 from "../Pages/Forms/Form Widgets/FormSelect2";
import FormSwitch from "../Pages/Forms/Form Widgets/FormSwitch";
import FormTouchspin from "../Pages/Forms/Form Widgets/FormTouchSpin";
import FormDateRangepicker from "../Pages/Forms/Form Widgets/FormDateRangepicker";
import FromClipboard from "../Pages/Forms/Form Widgets/FormClipboard";
import BlogDetail from "../Pages/Blog/BlogDetail";
import BlogPost from "../Pages/Blog/BlogPost";
import BlogSingle from "../Pages/Blog/BlogSingle";
import LearningList from "../Pages/Learning/LearningList";
import DetailedList from "../Pages/Learning/DetailedList";
import CardView from "../Pages/Job Search/CardView";
import ListView from "../Pages/Job Search/ListView";
import ApplyNow from "../Pages/Job Search/Apply";
import JobDetails from "../Pages/Job Search/JobDetail";
import CKEditor from "../Pages/Editor/CkEditor";
import MDEEditor from "../Pages/Editor/MDEEditor";
import ACEEditor from "../Pages/Editor/ACECodeEditor";
import KnowledgeBase from "../Pages/Knowledgebase/KnowledgeBase";
import KnowledgeCategory from "../Pages/Knowledgebase/KnowledgeCategory";
import KnowledgeDetails from "../Pages/Knowledgebase/KnowledgeDetails";
import SamplePage from "../Pages/Sample Page";
import LeafletMap from "../Pages/Maps/LeafletMap";
import MapJs from "../Pages/Maps/MapJs";
import SupportTickit from "../Pages/Support Tickit";
import FAQ from "../Pages/FAQ";
import GalleryGrid from "../Pages/Gallery/GalleryGrid";
import GalleryGridDesc from "../Pages/Gallery/GalleryGridDesc";
import MasonryGallery from "../Pages/Gallery/MasonryGallery";
import MasonryGalleryDesc from "../Pages/Gallery/MasonryGalleryDesc";
import HoverEffects from "../Pages/Gallery/HoverEffect";
import DataTable from "../Pages/Tables/DataTable";
import BasicTable from "../Pages/Tables/Reactstrap Table/BasicTable";
import SizingTable from "../Pages/Tables/Reactstrap Table/Sizingtable";
import StylingTable from "../Pages/Tables/Reactstrap Table/StylingTable";
import BorderTable from "../Pages/Tables/Reactstrap Table/BorderTable";
import DefaultStyle from "../Pages/Buttons/Defaultstyle";
import FlagIcon from "../Pages/Icons/FlagIcons";
import FontAwesome from "../Pages/Icons/FontAwesom";
import IcoIcons from "../Pages/Icons/IcoIcons";
import ThemifyIcon from "../Pages/Icons/ThemifyIcon";
import FeatherIcon from "../Pages/Icons/FeatherIcon";
import WhetherIcon from "../Pages/Icons/WhetherIcon";
import ApexChart from "../Pages/Charts/ApexChart";
import ChartistChart from "../Pages/Charts/ChartistChart";
import ChartJs from "../Pages/Charts/ChartJs";
import GoogleChart from "../Pages/Charts/GoogleChart";
import BasicCard from "../Pages/Bonus UI/BasicCard";
import CreativeCard from "../Pages/Bonus UI/CreativeCard";
import DraggingCard from "../Pages/Bonus UI/DraggingCard";
import TabCard from "../Pages/Bonus UI/TabCard";
import Carousel from "../Pages/Bonus UI/Carousels";
import BreadcrumbsClass from "../Pages/Bonus UI/Breadcrumb";
import ImageCroppers from "../Pages/Bonus UI/ImageCropper";
import Scrollables from "../Pages/Bonus UI/Scrollable";
import BootstrapNotify from "../Pages/Bonus UI/BootstrapNotify";
import RatingClass from "../Pages/Bonus UI/Rating";
import DropzoneClass from "../Pages/Bonus UI/Dropzone";
// import Tours from '../Pages/Bonus UI/Tours';
import Ribbons from "../Pages/Bonus UI/Ribbon";
import RangeSlider from "../Pages/Bonus UI/RangeSilder";
import Pagination from "../Pages/Bonus UI/Pagination";
import ImageUpload from "../Pages/Bonus UI/ImageUpload";
import Sticky from "../Pages/Bonus UI/Sticky";
import TimeLines from "../Pages/Bonus UI/TimeLine";
import SweetAlerts from "../Pages/Bonus UI/SweetAleart";
import TreeViews from "../Pages/Bonus UI/TreeView";
import DragAndDrop from "../Pages/Bonus UI/DragAndDrop";
import Steps from "../Pages/Bonus UI/Steps";

import AddSite from "../Pages/Site/AddSite";
import SiteList from "../Pages/Site/SiteList";
import AddSLinkUserwithSite from "../Pages/Site/AddSite/linkUserwithSite";

import UserDashboard from "../Pages/DashBoard/User/index";
import CompanyAssetOwner from "../Pages/CompanyAssetOwner/index";
import AssetOwnerList from "../Pages/AssetOwner/index";
import Assets from "../Pages/Assets/AddAsset/index";
import CategoryListAssets from "../Pages/Assets/AddAsset/CategoryList";
import AddAssets from "../Pages/Assets/AddAsset/AddAssets";
import AssetTemplate from "../Pages/Assets/AssetTemplate/index";
import AddAssetTemplate from "../Pages/Assets/AssetTemplate/AddAssetTemplate";
import ViewAssetContain from "../Pages/Assets/AddAsset/ViewAsset";
import UserPanel from "../Pages/UserPanel/index";
import CategoryAssets from "../Pages/UserPanel/categoryAssets";
import FormsList from "../Pages/UserPanel/formsList";
import ListDeletedAsset from "../Pages/Assets/AddAsset/deletedAssets";

//UserPanel
import SiteUserPanel from "../Pages/SiteUserPanel/siteuserpanel";
import AssetTable from "../Pages/SiteUserPanel/assettable";
import AddEditLogBook from "../Pages/UserPanel/LogBook/index";
import AddEditRepairs from "../Pages/UserPanel/Repair/index";
import AddEditTesting from "../Pages/UserPanel/Testing/index";

//-------
import AddForm from "../Pages/AddForm/index";
import ServiceGroupDetails from "../Pages/AssetCategory/index";
import FormDetails from "../Pages/AssetCategory/forms";
import CategoriesDetails from "../Pages/AssetCategory/categories";

//SiteOwnerPanel--- serviceProvider----
import ServiceProvider from "../Pages/SiteOwnerPanel/serviceProvider";
import AddEditSP from "../Pages/SiteOwnerPanel/addEditSp";

//SiteOwnerPanel--- Site User----
import SiteUser from "../Pages/SiteOwnerPanel/siteUser";
import AddEditSiteUser from "../Pages/SiteOwnerPanel/addEditSiteUser";

import ViewCompanyDetails from "../Pages/CompanyAssetOwner/ViewDetails/index";

//Asset Owner Details--------------------------

import AddAssetOwner from "../Pages/AssetOwner/AddAssetOwner";

import GenerateReportsPage from "../Pages/Reports/index";

import TasksAOContain from "../Pages/TasksAO/index";
import TasksAOContainAoSp from "../Pages/TasksAoSp/index";
import TasksAOContainSp from "../Pages/TasksAoSp/sptasks";
import AddTasksAOContain from "../Pages/TasksAO/AddTasks";

import NotificationConfiguration from "../Pages/NotificationConfiguration/index";

import Subscriptions from "../Pages/Subscriptions/index";
import SubscriptionsListAdmin from "../Pages/Subscriptions/subscriptionsListAdmin";
import BulkDetails from "../Pages/BulkImport/bulkimport";
import BulkAdminDetails from "../Pages/BulkImport/bulkimportadmin";
import CatCertContain from "../Pages/Categories/categoriesCertificates";
import CatRelatedCertiDetails from "../Pages/Categories/catrelatedCertificates";
import AddCertificates from "../Pages/Categories/addCertificate";
import TestingFail from "../Pages/UserPanel/testing";

import ReportAssetOwnerReportDetails from "../Pages/Reports/generatereportfasset";
import AssetLogbookOwnerDetails from "../Pages/Reports/assetslogbook";
import CatListContain from "../Pages/Reports/catList";

import SiteView from "../Pages/Site/SiteList/siteView";
import CategoriesSubList from "../Pages/Site/SiteList/listSubCat";
import AODashboard from "../Pages/DashBoard/User/AssetOwnerDashboard";
import SiteTestResult from "../Pages/Site/SiteList/testResult";

import PendingServiceProvider from "../Pages/SiteOwnerPanel/pendingServiceProvider";
import PendingSiteUser from "../Pages/SiteOwnerPanel/pendingSiteUser";

import ReportImagingContain from "../Pages/Assets/AddAsset/ReportThermal";
import AddReportContain from "../Pages/Assets/AddAsset/AddReports";
import AssetsCountt from "../Pages/AssetsCount/index";
import Transactions from "../Pages/Subscriptions/transactions";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard/default/`,
    Component: <Default />,
  },
  // { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/`, Component: <Ecommerce /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/`, Component: <Crypto /> },

  {
    path: `${process.env.PUBLIC_URL}/widgets/general/`,
    Component: <General />,
  },
  { path: `${process.env.PUBLIC_URL}/widgets/chart/`, Component: <Charts /> },

  {
    path: `${process.env.PUBLIC_URL}/project/projectlist/`,
    Component: <ProjectList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/project/createlist/`,
    Component: <CreateList />,
  },

  { path: `${process.env.PUBLIC_URL}/filemanager`, Component: <FileManager /> },

  { path: `${process.env.PUBLIC_URL}/kanbanboard`, Component: <KanbanBoard /> },

  {
    path: `${process.env.PUBLIC_URL}/ecommerce/product`,
    Component: <Products />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productpage`,
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productlist`,
    Component: <ProductList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/paymentdetails`,
    Component: <PaymentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/orderhistory`,
    Component: <OrderHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice`,
    Component: <Invoice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/cart`,
    Component: <ProductCart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/wishlist`,
    Component: <WishList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/checkout`,
    Component: <CheckOut />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/pricing`,
    Component: <PricingMembership />,
  },

  { path: `${process.env.PUBLIC_URL}/email/mailbox`, Component: <MailInbox /> },
  { path: `${process.env.PUBLIC_URL}/email/readmail`, Component: <ReadMail /> },
  { path: `${process.env.PUBLIC_URL}/email/compose`, Component: <Compose /> },

  { path: `${process.env.PUBLIC_URL}/chat/chatapp`, Component: <ChatApp /> },
  {
    path: `${process.env.PUBLIC_URL}/chat/videocall`,
    Component: <VideoChat />,
  },

  {
    path: `${process.env.PUBLIC_URL}/users/userprofile`,
    Component: <UsersProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/useredit`,
    Component: <UsersEdit />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/usercard`,
    Component: <UsersCardss />,
  },

  { path: `${process.env.PUBLIC_URL}/bookmarks`, Component: <Bookmarks /> },

  { path: `${process.env.PUBLIC_URL}/social-app`, Component: <SocialApp /> },

  {
    path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`,
    Component: <Calender />,
  },
  {
    path: `${process.env.PUBLIC_URL}/calandar/draggable-calandar`,
    Component: <Draggable />,
  },

  {
    path: `${process.env.PUBLIC_URL}/searchresult`,
    Component: <SearchResult />,
  },

  {
    path: `${process.env.PUBLIC_URL}/forms-control/formValidation`,
    Component: <FormValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/baseInput`,
    Component: <BaseInput />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/inputGroup`,
    Component: <InputGroups />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/megaOptions`,
    Component: <MegaOptions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/radioCheckbox`,
    Component: <RadioCheckbox />,
  },

  {
    path: `${process.env.PUBLIC_URL}/form-widget/datepicker`,
    Component: <FormDatapicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/typeahead`,
    Component: <FormTypeahead />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/datetimepicker`,
    Component: <FormDateTime />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/select2`,
    Component: <FormSelect2 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/switch`,
    Component: <FormSwitch />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/touchspin`,
    Component: <FormTouchspin />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/rangepicker`,
    Component: <FormDateRangepicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/clipboard`,
    Component: <FromClipboard />,
  },

  {
    path: `${process.env.PUBLIC_URL}/form-layout/formDefault`,
    Component: <FormDefault />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-layout/formWizard`,
    Component: <FormWizard />,
  },

  { path: `${process.env.PUBLIC_URL}/todo`, Component: <Todo /> },
  {
    path: `${process.env.PUBLIC_URL}/todofirebase`,
    Component: <TodoFirebase />,
  },

  { path: `${process.env.PUBLIC_URL}/tasks`, Component: <Tasks /> },

  { path: `${process.env.PUBLIC_URL}/contacts`, Component: <Contact /> },
  {
    path: `${process.env.PUBLIC_URL}/contacts/firebase`,
    Component: <ContactFirebase />,
  },

  {
    path: `${process.env.PUBLIC_URL}/blog/blogdetail`,
    Component: <BlogDetail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/blogsingle`,
    Component: <BlogSingle />,
  },
  { path: `${process.env.PUBLIC_URL}/blog/blogpost`, Component: <BlogPost /> },

  {
    path: `${process.env.PUBLIC_URL}/ui-kits/typography/`,
    Component: <Typography />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/avatar/`, Component: <Avatars /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/helperclass/`,
    Component: <HelperClasses />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/grid/`, Component: <Grid /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tagsandpills/`,
    Component: <TagAndPills />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/progress-bar/`,
    Component: <Progressbars />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/modal/`, Component: <Modals /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/alert/`, Component: <Alerts /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/popover/`,
    Component: <PopOvers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tooltips/`,
    Component: <Tooltips />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/spinner/`,
    Component: <Spinners />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/dropdown/`,
    Component: <Dropdowns />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap/`,
    Component: <TabBootstrap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tab-line/`,
    Component: <TabLine />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/accordion/`,
    Component: <Accordian />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/shadow/`, Component: <Shadow /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/list/`, Component: <List /> },

  {
    path: `${process.env.PUBLIC_URL}/learning/learning-list`,
    Component: <LearningList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/learning/learning-detail`,
    Component: <DetailedList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/cardView`,
    Component: <CardView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/job-list`,
    Component: <ListView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/job-detail`,
    Component: <JobDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/job-apply`,
    Component: <ApplyNow />,
  },

  {
    path: `${process.env.PUBLIC_URL}/editor/ckEditor`,
    Component: <CKEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/mdeEditor`,
    Component: <MDEEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/ace-code-editor`,
    Component: <ACEEditor />,
  },

  {
    path: `${process.env.PUBLIC_URL}/knowledgebase`,
    Component: <KnowledgeBase />,
  },
  {
    path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-category`,
    Component: <KnowledgeCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-details`,
    Component: <KnowledgeDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/sample-page`,
    Component: <SamplePage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/mapsjs`,
    Component: <MapJs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leafletmap`,
    Component: <LeafletMap />,
  },

  {
    path: `${process.env.PUBLIC_URL}/support-ticket`,
    Component: <SupportTickit />,
  },

  {
    path: `${process.env.PUBLIC_URL}/faq`,
    Component: <FAQ />,
  },

  {
    path: `${process.env.PUBLIC_URL}/gallery/imageGallery`,
    Component: <GalleryGrid />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/imageWithDesc`,
    Component: <GalleryGridDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/mesonryGallery`,
    Component: <MasonryGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/mesonryDesc`,
    Component: <MasonryGalleryDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/imageHover`,
    Component: <HoverEffects />,
  },

  {
    path: `${process.env.PUBLIC_URL}/tables/basictable`,
    Component: <BasicTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/sizingtable`,
    Component: <SizingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/stylingtable`,
    Component: <StylingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/bordertable`,
    Component: <BorderTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/datatable`,
    Component: <DataTable />,
  },

  {
    path: `${process.env.PUBLIC_URL}/button`,
    Component: <DefaultStyle />,
  },

  {
    path: `${process.env.PUBLIC_URL}/icons/flagicon`,
    Component: <FlagIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontawesome`,
    Component: <FontAwesome />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/ico`,
    Component: <IcoIcons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/thimify`,
    Component: <ThemifyIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/feather`,
    Component: <FeatherIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/wheater`,
    Component: <WhetherIcon />,
  },

  {
    path: `${process.env.PUBLIC_URL}/charts/apex`,
    Component: <ApexChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chartist`,
    Component: <ChartistChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chartjs`,
    Component: <ChartJs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/google`,
    Component: <GoogleChart />,
  },

  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards`,
    Component: <BasicCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards`,
    Component: <CreativeCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/draggingCards`,
    Component: <DraggingCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard`,
    Component: <TabCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`,
    Component: <Carousel />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`,
    Component: <BreadcrumbsClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper`,
    Component: <ImageCroppers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`,
    Component: <Scrollables />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`,
    Component: <BootstrapNotify />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rating`,
    Component: <RatingClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`,
    Component: <DropzoneClass />,
  },
  // {
  //     path: `${process.env.PUBLIC_URL}/bonus-ui/tourComponent`, Component: <Tours />
  // },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`,
    Component: <Ribbons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider`,
    Component: <RangeSlider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`,
    Component: <Pagination />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`,
    Component: <ImageUpload />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes`,
    Component: <Sticky />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`,
    Component: <TimeLines />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/sweetAlert`,
    Component: <SweetAlerts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/treeview`,
    Component: <TreeViews />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp`,
    Component: <DragAndDrop />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/step`,
    Component: <Steps />,
  },
  {
    path: `${process.env.PUBLIC_URL}/site/addSite`,
    Component: <AddSite />,
  },
  {
    path: `${process.env.PUBLIC_URL}/sites`,
    Component: <SiteList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/site/linksite`,
    Component: <AddSLinkUserwithSite />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboards`,
    Component: <UserDashboard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/details`,
    Component: <CompanyAssetOwner />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assetOwner`,
    Component: <AssetOwnerList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/serviceGroups`,
    Component: <ServiceGroupDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/formDetails`,
    Component: <FormDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assetcategory`,
    Component: <CategoriesDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assetcategory/add-forms`,
    Component: <AddForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assets`,
    Component: <Assets />,
  },
  {
    path: `${process.env.PUBLIC_URL}/catassetlist`,
    Component: <CategoryListAssets />,
  },
  {
    path: `${process.env.PUBLIC_URL}/asset/add`,
    Component: <AddAssets />,
  },
  {
    path: `${process.env.PUBLIC_URL}/asset/deleteList`,
    Component: <ListDeletedAsset />,
  },
  {
    path: `${process.env.PUBLIC_URL}/asset/view`,
    Component: <ViewAssetContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assettemplates`,
    Component: <AssetTemplate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assettemplates/add`,
    Component: <AddAssetTemplate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/userpanels`,
    Component: <UserPanel />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assetsLists`,
    Component: <CategoryAssets />,
  },
  {
    path: `${process.env.PUBLIC_URL}/formsLists`,
    Component: <FormsList />,
  },
  { path: `${process.env.PUBLIC_URL}/userpanel`, Component: <SiteUserPanel /> },
  {
    path: `${process.env.PUBLIC_URL}/userpanel/assettable/:category`,
    Component: <AssetTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/userpanel/addeditLogbook/`,
    Component: <AddEditLogBook />,
  },
  {
    path: `${process.env.PUBLIC_URL}/formsLists/addeditTesting/`,
    Component: <AddEditTesting />,
  },
  {
    path: `${process.env.PUBLIC_URL}/userpanel/addeditRepairs/`,
    Component: <AddEditRepairs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/reports`,
    Component: <GenerateReportsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tasksao`,
    Component: <TasksAOContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tasksaosp`,
    Component: <TasksAOContainAoSp />,
  },
  {
    path: `${process.env.PUBLIC_URL}/taskssp`,
    Component: <TasksAOContainSp />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tasksao/addtasks`,
    Component: <AddTasksAOContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/addtasks/:id`,
    Component: <AddTasksAOContain />,
  },
  //SiteOwnerPanel--- serviceProvider----

  {
    path: `${process.env.PUBLIC_URL}/siteownerpanel/serviceprovider`,
    Component: <ServiceProvider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/siteownerpanel/pendingserviceprovider`,
    Component: <PendingServiceProvider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/sites/pendingsiteuser`,
    Component: <PendingSiteUser />,
  },
  { path: `${process.env.PUBLIC_URL}/addedit/:id`, Component: <AddEditSP /> },

  {
    path: `${process.env.PUBLIC_URL}/addedit/:id/:view`,
    Component: <AddEditSP />,
  },

  //SiteOwnerPanel--- Site User----

  {
    path: `${process.env.PUBLIC_URL}/siteownerpanel/siteUser`,
    Component: <SiteUser />,
  },

  {
    path: `${process.env.PUBLIC_URL}/addeditsiteuser/:id`,
    Component: <AddEditSiteUser />,
  },

  {
    path: `${process.env.PUBLIC_URL}/addeditsiteuser/:id/:view`,
    Component: <AddEditSiteUser />,
  },

  //site OwnerPanel----- CompanyDetails id
  {
    path: `${process.env.PUBLIC_URL}/viewdetails/:id`,
    Component: <ViewCompanyDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/viewdetails/:id/:view`,
    Component: <ViewCompanyDetails />,
  },
  //Asset Owner Details--------------------------
  {
    path: `${process.env.PUBLIC_URL}/viewAssetOwnerdetails/`,
    Component: <AddAssetOwner />,
  },
  {
    path: `${process.env.PUBLIC_URL}/viewAssetOwnerdetails/:id`,
    Component: <AddAssetOwner />,
  },
  {
    path: `${process.env.PUBLIC_URL}/viewAssetOwnerdetails/:id/:view`,
    Component: <AddAssetOwner />,
  },
  {
    path: `${process.env.PUBLIC_URL}/notificationConfig`,
    Component: <NotificationConfiguration />,
  },
  {
    path: `${process.env.PUBLIC_URL}/subscriptions`,
    Component: <Subscriptions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/subscriptionslistadmin`,
    Component: <SubscriptionsListAdmin />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bulkimport`,
    Component: <BulkDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bulkimportadmin`,
    Component: <BulkAdminDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/testing`,
    Component: <TestingFail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/generatereportfasset`,
    Component: <ReportAssetOwnerReportDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/generatereportfasset/assetslogbook`,
    Component: <AssetLogbookOwnerDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/sites/siteview`,
    Component: <SiteView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/sites/listsubcat`,
    Component: <CategoriesSubList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboardsao`,
    Component: <AODashboard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/certificates`,
    Component: <CatCertContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/catCertificates`,
    Component: <CatRelatedCertiDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/reports/catList`,
    Component: <CatListContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/addcertificates`,
    Component: <AddCertificates />,
  },
  {
    path: `${process.env.PUBLIC_URL}/testResult`,
    Component: <SiteTestResult />,
  },
  {
    path: `${process.env.PUBLIC_URL}/thermalReport`,
    Component: <ReportImagingContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/addReport`,
    Component: <AddReportContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assetscount`,
    Component: <AssetsCountt />,
  },
  {
    path: `${process.env.PUBLIC_URL}/transactions`,
    Component: <Transactions />,
  },
];
