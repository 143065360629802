import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import NotificationConfigurationContain from '../../Component/NotificationConfiguration/index';

const NotificationConfiguration = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="NotificationConfiguration" />
      <NotificationConfigurationContain />
    </Fragment>
  );
};
export default NotificationConfiguration;