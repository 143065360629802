/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_mobile_analytics_app_id": "415e470ec9f84e7e9a9f12256a15c8d5",
    "aws_mobile_analytics_app_region": "ap-southeast-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "415e470ec9f84e7e9a9f12256a15c8d5",
            "region": "ap-southeast-2"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "415e470ec9f84e7e9a9f12256a15c8d5",
                "region": "ap-southeast-2"
            }
        },
        "Email": {
            "AWSPinpoint": {
                "appId": "415e470ec9f84e7e9a9f12256a15c8d5",
                "region": "ap-southeast-2"
            }
        }
    },
    "aws_appsync_graphqlEndpoint": "https://vrybdnvke5eexlrsszq2ueem7u.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ox6gomta4baghjuzk6hpogw25y",
    "aws_cognito_identity_pool_id": "ap-southeast-2:ce7f8b2c-d240-45d8-af33-19698ba3565c",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_Y8sY9qqTz",
    "aws_user_pools_web_client_id": "6sfrm9ft2flhji5iuj2i0u3u11",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "kya-20230610153230-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d1edl3dim4ep60.cloudfront.net",
    "aws_user_files_s3_bucket": "kyauploadimages133428-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
