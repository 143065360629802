import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import SiteListDetails from '../../../Component/Site/SiteList';

const SiteList = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Site" title="Manage Sites" />
      <SiteListDetails />
    </Fragment>
  );
};
export default SiteList;