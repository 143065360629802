import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import PendingServiceProviderDetails from '../../Component/SiteOwnerPanel/pendingServiceProvider';

const PendingServiceProvider = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Service Providers" title="Pending Service Providers" />
      <PendingServiceProviderDetails />
    </Fragment>
  );
};
export default PendingServiceProvider;